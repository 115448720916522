/* eslint-disable react/prop-types */
import { Box, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import globalClasses from 'styles/globalStyle.module.scss';

import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const EmpInfoForm = ({
  control,
  errors,
  setValue,
  inputRef,
  addressLookupData,
  addressLookupDataResponse,
  clearErrors,
}) => {
  const [watchEmpAddress1] = useWatch({
    control,
    name: ['empAddressLine1', 'empAddressLine2', 'empZipcode', 'empCity'],
  });

  const handleAutoCompleteData = (selectedObj) => {
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City } = selectedObj?.Address;
      console.log('AddressLine1, PostalCode, City', AddressLine1, PostalCode, City);

      setValue('empAddressLine1', AddressLine1);
      setValue('empZipcode', PostalCode.split('-')[0]);

      City = cityList.find((x) => x.value === City)?.value || '';
      console.log('city', City);
      setValue('empCity', City);
      clearErrors(['empAddressLine1', 'empZipcode', 'empCity']);
    } else {
      setValue('empAddressLine1', '');
      setValue('empZipcode', '');
      setValue('empCity', '');
    }
  };

  const { occupationList, empStatusList, cityList, industryList } = useContext(AuthContext);

  return (
    <>
      <CustomCollapse heading="Employment Information" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Employment status"
              control={control}
              name="empStatus"
              errors={!!errors?.empStatus}
              errorText={errors?.empStatus?.message}
              options={empStatusList}
              defaultValue=""
              required
              displayEmpty
              inputRef={inputRef}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.empName}
              errorText={errors?.empName?.message}
              placeholder="Enter text"
              label="Employer Name"
              name="empName"
              regex={/^[a-z0-9A-Záéíóúñü¿¡\s]*$/}
              maxLength="50"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomAutoComplete
              id="empAddressLine1"
              name="empAddressLine1"
              flexDirection="column"
              control={control}
              dataTestIdAutocomplete="autocomplete_address1"
              dataTestIdForLabel="empAddressLine1"
              dataTestIdForInput="empAddressLine1"
              required
              regex={/^[A-Za-z0-9áéíóúñü¿¡,./-\s]$/}
              label="Employer Address Line 1"
              options={
                addressLookupData && watchEmpAddress1 && watchEmpAddress1?.length >= 6
                  ? addressLookupData
                  : []
              }
              handleChange={(_event, selectedValue) => {
                if (watchEmpAddress1 && watchEmpAddress1.length >= 6) {
                  const index = addressLookupData.indexOf(selectedValue);
                  const selectedObj = addressLookupDataResponse[index];
                  handleAutoCompleteData(selectedObj);
                }
              }}
              maxLength={40}
              autoFocus
              placeholder="Enter text"
              fullWidth
              error={!!errors?.empAddressLine1}
              errorText={errors?.empAddressLine1?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.empAddressLine2}
              errorText={errors?.empAddressLine2?.message}
              placeholder="Enter text"
              label="Employer Address Line 2"
              name="empAddressLine2"
              flexDirection="column"
              regex={/^[A-Za-z0-9áéíóúñü¿¡,./-\s]$/}
              maxLength="20"
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Country"
              control={control}
              name="empCountry"
              errors={!!errors?.empCountry}
              errorText={errors?.empCountry?.message}
              options={[
                {
                  name: 'United States',
                  value: 'USA',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
              isDisabled
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="State"
              control={control}
              name="empState"
              errors={!!errors?.empState}
              errorText={errors?.empState?.message}
              options={[
                {
                  name: 'PR',
                  value: 'PR',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
              isDisabled
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="City"
              control={control}
              name="empCity"
              errors={!!errors?.empCity}
              errorText={errors?.empCity?.message}
              options={cityList}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.empZipcode}
              errorText={errors?.empZipcode?.message}
              placeholder="Enter text"
              label="Zipcode"
              name="empZipcode"
              regex={/^[0-9]$/}
              maxLength="5"
              type="text"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.jobTitle}
              errorText={errors?.jobTitle?.message}
              placeholder="Enter text"
              label="Job Title"
              // regex={/^[a-zA-Záéíóúñü¿¡\s]*$/g}
              maxLength="30"
              type="text"
              name="jobTitle"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomAutoComplete
              id="occupation"
              name="occupation"
              flexDirection="column"
              control={control}
              dataTestIdAutocomplete="autocomplete_occupation"
              dataTestIdForLabel="occupation"
              dataTestIdForInput="occupation"
              required
              label="Occupation"
              options={occupationList?.length > 0 && occupationList?.map((x) => x?.name)}
              handleChange={(_event, selectedValue) => {
                console.log('selected val', selectedValue);
                setValue('occupation', selectedValue);
              }}
              maxLength={50}
              autoFocus
              placeholder="Enter text"
              fullWidth
              error={errors?.occupation}
              errorText={errors?.occupation?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              control={control}
              options={industryList}
              errors={!!errors?.industry}
              errorText={errors?.industry?.message}
              placeholder="Enter text"
              regex={/^[a-zA-Záéíóúñü¿¡\s]*$/g}
              label="Industry"
              name="industry"
              required
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <InputLabel htmlFor={name}>
              <span style={{ color: 'red' }}> *&nbsp;</span>Time Under Current Employer Name
            </InputLabel>

            <Grid item lg={12} md={12} sm={12} xs={12} container gap={1} alignItems="flex-start">
              <Grid
                item
                lg={5.5}
                md={5.5}
                sm={12}
                xs={12}
                display="flex"
                alignItems="center"
                sx={{
                  gap: '16px',
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" gap={1} alignItems="center">
                    <CustomNumberFormat
                      name="empYears"
                      id="empYears"
                      regex={/^[0-9]$/}
                      dataTestId="empYears"
                      placeholder="0"
                      control={control}
                      error={!!errors?.empYears}
                      maxLength={2}
                    />
                    <Typography variant="subtitle2" component="p">
                      Years
                    </Typography>
                  </Box>
                  {!!errors?.empYears && (
                    <FormHelperText gap={2} className={globalClasses.customSelectError}>
                      {' '}
                      {errors?.empYears?.message}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                lg={5.5}
                md={5.5}
                sm={12}
                xs={12}
                display="flex"
                alignItems="center"
                sx={{
                  gap: '16px',
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" gap={1} alignItems="center">
                    <CustomNumberFormat
                      name="empMonths"
                      id="empMonths"
                      regex={/^[0-9]$/}
                      dataTestId="empMonths"
                      placeholder="0"
                      control={control}
                      error={!!errors?.empMonths}
                      maxLength={2}
                    />
                    <Typography variant="subtitle2" component="p">
                      Months
                    </Typography>
                  </Box>
                  {!!errors?.empMonths && (
                    <FormHelperText gap={2} className={globalClasses.customSelectError}>
                      {' '}
                      {errors?.empMonths?.message}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="monthlyIncome"
              id="monthlyIncome"
              dataTestId="monthlyIncome"
              placeholder="Enter value"
              label="Gross Monthly Income"
              control={control}
              error={!!errors?.monthlyIncome}
              errorText={errors?.monthlyIncome?.message}
              thousandSeparator={true}
              prefix={'$ '}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name="mobile"
              areaCodeName="areaCode"
              label="Work Phone"
              flexDirection="column"
              control={control}
              error={!!errors?.mobile}
              errorText={errors?.mobile?.message}
              error2={!!errors?.areaCode}
              errorText2={errors?.areaCode?.message}
              required
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </>
  );
};
export default EmpInfoForm;
