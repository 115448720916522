import * as yup from 'yup';

export const userRequestSchema = yup.object().shape({
  firstName: yup
    .string()
    .required('* Mandatory Field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),

  lastName: yup
    .string()
    .required('* Mandatory Field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),

  secondLastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val ? val?.length <= 25 : true))
    .matches(/^[a-zA-Záéíóúñü¿¡]*$/, '* Invalid format'),
  mobileDealer: yup
    .string()
    .required('* Mandatory field')
    .test('length', '* Mobile number should have 7 digits', (val) => {
      return val?.length === 7;
    })
    .matches(/^[0-9]+$/g),
  areaCodeDealer: yup
    .string()
    .required('* Area code is mandatory')
    .matches(/^[0-9]+$/g),
  mobileUser: yup
    .string()
    .required('* Mandatory field')
    .test('length', '* Mobile number should have 7 digits', (val) => {
      return val?.length === 7;
    })
    .matches(/^[0-9]+$/g),
  areaCodeUser: yup
    .string()
    .required('* Area code is mandatory')
    .matches(/^[0-9]+$/g),
  email: yup
    .string()
    .required('* Mandatory Field')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i, '*Invalid email format')
    .max(319, '* Invalid email length')
    .test('len', 'error', (val) => {
      const value = val && val?.split('@');
      return value?.[0]?.length <= 64 && value[1]?.length <= 255;
    }),
  ssn: yup
    .string()
    .required('*Mandatory Field')
    .matches(/^[0-9]+$/g, '* Invalid format')
    .test('length', '* Social security number should have 9 digit', (val) => val?.length === 9),
  dob: yup
    .date()
    .transform((value) => {
      return value && !isNaN(value.getTime()) ? new Date(value) : null;
    })
    .nullable()
    .required('*Mandatory Field')
    .max(new Date(), 'Please enter a valid dob')
    .test(
      'age',
      'User must be 18 years or older',
      (value) => value && new Date().getFullYear() - new Date(value)?.getFullYear() >= 18
    ),
  accessType: yup.string().required('*Mandatory Field'),
  dealer: yup.string().required('*Mandatory Field'),
});
