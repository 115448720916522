/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import AuthContext from 'context/AuthContext';
import { useContext } from 'react';

import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';

const AdditionalIncome = ({ control, errors, setShowAdditionalIncome, setValue }) => {
  const { occupationList } = useContext(AuthContext);
  return (
    <>
      <CustomCollapse
        heading="Additional Income"
        open={true}
        setShowAdditionalIncome={setShowAdditionalIncome}
        removeButton
      >
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.addEmpName}
              errorText={errors?.addEmpName?.message}
              regex={/^[a-z0-9A-Záéíóúñü¿¡,./-\s]*$/}
              maxLength="50"
              placeholder="Enter text"
              label="Employer Name"
              name="addEmpName"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.addJobTitle}
              errorText={errors?.addJobTitle?.message}
              placeholder="Enter text"
              label="Job Title"
              // regex={/^[a-zA-Záéíóúñü¿¡\s]*$/g}
              maxLength="30"
              name="addJobTitle"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomAutoComplete
              id="addOccupation"
              name="addOccupation"
              flexDirection="column"
              control={control}
              dataTestIdAutocomplete="autocomplete_addOccupation"
              dataTestIdForLabel="addOccupation"
              dataTestIdForInput="addOccupation"
              required
              label="Occupation"
              options={occupationList?.length > 0 && occupationList?.map((x) => x?.name)}
              handleChange={(_event, selectedValue) => {
                setValue('addOccupation', selectedValue);
              }}
              maxLength={50}
              autoFocus
              placeholder="Enter text"
              fullWidth
              error={errors?.addOccupation}
              errorText={errors?.addOccupation?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name="mobile2"
              areaCodeName="addAreaCode"
              label="Work Phone"
              flexDirection="column"
              control={control}
              error={!!errors?.mobile2}
              errorText={errors?.mobile2?.message}
              error2={!!errors?.addAreaCode}
              errorText2={errors?.addAreaCode?.message}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="addMonthlyIncome"
              id="addMonthlyIncome"
              dataTestId="addMonthlyIncome"
              placeholder="Enter value"
              label="Gross Monthly Income"
              control={control}
              error={!!errors?.addMonthlyIncome}
              errorText={errors?.addMonthlyIncome?.message}
              thousandSeparator={true}
              prefix={'$ '}
              required
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </>
  );
};
export default AdditionalIncome;
