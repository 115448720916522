import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import styledclass from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

export const FinancingDetails = ({ monthlyPayData, calculatedData }) => {
  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>Financed Fees</TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {' '}
                {numberToCurrency(monthlyPayData?.financedFees)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>
                Registration Fees
              </TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.registrationFees)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>
                Balance Amount Finance
              </TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.amountFinanced)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>Net Trade In</TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.netTradeIn)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>Finance Charge</TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.financeCharge)}
              </TableCell>
            </TableRow>
            {calculatedData?.formData?.programVal !== 'Conventional' && (
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Balloon Payment
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(monthlyPayData?.baloonPayment)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>
                Non-Financed Fees
              </TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {numberToCurrency(monthlyPayData?.nonFinancedFees)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell classes={{ root: styledclass.tableHeadingList }}>APR</TableCell>
              <TableCell classes={{ root: styledclass.currencyValue }}>
                {calculatedData?.formData?.rate} %
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={5}>
        <Typography
          variant="subtitle1"
          component="h6"
          my={2}
          // color="#fff"
          sx={{ fontWeight: 700 }}
        >
          Payment Schedule
        </Typography>
        <Table aria-label="simple table" className={styledclass.tableContainer}>
          <TableBody>
            <TableRow>
              <TableCell classes={{ root: styledclass.TableHeading }}>No. of Payments</TableCell>
              <TableCell classes={{ root: styledclass.TableHeading }}>Payment Amount</TableCell>
              <TableCell classes={{ root: styledclass.TableHeading }}>Start Date</TableCell>
            </TableRow>
          </TableBody>
          <TableBody>
            {monthlyPayData?.paymentSchedules?.map((row) => (
              <TableRow key={row?.startDate}>
                <TableCell classes={{ root: { padding: '10px' } }}>
                  {row?.noOfPayments || row?.NoOfPayments}
                </TableCell>
                <TableCell classes={{ root: { padding: '10px' } }}>
                  {row?.paymentAmount
                    ? numberToCurrency(row?.paymentAmount?.toFixed?.(2))
                    : numberToCurrency((+row?.Amount)?.toFixed?.(2))}
                </TableCell>
                <TableCell classes={{ root: { padding: '10px' } }}>
                  {row?.startDate || row?.StartDate}
                </TableCell>
              </TableRow>
            )) || (
              <TableRow>
                <TableCell classes={{ root: { padding: '10px' } }}>--</TableCell>
                <TableCell classes={{ root: { padding: '10px' } }}>--</TableCell>
                <TableCell classes={{ root: { padding: '10px' } }}>--</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default FinancingDetails;

FinancingDetails.propTypes = {
  monthlyPayData: PropTypes.instanceOf(Object),
  calculatedData: PropTypes.instanceOf(Object),
};
FinancingDetails.defaultProps = {
  calculatedData: {},
  monthlyPayData: {},
};
