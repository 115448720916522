/* eslint-disable no-unused-vars */
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import CustomCollapse from 'components/CustomCollapse';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import { SSN } from 'components/SSN';

const BasicInformation = ({ control, errors, maritalStatusList, setValue, clearErrors }) => {
  const watchSSN = useWatch({
    control,
    name: 'ssn',
  });
  console.log('watchSSN', watchSSN);
  const handleKeyDownSSN = (value) => {
    if (value.match(/^\d/)) {
      console.log('value', value);
      setValue('ssn', value);
      clearErrors('ssn');
    } else if (value === '') {
      setValue('ssn', value);
    }
  };

  return (
    <div>
      <CustomCollapse heading="Basic Information" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.firstName}
              errorText={errors?.firstName?.message}
              maxLength="15"
              regex={/^[A-Za-záéíóúñü¿¡\s]$/}
              placeholder="Enter text"
              label="First Name"
              name="firstName"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.lastName}
              errorText={errors?.lastName?.message}
              maxLength="25"
              regex={/^[A-Za-záéíóúñü¿¡\s]$/}
              placeholder="Enter text"
              label="Last Name"
              name="lastName"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.middleName}
              errorText={errors?.middleName?.message}
              maxLength="20"
              regex={/^[A-Za-záéíóúñü¿¡\s]$/}
              placeholder="Enter text"
              name="middleName"
              label="Middle initial"
              flexDirection="column"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.secondLastName}
              errorText={errors?.secondLastName?.message}
              maxLength="25"
              regex={/^[A-Za-záéíóúñü¿¡\s]$/}
              placeholder="Enter text"
              label="Second Last Name"
              name="secondLastName"
              flexDirection="column"
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomDatePicker
              name="dob"
              label="Date of Birth"
              control={control}
              error={!!errors?.dob}
              errorText={errors?.dob?.message}
              required
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name="mobile"
              areaCodeName="areaCode"
              label="Mobile"
              flexDirection="column"
              control={control}
              error={!!errors?.mobile}
              errorText={errors?.mobile?.message}
              error2={!!errors?.areaCode}
              errorText2={errors?.areaCode?.message}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name="mobile2"
              areaCodeName="areaCode2"
              label="Alternate Phone Number"
              flexDirection="column"
              control={control}
              error={!!errors?.mobile2}
              errorText={errors?.mobile2?.message}
              error2={!!errors?.areaCode2}
              errorText2={errors?.areaCode2?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {/* <>
              <Controller
                control={control}
                name="ssn"
                render={({ field }) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={0.625}
                    my={0.5}
                    flexDirection="column"
                  >
                    <InputLabel htmlFor="ssn">
                      <span style={{ color: 'red' }}>*&nbsp;</span> Social Security Number
                    </InputLabel>
                    <TextField
                      {...field}
                      id="ssn"
                      name="ssn"
                      InputProps={{
                        'data-testid': 'ssn',

                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowMask(!showMask)} edge="end">
                              {showMask ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        value:
                          showMask && field?.value
                            ? `${field?.value?.replace(/^\d{5}/, 'XXXXX')}`
                            : field?.value,
                        maxLength: '9',
                      }}
                      placeholder="Enter value"
                    />
                  </Box>
                )}
              />
              {!!errors?.ssn && (
                <FormHelperText gap={2} className={globalClasses.customSelectError}>
                  {' '}
                  {errors?.ssn?.message}
                </FormHelperText>
              )}
            </> */}
            <SSN handleKeyDown={handleKeyDownSSN} ssn={watchSSN} errors={errors} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.email}
              errorText={errors?.email?.message}
              placeholder="Enter text"
              label="Email"
              flexDirection="column"
              name="email"
              required
            />
          </Grid>

          {/* <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.numberOfDependents}
              errorText={errors?.numberOfDependents?.message}
              placeholder="Enter text"
              label="Number of Dependants"
              flexDirection="column"
              name="numberOfDependents"
              type="number"
            />
          </Grid> */}

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              control={control}
              options={maritalStatusList}
              label="Marital Status"
              required
              name="maritalStatus"
              errors={!!errors?.maritalStatus}
              errorText={errors?.maritalStatus?.message}
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};
export default BasicInformation;

BasicInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  maritalStatusList: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
};
BasicInformation.defaultProps = {
  control: {},
  errors: {},
  maritalStatusList: [],
  setValue: () => {},
  clearErrors: () => {},
};
