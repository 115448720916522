/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import Add from 'assets/svg/AddIcon.svg';
import Remove from 'assets/svg/RemoveIcon.svg';
import LeftArrow from 'assets/svg/leftArrow.svg';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';

const CoAppDetailDialog = ({
  reset,
  setValue,
  control,
  errors,
  watch,
  setAddCoSigner,
  addCoSigner,
  coSignerContainer,
  setCosignerContainer,
  clearErrors,
  setCosignerEnabled,
  handleSubmit,
  fields,
  append,
  remove,
}) => {
  const { showCoAppDet, setShowCoAppDet, setShowInviManual, setShowCoApp, setCreateAppSchema1 } =
    useContext(CreateApplicationContext);
  const { setOpenPopUp } = useContext(AuthContext);

  const watchApplicantOrSigner = watch('applicantOrSigner');

  const handleClose = () => {
    setShowCoAppDet(false);
    setOpenPopUp(false);
    reset();
  };
  const handleContinue = (data) => {
    setShowCoAppDet(false);
    setShowInviManual(true);

    setCreateAppSchema1(data);
  };
  const handleBack = () => {
    setShowCoAppDet(false);
    setShowCoApp(true);
  };

  const AddRemoveBtn = (string, icon) => {
    return (
      <Box display="flex" alignItems="center">
        <img src={icon} alt="Add Button" style={{ marginRight: '8px' }} /> {string}
      </Box>
    );
  };

  return (
    <>
      <Dialog
        open={showCoAppDet}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Box display="flex" alignItems="center">
            <img src={LeftArrow} onClick={handleBack} />
            <Typography variant="h3" component="span">
              &nbsp;Create Application
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <Box display="flex" flexDirection="column" gap={1} px={5} mb={2.5}>
            <Typography variant="h4" textAlign="center" mt={3}>
              Ok! Add your co-applicant / co-signer details
            </Typography>

            {watchApplicantOrSigner?.includes('coApplicant') && (
              <Grid container>
                <Grid container gap={1} justifyContent="space-between">
                  <Grid item lg={12} md={12} sm={12}>
                    <Box className={classes.heading}>
                      <Typography
                        variant="h6"
                        component="h3"
                        my={2}
                        style={{ fontWeight: 700, margin: '0' }}
                      >
                        Co-Applicant
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={5.5} md={6} sm={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.CoAppFirstName}
                      errorText={errors?.CoAppFirstName?.message}
                      placeholder="Enter text"
                      label="First Name"
                      name="CoAppFirstName"
                      maxLength="15"
                      regex={/^[A-Za-záéíóúñü¿¡\s]$/}
                      flexDirection="column"
                      required
                    />
                  </Grid>
                  <Grid item lg={5.5} md={6} sm={12}>
                    <CustomInputBox
                      control={control}
                      errors={!!errors?.CoAppLastName}
                      errorText={errors?.CoAppLastName?.message}
                      placeholder="Enter text"
                      label="Last Name"
                      name="CoAppLastName"
                      maxLength="25"
                      regex={/^[A-Za-záéíóúñü¿¡\s]$/}
                      flexDirection="column"
                      required
                    />
                  </Grid>
                </Grid>
                {watchApplicantOrSigner?.includes('coApplicant') &&
                  !watchApplicantOrSigner?.includes('coSigner') && (
                    <Box sx={{ margin: '40px 0' }}>
                      <Link
                        component="button"
                        variant="subtitle2"
                        color="secondary"
                        data-testid="addRemoveBtn_testid"
                        onClick={() => {
                          setCosignerContainer(!coSignerContainer);
                          if (!coSignerContainer) {
                            setCosignerEnabled(true);
                            setValue('CoSigner', [
                              { CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' } },
                            ]);
                          } else {
                            remove(1);
                            setAddCoSigner(false);
                            clearErrors([
                              'CoSigner[0].CoSignerName.CoSignerFirstName',
                              'CoSigner[0].CoSignerName.CoSignerLastName',
                            ]);
                            setValue('CoSigner', [
                              { CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' } },
                            ]);
                            setCosignerEnabled(false);
                          }
                        }}
                      >
                        {!coSignerContainer && !watchApplicantOrSigner?.includes('coSigner')
                          ? AddRemoveBtn('Add CoSigner(s)', Add)
                          : AddRemoveBtn('Remove CoSigner(s)', Remove)}
                      </Link>
                    </Box>
                  )}
              </Grid>
            )}

            {(watchApplicantOrSigner?.includes('coSigner') || coSignerContainer) && (
              <Grid container>
                <Grid container gap={0.5} justifyContent="space-between">
                  <Grid item lg={12} md={12} sm={12}>
                    <Box className={classes.heading}>
                      <Typography
                        variant="h6"
                        component="h3"
                        my={2}
                        style={{ fontWeight: 700, margin: '0' }}
                      >
                        Co-Signer(s)
                      </Typography>
                    </Box>
                  </Grid>
                  {fields.map((fieldss, index) => {
                    return (
                      <>
                        <Grid item lg={5.5} md={6} sm={12} key={fieldss.id}>
                          <CustomInputBox
                            control={control}
                            errors={
                              !!errors?.CoSigner?.[index]?.CoSignerName?.CoSignerFirstName || false
                            }
                            errorText={
                              errors?.CoSigner?.[index]?.CoSignerName?.CoSignerFirstName?.message ||
                              ''
                            }
                            maxLength="15"
                            regex={/^[A-Za-záéíóúñü¿¡\s]$/}
                            placeholder="Enter text"
                            label="First Name"
                            name={`CoSigner[${index}].CoSignerName.CoSignerFirstName`}
                            flexDirection="column"
                            required
                          />
                        </Grid>
                        <Grid item lg={5.5} md={6} sm={12}>
                          <CustomInputBox
                            control={control}
                            errors={
                              !!errors?.CoSigner?.[index]?.CoSignerName?.CoSignerLastName || false
                            }
                            errorText={
                              errors?.CoSigner?.[index]?.CoSignerName?.CoSignerLastName?.message ||
                              ''
                            }
                            maxLength="25"
                            regex={/^[A-Za-záéíóúñü¿¡\s]$/}
                            placeholder="Enter text"
                            label="Last Name"
                            name={`CoSigner[${index}].CoSignerName.CoSignerLastName`}
                            flexDirection="column"
                            required
                          />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Box sx={{ margin: '40px 0' }}>
                  <Link
                    component="button"
                    variant="subtitle2"
                    color="secondary"
                    data-testid="coSignerAddBtn"
                    onClick={() => {
                      setAddCoSigner(!addCoSigner);
                      if (!addCoSigner) {
                        append({ CoSignerFirstName: '', CoSignerLastName: '' });
                      } else if (addCoSigner) {
                        remove(1);
                      }
                    }}
                  >
                    {!addCoSigner ? AddRemoveBtn('Add', Add) : AddRemoveBtn('Remove', Remove)}
                  </Link>
                </Box>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={handleSubmit(handleContinue)}
          >
            {!watchApplicantOrSigner ? 'Skip & Continue' : 'Continue'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoAppDetailDialog;
