import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { nameMapping } from 'utils/utility';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const LookupSecondDialog = ({ control, errors, isValid, handleSubmit, onNext, reset }) => {
  const {
    showSecondaryPopup,
    setShowSecondaryPopup,
    setShowMainPopup,
    setShowInterPopup,
    yearList,
    makeList,
    modelList,
    subModelList,
    styleList,
    stateList,
  } = useContext(AuthContext);
  const handleBack = () => {
    setShowSecondaryPopup(false);
    if (watchCollateralType === 'used') {
      setShowInterPopup(true);
    } else {
      setShowMainPopup(true);
    }
  };

  const handleClose = () => {
    reset();
    setShowSecondaryPopup(false);
  };

  const [
    watchCollateralType,
    watchValueGuide,
    watchyear,
    watchmake,
    watchModel,
    watchSubModel,
    watchlookupType,
    watchStyle,
  ] = useWatch({
    control,
    name: [
      'collateralType',
      'valueGuide',
      'year',
      'make',
      'model',
      'subModel',
      'lookuptype',
      'style',
      'vin',
      'state',
      'mileage',
    ],
  });
  console.log(
    'in popup 2  selected collateral values',
    watchCollateralType,
    watchValueGuide,
    watchlookupType
  );
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={showSecondaryPopup}
      sx={{ minHeight: '80%' }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box display="flex" gap={2}>
          <KeyboardBackspaceIcon onClick={handleBack} sx={{ cursor: 'pointer' }} />
          <Typography variant="h3">Lookup</Typography>
        </Box>
        <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
      </BootstrapDialogTitle>
      <DialogContent>
        <Typography
          variant="h5"
          sx={{ fontWeight: 700 }}
        >{`${nameMapping[watchValueGuide]} - ${nameMapping[watchCollateralType]}`}</Typography>
        <Box display="flex" flexDirection="column" my={2.5} px={5}>
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }} color="primary">
            General Information
          </Typography>
          <Divider sx={{ my: 2 }} />
          {watchlookupType === 'manual' && (
            <Grid container item spacing={1}>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Year"
                    isDisabled={!yearList.length}
                    control={control}
                    name="year"
                    options={yearList}
                    px={1.25}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Make"
                    isDisabled={!watchyear}
                    control={control}
                    name="make"
                    options={makeList}
                    px={1.25}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Model"
                    isDisabled={!watchmake}
                    control={control}
                    name="model"
                    options={modelList}
                    px={1.25}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Sub Model"
                    control={control}
                    name="subModel"
                    options={subModelList}
                    isDisabled={!watchModel}
                    px={1.25}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{ display: watchValueGuide === 'Distributor data' ? 'none' : '' }}
              >
                <Grid item md={6} xs={12}>
                  <CustomSelectBox
                    flexDirection="column"
                    required
                    label="Style"
                    control={control}
                    name="style"
                    options={styleList}
                    isDisabled={!watchSubModel}
                    px={1.25}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomInputBox
                    flexDirection="column"
                    required
                    label="Mileage"
                    name="mileage"
                    control={control}
                    error={!!errors?.mileage}
                    errorText={errors?.mileage?.message}
                    isDisabled={!watchStyle}
                    px={1.25}
                  />
                </Grid>
                {watchCollateralType === 'used' && (
                  <Grid item md={6} xs={12}>
                    <CustomSelectBox
                      flexDirection="column"
                      required
                      label="State"
                      name="state"
                      control={control}
                      options={stateList}
                      defaultValue="PR"
                      error={!!errors?.state}
                      errorText={errors?.state?.message}
                      px={1.25}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', gap: '20px' }}>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onNext)}
          disabled={!isValid}
          variant="contained"
          color="secondary"
        >
          Continue
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};
LookupSecondDialog.propTypes = {
  errors: PropTypes.instanceOf(Object),
  control: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onNext: PropTypes.func,
  reset: PropTypes.func,
  // watch: PropTypes.instanceOf(Object),
};
LookupSecondDialog.defaultProps = {
  control: {},
  errors: {},
  isValid: false,
  handleSubmit: () => {},
  onNext: () => {},
  reset: () => {},
  // watch: {},
};

export default LookupSecondDialog;
