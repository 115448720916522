import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Link } from '@mui/material';
import PropTypes from 'prop-types';

const CreateApplicationFooter = ({
  disabledFlag,
  onBackClick,
  onSaveContinue,
  saveDisabledFlag,
  isSubmit,
  onSaveDraft,
  draftDisabledFlag,
}) => {
  console.log('----save disabled flag---', saveDisabledFlag);
  const saveText = isSubmit ? 'Submit' : 'Continue';
  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" sx={{ cursor: disabledFlag ? 'none' : 'pointer' }}>
        <KeyboardArrowLeftIcon
          sx={{ opacity: disabledFlag ? 0.5 : 1 }}
          color="secondary"
          disabled={disabledFlag}
        />
        <Link
          component="button"
          variant="subtitle2"
          color="secondary"
          disabled={disabledFlag}
          onClick={onBackClick}
        >
          Back
        </Link>
      </Box>
      <Box display="flex" gap={2} py={4}>
        <Link component="button" variant="subtitle2" color="secondary" sx={{ fontSize: '16px' }}>
          Discard Application
        </Link>

        <Button
          color="secondary"
          variant="outlined"
          onClick={onSaveDraft}
          disabled={draftDisabledFlag}
        >
          Save As Draft
        </Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={onSaveContinue}
          disabled={!!saveDisabledFlag}
        >
          {saveText}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateApplicationFooter;

CreateApplicationFooter.propTypes = {
  disabledFlag: PropTypes.bool,
  onBackClick: PropTypes.func,
  onSaveContinue: PropTypes.func,
  saveDisabledFlag: PropTypes.bool,
  isSubmit: PropTypes.bool,
  onSaveDraft: PropTypes.func,
  draftDisabledFlag: PropTypes.bool,
};
CreateApplicationFooter.defaultProps = {
  disabledFlag: false,
  saveDisabledFlag: false,
  onBackClick: () => {},
  onSaveContinue: () => {},
  isSubmit: false,
  onSaveDraft: () => {},
  draftDisabledFlag: false,
};
