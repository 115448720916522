import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import NewUserRequestContext from 'context/NewUserRequestContext';
import moment from 'moment';
// import RegisterUserContext from 'context/RegisterUserContext';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { userRequestSchema } from 'schemaValidations/userRequestSchema';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

import UserRequestInformation from './UserRequestInformation/UserRequestInformation';

// import UserRequestSuccessModal from './UserRequestSuccessModal';

const UserRequest = () => {
  const [loader, setLoader] = useState(false);
  const ApiClient = new ApiClass();
  const [formDetails, setFormDetails] = useState({ dealers: [] });
  let { accessToken, idToken } = useContext(AuthContext);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',

    defaultValues: {
      dealer: '',
      // userId: '',
      mobileDealer: '',
      areaCodeDealer: '787',
      mobileUser: '',
      areaCodeUser: '787',
      firstName: '',
      lastName: '',
      secondLastName: '',
      email: '',
      ssn: '',
      dob: '',
      accessType: '',
      comment: '',
    },
    resolver: yupResolver(userRequestSchema),
  });
  const [
    watchdealer,
    // watchuserId,
    watchmobileDealer,
    watchareaCodeDealer,
    watchmobileUser,
    watchareaCodeUser,
    watchfirstName,
    watchlastName,
    watchsecondLastName,
    watchemail,
    watchssn,
    watchaccessType,
    watchcomment,
    watchdob,
  ] = watch([
    'dealer',
    // 'userId',
    'mobileDealer',
    'areaCodeDealer',
    'mobileUser',
    'areaCodeUser',
    'firstName',
    'lastName',
    'secondLastName',
    'email',
    'ssn',
    'accessType',
    'comment',
    'dob',
  ]);
  console.log({ errors });
  const navigate = useNavigate();

  const nextStep = async () => {
    const formObj = {
      dealer: watchdealer,
      dealerNumber: '73635',
      phone: '+1' + watchareaCodeDealer + watchmobileDealer,
      accessType: watchaccessType,
      firstName: watchfirstName,
      lastName: watchlastName,
      secondLastName: watchsecondLastName,
      ssn: watchssn,
      dob: moment(watchdob).format('YYYY-DD-MM'),
      phoneUser: '+1' + watchareaCodeUser + watchmobileUser,
      email: watchemail,
      comments: watchcomment,
      autherize: true,
      title: 'Manager',
      date: moment().format('YYYY-DD-MM'),
      name: 'John Smith',
    };
    console.log('formObj');
    console.log(formObj);
    setFormDetails({ ...formDetails, ...formObj });
    // setActiveStep(activeStep + 1);
    console.log('.....................................');
    const getIdmToken = async () => {
      setLoader(true);
      const tokenResponse = await ApiClient.post(
        UrlHelpers.accessPolicy,
        {},
        {
          headers: {
            appid: 'auto',
            idtoken: idToken,
            accessType: 'UI',
            accesstoken: accessToken,
            'Content-Type': 'application/json',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
          },
        }
      );
      console.log('tokenResponse..', tokenResponse);
      setLoader(false);
      return tokenResponse;
    };
    const tokenRes = await getIdmToken();
    console.log('tokenRes');
    console.log(tokenRes);
    try {
      setLoader(true);
      const r = await ApiClient.post(
        `${UrlHelpers.newUserRequest}`,
        { ...formObj },
        {
          headers: {
            // appid: 'auto',
            // 'x-api-key': process.env.REACT_APP_X_API_KEY,
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + tokenRes.accessToken,
          },
        }
      );
      // setData(resp);
      console.log('f===================');
      console.log(r);
      navigate('/manage-dealers');
    } catch (e) {
      console.log('kkkkkkkkkk');
      console.log('e', e);
    } finally {
      setLoader(false);
      console.log('finally');
    }
  };
  useEffect(() => {
    console.log(errors);
  }, [errors]);
  return (
    <>
      {loader && <Loader open={loader} />}
      <NewUserRequestContext.Provider
        value={{
          formDetails,
          setFormDetails,
        }}
      >
        <Box py={3} px={2} display="flex" justifyContent="space-between">
          <Typography variant="h3">New User Request</Typography>
        </Box>
        {/* <Loader open={formLoader} /> */}
        <Box display="flex" justifyContent="center">
          <Box sx={{ width: '70%' }}>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              {/* <Typography variant="h4" component="p" py={5} textAlign="center">
                Alright! Let’s create user profile in just 4 steps
              </Typography> */}
              {/* <HorizontalLinearStepper steps={steps} activeStep={activeStep - 1} />
                {getStepComponent[activeStep]} */}
              {/* <Typography variant="h3" component="p" py={4}>
                User Information
              </Typography> */}
              <Grid id="1" container justifyContent="center">
                <Box id="2" sx={{ width: '70%' }}>
                  <UserRequestInformation control={control} errors={errors} />
                </Box>
              </Grid>
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
                  <Link
                    component="button"
                    onClick={() => {
                      navigate('/manage-dealers');
                    }}
                    variant="subtitle2"
                    color="secondary"
                    sx={{ fontSize: '16px' }}
                    data-testid="discard-userinfo-application"
                  >
                    Discard Application
                  </Link>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleSubmit(nextStep)}
                    data-testid="save-continue-userinfo"
                    disabled={!formDetails.authorize || Object.entries(errors)?.length}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </NewUserRequestContext.Provider>
      {/* <UserRequestSuccessModal /> */}
    </>
  );
};

export default UserRequest;
