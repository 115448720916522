import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import classes from 'styles/globalStyle.module.scss';

const Footer = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Toolbar
        sx={{
          bottom: '0',
          width: '100%',
          position: 'fixed',
          backgroundColor: '#E6E6E6',
          display: 'flex',
          justifyContent: 'space-around',
        }}
        classes={{ root: classes.footer }}
      >
        <Typography variant="body2">
          Oriental Financial Services LLC, member FINRA/SIPC, and Oriental Bank are subsidiaries of
          OFG Bancorp.
        </Typography>
        <Typography sx={{ textAlign: 'right' }} variant="body2">
          © 2023 Oriental. All Rights Reserved.
        </Typography>
      </Toolbar>
    </Box>
  );
};

export default Footer;
