import { Box, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';

import CustomCollapse from 'components/CustomCollapse';

const InformationRequestSection = ({ informationRequest, label, cousersData }) => {
  const { maritalStatusList } = useContext(AuthContext);
  const maritalMapping =
    maritalStatusList.find((x) => x.value === informationRequest?.MaritalStatus)?.name || '--';
  const [isSame, setIsSame] = useState('No');
  useEffect(() => {
    if (informationRequest?.Addresses?.length > 0) {
      const isSameAddress = isSameCheck();
      setIsSame(isSameAddress);
    }
  }, [informationRequest?.Addresses?.length]);
  const isSameCheck = () => {
    const newArray =
      informationRequest?.Addresses?.length > 0 &&
      informationRequest?.Addresses.map((x) => {
        let obj = { ...x };
        delete obj?.AddrType;
        delete obj?.UtilityBill;
        return obj;
      });
    const residential = JSON.stringify(newArray?.[0]);
    const mailing = JSON.stringify(newArray?.[1]);
    console.log('same check', residential === mailing, residential, mailing);
    if (residential === mailing) {
      return 'Yes';
    }
    return 'No';
  };
  const { setReviewFlag, setActiveStep, setUserType, setReviewData } =
    useContext(MainApplicationContext);
  const onEdit = () => {
    let data = informationRequest;
    if (label.indexOf('Primary') > -1) {
      setUserType('');
      data = { ...data, ...cousersData };
    } else if (label.indexOf('Co-Applicant') > -1) {
      setUserType('coapplicant');
    } else if (label.indexOf('Co-Signer 1') > -1) {
      setUserType('cosigner1');
    } else if (label.indexOf('Co-Signer 2') > -1) {
      setUserType('cosigner2');
    }
    setReviewData(data);
    setReviewFlag(true);
    setActiveStep(1);
  };
  return (
    <CustomCollapse
      heading={`Information on Request for ${label}`}
      open={true}
      editable={true}
      onEdit={onEdit}
    >
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
        <Typography variant="subtitle2">First Name</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.CustName?.FirstName}
        </Typography>
        <Typography variant="subtitle2">Middle Initial</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.CustName?.MiddleName || '--'}
        </Typography>
        <Typography variant="subtitle2">Last Name</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.CustName?.LastName}
        </Typography>

        <Typography variant="subtitle2">Gender</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Gender}
        </Typography>
        <Typography variant="subtitle2">Date of Birth</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.DateOfBirth}
        </Typography>
        <Typography variant="subtitle2">Mobile</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Contacts?.Mobile}
        </Typography>
        <Typography variant="subtitle2">Alternate Phone Number</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Contacts?.AlternatePhoneNumber || '--'}
        </Typography>
        <Typography variant="subtitle2">Email</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Contacts?.ApplicantEmail}
        </Typography>
        <Typography variant="subtitle2">Social Security Number</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.SSN}
        </Typography>
        <Typography variant="subtitle2">Marital Status</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {maritalMapping}
        </Typography>
      </Box>
      <Typography variant="h6" fontWeight="700 !important" my="20px !important">
        Residential Address
      </Typography>
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
        <Typography variant="subtitle2">Utility Bill</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[0]?.UtilityBill || '--'}
        </Typography>
        <Typography variant="subtitle2">Address Line 1</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[0]?.LineOne}
        </Typography>
        <Typography variant="subtitle2">Address Line 2</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[0]?.LineTwo || '--'}
        </Typography>
        <Typography variant="subtitle2">Country</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[0]?.AddrCountry}
        </Typography>
        <Typography variant="subtitle2">State</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[0]?.AddrState}
        </Typography>
        <Typography variant="subtitle2">City</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[0]?.AddrCity}
        </Typography>
        <Typography variant="subtitle2">Zip Code</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[0]?.ZipCode}
        </Typography>
      </Box>

      <Typography variant="h6" fontWeight="700 !important" my="20px !important">
        Mailing Address
      </Typography>
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
        <Typography variant="subtitle2">Mailing address same as the residential address</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {isSame}
        </Typography>
        <Typography variant="subtitle2">Address Line 1</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[1]?.LineOne}
        </Typography>
        <Typography variant="subtitle2">Address Line 2</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[1]?.LineTwo || '--'}
        </Typography>
        <Typography variant="subtitle2">Country</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[1]?.AddrCountry}
        </Typography>
        <Typography variant="subtitle2">State</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[1]?.AddrState}
        </Typography>
        <Typography variant="subtitle2">City</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[1]?.AddrCity}
        </Typography>
        <Typography variant="subtitle2">Zip Code</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.Addresses?.[1]?.ZipCode}
        </Typography>
      </Box>

      <Typography variant="h6" fontWeight="700 !important" my="20px !important">
        Residential Status
      </Typography>
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
        <Typography variant="subtitle2">Residence Status</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {informationRequest?.ResidentialStatus}
        </Typography>
        <Typography variant="subtitle2">Time at Residence</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {`${informationRequest?.TimeOfResidenceInYears} Years ${informationRequest?.TimeOfResidenceInMonths} Months`}
        </Typography>
      </Box>
    </CustomCollapse>
  );
};

export default InformationRequestSection;

InformationRequestSection.propTypes = {
  informationRequest: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  cousersData: PropTypes.instanceOf(Object),
};
InformationRequestSection.defaultProps = {
  informationRequest: {},
  label: '',
  cousersData: {},
};
