import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import blackbookLogo from 'assets/svg/blackbook.svg';
import carNew from 'assets/svg/car-new.svg';
import carUsed from 'assets/svg/car-used.svg';
import distributordataLogo from 'assets/svg/distributordata.svg';
import AuthContext from 'context/AuthContext';
// import LookupContext from 'context/LookupContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';

const LookupDialog = ({ control, setValue, reset }) => {
  const { showMainPopup, setShowMainPopup, setShowSecondaryPopup, setShowInterPopup } =
    useContext(AuthContext);

  const [watchCollateralType, watchValueGuide] = useWatch({
    control,
    name: ['collateralType', 'valueGuide'],
  });

  console.log('in popup selected collateral values', watchCollateralType, watchValueGuide);
  const handleClose = () => {
    setShowMainPopup(false);
    reset();
  };

  const handleContinue = () => {
    setShowMainPopup(false);
    if (watchCollateralType === 'used' && watchValueGuide === 'Blackbook') {
      setShowInterPopup(true);
    } else {
      setShowSecondaryPopup(true);
    }
  };

  const toggleChange = (e, value) => {
    setValue('collateralType', value);
  };

  const toggleValueGuide = (e, value) => {
    setValue('valueGuide', value);
  };
  const collateralList = [
    { valuee: 'new', displayValue: 'New', logo: carNew },
    { valuee: 'used', displayValue: 'Used', logo: carUsed },
  ];

  const valueGuideList = [
    { valuee: 'Blackbook', displayValue: 'Black Book', logo: blackbookLogo },
    { valuee: 'Distributor data', displayValue: 'Distributor data', logo: distributordataLogo },
  ];

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showMainPopup}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span">
            Lookup
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4">Collateral Type</Typography>

            <CustomToggleButtonGroup
              name="collateralType"
              control={control}
              toggleChange={toggleChange}
              exclusive={true}
              toggleList={collateralList}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4">Value Guide Source</Typography>
            <CustomToggleButtonGroup
              name="valueGuide"
              control={control}
              exclusive={true}
              toggleChange={toggleValueGuide}
              toggleList={valueGuideList}
              disableCheck={watchCollateralType === 'used'}
              disableValue="Distributor data"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={!(watchValueGuide && watchCollateralType)}
          >
            Ok, Continue
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
LookupDialog.propTypes = {
  setValue: PropTypes.func,
  reset: PropTypes.func,
  control: PropTypes.instanceOf(Object),
};
LookupDialog.defaultProps = {
  control: {},
  setValue: () => {},
  reset: () => {},
};

export default LookupDialog;
