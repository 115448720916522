import { Box, Button, Paper, Tab, Tabs, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import GeneralInfo from 'pages/Lookup/components/GeneralInfo';
import PageHeading from 'pages/Lookup/components/PageHeading';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';

import TabPanel from 'components/TabPanel/TabPanel';

import VehicleValueSummaryNew from '../VehicleValueSummaryNew/VehicleValueSummaryNew';
import Residuals from './Residuals';
import StandardOptions from './StandardOptions';

const EquipmentAdjustmentNew = ({
  colors,
  options,
  productDetails,
  addDeductList,
  msrp,
  invoice: invoicee,
  uvc,
  uvcTieBreaker,
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
}) => {
  console.log('addDeductList', addDeductList);
  const [selectedRows, setSelectedRows] = useState([]);
  const { showSummary, setShowSummary, lookupLinkClicked, collateralInfo } =
    useContext(AuthContext);
  const [selectedDeductRows, setSelectedDeductRows] = useState([]);
  const [fields, setFields] = useState({});

  useEffect(() => {
    setSelectedDeductRows([]);
  }, [addDeductList]);
  const rows = colors?.map((x) => {
    const { category, description, retail = 0, invoice = 0 } = x;
    return {
      description,
      category,
      retail,
      invoice,
    };
  });
  const optRows = options.map((x) => {
    const {
      option_category: category,
      option_description: description,
      retail_amount: retail = 0,
      invoice_amount: invoice = 0,
    } = x;
    return {
      description,
      category,
      retail,
      invoice,
    };
  });
  rows.push(...optRows);

  const groupBy = (data, key) =>
    data.reduce((acc, item) => {
      // eslint-disable-next-line no-param-reassign

      const group = item[key];
      if (!acc[group]) {
        // eslint-disable-next-line no-param-reassign
        acc[group] = [];
      }
      acc[group].push(item);
      return acc;
    }, {});
  const uniqueRows = [...new Set(rows.map(JSON.stringify))].map(JSON.parse);
  const groupedByCategory = groupBy(uniqueRows, 'category');

  const { year, make, model, series, style } = productDetails;

  const handleCheckboxChange = (event, row) => {
    const selected = event.target.checked;
    if (selected) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((r) => r.description !== row.description));
    }
  };

  const handleResidualsCheckboxChange = (event, row) => {
    console.log('row', row, event);
    const selected = event.target.checked;
    if (selected) {
      setSelectedDeductRows([...selectedDeductRows, row]);
    } else {
      setSelectedDeductRows(selectedDeductRows.filter((r) => r.description !== row.description));
    }
  };

  console.log('selectedRows', selectedRows);

  const totalmsrp = selectedRows.reduce((acc, currentValue) => acc + currentValue.retail, 0);
  const totalInvoice = selectedRows.reduce((acc, currentValue) => acc + currentValue.invoice, 0);
  const totalResiduals = selectedDeductRows?.reduce(
    (acc, eachObj) => {
      let totall = acc;
      totall.residual_12 += eachObj?.residual_12;
      totall.residual_24 += eachObj?.residual_24;
      totall.residual_36 += eachObj?.residual_36;
      totall.residual_48 += eachObj?.residual_48;
      totall.residual_60 += eachObj?.residual_60;
      return totall;
    },
    { residual_12: 0, residual_24: 0, residual_36: 0, residual_48: 0, residual_60: 0 }
  );
  let mt = '140px';
  if (addDeductList.length === 0 && !showSummary) {
    mt = '70px';
  } else if (showSummary) {
    mt = '38px';
  }
  console.log('fields', fields);
  const onNext = () => {
    setFields({
      productDetails,
      equipmentAdjustment: { invoice: totalInvoice, msrp: totalmsrp },
      residuals: { ...totalResiduals },
      uvc,
      uvcTieBreaker,
      basevehicleValue: { msrp, invoice: invoicee },
    });
    setShowSummary(true);
  };
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  const [tabvalue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper sx={{ minHeight: '80vh', padding: '20px', marginBottom: '60px' }}>
      <PageHeading
        collateralType="New"
        valueGuide="Black Book"
        setShowSummary={setShowSummary}
        setSelectedRows={setSelectedRows}
      />
      <Box display="flex" className={classes.equipAdjustment}>
        {showSummary && (
          <VehicleValueSummaryNew
            equipmentAdjustment={fields?.equipmentAdjustment}
            residuals={fields?.residuals}
            uvc={fields?.uvc}
            uvcTieBreaker={fields?.uvcTieBreaker}
            basevehicleValue={fields?.basevehicleValue}
            selectedRows={selectedRows}
            coApplicant={coApplicant}
            coSigner={coSigner}
            reviewFlag={reviewFlag}
            calculationState={calculationState}
          />
        )}
        {!showSummary && (
          <Box display="flex" flexDirection="column" gap={2} flexGrow="1">
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              Optional Equipment
            </Typography>
            {addDeductList.length > 0 ? (
              <Box sx={{ width: '100%' }}>
                <Box>
                  <Tabs
                    value={tabvalue}
                    onChange={handleChange}
                    aria-label="new equipment adjustment"
                  >
                    <Tab label="Standard Options" {...a11yProps(0)} />
                    <Tab label="Residuals" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabvalue} index={0}>
                  <StandardOptions
                    year={year}
                    make={make}
                    model={model}
                    series={series}
                    style={style}
                    totalInvoice={totalInvoice}
                    totalmsrp={totalmsrp}
                    groupedByCategory={groupedByCategory}
                    selectedRows={selectedRows}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </TabPanel>
                <TabPanel value={tabvalue} index={1}>
                  <Residuals
                    year={year}
                    make={make}
                    model={model}
                    series={series}
                    style={style}
                    total={totalResiduals}
                    addDeductList={addDeductList}
                    selectedRows={selectedDeductRows}
                    handleCheckboxChange={handleResidualsCheckboxChange}
                  />
                </TabPanel>
              </Box>
            ) : (
              <Box width="100%">
                <StandardOptions
                  year={year}
                  make={make}
                  model={model}
                  series={series}
                  style={style}
                  totalInvoice={totalInvoice}
                  totalmsrp={totalmsrp}
                  groupedByCategory={groupedByCategory}
                  selectedRows={selectedRows}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button onClick={onNext} variant="contained" color="secondary">
                Get Vehicle Value
              </Button>
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="column"
          minWidth="25%"
          gap={2.5}
          mt={{ md: mt }}
          className={classes.tableOutline}
          maxHeight={showSummary ? '500px' : '450px'}
          backgroundColor="#f2f2f2"
          p={2.5}
        >
          <GeneralInfo
            productDetails={lookupLinkClicked ? collateralInfo?.productDetails : productDetails}
          />
        </Box>
      </Box>
    </Paper>
  );
};
export default EquipmentAdjustmentNew;

EquipmentAdjustmentNew.propTypes = {
  colors: PropTypes.instanceOf(Object),
  options: PropTypes.instanceOf(Object),
  productDetails: PropTypes.instanceOf(Object),
  addDeductList: PropTypes.instanceOf(Object),
  msrp: PropTypes.number,
  invoice: PropTypes.number,
  uvc: PropTypes.string,
  uvcTieBreaker: PropTypes.string,
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: PropTypes.bool,
  calculationState: PropTypes.string,
};
EquipmentAdjustmentNew.defaultProps = {
  colors: [],
  options: [],
  productDetails: {},
  addDeductList: [],
  msrp: 0,
  invoice: 0,
  uvc: '',
  uvcTieBreaker: '',
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
};
