import { Box, Grid, InputLabel, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const BasicInformation = ({ control, errors }) => {
  // const getDate = () => {
  //   const today = new Date();
  //   const yyyy = today.getFullYear();
  //   let mm = today.getMonth() + 1; // Months start at 0!
  //   let dd = today.getDate();

  //   if (dd < 10) dd = '0' + dd;
  //   if (mm < 10) mm = '0' + mm;

  //   return mm + '/' + dd + '/' + yyyy;
  // };
  return (
    <div>
      <CustomCollapse heading="Basic Information" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.userId}
              errorText={errors?.userId?.message}
              placeholder="Enter your text"
              label="User ID"
              name="userId"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.linkedUserId}
              errorText={errors?.linkedUserId?.message}
              placeholder="Enter your text"
              label="Linked User ID"
              name="linkedUserId"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.firstName}
              errorText={errors?.firstName?.message}
              placeholder="Enter your text"
              label="First Name"
              name="firstName"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.lastName}
              errorText={errors?.lastName?.message}
              placeholder="Enter your text"
              label="Last Name"
              name="lastName"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.secondLastName}
              errorText={errors?.secondLastName?.message}
              placeholder="Enter your text"
              label="Second Last Name"
              name="secondLastName"
              flexDirection="column"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <></>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.email}
              errorText={errors?.email?.message}
              placeholder="Enter your text"
              label="Email"
              name="email"
              flexDirection="column"
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomPhoneNumber
              name="mobile"
              areaCodeName="areaCode"
              label="Mobile"
              flexDirection="column"
              control={control}
              error={!!errors?.mobile}
              errorText={errors?.mobile?.message}
              error2={!!errors?.areaCode}
              errorText2={errors?.areaCode?.message}
              required
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>User Setup Date</InputLabel>
              <Typography sx={{ width: '100%' }}>{moment().format('MM/DD/YYYY')}</Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Time Zone</InputLabel>
              <Typography sx={{ width: '100%' }}>Puerto Rico</Typography>
            </Box>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Include In Loan Originator?"
              control={control}
              name="includeLoanOriginator"
              errors={!!errors?.includeLoanOriginator}
              errorText={errors?.includeLoanOriginator?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Include In Sales Manager?"
              control={control}
              name="includeSalesManager"
              errors={!!errors?.includeSalesManager}
              errorText={errors?.includeSalesManager?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Include In Reference?"
              control={control}
              name="includeReference"
              errors={!!errors?.includeReference}
              errorText={errors?.includeReference?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Can Only See Own Application?"
              control={control}
              name="canSeeOwnApplication"
              errors={!!errors?.canSeeOwnApplication}
              errorText={errors?.canSeeOwnApplication?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};

BasicInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
BasicInformation.defaultProps = {
  control: {},
  errors: {},
};
export default BasicInformation;
