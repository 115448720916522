import { Box, DialogContent, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';
import classes from 'styles/globalStyle.module.scss';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';

const ViewStandardEquipment = ({ openModal, setOpenModal, categoryList }) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Typography variant="h3" component="span">
          Standard Equipment
        </Typography>
        <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
      </BootstrapDialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={1.25} className={classes.tableOutline}>
          {categoryList.map((eachCategory, index) => (
            <Box
              display="flex"
              flexDirection="column"
              key={index}
              gap={1.25}
              className={classes.childBorderLine}
            >
              <Box sx={{ backgroundColor: '#f2f2f2' }}>
                <Typography variant="subtitle2" fontWeight="700" sx={{ padding: '8px' }}>
                  {eachCategory?.name}
                </Typography>
              </Box>
              {eachCategory?.equipment_list.map((el, innerIndex) => (
                <Box key={innerIndex} display="flex" gap={2}>
                  <Typography
                    variant="subtitle2"
                    sx={{ minWidth: '50%', maxWidth: '50%', padding: '8px' }}
                  >
                    {el?.sub_category}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ padding: '8px' }}>
                    {el?.equipment_value}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};
ViewStandardEquipment.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  categoryList: PropTypes.instanceOf(Object),
};
ViewStandardEquipment.defaultProps = {
  openModal: false,
  setOpenModal: () => {},
  categoryList: [],
};

export default ViewStandardEquipment;
