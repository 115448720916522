import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';

import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const MailingAddress = ({
  control,
  errors,
  cityList,
  addressLookupData,
  addressLookupDataResponse,
  setValue,
  clearErrors,
}) => {
  // const UtilityBillValues = [
  //   {
  //     label: 'LUMA',
  //     value: 'luma',
  //   },
  //   {
  //     label: 'AAA',
  //     value: 'aaa',
  //   },
  //   {
  //     label: 'Others',
  //     value: 'others',
  //   },
  // ];

  const [watchPerAddress1] = useWatch({
    control,
    name: ['perAddress1'],
  });

  const handleAutoCompleteData = (selectedObj) => {
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City } = selectedObj?.Address;
      setValue('perAddress1', AddressLine1);
      setValue('perZipcode', PostalCode.split('-')[0]);
      City = cityList.find((x) => x.value === City)?.value || '';
      setValue('perCity', City);
      clearErrors(['perAddress1', 'perZipcode', 'perCity']);
    } else {
      setValue('perAddress1', '');
      setValue('perZipcode', '');
      setValue('perCity', '');
    }
  };

  return (
    <>
      <CustomCollapse heading="Mailing Address" open={false}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          {/* <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomRadioButton
              control={control}
              radioValues={UtilityBillValues}
              label="Utility Bill"
              // required
              name="perUtilityBill"
              errors={!!errors?.perUtilityBill}
              errorText={errors?.perUtilityBill?.message}
            />
          </Grid> */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            {/* <CustomInputBox
              control={control}
              errors={!!errors?.perAddress1}
              errorText={errors?.perAddress1?.message}
              placeholder="Enter text"
              label="Address line 1"
              name="perAddress1"
              flexDirection="column"
              required
            /> */}

            <CustomAutoComplete
              id="perAddress1"
              name="perAddress1"
              flexDirection="column"
              control={control}
              regex={/^[a-zA-Z0-9áéíóúñü¿¡.,/-\s]*$/g}
              dataTestIdAutocomplete="autocomplete_address1"
              dataTestIdForLabel="perAddress1"
              dataTestIdForInput="addr1Input"
              required
              label="Address line 1"
              options={
                addressLookupData && watchPerAddress1 && watchPerAddress1?.length >= 6
                  ? addressLookupData
                  : []
              }
              handleChange={(_event, selectedValue) => {
                if (watchPerAddress1 && watchPerAddress1.length >= 6) {
                  const index = addressLookupData.indexOf(selectedValue);
                  const selectedObj = addressLookupDataResponse[index];
                  handleAutoCompleteData(selectedObj);
                }
              }}
              handleInputChange={(_event, newInputValue) => {
                console.log('new Input Value', newInputValue);
              }}
              // filterOptions={(options) => options}
              maxLength={40}
              autoFocus
              placeholder="Enter text"
              fullWidth
              error={errors?.perAddress1}
              errorText={errors?.perAddress1?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.perAddress2}
              errorText={errors?.perAddress2?.message}
              maxLength="20"
              regex={/^[A-Za-z0-9áéíóúñü¿¡,./-\s]$/}
              placeholder="Enter text"
              label="Address line 2"
              flexDirection="column"
              name="perAddress2"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Country"
              control={control}
              name="perCountry"
              errors={!!errors?.perCountry}
              errorText={errors?.perCountry?.message}
              options={[
                {
                  name: 'United States',
                  value: 'USA',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
              isDisabled
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="State"
              control={control}
              name="perState"
              errors={!!errors?.perState}
              errorText={errors?.perState?.message}
              options={[
                {
                  name: 'PUERTO RICO',
                  value: 'PR',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
              isDisabled
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="City"
              control={control}
              name="perCity"
              errors={!!errors?.perCity}
              errorText={errors?.perCity?.message}
              options={cityList}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.perZipcode}
              errorText={errors?.perZipcode?.message}
              placeholder="Enter text"
              label="Zip code"
              name="perZipcode"
              regex={/^[0-9]$/}
              maxLength="5"
              type="text"
              flexDirection="column"
              required
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </>
  );
};
export default MailingAddress;

MailingAddress.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  cityList: PropTypes.array,
  clearErrors: PropTypes.func,
  addressLookupData: PropTypes.instanceOf(Object),
  addressLookupDataResponse: [],
  setValue: PropTypes.func,
};
MailingAddress.defaultProps = {
  control: {},
  errors: {},
  cityList: [],
  addressLookupData: [],
  addressLookupDataResponse: [],
  setValue: () => {},
  clearErrors: () => {},
};
