/* eslint-disable */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

// import { UrlHelpers } from 'urlHelpers/urlHelper';
import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import Loader from 'components/Loader/Loader';

const StyledTextarea = styled(TextareaAutosize)(
  () => `
  width: 100%;
  height: 100% !important;
  line-height: 1.5;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #909090;
`
);

export const RejectionDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 'clamp(400px, 50vw, 600px)',
    padding: '16px',
    minHeight: 'clamp(300px,35vh,100%)',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UserRequestRejectionDialog = () => {
  const { rejectClicked, setRejectClicked, setDialogOpen } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [comment, setComment] = useState('');
  const location = useLocation();
  const { reqId } = location?.state || {};

  const ApiClient = new ApiClass();

  const handleClose = () => {
    setRejectClicked(false);
  };

  const handleContinue = async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        `${UrlHelpers.userRequestStatus}status?crit=reject+${reqId}`,
        {
          comment: comment,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log(resp);
      setDialogOpen(true);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
    setRejectClicked(false);
  };

  return (
    <>
      <RejectionDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={rejectClicked}
      >
        {loader && <Loader open={loader} />}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span">
            Reject Request
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          <Typography variant="subtitle2" fontWeight="700" component="span">
            <span style={{ color: '#D71721' }}>*</span>Comment
          </Typography>
          <Box sx={{ height: '145px' }}>
            <StyledTextarea
              minRows={3}
              placeholder="Enter your comments"
              onChange={(e) => setComment(e.target.value)}
              data-testid='textarea'
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={!comment}
          >
            Submit
          </Button>
        </DialogActions>
      </RejectionDialog>
    </>
  );
};

export default UserRequestRejectionDialog;
