import { Box, Link, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

const UserRequestDashboard = () => {
  const [userRequestsData, setUserRequestsData] = useState();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const ApiClient = new ApiClass();

  useEffect(async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(
        `${UrlHelpers.userRequestStatus}fetch`,
        {},
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log(resp);
      setUserRequestsData(resp);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, []);

  const handleLinkClick = (requestId) => {
    navigate('/user-request', { state: { reqId: requestId } });
  };
  return (
    <>
      {loader && <Loader open={loader} />}
      <Paper sx={{ padding: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <Typography variant="h3">Applications</Typography>
          <Typography variant="subtitle1" fontWeight={700}>
            User Request
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            // className={classes.childBorderLine}
            mt={1}
            sx={{ borderWidth: '1px 1px 0px 1px', borderStyle: 'solid', borderColor: '#DADADA' }}
          >
            <Box
              display="flex"
              gap={2}
              sx={{ backgroundColor: '#F2F2F2', borderBottom: '1px solid #DADADA' }}
              p={2}
              // className={classes.childBorderLine}
            >
              <Typography
                variant="subtitle2"
                sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
              >
                Dealer Code
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
              >
                First Name
              </Typography>

              <Typography
                variant="subtitle2"
                sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
              >
                Last Name
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
              >
                Access Type
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ minWidth: '40%', maxWidth: '40%', fontWeight: 700 }}
              >
                Comment
              </Typography>
            </Box>
            {userRequestsData?.map((eachObj, dataIndex) => {
              const { dealerNumber, firstName, lastName, accessTypeDealer, comments, requestId } =
                eachObj;
              return (
                <Box
                  display="flex"
                  gap={2}
                  p={2}
                  key={dataIndex}
                  sx={{ borderBottom: '1px solid #DADADA' }}
                >
                  <Link
                    component="button"
                    variant="subtitle2"
                    onClick={() => handleLinkClick(requestId)}
                    color="secondary"
                    sx={{ minWidth: '15%', maxWidth: '15%', textAlign: 'left' }}
                  >
                    {dealerNumber}
                  </Link>

                  <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                    {firstName}
                  </Typography>

                  <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                    {lastName}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                    {accessTypeDealer}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ minWidth: '40%', maxWidth: '40%' }}>
                    {comments}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default UserRequestDashboard;
