import { Grid, Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import classes from '../../styles/resultPage.module.scss';

const Results = ({ values, watch }) => {
  const [calculatedData, setCalculatedData] = useState({});
  const createData = (name, value) => {
    return { name, value };
  };
  const defaultValues = (value1, value2) => {
    if (value1 || value2) {
      return value1 || value2;
    } else if (parseInt(value1) === 0) {
      return value1;
    } else if (parseInt(value2) === 0) {
      return value2;
    }
    return '--';
  };
  const defaultPrefixValues = (value, value2, prefix) => {
    if (((value || value2) && prefix) || parseInt(value) === 0 || parseInt(value2) === 0) {
      return prefix;
    }
    return '';
  };

  const watchRate = watch('rate');
  const watchProgram = watch('programVal');

  useEffect(() => {
    if (values?.flowData?.data) {
      setCalculatedData(values?.flowData?.data);
    }
  }, []);
  console.log('CalculatedData - resultpage', calculatedData);

  const rows = [
    createData(
      'Financed Fees',
      <NumberFormat
        value={defaultValues(
          values?.data?.financedFees,
          calculatedData?.calculatedData?.financedFees
        )}
        displayType="text"
        thousandSeparator={true}
        prefix={defaultPrefixValues(
          values?.data?.financedFees,
          calculatedData?.calculatedData?.financedFees,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Amount Financed',
      <NumberFormat
        value={defaultValues(
          values?.data?.amountFinanced,
          calculatedData?.calculatedData?.amountFinanced
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.amountFinanced,
          calculatedData?.calculatedData?.amountFinanced,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Balance Amount Finance',
      <NumberFormat
        value={defaultValues(values?.data?.balance, calculatedData?.calculatedData?.balance)}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.balance,
          calculatedData?.calculatedData?.balance,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Balloon Payment',
      <NumberFormat
        value={
          watchProgram === 'Oriental A+'
            ? calculatedData &&
              defaultValues(values?.formData?.balloonPay, calculatedData?.baloonPayment)
            : '--'
        }
        thousandSeparator={true}
        displayType="text"
        prefix={
          watchProgram === 'Oriental A+'
            ? calculatedData &&
              defaultPrefixValues(values?.formData?.balloonPay, calculatedData?.baloonPayment, '$')
            : ''
        }
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'APR',
      <NumberFormat
        value={defaultValues(watchRate, calculatedData?.apr)}
        displayType="text"
        suffix={defaultPrefixValues(watchRate, '', '%')}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Registration Fees',
      <NumberFormat
        value={defaultValues(
          values?.data?.registrationFees?.toFixed(0.5),
          calculatedData?.calculatedData?.registrationFees?.toFixed(0.5)
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.registrationFees,
          calculatedData?.calculatedData?.registrationFees?.toFixed(0.5),
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Net Trade In',
      <NumberFormat
        value={defaultValues(values?.data?.netTradeIn, calculatedData?.calculatedData?.netTradeIn)}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.netTradeIn,
          calculatedData?.calculatedData?.netTradeIn,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Finance Charge',

      <NumberFormat
        value={defaultValues(
          values?.data?.financeCharge,
          calculatedData?.calculatedData?.financeCharge
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.financeCharge,
          calculatedData?.calculatedData?.financeCharge,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
    createData(
      'Deferred Balance',
      <NumberFormat
        value={defaultValues(
          values?.data?.deferredBalance,
          calculatedData?.calculatedData?.deferredBalance
        )}
        thousandSeparator={true}
        displayType="text"
        prefix={defaultPrefixValues(
          values?.data?.deferredBalance,
          calculatedData?.calculatedData?.deferredBalance,
          '$'
        )}
        className={classes.results}
        isNumericString={true}
      />
    ),
  ];
  return (
    <Grid container spacing={3}>
      <Grid item lg={12} md={12} sm={12}>
        <Table aria-label="simple table" className={classes.tableContainer}>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell
                  component="th"
                  scope="row"
                  classes={{ root: classes.TableCell }}
                  sx={{ width: '60%' }}
                >
                  {row.name}
                </TableCell>
                <TableCell classes={{ root: classes.TableCell }}>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

export default Results;

Results.propTypes = {
  values: PropTypes.instanceOf(Object),
  watch: PropTypes.func,
  getValues: PropTypes.func,
};
Results.defaultProps = {
  values: {},
  watch: () => {},
  getValues: () => {},
};
