/* eslint-disable no-unused-vars */
import { Box, Button, Paper, Typography } from '@mui/material';
import lookupMain from 'assets/images/lookup-main.png';
import AuthContext from 'context/AuthContext';
import LookupCommon from 'pages/LookupCommonComponent/LookupCommon';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';

import Loader from 'components/Loader/Loader';

import DistributorDataResult from './DistributorDataResult/DistributorDataResult';
import EquipmentAdjustment from './EquipmentAdjustment/EquipmentAdjustment';
import EquipmentAdjustmentNew from './EquipmentAdjustmentNew/EquipmentAdjustmentNew';

const LookupNew = () => {
  const location = useLocation();
  console.log('location-------', location);
  const { setShowMainPopup, yearList, pageData, showPage, setShowPage } = useContext(AuthContext);

  console.log('year list', yearList);
  const { coApplicant, coSigner, reviewFlag, calculationState } = location?.state || {};

  console.log('location?.state', location?.state);
  console.log('coApplicant, coSigner ', coApplicant, coSigner);
  const lookupSubmit = () => {
    setShowMainPopup(true);
  };

  return (
    <>
      {showPage === 'loader' && <Loader open={showPage === 'loader'} />}
      <LookupCommon />
      {!showPage && (
        <>
          <Typography variant="h3" component="h1">
            Lookup
          </Typography>
          <Paper className={classes.lookup} sx={{ marginTop: '20px' }}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              gap={2.5}
              width="50%"
            >
              <img src={lookupMain} width="442px" height="370px" />
              <Typography variant="h1" component="h3" textAlign="center">
                Buy Smart, Step by Step <br />
                We will help you shop like a pro
              </Typography>
              <Typography variant="h5">
                Check out cars near you that could be the perfect match
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={lookupSubmit}
                sx={{ marginBottom: '30px', marginTop: '30px' }}
              >
                Let&apos;s Lookup
              </Button>
            </Box>
          </Paper>
        </>
      )}
      {showPage === 'distributorDataResult' && (
        <DistributorDataResult
          data={pageData?.data}
          valueGuide="Distributor data"
          collateralType="new"
          setShowPage={setShowPage}
          coApplicant={coApplicant}
          coSigner={coSigner}
          reviewFlag={reviewFlag}
          calculationState={calculationState}
        />
      )}
      {showPage === 'equipmentAdjustment' && (
        <EquipmentAdjustment
          data={pageData?.data}
          productDetails={pageData?.productDetails}
          coApplicant={coApplicant}
          coSigner={coSigner}
          reviewFlag={reviewFlag}
          calculationState={calculationState}
        />
      )}
      {showPage === 'equipmentAdjustmentNew' && (
        <EquipmentAdjustmentNew
          colors={pageData?.colors}
          options={pageData?.options}
          productDetails={pageData?.productDetails}
          msrp={pageData?.msrp}
          addDeductList={pageData?.addDeductList}
          invoice={pageData?.invoice}
          uvc={pageData?.uvc}
          uvcTieBreaker={pageData?.uvcTieBreaker}
          coApplicant={coApplicant}
          coSigner={coSigner}
          reviewFlag={reviewFlag}
          calculationState={calculationState}
        />
      )}
    </>
  );
};

export default LookupNew;
