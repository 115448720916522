import { Box, Button, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { parseJwt } from 'utils/utility';

// import DummyDashboard from './DummyDashboard';
import UserRequestDashboard from './UserRequestDashboard';

const Home = () => {
  let { idToken, setOpenPopUp, setCreateAppFlag, setLookupSaved } = useContext(AuthContext);

  const [userName, setuserName] = useState('');
  const [role, setRole] = useState('Role not specified');

  useEffect(() => {
    const parsedToken = parseJwt(idToken);
    let { name } = parsedToken;
    const roleID = parsedToken?.['custom:RoleID'];
    if (roleID) setRole(roleID);
    if (name) {
      setuserName(name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="column" className={classes.dummyHome} gap={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4">
          Welcome {userName}{' '}
          <Typography variant="span" color="primary">
            {`( ${role} )`}
          </Typography>
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setOpenPopUp(true);
            setCreateAppFlag(true);
            setLookupSaved(false);
            // setCollateralInfo({});
          }}
        >
          Create Application
        </Button>
      </Box>

      <UserRequestDashboard />
    </Box>
  );
};

export default Home;
