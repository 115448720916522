/* eslint-disable */
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import Loader from 'components/Loader/Loader';

const ApproveRequest = ({ control, errors, handleSubmit }) => {
  const { setApproveClicked, setDialogOpen } = useContext(AuthContext);
  const [alertToggle, setAlertToggle] = useState('flex');
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const { reqId } = location?.state || {};

  const navigate = useNavigate();

  const ApiClient = new ApiClass();

  const [
    watchuserId,
    watchlinkedUserId,
    watchincludeInLoanOriginator,
    watchincludeInSalesManager,
    watchincludeInReference,
    watchcanSeeOwnApplication,
    watchactive,
    watchmaximizeTY,
    watchteam,
    watchsecurityProfile,
  ] = useWatch({
    control,
    name: [
      'userId',
      'linkedUserId',
      'includeInLoanOriginator',
      'includeInSalesManager',
      'includeInReference',
      'canSeeOwnApplication',
      'active',
      'maximizeTY',
      'team',
      'securityProfile',
    ],
  });

  const handleClose = () => {
    setAlertToggle('none');
  };

  const submitAction = async () => {
    const payload = {
      userId: watchuserId,
      linkedUserId: watchlinkedUserId,
      includeLoanOriginator: watchincludeInLoanOriginator === 'Yes' ? true : false,
      includeSalesManager: watchincludeInSalesManager === 'Yes' ? true : false,
      includeReference: watchincludeInReference === 'Yes' ? true : false,
      canSeeOwnApplication: watchcanSeeOwnApplication === 'Yes' ? true : false,
      isActive: watchactive === 'Yes' ? true : false,
      maximizeTty: watchmaximizeTY === 'Yes' ? true : false,
      team: watchteam,
      securityProfile: watchsecurityProfile,
    };
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        `${UrlHelpers.userRequestStatus}status?crit=approve+${reqId}`,
        {
          ...payload,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log(resp);
      setDialogOpen(true);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
    // setApproveClicked(false);
  };

  return (
    <div>
      {loader && <Loader open={loader} />}
      <Typography variant="h3" py={2}>
        Additional Required Information
      </Typography>
      <Box sx={{ width: '70%', margin: 'auto' }}>
        <Box
          sx={{
            display: `${alertToggle}`,
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '10px',
            justifyContent: 'space-between',
            background: '#F1F6FD',
            borderWidth: '0px 0px 0px 5px',
            borderStyle: 'solid',
            borderColor: '#0052EA',
            gap: '10px',
          }}
        >
          <Box display="flex" sx={{ gap: '10px' }}>
            <InfoOutlinedIcon sx={{ color: '#0052EA' }} />
            <Typography variant="subtitle2">
              To approve, please fill the additional information and submit the request
            </Typography>
          </Box>
          <IconButton
            data-testid="closeBtn"
            onClick={handleClose}
            sx={{ height: '16px', width: '16px' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <CustomCollapse heading="Basic Information" open={true}>
          <Grid container rowSpacing={1} columnSpacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.userId}
                errorText={errors?.userId?.message}
                placeholder="Enter your text"
                label="User ID"
                name="userId"
                flexDirection="column"
                required
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomInputBox
                control={control}
                errors={!!errors?.linkedUserId}
                errorText={errors?.linkedUserId?.message}
                placeholder="Enter your text"
                label="Linked User ID"
                name="linkedUserId"
                flexDirection="column"
                required
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Include In Loan Originator?"
                control={control}
                name="includeInLoanOriginator"
                errors={!!errors?.includeInLoanOriginator}
                errorText={errors?.includeInLoanOriginator?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Include In Sales Manager?"
                control={control}
                name="includeInSalesManager"
                errors={!!errors?.includeInSalesManager}
                errorText={errors?.includeInSalesManager?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Include In Reference?"
                control={control}
                name="includeInReference"
                errors={!!errors?.includeInReference}
                errorText={errors?.includeInReference?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Can Only See Own Application?"
                control={control}
                name="canSeeOwnApplication"
                errors={!!errors?.canSeeOwnApplication}
                errorText={errors?.canSeeOwnApplication?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Active"
                control={control}
                name="active"
                errors={!!errors?.active}
                errorText={errors?.active?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Maximize TY"
                control={control}
                name="maximizeTY"
                errors={!!errors?.maximizeTY}
                errorText={errors?.maximizeTY?.message}
                options={[
                  {
                    name: 'Yes',
                    value: 'Yes',
                  },
                  {
                    name: 'No',
                    value: 'No',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Team"
                control={control}
                name="team"
                errors={!!errors?.team}
                errorText={errors?.team?.message}
                options={[
                  {
                    name: 'Oriental1',
                    value: 'oriental1',
                  },
                  {
                    name: 'Oriental2',
                    value: 'oriental2',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label="Security Profile"
                control={control}
                name="securityProfile"
                errors={!!errors?.securityProfile}
                errorText={errors?.securityProfile?.message}
                options={[
                  {
                    name: 'APPLICATION ENTRY',
                    value: 'APPLICATION ENTRY',
                  },
                  {
                    name: 'DEALER',
                    value: 'DEALER',
                  },
                  {
                    name: 'MANAGER',
                    value: 'MANAGER',
                  },
                  {
                    name: 'SALES REPRESENTATIVE',
                    value: 'SALES REPRESENTATIVE',
                  },
                  {
                    name: 'DEALER 2',
                    value: 'DEALER 2',
                  },
                  {
                    name: 'Manager 2',
                    value: 'Manager 2',
                  },
                ]}
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
          </Grid>
        </CustomCollapse>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
          <Button
            component="button"
            onClick={() => {
              navigate('/home');
              setApproveClicked(false);
            }}
            variant="outlined"
            color="secondary"
            sx={{ fontSize: '16px' }}
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(submitAction)}
            data-testid="approve-userreq"
            disabled={Object.entries(errors)?.length}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </div>
  );
};

ApproveRequest.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
ApproveRequest.defaultProps = {
  control: {},
  errors: {},
};
export default ApproveRequest;
