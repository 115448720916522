import { Box, Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import classes from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

const StandardOptions = ({
  year,
  make,
  model,
  series,
  style,
  totalInvoice,
  totalmsrp,
  groupedByCategory,
  selectedRows,
  handleCheckboxChange,
}) => {
  return (
    <>
      <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
        Select options for {year} {make} {model} {series} {style} - Spec{' '}
      </Typography>
      <Box display="flex" flexDirection="column" className={classes.tableOutline} mt={2}>
        <Box
          display="flex"
          gap={2}
          sx={{ backgroundColor: '#f2f2f2' }}
          p={2}
          className={classes.borderTable}
        >
          <Typography
            variant="subtitle2"
            sx={{ flexGrow: 1, fontWeight: 700, paddingLeft: '30px' }}
          >
            Description
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
          >
            Invoice
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
          >
            MSRP
          </Typography>
        </Box>
        {Object.keys(groupedByCategory).map((category, outerIndex) => (
          <Box
            display="flex"
            flexDirection="column"
            key={outerIndex}
            className={classes.childBorderLine}
          >
            <Typography
              variant="subtitle2"
              sx={{
                backgroundColor: '#F9F9F9;',
                padding: '16px',
                paddingLeft: '40px',
                fontWeight: 700,
              }}
              className={classes.borderTable}
            >
              {category}
            </Typography>
            {groupedByCategory[category]?.map?.((x, innerIndex) => {
              const { description, invoice, retail } = x;

              return (
                <Box display="flex" gap={2} key={innerIndex} p={2}>
                  <Checkbox
                    color="secondary"
                    checked={selectedRows.find((el) => el.description === description)}
                    onChange={(event) => handleCheckboxChange(event, x)}
                  />
                  <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                    {description}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                    {numberToCurrency(invoice)}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                    {numberToCurrency(retail)}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        ))}
        <Box
          display="flex"
          gap={2}
          sx={{ backgroundColor: '#f2f2f2' }}
          p={1}
          className={classes.borderTable}
        >
          <Typography
            variant="subtitle2"
            sx={{ flexGrow: 1, fontWeight: 700, padding: '16px', paddingLeft: '30px' }}
          >
            Total Adjustments
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700, padding: '16px 0px' }}
          >
            {numberToCurrency(totalInvoice)}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700, padding: '16px 0px' }}
          >
            {numberToCurrency(totalmsrp)}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default StandardOptions;

StandardOptions.propTypes = {
  year: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  series: PropTypes.string,
  style: PropTypes.string,
  totalInvoice: PropTypes.number,
  totalmsrp: PropTypes.number,
  groupedByCategory: PropTypes.instanceOf(Object),
  selectedRows: PropTypes.instanceOf(Object),
  handleCheckboxChange: PropTypes.func,
};
StandardOptions.defaultProps = {
  year: '',
  make: '',
  model: '',
  series: '',
  style: '',
  totalInvoice: 0,
  totalmsrp: 0,
  groupedByCategory: [],
  selectedRows: [],
  handleCheckboxChange: () => {},
};
