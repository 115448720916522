import { Box, Divider, Typography } from '@mui/material';
import LeftArrow from 'assets/svg/leftArrow.svg';
import PropTypes from 'prop-types';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';

const ApplicationDialogHeading = ({ handleClose, handleBack }) => {
  return (
    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
      <Box display="flex" alignItems="center">
        <img src={LeftArrow} onClick={handleBack} />
        <Typography variant="h3" component="span">
          &nbsp;Create Application
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
    </BootstrapDialogTitle>
  );
};

export default ApplicationDialogHeading;

ApplicationDialogHeading.propTypes = {
  handleClose: PropTypes.func,
  handleBack: PropTypes.func,
};
ApplicationDialogHeading.defaultProps = {
  handleClose: () => {},
  handleBack: () => {},
};
