import { Box, Button, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import FinancialTermsImage from 'assets/svg/FinancialTermsImage.svg';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { removeNullValues } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import CreateApplicationFooter from '../CreateApplicationFooter';
import { frameFinanceTermPayload } from '../InformationRequest/moduleUtilities/InfoReqPayload';
import EstimatedMonthlyPayment from './EstimatedMonthlyPayment.js/EstimatedMonthlyPayment';
import PaymentCalculatorDetails from './PaymentCalculatorDetails/PaymentCalculatorDetails';

const FinancialTerms = () => {
  const navigate = useNavigate();

  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const [calculatedData, setCalculatedData] = useState({});
  const {
    userType,
    setActiveStep,
    calculationState,
    setCompletedSteps,
    reviewFlag,
    coApplicant,
    coSigner,
    setOpenDraftModal,
  } = useContext(MainApplicationContext);
  const { createAppApplicationId } = useContext(AuthContext);

  const onBackClick = () => {
    setActiveStep(3);
  };
  useEffect(async () => {
    if (calculationState) {
      try {
        setLoader(true);
        let calculationData = await ApiClient.get(
          `${UrlHelpers.financeTermsPayCalcSave}?applicationId=${createAppApplicationId}&type=FinanceTermPayCalc`
        );

        if (calculationData) {
          const resultFromPayCalc = JSON.parse(calculationData?.[0]?.searchResult || '{}');

          setCalculatedData(resultFromPayCalc);
        }
      } catch (e) {
        console.log('error fetching', e);
      } finally {
        setLoader(false);
      }
    }
  }, [calculationState]);

  const handleSave = async ({ draft = false }) => {
    let finalPayCalc = calculatedData?.calculatedData?.paymentSchedules?.map((each) => {
      return {
        NoOfPayments: each.noOfPayments,
        Amount: each.paymentAmount,
        StartDate: each.startDate,
      };
    });
    const formData = {
      FinancialTerms: {
        EstimatedMonthlyPayment: {
          FinancedFees: removeNullValues(calculatedData?.calculatedData?.financedFees),
          RegistrationFees: removeNullValues(calculatedData?.calculatedData?.registrationFees),
          AmountFinanced: removeNullValues(calculatedData?.calculatedData?.amountFinanced),
          BalanceAmountFinance: removeNullValues(calculatedData?.calculatedData?.balance),
          NetTradeIn: removeNullValues(calculatedData?.calculatedData?.netTradeIn),
          FinanceCharge: removeNullValues(calculatedData?.calculatedData?.financeCharge),
          BaloonPayment: removeNullValues(calculatedData?.baloonPayment),
          NonFinancedFees: removeNullValues(calculatedData?.calculatedData?.nonFinancedFees),
          APR: removeNullValues(calculatedData?.apr),
          PaymentSchedule: finalPayCalc,
        },
        PaymentCalculatorDetails: {
          Program: removeNullValues(calculatedData?.formData?.programVal),
          Promotion: removeNullValues(calculatedData?.formData?.promotion),
          NewUsed: removeNullValues(calculatedData?.formData?.newOrUsed),
          VehicleClass:
            removeNullValues(calculatedData?.formData?.vehicleClass) === 'import'
              ? 'imported'
              : 'local',
          Term: removeNullValues(calculatedData?.formData?.term),
          Rate: removeNullValues(calculatedData?.formData?.rate),
          ContractDate: moment(removeNullValues(calculatedData?.formData?.contractDate)).format(
            'DD/MM/yyyy'
          ),
          SalePrice: removeNullValues(calculatedData?.formData?.salePrice),
          TradeInDebt: removeNullValues(calculatedData?.formData?.tradeDept),
          CashDown: removeNullValues(calculatedData?.formData?.cashDown),
          TradeInAllowance: removeNullValues(calculatedData?.formData?.tradeAllowance),
          Rebate: removeNullValues(calculatedData?.formData?.rebate),
          NetTradeIn: removeNullValues(calculatedData?.formData?.netTrade),
          CarCare: removeNullValues(calculatedData?.formData?.carCare),
          ExtendedWarranty: removeNullValues(calculatedData?.formData?.extendedWarranty),
          ServiceContract: removeNullValues(calculatedData?.formData?.serviceContract),
          PaintProtection: removeNullValues(calculatedData?.formData?.paintProtection),
          Other: removeNullValues(calculatedData?.formData?.other),
          Year: removeNullValues(calculatedData?.formData?.year),
          Brand: removeNullValues(calculatedData?.formData?.make),
          Model: removeNullValues(calculatedData?.formData?.model),
          Balance: removeNullValues(calculatedData?.formData?.balance),
          CreditLife: removeNullValues(calculatedData?.formData?.creditLife),
          CreditLifeType: removeNullValues(calculatedData?.formData?.creditLifeType),
          VehicleInsuranceType: removeNullValues(calculatedData?.formData?.vehicleInsuranceType),
          CreditLifeLifeInsuranceAmount: removeNullValues(
            calculatedData?.formData?.creditLifeAmount
          ),
          VehicleInsuranceAmount: removeNullValues(
            calculatedData?.formData?.vehicleInsuranceAmount
          ),
          GapFee: removeNullValues(calculatedData?.formData?.gapFee),
          RegistrationFees: removeNullValues(calculatedData?.formData?.registrationfee),
          MonthsDeferred: removeNullValues(calculatedData?.formData?.monthDeferred),
        },
      },
    };
    const financeTermPayload = await frameFinanceTermPayload({
      formData,
      userType,
      createAppApplicationId,
      dealerId: '1234',
      userId: 'co2607',
      step: 4,
    });

    try {
      setLoader(true);
      const endpoint = draft ? UrlHelpers.applicationDraft : UrlHelpers.informationReqSave;
      let calculate = await ApiClient.post(
        endpoint,
        { ...financeTermPayload },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      console.log('financeTerms', calculate);
      if (calculate && !draft) {
        setActiveStep(5);
        setCompletedSteps([1, 2, 3, 4]);
      } else if (draft && calculate) {
        setOpenDraftModal(true);
      }
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Typography variant="h3" component="p" py={4}>
        Financing Terms
      </Typography>
      <Loader open={loader} />
      <Grid container justifyContent="center">
        <Box sx={{ width: '70%' }}>
          {!calculationState && (
            <Grid container justifyContent="center">
              <Grid
                container
                lg={12}
                md={12}
                justifyContent="space-between"
                sx={{
                  background: 'linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%)',
                  border: '1px solid #909090',
                  padding: '22px',
                }}
              >
                <Grid item lg={4} md={4} p={1}>
                  <img src={FinancialTermsImage} alt="Calculator Image" style={{ width: '100%' }} />
                </Grid>
                <Grid item lg={8} md={8} p={1}>
                  <Box>
                    <Typography variant="h3" component="p" color="primary">
                      Payment Calculator Utility
                    </Typography>
                    <Typography variant="subtitle2" component="p" py={3}>
                      Value lookup utility benefits to enter the collateral vehicle information and
                      help the result to be displayed on the screen. Go ahead and click below
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ maxHeight: '40px' }}
                      onClick={() =>
                        navigate('/paymentCalculator', {
                          state: { flow: 'financialTerms', coApplicant, coSigner },
                        })
                      }
                    >
                      Payment Calculator
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}

          {Object.entries(calculatedData).length > 0 && calculationState && (
            <>
              <EstimatedMonthlyPayment calculatedData={calculatedData} />
              <PaymentCalculatorDetails calculatedData={calculatedData} />
            </>
          )}
        </Box>
      </Grid>

      <CreateApplicationFooter
        disabledFlag={reviewFlag}
        onBackClick={onBackClick}
        onSaveContinue={handleSave}
        saveDisabledFlag={!Object.entries(calculatedData).length}
        onSaveDraft={() => handleSave({ draft: true })}
        draftDisabledFlag={!Object.entries(calculatedData).length}
      />
    </>
  );
};

export default FinancialTerms;
