import React from 'react';

const AuthContext = React.createContext({
  accessToken: false,
  setAccessToken: () => {},
  userType: '',
  setUserType: () => {},
  openPopUp: false,
  setOpenPopUp: () => {},
  createAppApplicationId: '',
  setCreateAppApplicationId: () => {},
});

export default AuthContext;
