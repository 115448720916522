import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { Box, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';

const AddCoUsers = ({ control, errors, setValue, setCoApplicantFlag, clearErrors }) => {
  const [watchCoApplicant, watchCoSigner] = useWatch({
    control,
    name: ['coApplicant', 'coSigner'],
  });
  console.log('watchCoApplicant', watchCoApplicant, watchCoSigner);
  const [showCoApplicant, setShowCoApplicant] = useState(false);
  const [showCoSigner, setShowCoSigner] = useState(false);

  const { fields, append, remove } = useFieldArray({
    name: 'coSigner',
    control,
  });
  console.log('fields', fields);
  const removeCoApplicant = () => {
    setShowCoApplicant(false);
    setCoApplicantFlag(false);
    setValue('coApplicant', {});
    clearErrors('coApplicant');
  };

  return (
    <>
      {Object.entries(watchCoApplicant).length === 0 &&
        watchCoSigner.length === 0 &&
        !showCoSigner &&
        !showCoApplicant && (
          <Box display="flex" gap={4}>
            <Box display="flex" gap={1}>
              <ControlPointIcon color="secondary" />
              <Link
                component="button"
                color="secondary"
                variant="subtitle2"
                onClick={() => {
                  setShowCoApplicant(true);
                  setCoApplicantFlag(true);
                }}
              >
                Add Co-applicant
              </Link>
            </Box>
            <Box display="flex" gap={1}>
              <ControlPointIcon color="secondary" />
              <Link
                component="button"
                variant="subtitle2"
                color="secondary"
                onClick={() => {
                  append({
                    CoSignerNumber: watchCoSigner.length + 1,
                    CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' },
                  });
                  setShowCoSigner(true);
                }}
              >
                Add Co-Signer
              </Link>
            </Box>
          </Box>
        )}
      {(showCoApplicant || Object.entries(watchCoApplicant)?.length > 0) && (
        <>
          <CustomCollapse heading="Co-Applicant" open={true}>
            <Box display="flex" flexDirection="column">
              <Box display="flex" gap={2.5}>
                <Box display="flex" flexDirection="column" flexGrow="1">
                  <CustomInputBox
                    control={control}
                    errors={!!errors?.coApplicant?.CoAppFirstName}
                    errorText={errors?.coApplicant?.CoAppFirstName?.message}
                    placeholder="Enter text"
                    label="First Name"
                    name="coApplicant.CoAppFirstName"
                    flexDirection="column"
                    maxLength="15"
                    regex={/^[A-Za-z]$/}
                  />
                </Box>
                <Box display="flex" flexDirection="column" flexGrow="1">
                  <CustomInputBox
                    control={control}
                    errors={!!errors?.coApplicant?.CoAppLastName}
                    errorText={errors?.coApplicant?.CoAppLastName?.message}
                    placeholder="Enter text"
                    label="Last Name"
                    name="coApplicant.CoAppLastName"
                    flexDirection="column"
                    maxLength="25"
                    regex={/^[A-Za-z]$/}
                  />
                </Box>
              </Box>

              <Box display="flex" gap={1}>
                <CancelOutlinedIcon color="secondary" />
                <Link
                  component="button"
                  color="secondary"
                  variant="subtitle2"
                  onClick={removeCoApplicant}
                >
                  Remove
                </Link>
              </Box>
            </Box>
          </CustomCollapse>
          {!watchCoSigner?.length && !showCoSigner && (
            <Box display="flex" gap={1}>
              <ControlPointIcon color="secondary" />
              <Link
                component="button"
                color="secondary"
                variant="subtitle2"
                onClick={() => {
                  append({
                    CoSignerNumber: watchCoSigner.length + 1,
                    CoSignerName: {
                      CoSignerFirstName: '',
                      CoSignerLastName: '',
                    },
                  });
                  setShowCoSigner(true);
                }}
              >
                Add Co-Signer
              </Link>
            </Box>
          )}{' '}
        </>
      )}

      {(showCoSigner || watchCoSigner.length > 0) && (
        <>
          {Object.entries(watchCoApplicant).length === 0 && !showCoApplicant && (
            <Box display="flex" gap={1}>
              <ControlPointIcon color="secondary" />
              <Link
                component="button"
                color="secondary"
                variant="subtitle2"
                onClick={() => {
                  setShowCoApplicant(true);
                  setCoApplicantFlag(true);
                }}
              >
                Add Co-applicant
              </Link>
            </Box>
          )}
          <CustomCollapse heading="Co-Signer" open={true}>
            {fields?.length > 0 &&
              fields?.map((_x, index) => {
                return (
                  <Box display="flex" flexDirection="column" key={_x.id}>
                    <Box display="flex" gap={2.5}>
                      <Box display="flex" flexDirection="column" flexGrow="1">
                        <CustomInputBox
                          control={control}
                          errors={!!errors?.coSigner?.[index]?.CoSignerName?.CoSignerFirstName}
                          errorText={
                            errors?.coSigner?.[index]?.CoSignerName?.CoSignerFirstName?.message
                          }
                          placeholder="Enter text"
                          label="First Name"
                          name={`coSigner[${index}].CoSignerName.CoSignerFirstName`}
                          flexDirection="column"
                          maxLength="15"
                          regex={/^[A-Za-z]$/}
                        />
                      </Box>
                      <Box display="flex" flexDirection="column" flexGrow="1">
                        <CustomInputBox
                          control={control}
                          errors={!!errors?.coSigner?.[index]?.CoSignerName?.CoSignerLastName}
                          errorText={
                            errors?.coSigner?.[index]?.CoSignerName?.CoSignerLastName?.message
                          }
                          placeholder="Enter text"
                          label="Last Name"
                          name={`coSigner[${index}].CoSignerName.CoSignerLastName`}
                          flexDirection="column"
                          maxLength="25"
                          regex={/^[A-Za-z]$/}
                        />
                      </Box>
                    </Box>

                    <Box display="flex" gap={2.5}>
                      {fields?.length < 2 && (
                        <Box display="flex" gap={1}>
                          <ControlPointIcon color="secondary" />
                          <Link
                            color="secondary"
                            component="button"
                            variant="subtitle2"
                            onClick={() => {
                              append({
                                CoSignerNumber: watchCoSigner.length + 1,
                                CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' },
                              });
                            }}
                          >
                            Add
                          </Link>
                        </Box>
                      )}
                      <Box display="flex" gap={1}>
                        <CancelOutlinedIcon color="secondary" />
                        <Link
                          color="secondary"
                          component="button"
                          variant="subtitle2"
                          onClick={() => {
                            if (index === 0 && fields?.length === 1) {
                              setShowCoSigner(false);
                            }
                            remove(index);
                          }}
                        >
                          Remove
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </CustomCollapse>
        </>
      )}
    </>
  );
};

export default AddCoUsers;

AddCoUsers.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  setError: PropTypes.func,
  setCoApplicantFlag: PropTypes.func,
  clearErrors: PropTypes.func,
};
AddCoUsers.defaultProps = {
  control: {},
  errors: {},
  setValue: () => {},
  setError: () => {},
  setCoApplicantFlag: () => {},
  clearErrors: () => {},
};
