import { Box, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { nameMapping } from 'utils/utility';

const GeneralInfo = ({ productDetails }) => {
  const { stateList } = useContext(AuthContext);
  const {
    valueGuide,
    collateralType,
    year,
    make,
    model,
    series,
    subModel,
    style,
    state,
    mileage,
    vin,
  } = productDetails;
  const stateMapping = stateList?.find((x) => x.value === state)?.name;
  console.log('stateMapping', { stateMapping, stateList, state });
  return (
    <>
      <Typography variant="subtitle1" sx={{ fontWeight: '700' }}>
        General Info
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        className={classes.borderTable}
        sx={{ backgroundColor: 'white', border: '1px solid #DADADA' }}
      >
        <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
          <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
            Collateral Type
          </Typography>
          <Typography variant="subtitle2" fontWeight="700">
            {nameMapping[collateralType]}
          </Typography>
        </Box>
        <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
          <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
            Value Guide Selection
          </Typography>
          <Typography variant="subtitle2" fontWeight="700">
            {nameMapping[valueGuide]}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        className={classes.borderTable}
        sx={{ minWidth: '70%', backgroundColor: 'white', border: '1px solid #DADADA' }}
      >
        {vin && (
          <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
            <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
              VIN
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              {vin}
            </Typography>
          </Box>
        )}
        <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
          <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
            Year
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {year}
          </Typography>
        </Box>
        <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
          <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
            Make
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {make}
          </Typography>
        </Box>
        <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
          <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
            Model
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {model}
          </Typography>
        </Box>
        <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
          <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
            {valueGuide === 'Distributor data' ? 'Detail' : 'Trim'}
          </Typography>
          <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
            {valueGuide === 'Distributor data' ? subModel : series}
          </Typography>
        </Box>
        {valueGuide !== 'Distributor data' && (
          <>
            <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
              <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
                Style
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {style}
              </Typography>
            </Box>
            {collateralType === 'used' && (
              <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
                <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
                  State
                </Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                  {stateMapping || state}
                </Typography>
              </Box>
            )}
            <Box display="flex" sx={{ borderBottom: '1px solid #F2F2F2' }} p={1}>
              <Typography variant="subtitle2" sx={{ minWidth: '150px' }}>
                Mileage
              </Typography>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                {mileage}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default GeneralInfo;

GeneralInfo.propTypes = {
  productDetails: PropTypes.instanceOf(Object),
};
GeneralInfo.defaultProps = {
  productDetails: {},
};
