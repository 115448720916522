/* eslint-disable react/prop-types */
import { Box, FormHelperText, InputLabel } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styles from 'styles/globalStyle.module.scss';

const CustomMultilineInput = (props) => {
  const {
    control,
    label,
    name,
    errors,
    errorText,
    isDisabled,
    required,
    // classes,
    flexDirection,
    placeholder,
    type,
    rows,

    ...rest
  } = props;

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Box
            display="flex"
            // alignItems="center"
            gap={0.625}
            my={0.5}
            flexDirection={flexDirection}
            {...rest}
          >
            <InputLabel htmlFor={name}>
              {required && <span style={{ color: 'red' }}>*&nbsp;</span>} {label}
            </InputLabel>

            <TextareaAutosize
              id={name}
              name={name}
              type={type}
              disabled={isDisabled ? true : false}
              {...field}
              // classes={{ root: classes }}
              className={styles.multilineInputComment}
              inputProps={{
                'data-testid': name,
              }}
              placeholder={placeholder}
              minRows={rows}
              // multiline
            />
          </Box>
        )}
      />
      {errors && (
        <FormHelperText gap={2} className={styles.customSelectError}>
          {' '}
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomMultilineInput;

CustomMultilineInput.propTypes = {
  control: PropTypes.instanceOf(Object),
  // classes: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.bool,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,

  errorText: PropTypes.string,
  label: PropTypes.string,
  flexDirection: PropTypes.string,
  placeholder: PropTypes.string,
  rest: PropTypes.instanceOf(Object),
  rows: PropTypes.number,
};
CustomMultilineInput.defaultProps = {
  control: {},
  // classes: '',
  name: '',
  type: 'text',
  label: '',
  errors: false,
  isDisabled: false,
  required: false,
  errorText: '',
  placeholder: '',
  flexDirection: 'row',
  rest: {},
  rows: 4,
};
