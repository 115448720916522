import { Box, Checkbox, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import classes from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

const Residuals = ({
  year,
  make,
  model,
  series,
  style,
  addDeductList,
  total,
  selectedRows,
  handleCheckboxChange,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="subtitle2">
        Select options for {year} {make} {model} {series} {style} - Spec{' '}
      </Typography>
      <Box display="flex" flexDirection="column" className={classes.tableOutline}>
        <Box
          display="flex"
          gap={2}
          sx={{ backgroundColor: '#F2F2F2' }}
          p={2}
          className={classes.borderTable}
        >
          <Typography
            variant="subtitle2"
            sx={{ flexGrow: 1, fontWeight: '700', marginLeft: '35px' }}
          >
            Description
          </Typography>

          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            12 Month
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            24 Month
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            36 Month
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            48 Month
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            60 Month
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
          {addDeductList?.map?.((x, innerIndex) => {
            const { description } = x;
            const partObj = (({
              residual_12,
              residual_24,
              residual_36,
              residual_48,
              residual_60,
            }) => ({
              residual_12,
              residual_24,
              residual_36,
              residual_48,
              residual_60,
            }))(x);
            return (
              <Box display="flex" gap={2} key={innerIndex} p={2}>
                <Checkbox
                  color="secondary"
                  checked={selectedRows.find((el) => el.description === description)}
                  onChange={(event) => handleCheckboxChange(event, x)}
                />
                <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                  {description}
                </Typography>
                {Object.keys(partObj).map((eachKey, index) => (
                  <Typography
                    key={index}
                    variant="subtitle2"
                    sx={{
                      minWidth: '10%',
                      maxWidth: '10%',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {numberToCurrency(x[eachKey])}
                  </Typography>
                ))}
              </Box>
            );
          })}
        </Box>

        <Box
          display="flex"
          gap={2}
          sx={{ backgroundColor: '#F2F2F2' }}
          p={2}
          className={classes.borderTable}
        >
          <Typography
            variant="subtitle2"
            sx={{ flexGrow: 1, fontWeight: '700', marginLeft: '35px' }}
          >
            Total Equipment Adjustment
          </Typography>
          {Object.keys(total).map((eachKey, index) => (
            <Typography
              variant="subtitle2"
              sx={{
                minWidth: '10%',
                maxWidth: '10%',
                overflowWrap: 'break-word',
                fontWeight: '700',
              }}
              key={index}
            >
              {numberToCurrency(total[eachKey])}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Residuals;

Residuals.propTypes = {
  year: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  series: PropTypes.string,
  style: PropTypes.string,
  total: PropTypes.instanceOf(Object),
  addDeductList: PropTypes.instanceOf(Object),
  selectedRows: PropTypes.instanceOf(Object),
  handleCheckboxChange: PropTypes.func,
};
Residuals.defaultProps = {
  year: '',
  make: '',
  model: '',
  series: '',
  style: '',
  total: {},
  addDeductList: [],
  selectedRows: [],
  handleCheckboxChange: () => {},
};
