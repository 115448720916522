import { yupResolver } from '@hookform/resolvers/yup';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { CreateApplicationSchema } from 'schemaValidations/CreateApplicationSchema.js';

import ApplicationType from '../ApplicationTypeDialog/ApplicationTypeDialog.js';
import CoAppDetailDialog from '../CoAppDetailDialog/CoAppDetailDialog.js';
import CoAppDialog from '../CoAppDialog/CoAppDialog.js';
import InvitationMaualDialog from '../InvitationManualDialog/InvitationManualDialog.js';
import MoreDetailDialog from '../MoreDetailDialog/MoreDetailDialog.js';

const CreateAppMain = () => {
  const [showApptype, setShowAppType] = useState(true);
  const [showCoApp, setShowCoApp] = useState(false);
  const [showInviManual, setShowInviManual] = useState(false);
  const [isMailResidentSame, setIsMailResidentSame] = useState(true);

  const [showCoAppDet, setShowCoAppDet] = useState(false);
  const [coSignerContainer, setCosignerContainer] = useState(false);
  const [addCoSigner, setAddCoSigner] = useState(false);
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const [applicationId, setApplicationId] = useState('');
  const [isSkipped, setIsSkipped] = useState(false);
  const [appOrSign, setAppOrSign] = useState([]);
  const [cosignerEnabled, setCosignerEnabled] = useState(false);
  const [showInformationRequestPage, setShowInformationRequestPage] = useState(false);
  const [createAppSchema1, setCreateAppSchema1] = useState({});

  const {
    control,
    watch,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      applicationType: '',
      applicantOrSigner: [],
      invitationOrManual: '',
      CoAppFirstName: '',
      CoAppLastName: '',
      skipped: isSkipped,

      isNewCoSigner: false,

      CoSigner: [{ CoSignerName: { CoSignerFirstName: '', CoSignerLastName: '' } }],
    },
    resolver: yupResolver(CreateApplicationSchema(isSkipped, appOrSign, cosignerEnabled)),
  });

  const { fields, append, remove } = useFieldArray({
    name: 'CoSigner',
    control,
  });
  useEffect(() => {
    setValue('isNewCoSigner', addCoSigner);
    setAppOrSign(getValues('applicantOrSigner'));
  }, [addCoSigner, getValues('applicantOrSigner')]);

  return (
    <>
      <CreateApplicationContext.Provider
        value={{
          showApptype,
          setShowAppType,
          showCoApp,
          setShowCoApp,
          showInviManual,
          setShowInviManual,
          showCoAppDet,
          setShowCoAppDet,
          showMoreDetail,
          setShowMoreDetail,
          applicationId,
          setApplicationId,
          showInformationRequestPage,
          setShowInformationRequestPage,
          isMailResidentSame,
          setIsMailResidentSame,
          isSkipped,
          setIsSkipped,
          createAppSchema1,
          setCreateAppSchema1,
        }}
      >
        {showApptype && (
          <ApplicationType
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
          />
        )}
        {showCoApp && (
          <CoAppDialog
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
            setCosignerEnabled={setCosignerEnabled}
          />
        )}

        {showCoAppDet && (
          <CoAppDetailDialog
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            watch={watch}
            addCoSigner={addCoSigner}
            setAddCoSigner={setAddCoSigner}
            coSignerContainer={coSignerContainer}
            setCosignerContainer={setCosignerContainer}
            handleSubmit={handleSubmit}
            fields={fields}
            append={append}
            remove={remove}
            clearErrors={clearErrors}
            cosignerEnabled={cosignerEnabled}
            setCosignerEnabled={setCosignerEnabled}
          />
        )}
        {showInviManual && (
          <InvitationMaualDialog
            control={control}
            reset={reset}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
          />
        )}
        {showMoreDetail && (
          <MoreDetailDialog
            control={control}
            reset={reset}
            setValue={setValue}
            errors={errors}
            getValues={getValues}
            watch={watch}
            handleSubmit={handleSubmit}
          />
        )}
      </CreateApplicationContext.Provider>
    </>
  );
};
export default CreateAppMain;
