import ApiClass from 'Api/ApiClient';
import axios from 'axios';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { imgExtension } from 'utils/utility';

export const getAccessToken = async () => {
  console.log('into get token');
  const tokenResponse = await axios.post(
    process.env.REACT_APP_COMMON_SERVICE_TOKEN_URL,
    {
      client_id: process.env.REACT_APP_COMMON_SERVICE_CLIENT_ID,
      grant_type: 'client_credentials',
      client_secret: process.env.REACT_APP_COMMON_SERVICE_CLIENT_SECRET,
    },
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
  );
  console.log('into get token resp', tokenResponse);
  const { access_token } = tokenResponse?.data;
  return access_token;
};

export const getAddressLookupData = async (country, word, token) => {
  const ApiClient = new ApiClass();
  let addressLookupResponse = {};
  try {
    addressLookupResponse = await ApiClient.get(
      `${UrlHelpers.commonComponentsAddressLookup}?ff=${word}&country=${country}&maxrecords=7`,
      {
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      }
    );
  } catch (e) {
    return addressLookupResponse;
  }
  return addressLookupResponse;
};

export const ocrFileUpload = async (
  file,
  commonToken,
  typee,
  successCallback,
  failurecallback,
  setLoader
) => {
  const ApiClient = new ApiClass();
  if (file && commonToken && typee) {
    const formData = new FormData();
    formData.append('file', file);

    const { name, type } = file;
    let format = imgExtension(type);
    try {
      setLoader(true);
      const response = await ApiClient.post(UrlHelpers.commonComponentsOcr, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${commonToken}`,
        },
        params: {
          format,
          name,
          type: 'Home Adress Proof',
          source: 'Auto',
          eventType: 'UI',
          billType: typee,
        },
      });

      successCallback(response);
    } catch (e) {
      console.log('fiel upload err', e);
      failurecallback(e);
    } finally {
      setLoader(false);
    }
  }
};
export const emailValidation = async (email, commonToken, setError, setLoader) => {
  let validFlag = true;
  const ApiClient = new ApiClass();
  try {
    setLoader(true);
    const response = await ApiClient.post(
      UrlHelpers.commonComponentsEmailVerification,
      [
        {
          email,
          eventType: 'UI',
          source: 'auto',
        },
      ],
      {
        headers: { Authorization: `Bearer ${commonToken}`, 'Content-Type': 'application/json' },
      }
    );
    const { status, message } = response[0];
    if (status !== 'Valid' && message === 'Email Domain Not Found') {
      setError('email', { type: 'custom', message: 'Invalid email domain' });
      validFlag = false;
    } else if (status !== 'Valid') {
      setError('email', { type: 'custom', message: 'Invalid email' });
      validFlag = false;
    }
  } catch (e) {
    console.log(e);
    validFlag = false;
  } finally {
    setLoader(false);
  }
  return validFlag;
};

export const phoneValidation = async (
  areaCode1,
  mobile1,
  areaCode2,
  mobile2,
  commonToken,
  setError,
  setLoader
) => {
  let validFlag = true;
  const payload = [
    {
      phoneNumber: areaCode1 + mobile1,
      eventType: 'UI',
      source: 'auto',
    },
  ];
  if (areaCode2 && mobile2) {
    payload.push({
      phoneNumber: areaCode2 + mobile2,
      eventType: 'UI',
      source: 'auto',
    });
  }
  const ApiClient = new ApiClass();
  try {
    setLoader(true);
    const response = await ApiClient.post(UrlHelpers.commonComponentsPhoneVerification, payload, {
      headers: { Authorization: `Bearer ${commonToken}`, 'Content-Type': 'application/json' },
    });
    console.log('---------------------', response);
    const mobile1status = response.find((x) => x.phoneNumber === areaCode1 + mobile1)?.status;
    if (mobile1status !== 'Valid') {
      setError('mobile', { type: 'custom', message: 'Invalid Phone Number' });
      validFlag = false;
    }
    if (areaCode2 && mobile2) {
      const mobile2status = response.find((x) => x.phoneNumber === areaCode2 + mobile2)?.status;
      if (mobile2status !== 'Valid') {
        setError('mobile2', { type: 'custom', message: 'Invalid Phone Number' });
        validFlag = false;
      }
    }
  } catch (e) {
    console.log(e);
    console.log('---------------------in Catch');
  } finally {
    setLoader(false);
  }
  return validFlag;
};
