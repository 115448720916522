import { Box, Button, Grid, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import RegisterUserContext from 'context/RegisterUserContext';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomCollapse from 'components/CustomCollapse';
import Loader from 'components/Loader/Loader';

const UserProfileSubmit = () => {
  const [loader, setLoader] = useState(false);
  const { formDetails } = useContext(RegisterUserContext);
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const yesAndNo = {
    Yes: true,
    No: false,
  };
  const submitProfile = async () => {
    // console.log('formDetails');
    // console.log(formDetails);
    // console.log('moment(formDetails.dob)');
    // console.log(moment(formDetails.dob).format('YYYY-DD-MM'));
    const formData = {
      ...formDetails,
      userSetupDate: moment().format('YYYY-DD-MM'),
      timeZone: 'PR',
      userType: 'Dealer Portal',
      dealerType: 'dealer',
      status: 'active',
      lastInactivationDate: null,
      lastReActivationDate: null,
      lastLogin: null,
    };
    formData.dob = moment(formDetails.dob).format('YYYY-DD-MM');
    formData.dealers = formDetails?.dealers?.map(({ dealerId }) => dealerId);

    formData['includeLoanOriginator'] = yesAndNo[formDetails['includeLoanOriginator']];
    formData['includeReference'] = yesAndNo[formDetails['includeReference']];
    formData['includeSalesManager'] = yesAndNo[formDetails['includeSalesManager']];
    formData['isActive'] = yesAndNo[formDetails['isActive']];
    formData['canSeeOwnApplication'] = yesAndNo[formDetails['canSeeOwnApplication']];
    formData['maximizeTty'] = yesAndNo[formDetails['maximizeTty']];
    formData.phone = '+1' + formDetails.areaCode + formDetails.mobile;
    console.log('final formData');
    console.log(formData);
    try {
      setLoader(true);
      const r = await ApiClient.post(
        `${UrlHelpers.enrollUserSubmit}`,
        { ...formData },
        {
          headers: {
            appid: 'auto',
            'x-api-key': process.env.REACT_APP_X_API_KEY,
            'Content-Type': 'application/json',
          },
        }
      );
      // setData(resp);
      console.log('f===================');
      console.log(r);
      navigate('/manage-dealers');
    } catch (e) {
      console.log('kkkkkkkkkk');
      console.log('e', e);
    } finally {
      setLoader(false);
      console.log('finally');
    }
  };
  // formDetails;
  return (
    <>
      {loader && <Loader open={loader} />}
      <Typography variant="h3" component="p" py={4}>
        Review and Submit
      </Typography>
      <Grid container justifyContent="center">
        <Box sx={{ width: '70%' }}>
          <CustomCollapse heading="User Information" open={true}>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">User ID</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.userId || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Linked User</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.linkedUserId || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">ID Time Zone</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.timeZone || 'PR'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">User Setup Date</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.userSetupDate || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">First Name</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.firstName || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Last Name</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.lastName || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Second Last Name</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.secondLastName || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Phone</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {'+1' + formDetails?.areaCode + formDetails?.mobile}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Email</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails.email || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Include in Loan Dealer?</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.includeLoanOriginator === true ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Include in Sales Manager?</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.includeSalesManager === true ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Include in Reference?</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.includeReference === true ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">
                  Can only see own applications in portal?
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                  {formDetails?.canSeeOwnApplication === true ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            </Grid>
          </CustomCollapse>
          <CustomCollapse heading="User Status and Configuration" open={true}>
            <Typography
              fontSize="18px !important"
              sx={{ fontWeight: 'bold', marginBottom: '10px' }}
            >
              User Status
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Status</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">{formDetails.status}</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Password</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">{'-'}</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Active</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">
                  {formDetails?.isActive ? 'Yes' : 'No'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Last Inactivation Date</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">
                  {formDetails?.lastInactivationDate || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Last Reactivation Date</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">
                  {formDetails?.lastReActivationDate || '-'}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Last Login</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">{formDetails?.lastLogin || '-'}</Typography>
              </Grid>
            </Grid>

            <Typography
              fontSize="18px !important"
              sx={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}
            >
              User Configuration
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Maximize TTY?</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">
                  {formDetails?.maximizeTty === true ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              fontSize="18px !important"
              sx={{ fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}
            >
              Miscellaneous fields
            </Typography>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Date Of Birth</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">
                  {formDetails?.dob ? moment(formDetails.dob).format('YYYY-DD-MM') : ''}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">SSN</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">{formDetails.ssn || ''}</Typography>
              </Grid>
            </Grid>
          </CustomCollapse>
          <CustomCollapse heading="User Profile" open={true}>
            <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Team</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">{formDetails.team || ''}</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">Security Profile</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">
                  {formDetails.securityProfile || ''}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">User Type</Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography fontSize="14px !important">{'Dealer Portal'}</Typography>
              </Grid>
            </Grid>
          </CustomCollapse>
        </Box>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
          <Link
            component="button"
            onClick={() => {
              navigate('/manage-dealers');
            }}
            variant="subtitle2"
            color="secondary"
            sx={{ fontSize: '16px' }}
            data-testid="discard-userprofilesubmit-application"
          >
            Discard Application
          </Link>
          {/* <Button color="secondary" variant="outlined">
            Save as draft
          </Button> */}
          <Button
            color="secondary"
            variant="contained"
            onClick={submitProfile}
            // disabled={Object.entries(errors)?.length}
            data-testid="save-continue-userprofilesubmit"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserProfileSubmit;
