/* eslint-disable react/prop-types */
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { getAccessToken } from 'Services/commonComponentsApi';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MoreDetailDialogSchema } from 'schemaValidations/MoreDetailDialogSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import Loader from 'components/Loader/Loader';

import ApplicationDialogHeading from '../InformationRequest/moduleUtilities/ApplicationDialogHeading';
import { frameInfoReqPayload } from '../InformationRequest/moduleUtilities/InfoReqPayload';
import { popUpApis } from '../InformationRequest/moduleUtilities/dropdownApis';

// eslint-disable-next-line no-unused-vars
const MoreDetailDialog = ({ control: controlMain, getValues }) => {
  const { showMoreDetail, setShowMoreDetail, setShowInviManual, createAppSchema1 } =
    useContext(CreateApplicationContext);
  const {
    setOpenPopUp,
    setCreateAppApplicationId,
    loanPurposeList,
    setLoanPurposeList,
    programList,
    setProgramList,
    commonToken,
    setCommonToken,
  } = useContext(AuthContext);
  const { userType } = useContext(MainApplicationContext);
  const [popUpLoader, setPopUpLoader] = useState(false);
  const [initialApiLoader, setInitialApiLoader] = useState(false);
  const ApiClient = new ApiClass();
  const navigate = useNavigate();
  const handleClose = () => {
    setShowMoreDetail(false);
    setOpenPopUp(false);
  };
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      loanPurpose: '',
      program: '',
      dealer: '',
      manager: '',
      dealerSalesReference: '',
    },
    resolver: yupResolver(MoreDetailDialogSchema),
  });

  useEffect(async () => {
    if (!commonToken) {
      const token = await getAccessToken();

      setCommonToken(token);
    }
    await popUpApis({
      loanPurposeList,
      setLoanPurposeList,
      programList,
      setProgramList,
      setInitialApiLoader,
    });
  }, []);

  useEffect(() => {
    if (loanPurposeList?.length) {
      setValue('loanPurpose', loanPurposeList[0]?.value);
    }
  }, [loanPurposeList]);
  const [CoAppFirstName, CoAppLastName, CoSigner] = useWatch({
    control: controlMain,
    name: ['CoAppFirstName', 'CoAppLastName', 'CoSigner'],
  });

  let additionalUsers = {};
  const cosignerArray = CoSigner.filter((x) => !!x.CoSignerName?.CoSignerFirstName) || [];
  if (CoAppFirstName) {
    additionalUsers.coApplicant = {
      CoAppFirstName,
      CoAppLastName,
    };
  }
  if (cosignerArray?.length) {
    let cosignerArraywithNumber = cosignerArray.map((x, index) => {
      return { ...x, CoSignerNumber: index + 1 };
    });
    additionalUsers.coSigner = cosignerArraywithNumber;
  }

  const handleBack = () => {
    setShowMoreDetail(false);
    setShowInviManual(true);
  };

  const handleContinue = async (data) => {
    const { loanPurpose, program, dealer, manager, dealerSalesReference } = data;

    const coApplicant = {
      CoAppFirstName: createAppSchema1?.CoAppFirstName,
      CoAppLastName: createAppSchema1?.CoAppLastName,
    };
    const formData = {
      LoanAppType: 'Personal',
      CreateAppType: 'Manual',
      LoanInformation: {
        LoanPurpose: loanPurpose,
        Program: program,
        Dealer: dealer,
        Manager: manager,
        DealerSalesReference: dealerSalesReference,
      },
    };
    const createAppPayload = await frameInfoReqPayload({
      formData,
      userType,
      coApplicant: createAppSchema1?.CoAppFirstName ? coApplicant : {},
      coSigner: createAppSchema1?.CoSigner?.[0]?.CoSignerName?.CoSignerFirstName
        ? createAppSchema1?.CoSigner
        : [],

      dealerId: '1234',
      userId: 'co2607',
      step: 0,
    });
    try {
      setPopUpLoader(true);
      let dataa = await ApiClient.post(
        UrlHelpers.informationReqSave,

        { ...createAppPayload },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (dataa) {
        setCreateAppApplicationId(dataa?.applicationId);

        setShowMoreDetail(false);
        setOpenPopUp(false);
        navigate('/home/create-app', {
          state: {
            activeStep: 1,
            coApplicant: additionalUsers?.coApplicant,
            coSigner: additionalUsers?.coSigner,
          },
        });
      }
    } catch (e) {
      console.log('error fetching', e);
      setOpenPopUp(false);
    } finally {
      setPopUpLoader(false);
    }
  };

  return (
    <>
      <Dialog
        open={showMoreDetail}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <Loader open={initialApiLoader} />
        <ApplicationDialogHeading handleBack={handleBack} handleClose={handleClose} />
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}
        >
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4" textAlign="center" my={3}>
              Nice! Just few more details to create the application
            </Typography>
            <Loader open={popUpLoader} />
            <Grid container gap={1} justifyContent="space-between" rowSpacing={2}>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Loan Purpose"
                  control={control}
                  name="loanPurpose"
                  errors={!!errors?.loanPurpose}
                  errorText={errors?.loanPurpose?.message}
                  options={loanPurposeList}
                  isDisabled={loanPurposeList?.length}
                  required
                  displayEmpty
                />
              </Grid>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomRadioButton
                  control={control}
                  radioValues={programList}
                  label="Program"
                  required
                  name="program"
                  dataTestId="program"
                  errors={!!errors?.program}
                  errorText={errors?.program?.message}
                  displayInline
                />
              </Grid>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Dealer"
                  control={control}
                  name="dealer"
                  errors={!!errors?.dealer}
                  errorText={errors?.dealer?.message}
                  options={[{ name: 'General Motors', value: 'generalMotors' }]}
                  defaultValue=""
                  required
                  displayEmpty
                />
              </Grid>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Manager"
                  control={control}
                  name="manager"
                  errors={!!errors?.manager}
                  errorText={errors?.manager?.message}
                  options={[{ name: 'Manager', value: 'manager' }]}
                  defaultValue=""
                  required
                  displayEmpty
                />
              </Grid>
              <Grid item lg={5.5} md={6} sm={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Dealer Sales Reference"
                  control={control}
                  name="dealerSalesReference"
                  errors={!!errors?.dealerSalesReference}
                  errorText={errors?.dealerSalesReference?.message}
                  options={[{ name: 'Reference', value: 'reference' }]}
                  defaultValue=""
                  required
                  displayEmpty
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={handleSubmit(handleContinue)}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MoreDetailDialog;

MoreDetailDialog.propTypes = {
  control: PropTypes.instanceOf(Object),
};
MoreDetailDialog.defaultProps = {
  control: {},
};
