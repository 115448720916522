import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { Box, Button, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { numberToCurrency } from 'utils/utility';

import Loader from 'components/Loader/Loader';

const VehicleValueSummary = ({
  data,
  selectedRows,
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
}) => {
  const {
    setShowSummary,
    createAppFlag,
    pageData,
    collateralInfo,
    setCollateralInfo,
    setLookupSaved,
    createAppApplicationId,
    lookupLinkClicked,
    setLookupValue,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const payload = {
      vehicleValues: {
        basevehicleValue: data?.base_whole_xclean,
        equipmentAdjustment: data?.add_deduct_whole_xclean || 0,
        mileageAdjustment: data?.mileage_whole_xclean,
        selectedEquipmentAjustment: selectedRows,
        priceIncludes: data?.price_includes,
        originalMsrp: data?.msrp,
        totalMSRP: totalFullObj[0]?.WholeSale.xClean,
      },
      productDetails: pageData?.productDetails,
    };
    if (!createAppFlag && !lookupLinkClicked) {
      setLookupValue([{ ...payload }]);
    }
  }, [data]);

  const vehicleSummary = [
    {
      name: 'WholeSale',
      data: [
        {
          name: 'Base Vehicle Value',
          xClean: data?.base_whole_xclean || collateralInfo?.vehicleValues?.basevehicleValue,
          // clean: data?.base_whole_clean,
          // avg: data?.base_whole_avg,
          // rough: data?.base_whole_rough,
        },

        {
          name: 'Equipment Adjustment',
          xClean:
            data?.add_deduct_whole_xclean ||
            collateralInfo?.vehicleValues?.equipmentAdjustment ||
            0,
          // clean: data?.add_deduct_whole_clean || 0,
          // avg: data?.add_deduct_whole_avg || 0,
          // rough: data?.add_deduct_whole_rough || 0,
        },

        {
          name: 'Mileage Adjustment',
          xClean: data?.mileage_whole_xclean || collateralInfo?.vehicleValues?.mileageAdjustment,
          // clean: data?.mileage_whole_clean,
          // avg: data?.mileage_whole_avg,
          // rough: data?.mileage_whole_rough,
        },
      ],
    },
    // {
    //   name: 'Retail',
    //   data: [
    //     {
    //       name: 'Base Vehicle Value',
    //       xClean: data?.base_retail_xclean,
    //       clean: data?.base_retail_clean,
    //       avg: data?.base_retail_avg,
    //       rough: data?.base_retail_rough,
    //     },

    //     {
    //       name: 'Equipment Adjustment',
    //       xClean: data?.add_deduct_retail_xclean || 0,
    //       clean: data?.add_deduct_retail_clean || 0,
    //       avg: data?.add_deduct_retail_avg || 0,
    //       rough: data?.add_deduct_retail_rough || 0,
    //     },

    //     {
    //       name: 'Mileage Adjustment',
    //       xClean: data?.mileage_retail_xclean,
    //       clean: data?.mileage_retail_clean,
    //       avg: data?.mileage_retail_avg,
    //       rough: data?.mileage_retail_rough,
    //     },
    //   ],
    // },
    // {
    //   name: 'Trade In',
    //   data: [
    //     {
    //       name: 'Base Vehicle Value',

    //       clean: data?.base_tradein_clean,
    //       avg: data?.base_tradein_avg,
    //       rough: data?.base_tradein_rough,
    //     },

    //     {
    //       name: 'Equipment Adjustment',

    //       clean: data?.add_deduct_tradein_clean || 0,
    //       avg: data?.add_deduct_tradein_avg || 0,
    //       rough: data?.add_deduct_tradein_rough || 0,
    //     },

    //     {
    //       name: 'Mileage Adjustment',

    //       clean: data?.mileage_tradein_clean,
    //       avg: data?.mileage_tradein_avg,
    //       rough: data?.mileage_tradein_rough,
    //     },
    //   ],
    // },
  ];

  const totalFullObj = vehicleSummary.map((x) => {
    const totalcalc = x?.data?.reduce(
      (acc, eachObj) => {
        let totall = acc;
        totall.xClean += eachObj?.xClean;
        return totall;
      },
      { xClean: 0 }
    );

    return {
      [x.name]: totalcalc,
    };
  });

  console.log('total', totalFullObj[0]);
  console.log('total1', totalFullObj[0].WholeSale);
  console.log('total2', totalFullObj[0].WholeSale.xClean);

  const onSaveValue = async () => {
    const payload = {
      vehicleValues: {
        basevehicleValue: data?.base_whole_xclean,
        equipmentAdjustment: data?.add_deduct_whole_xclean || 0,
        mileageAdjustment: data?.mileage_whole_xclean,
        selectedEquipmentAjustment: selectedRows,
        priceIncludes: data?.price_includes,
        originalMsrp: data?.msrp,
        totalMSRP: totalFullObj[0]?.WholeSale.xClean,
      },
      productDetails: pageData?.productDetails,
    };

    if (!lookupLinkClicked) {
      setCollateralInfo({ ...payload });
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.saveLookupValue,
          {
            applicationId: createAppApplicationId,
            type: 'vehicle',
            searchResult: JSON.stringify({ ...payload }),
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        console.log(resp);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    }
    setLookupSaved(true);
    navigate('/home/create-app', {
      state: {
        activeStep: 3,
        completedSteps: [1, 2],
        coApplicant,
        coSigner,
        reviewFlag,
        calculationState,
      },
    });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2.5} flexGrow="1" pb={5}>
      {loader && <Loader open={loader} />}
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Vehicle Values
        </Typography>
        <Box display="flex" gap="2px">
          <LocalPrintshopOutlinedIcon color="secondary" />
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </Link>
        </Box>
      </Box>

      {vehicleSummary.map((x, index) => {
        return (
          <Box display="flex" flexDirection="column" key={index} className={classes.tableOutline}>
            <Typography
              variant="subtitle2"
              sx={{
                backgroundColor: '#F2F2F2',
                borderBottom: '1px solid f2f2f2',
                padding: '10px',
                fontWeight: 700,
              }}
            >
              {x.name}
            </Typography>
            <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
              <Box
                display="flex"
                gap={2}
                sx={{ backgroundColor: '#f9f9f9' }}
                p={2}
                className={classes.childBorderLine}
              >
                <Typography variant="subtitle2" sx={{ flexGrow: 1, fontWeight: 700 }}></Typography>

                {x.name !== 'Trade In' && (
                  <Typography
                    variant="subtitle2"
                    sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                  >
                    XClean
                  </Typography>
                )}
                {/* <Typography
                  variant="subtitle2"
                  sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                >
                  Clean
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                >
                  Average
                </Typography> */}
                {/* <Typography
                  variant="subtitle2"
                  sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                >
                  Rough
                </Typography> */}
              </Box>
              {x?.data?.map((eachObj, dataIndex) => {
                const { name, xClean } = eachObj;
                return (
                  <Box display="flex" gap={2} p={2} key={dataIndex}>
                    <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                      {name}
                    </Typography>
                    {x.name !== 'Trade In' && (
                      <Typography variant="p" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                        {numberToCurrency(xClean)}
                      </Typography>
                    )}
                    {/* <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                      {numberToCurrency(clean)}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                      {numberToCurrency(avg)}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                      {numberToCurrency(rough)}
                    </Typography> */}
                  </Box>
                );
              })}
              <Box display="flex" gap={2} sx={{ backgroundColor: '#f9f9f9' }} p={2}>
                <Typography variant="p" sx={{ flexGrow: 1, fontWeight: 700 }}>
                  Total value
                </Typography>
                {x.name !== 'Trade In' && (
                  <Typography
                    variant="subtitle2"
                    sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                  >
                    {numberToCurrency(totalFullObj?.[index]?.[x.name]?.xClean)}
                  </Typography>
                )}
                {/* <Typography
                  variant="subtitle2"
                  sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                >
                  {numberToCurrency(totalFullObj?.[index]?.[x.name]?.clean)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                >
                  {numberToCurrency(totalFullObj?.[index]?.[x.name]?.avg)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                >
                  {numberToCurrency(totalFullObj?.[index]?.[x.name]?.rough)}
                </Typography> */}
              </Box>
            </Box>
          </Box>
        );
      })}

      <Box display="flex" flexDirection="column" gap={1.25} className={classes.tableOutline}>
        <Typography
          variant="subtitle2"
          sx={{
            backgroundColor: '#F2F2F2',
            borderBottom: '1px solid f2f2f2',
            padding: '10px',
            fontWeight: 700,
          }}
        >
          Miscellaneous
        </Typography>

        <Box display="flex" className={classes.borderTable} p={2} justifyContent="space-between">
          <Typography variant="subtitle2" sx={{ minWidth: '200px' }}>
            Price Includes :
          </Typography>
          <Typography variant="subtitle2" sx={{ minWidth: '10%' }}>
            {data?.price_includes || collateralInfo?.vehicleValues?.priceIncludes}
          </Typography>
        </Box>
        <Box display="flex" p={2} justifyContent="space-between">
          <Typography variant="subtitle2" sx={{ minWidth: '200px' }}>
            Original MSRP
          </Typography>
          <Typography variant="subtitle2" sx={{ minWidth: '10%' }}>
            {numberToCurrency(data?.msrp || collateralInfo?.vehicleValues?.originalMsrp)}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <Box display="flex" alignItems="center">
          {!lookupLinkClicked && (
            <>
              <KeyboardArrowLeftIcon color="secondary" />
              <Link
                component="button"
                variant="subtitle2"
                color="secondary"
                onClick={() => setShowSummary(false)}
              >
                Back
              </Link>
            </>
          )}
        </Box>
        <Box>
          {createAppFlag && (
            <Button onClick={onSaveValue} variant="contained" color="secondary">
              Save Value
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VehicleValueSummary;

VehicleValueSummary.propTypes = {
  data: PropTypes.instanceOf(Object),
  selectedRows: PropTypes.instanceOf(Array),
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: PropTypes.bool,
  calculationState: PropTypes.string,
};
VehicleValueSummary.defaultProps = {
  data: {},
  selectedRows: [],
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
};
