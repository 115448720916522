import ApiClass from 'Api/ApiClient';
import { UrlHelpers } from 'urlHelpers/urlHelper';

export const yearLists = async (setLoader) => {
  const ApiClient = new ApiClass();
  let response = null;
  try {
    setLoader(true);
    response = await ApiClient.get(UrlHelpers.distributedYear, {
      headers: { 'Content-Type': 'application/json' },
    });
    response = response?.slice(0, 2);
    return response;
  } catch (e) {
    console.log('error fetching', e);
    // return response;
  } finally {
    setLoader(false);
  }
  return response;
};

export const makeLists = async (setLoader, year) => {
  const ApiClient = new ApiClass();
  let response = null;
  try {
    setLoader(true);
    response = await ApiClient.get(`${UrlHelpers.distributedMakeList}?year=${year}`, {
      headers: { 'Content-Type': 'application/json' },
    });

    return response;
  } catch (e) {
    console.log('error fetching', e);
    // return response;
  } finally {
    setLoader(false);
  }
  return response;
};
export const modelLists = async (setLoader, year, make) => {
  const ApiClient = new ApiClass();
  let response = null;
  try {
    setLoader(true);
    response = await ApiClient.get(`${UrlHelpers.distributedModelList}?year=${year}&make=${make}`, {
      headers: { 'Content-Type': 'application/json' },
    });

    return response;
  } catch (e) {
    console.log('error fetching', e);
    // return response;
  } finally {
    setLoader(false);
  }
  return response;
};
export const subModelLists = async (setLoader, year, make, model) => {
  const ApiClient = new ApiClass();
  let response = null;
  try {
    setLoader(true);
    response = await ApiClient.get(
      `${UrlHelpers.distributedSubModelList}?year=${year}&make=${make}&model=${model}`,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );

    return response;
  } catch (e) {
    console.log('error fetching', e);
    // return response;
  } finally {
    setLoader(false);
  }
  return response;
};
