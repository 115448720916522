import { getAccessToken } from 'Services/commonComponentsApi';
import AuthContext from 'context/AuthContext';
import ErrorPage from 'pages/ErrorPage';
import InviFlow from 'pages/InviFlow/InviFlow';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProtectedRouteOutlet } from '../Routes/ProtectedRoute';
import protectedRoutes from '../Routes/routes';
import VouchedFlow from '../pages/CreateApplication/VouchedFlow/VouchedFlow';
import LoginFlow from '../pages/LoginFlow/LoginFlow';

const App = () => {
  const [accessToken, setAccessToken] = useState('');
  const [commonToken, setCommonToken] = useState(false);
  const [idToken, setIdToken] = useState('');
  const [userType, setUserType] = useState('dealer');
  const [openPopUp, setOpenPopUp] = useState(false);
  const [createAppApplicationId, setCreateAppApplicationId] = useState('');
  const [createAppFlag, setCreateAppFlag] = useState(false);
  const [showMainPopup, setShowMainPopup] = useState(false);
  const [showSecondaryPopup, setShowSecondaryPopup] = useState(false);
  const [showInterPopup, setShowInterPopup] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [subModelList, setSubModelList] = useState([]);
  const [styleList, setStyleList] = useState([]);

  const [cityList, setCityList] = useState([]);
  const [residentStatusList, setResidentStatusList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [utilityBillList, setUtilityBillList] = useState([]);
  const [occupationList, setOccupationList] = useState([]);
  const [relationshipList, setRelationShipList] = useState([]);
  const [empStatusList, setEmpStatusList] = useState([]);
  const [industryList, setIndustryList] = useState([]);

  const [vinDetail, setVinDetail] = useState([]);
  const [distributorData, setDistributorResult] = useState([]);
  const [newVehicleDetails, setNewVehicleDetails] = useState([]);
  const [usedVehicleDetails, setUsedVehicleDetails] = useState([]);
  const [newDistributorDetails, setNewDistributorDetails] = useState([]);
  const [pageData, setPageData] = useState({});
  const [showPage, setShowPage] = useState('');
  const [collateralInfo, setCollateralInfo] = useState({});
  const [lookupSaved, setLookupSaved] = useState(false);
  const [lookupLinkClicked, setLookupLinkClicked] = useState(false);
  const [loanPurposeList, setLoanPurposeList] = useState([]);
  const [programList, setProgramList] = useState([]);
  const [newUsedList, setNewUsedList] = useState([]);
  const [monthsDeferredList, setMonthsDeferredList] = useState([]);
  const [lookupValue, setLookupValue] = useState({});
  const [rejectClicked, setRejectClicked] = useState(false);
  const [approveClicked, setApproveClicked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const tokenExpiry = 3600;

  useEffect(() => {
    const timer = setTimeout(async () => {
      const token = await getAccessToken();
      setCommonToken(token);
    }, tokenExpiry * 1000);
    return () => clearTimeout(timer);
  }, [commonToken]);

  return (
    <AuthContext.Provider
      value={{
        userType,
        accessToken,
        setUserType,
        setAccessToken,
        idToken,
        setIdToken,
        commonToken,
        setCommonToken,
        cityList,
        setCityList,
        residentStatusList,
        setResidentStatusList,
        maritalStatusList,
        setMaritalStatusList,
        utilityBillList,
        setUtilityBillList,
        occupationList,
        setOccupationList,
        empStatusList,
        setEmpStatusList,
        relationshipList,
        setRelationShipList,
        industryList,
        setIndustryList,
        openPopUp,
        setOpenPopUp,
        createAppApplicationId,
        setCreateAppApplicationId,
        createAppFlag,
        setCreateAppFlag,
        showSecondaryPopup,
        setShowSecondaryPopup,
        showMainPopup,
        setShowMainPopup,
        showInterPopup,
        setShowInterPopup,
        showSummary,
        setShowSummary,
        stateList,
        setStateList,
        yearList,
        setYearList,
        makeList,
        setMakeList,
        modelList,
        setModelList,
        subModelList,
        setSubModelList,
        styleList,
        setStyleList,
        vinDetail,
        setVinDetail,
        distributorData,
        setDistributorResult,
        newVehicleDetails,
        setNewVehicleDetails,
        usedVehicleDetails,
        setUsedVehicleDetails,
        newDistributorDetails,
        setNewDistributorDetails,
        pageData,
        setPageData,
        showPage,
        setShowPage,
        collateralInfo,
        setCollateralInfo,
        lookupSaved,
        setLookupSaved,
        lookupLinkClicked,
        setLookupLinkClicked,
        loanPurposeList,
        setLoanPurposeList,
        programList,
        setProgramList,
        newUsedList,
        setNewUsedList,
        monthsDeferredList,
        setMonthsDeferredList,
        lookupValue,
        setLookupValue,
        rejectClicked,
        setRejectClicked,
        approveClicked,
        setApproveClicked,
        dialogOpen,
        setDialogOpen,
      }}
    >
      <Routes>
        <Route exact path="/login" element={<LoginFlow />}></Route>
        <Route exact path="/invi-flow" element={<InviFlow />}></Route>
        <Route exact path="/saml-error" element={<ErrorPage />}></Route>
        <Route exact path="/vouched" element={<VouchedFlow />}></Route>
        {protectedRoutes.map((x, index) => (
          <Route key={index} path={x.path} exact element={<ProtectedRouteOutlet />}>
            <Route path="" element={x.element} />
          </Route>
        ))}

        <Route exact path="/#access_token=:token" element={null} />
      </Routes>
    </AuthContext.Provider>
  );
};
//sca analysis test

export default App;
