import { Box, FormHelperText, InputLabel, OutlinedInput, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import globalClasses from 'styles/globalStyle.module.scss';

const CustomPhoneNumber = (props) => {
  const phoneInputRef = useRef(null);
  const {
    control,
    label,
    name,
    error,
    errorText,
    error2,
    errorText2,
    isDisabled,
    required,
    classes,
    flexDirection,
    placeholder,
    areaCodeName,
    ...rest
  } = props;

  const handleAreaCodeKeyDown = (e) => {
    const value = e?.target?.value;
    if (value.length >= 3 && e.key !== 'Backspace') {
      phoneInputRef?.current?.focus();
    }
  };

  const handleInput = (event) => {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.625}
      my={0.5}
      flexDirection={flexDirection}
      {...rest}
    >
      <InputLabel htmlFor={name}>
        {required && <span style={{ color: 'red' }}>*&nbsp;</span>} {label}
      </InputLabel>
      <Box
        display="flex"
        sx={{ border: '1px solid #dadada', borderRadius: '2px', width: '99%' }}
        alignSelf="flex-start"
      >
        <Controller
          name={areaCodeName}
          control={control}
          render={({ field }) => {
            return (
              <TextField
                variant="outlined"
                type="tel"
                defaultValue="787"
                disabled={isDisabled ? true : false}
                {...field}
                classes={{ root: classes }}
                inputProps={{
                  'data-testid': 'areacode',
                  maxLength: '3',
                }}
                onKeyDown={handleAreaCodeKeyDown}
                onKeyPress={handleInput}
                InputProps={{
                  component: OutlinedInput,
                  classes: { notchedOutline: globalClasses.removeBorder },
                  sx: { paddingRight: 0 },
                }}
                sx={{ maxWidth: '30%' }}
                placeholder={placeholder}
              />
            );
          }}
        />
        <Controller
          id={name}
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <TextField
                variant="outlined"
                type="tel"
                defaultValue="787"
                disabled={isDisabled ? true : false}
                {...field}
                classes={{ root: classes }}
                inputProps={{
                  'data-testid': name,
                  maxLength: '7',
                  sx: { paddingLeft: 0 },
                }}
                onKeyPress={handleInput}
                InputProps={{
                  component: OutlinedInput,
                  classes: { notchedOutline: globalClasses.removeBorder },
                }}
                placeholder={placeholder}
                inputRef={phoneInputRef}
              />
            );
          }}
        />
      </Box>
      <Box display="flex" gap={2}>
        {error && (
          <FormHelperText gap={2} className={globalClasses.customSelectError}>
            {' '}
            {errorText}
          </FormHelperText>
        )}
        {error2 && (
          <FormHelperText gap={2} className={globalClasses.customSelectError}>
            {' '}
            {errorText2}
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default CustomPhoneNumber;

CustomPhoneNumber.propTypes = {
  control: PropTypes.instanceOf(Object),
  classes: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  error2: PropTypes.bool,
  isDisabled: PropTypes.bool,
  required: PropTypes.bool,
  errorText: PropTypes.string,
  errorText2: PropTypes.string,
  label: PropTypes.string,
  flexDirection: PropTypes.string,
  placeholder: PropTypes.string,
  areaCodeName: PropTypes.string,
  rest: PropTypes.instanceOf(Object),
};
CustomPhoneNumber.defaultProps = {
  control: {},
  classes: '',
  name: '',
  label: '',
  error: false,
  error2: false,
  isDisabled: false,
  required: false,
  errorText: '',
  errorText2: '',
  placeholder: '',
  flexDirection: 'row',
  rest: {},
  areaCodeName: '',
};
