import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import RegisterUserContext from 'context/RegisterUserContext';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { enrollDealerUserStatusSchema } from 'schemaValidations/enrollDealerUserStatusSchema';

import UserStatus from '../UserStatus/UserStatus';

// import BasicInformation from '../InformationRequest/BasicInformation/BasicInformation';

const UserStatusConfiguration = () => {
  const navigate = useNavigate();

  const { activeStep, setActiveStep, formDetails, setFormDetails } =
    useContext(RegisterUserContext);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',

    defaultValues: {
      isActive: '',
      maximizeTty: '',
      ssn: '',
      dob: '',
    },
    resolver: yupResolver(enrollDealerUserStatusSchema),
  });

  const [watchisActive, watchmaximizeTty, watchssn, watchdob] = watch([
    'isActive',
    'maximizeTty',
    'ssn',
    'dob',
  ]);
  const nextStep = () => {
    const formObj = {
      isActive: watchisActive,
      maximizeTty: watchmaximizeTty,
      ssn: watchssn,
      dob: watchdob,
    };
    setFormDetails({ ...formDetails, ...formObj });
    setActiveStep(activeStep + 1);
  };
  return (
    <>
      <Typography variant="h3" component="p" py={4}>
        User status and configuration
      </Typography>
      <Grid container justifyContent="center">
        <Box sx={{ width: '70%' }}>
          <UserStatus control={control} errors={errors} />
        </Box>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
          <Link
            component="button"
            onClick={() => {
              navigate('/manage-dealers');
            }}
            variant="subtitle2"
            color="secondary"
            sx={{ fontSize: '16px' }}
            data-testid="discard-userstatus-application"
          >
            Discard Application
          </Link>
          {/* <Button color="secondary" variant="outlined">
            Save as draft
          </Button> */}
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(nextStep)}
            disabled={Object.entries(errors)?.length}
            data-testid="save-continue-userstatus"
          >
            Save & continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserStatusConfiguration;
