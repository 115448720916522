import * as yup from 'yup';

export const approveRequestSchema = yup.object().shape({
  userId: yup
    .string()
    .required('* Mandatory Field')
    .test('len', 'Maximum 8 characters can be entered', (val) => val?.length <= 8)
    .matches(/^[a-zA-Záéíóúñü¿¡\d\s]*$/, '* Invalid format'),
  linkedUserId: yup
    .string()
    .required('* Mandatory Field')
    .test('len', 'Maximum 8 characters can be entered', (val) => val?.length <= 8)
    .matches(/^[a-zA-Záéíóúñü¿¡\d\s]*$/, '* Invalid format'),
  includeInLoanOriginator: yup.string().required('* Mandatory Field'),
  includeInSalesManager: yup.string().required('* Mandatory Field'),
  includeInReference: yup.string().required('* Mandatory Field'),
  canSeeOwnApplication: yup.string().required('* Mandatory Field'),
  active: yup.string().required('* Mandatory Field'),
  maximizeTY: yup.string().required('* Mandatory Field'),
  team: yup.string().required('* Mandatory Field'),
  securityProfile: yup.string().required('* Mandatory Field'),
});
