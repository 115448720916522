import CreateApplicationBase from 'pages/CreateApplication/CreateApplicationBase';
import InformationRequest from 'pages/CreateApplication/InformationRequest/InformationRequest';
import DistributedData from 'pages/DistributedData/DistributedData';
import Home from 'pages/Home/Home';
import Lookup from 'pages/Lookup/Lookup';
import PaymentCalculator from 'pages/PaymentCalculator/PaymentCalculator';
import { ManageDealers } from 'pages/RegisterUser/ManageDealers';
import RegisterUser from 'pages/RegisterUser/RegisterUser';
import UserRequest from 'pages/UserRequest/UserRequest';
import UserRequestStatus from 'pages/UserRequestStatus/UserRequestStatus';

const protectedRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/paymentCalculator',
    element: <PaymentCalculator />,
  },
  {
    path: '/distributed-data',
    element: <DistributedData />,
  },
  {
    path: '/lookup',
    element: <Lookup />,
  },
  {
    path: '/home/info-req',
    element: <InformationRequest />,
  },
  // {
  //   path: '/vouched',
  //   element: <VouchedFlow />,
  // },
  {
    path: '/home/create-app',
    element: <CreateApplicationBase />,
  },
  {
    path: '/manage-dealers',
    element: <ManageDealers />,
  },
  {
    path: '/register-user',
    element: <RegisterUser />,
  },
  {
    path: '/new-user-request',
    element: <UserRequest />,
  },
  {
    path: '/user-request',
    element: <UserRequestStatus />,
  },
];

export default protectedRoutes;
