import * as yup from 'yup';

export const EmploymentInformationSchema = yup.object().shape({
  empStatus: yup.string().required('* Mandatory Field'),
  empName: yup
    .string()
    .required('* Mandatory Field')
    .max(50, ' Employer name must be no more than 50 characters')
    .matches(/^[a-z0-9A-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
  empAddressLine1: yup
    .string()
    .required('* Mandatory Field')
    .max(40, 'Employer Address line 1 should be 40 characters or less')
    .matches(/^[a-z0-9A-Záéíóúñü¿¡,./-\s]*$/g, '* Invalid format'),
  empAddressLine2: yup
    .string()
    .nullable()
    .test('len', 'Employer Address line 2 should be 20 characters or less', (val) =>
      val ? val?.length <= 20 : true
    )
    .matches(/^[a-z0-9A-Záéíóúñü¿¡,./-\s]*$/, '* Invalid format'),
  empCountry: yup.string().required('* Mandatory Field'),
  empState: yup.string().required('* Mandatory Field'),
  empCity: yup.string().required('* Mandatory Field'),
  empZipcode: yup
    .string()
    .required('* Mandatory Field')
    .matches(/^[0-9]+$/, '* Invalid format')
    .test('len', '* Zipcode should have 5 digits', (val) => val?.length === 5),
  jobTitle: yup
    .string()
    .required('* Mandatory Field')
    .max(30, 'Job title must be no more than 30 characters')
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/g, '* Invalid format'),
  occupation: yup.string().nullable().required('* Mandatory Field'),
  industry: yup.string().required('* Mandatory field'),
  // .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/g, '* Invalid Format'),
  empYears: yup
    .string()
    .required('* Mandatory field')
    .matches(/^[0-9]*$/, '* Invalid Format')
    .test('month restriction', '* Invalid year', (val) => {
      return parseInt(val) <= 99;
    }),
  empMonths: yup
    .string()
    .required('* Mandatory field')
    .matches(/^[0-9]*$/, '* Invalid Format')
    .test('year restriction', '* Invalid month', (val) => {
      return parseInt(val) <= 11;
    })
    .when('empYears', {
      is: (val) => parseInt(val) === 0,
      then: yup
        .string()
        .test('check for zero', '* Please enter a valid value', (val) => parseInt(val) !== 0),
    }),
  mobile: yup
    .string()
    .required('* Mandatory field')
    .test('length', '* Mobile number should have 7 digits', (val) => {
      return val?.length === 7;
    })
    .matches(/^[0-9]+$/g),
  areaCode: yup
    .string()
    .required('* Area code is mandatory')
    .matches(/^[0-9]+$/g),
  monthlyIncome: yup
    .string()
    .nullable()
    .required('* Mandatory Field')
    .transform((value) => {
      // Remove the % sign and any non-numeric characters
      return value ? value.replace(/[^0-9.]/g, '') : null;
    }),

  addEmpName: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .required('* Mandatory Field')
      .max(50, 'Employer name must be no more than 50 characters')
      .matches(/^[a-z0-9A-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
  }),
  addJobTitle: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .required('* Mandatory Field')
      .max(30, 'Job title must be no more than 30 characters')
      .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/g, '* Invalid format'),
  }),
  mobile2: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .required('* Mandatory field')
      .test('length', '* Mobile number should have 7 digits', (val) => {
        return val?.length === 7;
      })
      .matches(/^[0-9]+$/g),
  }),
  addAreaCode: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .required('* Area code is mandatory')
      .matches(/^[0-9]+$/g),
  }),
  addOccupation: yup.string().when('showAddInfo', {
    is: true,
    then: yup.string().nullable().required('* Mandatory Field'),
  }),
  addMonthlyIncome: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .nullable()
      .required('* Mandatory Field')
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      }),
  }),
});

export const FormatValidationSchema = yup.object().shape({
  empStatus: yup.string(),
  empName: yup
    .string()
    .max(50, ' Employer name must be no more than 50 characters')
    .matches(/^[a-z0-9A-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
  empAddressLine1: yup
    .string()
    .max(40, 'Employer Address line 1 should be 40 characters or less')
    .matches(/^[a-z0-9A-Záéíóúñü¿¡,./-\s]*$/g, '* Invalid format'),
  empAddressLine2: yup
    .string()
    .nullable()
    .test('len', 'Employer Address line 2 should be 20 characters or less', (val) =>
      val ? val?.length <= 20 : true
    )
    .matches(/^[a-z0-9A-Záéíóúñü¿¡,./-\s]*$/, '* Invalid format'),
  empCountry: yup.string(),
  empState: yup.string(),
  empCity: yup.string(),
  empZipcode: yup
    .string()
    .test('len', '* Zipcode should have 5 digits', (val) => (val ? val?.length === 5 : true)),
  jobTitle: yup
    .string()
    .max(30, 'Job title must be no more than 30 characters')
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/g, '* Invalid format'),
  occupation: yup.string().nullable(),
  industry: yup.string(),
  // .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/g, '* Invalid Format'),
  empYears: yup.string().test('month restriction', '* Invalid year', (val) => {
    return val ? parseInt(val) <= 99 : true;
  }),
  empMonths: yup
    .string()
    .test('year restriction', '* Invalid month', (val) => {
      return val ? parseInt(val) <= 11 : true;
    })
    .when('empYears', {
      is: (val) => parseInt(val) === 0,
      then: yup
        .string()
        .test('check for zero', '* Please enter a valid value', (val) =>
          val ? parseInt(val) !== 0 : true
        ),
    }),
  mobile: yup.string().test('length', '* Mobile number should have 7 digits', (val) => {
    return val ? val?.length === 7 : true;
  }),
  areaCode: yup.string(),
  monthlyIncome: yup
    .string()
    .nullable()
    .transform((value) => {
      // Remove the % sign and any non-numeric characters
      return value ? value.replace(/[^0-9.]/g, '') : null;
    })

    .test('is-in-range', '* Invalid format', (value) => (value ? parseFloat(value) > 0 : true)),
  addEmpName: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .test('name length limit', '  Employer name must be no more than 50 characters', (val) => {
        return val ? val?.length <= 50 : true;
      })
      .matches(/^[a-z0-9A-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
  }),
  addJobTitle: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .test('name length limit', 'Job title must be no more than 30 characters', (val) => {
        return val ? val?.length <= 30 : true;
      })

      .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/g, '* Invalid format'),
  }),
  mobile2: yup.string().when('showAddInfo', {
    is: true,
    then: yup.string().test('length', '* Mobile number should have 7 digits', (val) => {
      return val ? val?.length === 7 : true;
    }),
  }),
  addAreaCode: yup.string().when('showAddInfo', {
    is: true,
    then: yup.string(),
  }),
  addOccupation: yup.string().nullable(),
  addMonthlyIncome: yup.string().when('showAddInfo', {
    is: true,
    then: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      }),
  }),
});
