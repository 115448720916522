/* eslint-disable */
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import Rejected from 'assets/images/Cancel.png';
import Approved from 'assets/images/Ok.png';
import AuthContext from 'context/AuthContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// import { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: 'clamp(400px, 50vw, 600px)',
    padding: '16px',
    minHeight: 'clamp(230px,30vh,100%)',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const StatusDialog = () => {
  const { approveClicked, setApproveClicked, setRejectClicked, dialogOpen, setDialogOpen } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const handleClose = () => {
    setDialogOpen(false);
    navigate('/home');
    setApproveClicked(false);
    setRejectClicked(false);
  };

  // const handleContinue = () => {
  //   setDialogOpen(false);
  // };

  return (
    <>
      <CustomDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogOpen}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}
        >
          <img src={approveClicked ? Approved : Rejected} />
          <Typography variant="h3" component="p">
            {approveClicked
              ? 'Application Approved successfully'
              : 'Application Rejected successfully'}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Go, Back Home
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Next Application
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default StatusDialog;
