import { Alert } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import CustomCollapse from 'components/CustomCollapse';

import FinancingDetails from '../FinancingDetails';

const EstimatedMonthlyPayment = ({ calculatedData }) => {
  const [showAlert, setShowAlert] = useState(false);
  const monthlyPayData = {
    ...calculatedData?.calculatedData,
    apr: calculatedData?.formData?.rate,
    baloonPayment: calculatedData?.formData?.balloonPay,
  };

  useEffect(() => {
    if (Object.entries(calculatedData).length > 0) setShowAlert(true);
  }, []);
  return (
    <>
      {showAlert && (
        <Alert
          severity="warning"
          onClose={() => setShowAlert(false)}
          sx={{
            background: 'rgba(239, 171, 57, 0.05)',
            borderWidth: '1px 1px 1px 5px',
            borderStyle: 'solid',
            borderColor: 'rgba(239, 171, 57, 0.5)',
          }}
        >
          Please click the payment calculator and enter vehicle payment information. Review the auto
          populated results in the financing terms and proceed. Iterate the value if required by
          taking the payment calculator action again.
        </Alert>
      )}

      <CustomCollapse
        heading="Estimated Monthly Payment"
        payCalcButton
        monthlyPayData={calculatedData}
        open={true}
      >
        <FinancingDetails monthlyPayData={monthlyPayData} calculatedData={calculatedData} />
      </CustomCollapse>
    </>
  );
};
export default EstimatedMonthlyPayment;

EstimatedMonthlyPayment.propTypes = {
  calculatedData: PropTypes.instanceOf(Object),
};
EstimatedMonthlyPayment.defaultProps = {
  calculatedData: {},
};
