import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;

TabPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),

  other: PropTypes.instanceOf(Object),
  index: PropTypes.number,

  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
TabPanel.defaultProps = {
  children: <></>,
  index: 0,
  value: 0,

  other: {},
};
