/* eslint-disable no-unused-vars */
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext } from 'react';

import Loader from 'components/Loader/Loader';

import CollateralInformation from './CollateralInformation/CollateralInformation';
import EmploymentInformation from './EmploymentInformation/EmploymentInformation';
import FinancialTerms from './FinancialTerms/FinancialTerms';
import InformationRequest from './InformationRequest/InformationRequest';
import ReviewAndSubmit from './ReviewAndSubmit/ReviewAndSubmit';

const RenderStep = () => {
  const { activeStep } = useContext(MainApplicationContext);
  switch (activeStep) {
    case 1: {
      return <InformationRequest />;
    }
    case 2: {
      return <EmploymentInformation />;
    }
    case 3: {
      return <CollateralInformation />;
    }
    case 4: {
      return <FinancialTerms />;
    }
    case 5: {
      return <ReviewAndSubmit />;
    }

    default: {
      return <Loader open />;
    }
  }
};

export default RenderStep;
