import CalculateIcon from '@mui/icons-material/Calculate';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import StorageIcon from '@mui/icons-material/Storage';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import AuthContext from 'context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Footer from 'components/Footer/Footer';
import Header from 'components/Header/Header';

import manageDealerIcon from '../../assets/manageDealerIcon.svg';
import classes from '../../styles/globalStyle.module.scss';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(12)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function SideBar() {
  const navigate = useNavigate();
  let {
    userType,
    accessToken,
    setUserType,
    setAccessToken,
    idToken,
    setIdToken,
    setShowPage,
    setCreateAppFlag,
    setApproveClicked,
    setRejectClicked,
  } = useContext(AuthContext);
  const [didMount, setDidMount] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = new useQuery();

  useEffect(() => {
    if (query.get('userType')) {
      userType = query.get('userType');
      setUserType(userType);
    }
    if (query.get('idToken') && query.get('accessToken')) {
      accessToken = query.get('accessToken');
      idToken = query.get('idToken');
      setAccessToken(accessToken);
      // to be removed
      setIdToken(idToken);
    }

    const hash = window?.location?.hash;

    if (hash && hash.indexOf('error_description') === -1) {
      accessToken = hash.split('&')[0]?.split('=')[1];
      idToken = hash.split('&')[1]?.split('=')[1];
      setAccessToken(accessToken);
      setIdToken(idToken);
      setUserType('banker');
    } else if (hash && hash.indexOf('error_description') > -1) {
      navigate('/saml-error');
    } else if (!accessToken) {
      navigate('/login');
    } else if (userType === 'new') {
      navigate('/invi-flow');
    }

    setDidMount(true);
  }, []);
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (_event, index) => {
    setShowPage('');
    setCreateAppFlag(false);
    setSelectedIndex(index);
    setApproveClicked(false);
    setRejectClicked(false);
    switch (index) {
      case 0: {
        navigate('/home');
        break;
      }
      case 1:
        navigate('/manage-dealers');
        break;
      case 2:
        navigate('/paymentCalculator');
        break;
      case 3:
        navigate('/lookup');
        break;
      case 4:
        navigate('/distributed-data');
        break;
      default:
        break;
    }
  };
  const handleIcons = (index) => {
    switch (index) {
      case 0:
        return <HomeIcon />;
      case 1:
        return <img src={manageDealerIcon} />;
      case 2:
        return <CalculateIcon />;
      case 3:
        return <FindInPageIcon />;
      case 4:
        return <StorageIcon />;
      default:
        break;
    }
  };

  return (
    <>
      {didMount && (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          <Drawer
            variant="permanent"
            open={open}
            PaperProps={{
              sx: {
                backgroundColor: '#F28023',
                color: '#fff',
              },
            }}
            sx={{ whiteSpace: 'break-spaces' }}
          >
            <DrawerHeader sx={{ display: 'block', padding: 0 }}>
              <List>
                <ListItem sx={{ padding: 0 }}>
                  <ListItemButton
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    sx={{
                      px: 2.5,
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : '',
                        justifyContent: 'center',
                      }}
                    >
                      <MenuIcon sx={{ color: 'white' }} />
                    </ListItemIcon>
                    {open && <ListItemText primary="Oriental" sx={{ opacity: open ? 1 : 0 }} />}
                  </ListItemButton>
                </ListItem>
              </List>
            </DrawerHeader>

            <Divider />
            <List>
              {['Home', 'Manage dealers', 'Payment Calculator', 'LookUp', 'Distributed data'].map(
                (text, index) => (
                  <ListItem key={text} disablePadding sx={{ display: 'block', margin: '15px 0' }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                      className={!open ? classes.listItemBtnOpen : classes.listItemBtnClose}
                      selected={selectedIndex === index}
                      onClick={(event) => handleListItemClick(event, index)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                          color: '#fff',
                        }}
                      >
                        {handleIcons(index)}
                      </ListItemIcon>

                      <ListItemText
                        primary={text}
                        primaryTypographyProps={{ fontSize: '0.85rem' }}
                      />
                    </ListItemButton>
                  </ListItem>
                )
              )}
            </List>
          </Drawer>

          <Box component="main" sx={{ flexGrow: 1 }}>
            <Header open={open} />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={classes.contentContainer}
            >
              <Paper elevation={0} className={classes.paperBg}>
                <Outlet />
              </Paper>
            </Box>
            <Footer />
          </Box>
        </Box>
      )}
    </>
  );
}
