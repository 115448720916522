import { Grid } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

import constants from '../../utils/constants';
import { VehicleClass, conventionalTerm, orientalTerm } from '../../utils/data';

const { program, term, contractDate, promotion, balloonPayment, rate } = constants;

const conventionalTermOptions = conventionalTerm?.map((x) => {
  const { name, value } = x;
  return { name, value };
});
const orientalTermOptions = orientalTerm?.map((x) => {
  const { name, value } = x;
  return { name, value };
});
const BasicVehicleDetail = ({ control, errors, optionsData, setValue, trigger, flow }) => {
  // const [programVal, setProgramVal] = useState(false);
  const { newUsedList, programList } = useContext(AuthContext);

  const { promotions } = optionsData;

  const promotionOptions = promotions?.map((x) => {
    const { description, campaign } = x;
    return { name: description, value: campaign };
  });
  // const rateOptions = promotions?.map((x) => {
  //   return { name: `${x.rate.toFixed(2)}%`, value: `${x.rate.toFixed(2)}` };
  // });

  const watchProgram = useWatch({
    control,
    name: 'programVal',
  });
  const watchPromotion = useWatch({
    control,
    name: 'promotion',
  });

  useEffect(() => {
    if (watchProgram === 'Oriental A+') {
      // setProgramVal(true);
      setValue('term', '');
      trigger('term');
      if (flow) {
        setValue(
          'term',
          ['10', '20', '30']?.includes(flow?.data?.formData?.term) ? flow?.data?.formData?.term : ''
        );
        trigger('term');
      }
    } else if (watchProgram === 'Conventional') {
      setValue('balloonPay', '');
      // setProgramVal(false);
      setValue('term', '');
      trigger('term');
      if (flow) {
        setValue('balloonPay', '');
        setValue(
          'term',
          ['12', '24', '36', '48', '60', '72', '84']?.includes(flow?.data?.formData?.term)
            ? flow?.data?.formData?.term
            : ''
        );
        trigger('term');
      }
    }
  }, [watchProgram]);

  useEffect(() => {
    console.log('two-flow-BVD', flow);
    if (watchPromotion !== promotions[0]?.description && !flow) {
      console.log('three-flow', flow);
      setValue('rate', '');
    } else if (watchPromotion === promotions[0]?.description) {
      setValue('rate', promotions[0]?.rate.toFixed(2));
      trigger('rate');
    }
  }, [watchPromotion]);
  useEffect(() => {
    if (flow) {
      // setProgramVal(true);
      setValue('term', flow?.data?.formData?.term);
    }
    if (flow?.data?.formData?.programVal && flow?.data?.formData?.term) {
      trigger('term');
    }
  }, []);

  return (
    <div>
      <Grid container my={0.5} rowSpacing={1} columnSpacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            label={program}
            control={control}
            name="programVal"
            errors={!!errors?.programVal}
            errorText={errors?.programVal?.message}
            options={programList}
            defaultValue=""
            required
            displayEmpty
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomSelectBox
            flexDirection="column"
            label={promotion}
            control={control}
            name="promotion"
            errors={!!errors.promotion}
            errorText={errors?.promotion?.message}
            options={promotionOptions}
            defaultValue=""
            required
            displayEmpty
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomRadioButton
            control={control}
            radioValues={newUsedList}
            label="New/Used"
            required
            name="newOrUsed"
            dataTestId="newOrUsed"
            errors={!!errors?.newOrUsed}
            errorText={errors?.newOrUsed?.message}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomRadioButton
            control={control}
            radioValues={VehicleClass}
            label="Vehicle Class"
            required
            name="vehicleClass"
            errors={!!errors?.vehicleClass}
            errorText={errors?.vehicleClass?.message}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} display="flex">
          <Grid container columnSpacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <CustomSelectBox
                flexDirection="column"
                label={term}
                control={control}
                name="term"
                errors={!!errors.term}
                errorText={errors?.term?.message}
                options={
                  (watchProgram === '' && []) ||
                  (watchProgram === 'Oriental A+' ? orientalTermOptions : conventionalTermOptions)
                }
                defaultValue=""
                required
                displayEmpty
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              {/* <CustomSelectBox
                flexDirection="column"
                label={rate}
                control={control}
                name="rate"
                errors={!!errors.rate}
                errorText={errors?.rate?.message}
                options={rateOptions}
                defaultValue={
                  watchPromotion === promotions[0]?.description
                    ? `${promotions[0]?.rate.toFixed(2)} `
                    : ''
                }
                required
                displayEmpty
              /> */}
              <CustomNumberFormat
                name="rate"
                id="rate"
                dataTestId="rate"
                label={rate}
                control={control}
                error={!!errors?.rate}
                errorText={errors?.rate?.message}
                suffix={'%'}
                decimalScale={2}
                maxValue={100.0}
                setValue={setValue}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomDatePicker
            name="contractDate"
            label={contractDate}
            control={control}
            error={!!errors?.contractDate}
            errorText={errors?.contractDate?.message}
            minDate={new Date()}
            maxDate={new Date(new Date().setMonth(new Date().getMonth() + 1))}
            required
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <CustomNumberFormat
            name="salePrice"
            id="salePrice"
            dataTestId="salePrice"
            label="Sale Price"
            placeholder="Enter value"
            control={control}
            error={!!errors?.salePrice}
            errorText={errors?.salePrice?.message}
            thousandSeparator={true}
            prefix={'$ '}
            required
          />
        </Grid>
        {watchProgram === 'Oriental A+' && (
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="balloonPay"
              id="balloonPay"
              dataTestId="balloonPay"
              label={balloonPayment}
              control={control}
              error={!!errors?.balloonPay}
              errorText={errors?.balloonPay?.message}
              thousandSeparator={true}
              prefix={'$ '}
              required={watchProgram === 'Oriental A+'}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default BasicVehicleDetail;

BasicVehicleDetail.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  trigger: PropTypes.func,
  optionsData: PropTypes.instanceOf(Object),
  flow: PropTypes.string,
};
BasicVehicleDetail.defaultProps = {
  control: {},
  errors: {},
  optionsData: {},
  setValue: () => {},
  trigger: () => {},
  flow: '',
};
