import { Box, Checkbox, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext, useEffect, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

import CreateApplicationFooter from '../CreateApplicationFooter';
import ApplicationPageHeading from '../InformationRequest/moduleUtilities/ApplicationPageHeading';
import CollateralInfoSection from './CollateralInfoSection';
import EmploymentInformationSection from './EmploymentInformationSection';
import FinancialTermsSection from './FinancialTermsSection';
import InformationRequestSection from './InformationRequestSection';

const ReviewAndSubmit = () => {
  const { createAppApplicationId } = useContext(AuthContext);
  const { setCompletedSteps, setAppSubmitted, setCaseReferenceId } =
    useContext(MainApplicationContext);
  const [loader, setLoader] = useState(false);
  const [pageData, setPageData] = useState({});
  const [selected, setSelected] = useState({ one: false, two: false });
  const ApiClient = new ApiClass();
  console.log('pageData', pageData);
  const handleChange = (key) => {
    let current = selected[key];
    setSelected({ ...selected, [key]: !current });
  };
  useEffect(async () => {
    setCompletedSteps([1, 2, 3, 4]);
    try {
      setLoader(true);
      const resp = await ApiClient.get(
        // `${UrlHelpers.getApplicationDetails}?applicationId=63d5c04e-caa9-4c72-93af-4b07d18ebbd5`
        `${UrlHelpers.getApplicationDetails}?applicationId=${createAppApplicationId}`
      );
      console.log('resp', resp);
      setPageData(resp?.applicationData);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, []);

  const submitApi = async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.post(UrlHelpers.createApiSubmit, {
        applicationId: createAppApplicationId,
      });

      console.log('submitted successfully', resp);
      setCaseReferenceId(resp?.CaseReferenceId);
      setAppSubmitted(true);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };

  const {
    Applicants: { InformationOnRequest = {}, EmploymentInformation = {} } = {},
    CoSigners = [],
    CoApplicants = [],
    CollateralInformation = {},
    FinancialTerms = {},
  } = pageData || {};

  console.log('get response', {
    InformationOnRequest,
    EmploymentInformation,
    CoSigners,
    CoApplicants,
    CollateralInformation,
    FinancialTerms,
  });
  return (
    <div className={classes.reviewAndSubmit}>
      <Loader open={loader} />
      <ApplicationPageHeading />

      <InformationRequestSection
        informationRequest={InformationOnRequest}
        label="Primary Applicant"
        cousersData={{ CoApplicants, CoSigners }}
      />
      {CoApplicants?.length > 0 &&
        CoApplicants?.map((x, index) => {
          console.log('into coapplicants employment section', x?.EmploymentInformation);
          return (
            <InformationRequestSection
              key={index}
              informationRequest={x?.InformationOnRequest}
              label="Co-Applicant"
            />
          );
        })}

      {CoSigners?.length > 0 &&
        CoSigners?.map((x, index) => {
          return (
            <InformationRequestSection
              key={index}
              informationRequest={x?.InformationOnRequest}
              label={`Co-Signer ${index + 1}`}
            />
          );
        })}

      <EmploymentInformationSection
        employmentInformation={EmploymentInformation}
        label="Primary Applicant"
      />
      {CoApplicants?.length > 0 &&
        CoApplicants?.map((x, index) => {
          console.log('into coapplicants employment section', x?.EmploymentInformation);
          return (
            <EmploymentInformationSection
              key={index}
              employmentInformation={x?.EmploymentInformation}
              label="Co-Applicant"
            />
          );
        })}

      {CoSigners?.length > 0 &&
        CoSigners?.map((x, index) => {
          return (
            <EmploymentInformationSection
              key={index}
              employmentInformation={x?.EmploymentInformation}
              label={`Co-Signer ${index + 1}`}
            />
          );
        })}

      <CollateralInfoSection collateralInformation={CollateralInformation} />
      <FinancialTermsSection financialTerms={FinancialTerms} />
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="#9090901A"
        my={1.25}
        p={1.25}
        gap={1}
      >
        <Box display="flex" gap={1}>
          <Checkbox
            color="primary"
            // checked={selectedRows.find((el) => el.name === name)}
            // onChange={(event) => handleCheckboxChange(event, x)}
            checked={selected?.one}
            onChange={() => handleChange('one')}
          />
          <Typography variant="subtitle2" color="#000000">
            By clicking the SUBMIT button, I certify that all information entered into the portal in
            connection with this submission accurately reflects the information we collect from
            applicants in connection with a credit application.
          </Typography>
        </Box>
        <Box display="flex" gap={1}>
          <Checkbox color="primary" checked={selected?.two} onChange={() => handleChange('two')} />
          <Typography variant="subtitle2" color="#000000">
            By clicking the SUBMIT button, I hereby certify that all the information entered into
            the portal, in connection with this submittal, accurately reflects the information we
            collected from the applicant(s) regarding an application of credit.
          </Typography>
        </Box>
      </Box>

      <CreateApplicationFooter
        disabledFlag={true}
        isSubmit={true}
        onSaveContinue={submitApi}
        saveDisabledFlag={Object.values(selected)?.filter((x) => x === false)?.length > 0}
      />
    </div>
  );
};

export default ReviewAndSubmit;
