import { Box, FormHelperText, InputLabel, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import globalClasses from 'styles/globalStyle.module.scss';

const CustomDatePicker = (props) => {
  const {
    control,
    label,
    name,
    error,
    errorText,
    isDisabled,
    classes,
    required,
    minDate,
    maxDate,
  } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Box display="flex" alignItems="center" flexDirection="column" gap={0.5} my={0.5}>
              <InputLabel htmlFor={name}>
                {required && <span style={{ color: 'red' }}> *&nbsp;</span>}
                {label}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  id={name}
                  name={name}
                  minDate={minDate}
                  maxDate={maxDate}
                  error={false}
                  disabled={isDisabled ? true : false}
                  {...field}
                  classes={{ root: classes }}
                  renderInput={(params) => {
                    return <TextField {...params} error={false} placeholder="MM/DD/YYYY" />;
                  }}
                />
              </LocalizationProvider>
            </Box>
          );
        }}
      />
      {error && (
        <FormHelperText gap={2} className={globalClasses.customSelectError}>
          {' '}
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomDatePicker;

CustomDatePicker.propTypes = {
  control: PropTypes.instanceOf(Object),
  classes: PropTypes.string,
  name: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};
CustomDatePicker.defaultProps = {
  control: {},
  classes: '',
  name: '',
  label: '',
  minDate: '',
  maxDate: '',
  error: false,
  isDisabled: false,
  errorText: '',
  required: false,
};
