/* eslint-disable react/prop-types */
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Invitation from 'assets/svg/invitation.svg';
import Manual from 'assets/svg/manualFlow.svg';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useContext } from 'react';

import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';

import ApplicationDialogHeading from '../InformationRequest/moduleUtilities/ApplicationDialogHeading';

const InvitationMaualDialog = ({ reset, control, setValue, getValues, watch }) => {
  const {
    showInviManual,
    setShowInviManual,
    setShowCoAppDet,
    setShowCoApp,
    setShowMoreDetail,
    isSkipped,
  } = useContext(CreateApplicationContext);
  const { setOpenPopUp } = useContext(AuthContext);
  const toggleChange = (e, value) => {
    setValue('invitationOrManual', value);
  };
  const handleClose = () => {
    console.log('val---->', getValues('invitationOrManual'));
    setShowInviManual(false);
    setOpenPopUp(false);
    reset();
  };
  const watchInviOrManual = watch('invitationOrManual');
  const handleContinue = () => {
    setShowInviManual(false);
    setShowMoreDetail(true);
  };
  const invitationOrManual = [
    { valuee: 'invitation', displayValue: 'Send invite to the applicant', logo: Invitation },
    { valuee: 'manual', displayValue: 'Enter customer details manually', logo: Manual },
  ];
  const handleBack = () => {
    setShowInviManual(false);
    if (!isSkipped) {
      setShowCoAppDet(true);
    } else {
      setShowCoApp(true);
    }
  };
  return (
    <>
      <Dialog
        open={showInviManual}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <ApplicationDialogHeading handleBack={handleBack} handleClose={handleClose} />
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4" textAlign="center" my={3}>
              Let’s go with your preference!
            </Typography>

            <CustomToggleButtonGroup
              exclusive={true}
              name="invitationOrManual"
              control={control}
              toggleChange={toggleChange}
              toggleList={invitationOrManual}
              disableCheck
              disableValue="Send invite to the applicant"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={() => {
              handleContinue();
            }}
            disabled={!watchInviOrManual}
          >
            Ok, Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvitationMaualDialog;
