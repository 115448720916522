import React from 'react';

const CreateApplicationContext = React.createContext({
  showApptype: false,
  setShowAppType: () => {},
  showCoApp: false,
  setShowCoApp: () => {},
  showCoAppDet: false,
  setShowCoAppDet: () => {},
  showInviManual: false,
  setShowInviManual: () => {},
  showMoreDetail: false,
  setShowMoreDetail: () => {},
  isMailResidentSame: true,
  setIsMailResidentSame: () => {},
  applicationId: '',
  setApplicationId: () => {},
  showInformationRequestPage: false,
  setShowInformationRequestPage: () => {},
  createAppSchema1: {},
  setCreateAppSchema1: () => {},
});

export default CreateApplicationContext;
