import ApiClass from 'Api/ApiClient';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import { applyReviewData, applyReviewDataStep2 } from './moduleUtilities';

// export const productNameMapping = (coApplicant = {}, coSigner = []) => {
//   let productName;
//   if (!Object.entries(coApplicant)?.length && !coSigner?.length) {
//     productName = 'AutoLoanManual';
//   } else if (Object.entries(coApplicant)?.length && !coSigner?.length) {
//     productName = 'AutoLoanManualCoApplicant';
//   } else if (coSigner?.length && !Object.entries(coApplicant)?.length) {
//     productName = 'AutoLoanManualCoSigner';
//   } else if (Object.entries(coApplicant)?.length && coSigner?.length) {
//     productName = 'AutoLoanManualCoApplicantCoSigner';
//   }
//   return productName;
// };

const stepMapping = {
  '0-': 'BasicInformationRequest',
  '0-coapplicant': 'BasicInformationRequest',
  '0-cosigner1': 'BasicInformationRequest',
  '0-cosigner2': 'BasicInformationRequest',
  '1-': 'CustomerInformation',
  '1-coapplicant': 'CoApplicantInformation',
  '1-cosigner1': 'CoSignerInformation',
  '1-cosigner2': 'CoSignerInformation',
  '2-': 'EmploymentInformation',
  '2-coapplicant': 'CoApplicantEmploymentInformation',
  '2-cosigner1': 'CoSignerEmploymentInformation',
  '2-cosigner2': 'CoSignerEmploymentInformation',
  '4-': 'FinancialTerms',
};
export const EventNameMapping = (activeStep, userType = '') => {
  return stepMapping[`${activeStep}-${userType}`];
};

export const frameInfoReqPayload = async ({
  formData,
  userType,
  coApplicant = {},
  coSigner = [],
  dealerId,
  createAppApplicationId,
  userId,
  step,
  deletePayload = {},
}) => {
  console.log('coapplicant,cosigner in framing req', coApplicant, coSigner);
  // submit primary applicant form
  // const productName = productNameMapping(coApplicant, coSigner);
  const productName = 'AutoLoanManual';
  const eventName = EventNameMapping(step, userType);
  let initPayload = {
    Event: eventName,
    Application: {
      Products: {
        ProductType: 'Indirect Loan Account',
        ProductName: productName,
      },

      // ApplicationID: createAppApplicationId,
      DealerId: dealerId,
      UserId: userId,
      // Applicants: { ...formData },
      Source: {
        Language: 'EN',
        Location: 'PR',
      },
    },
  };

  if (createAppApplicationId) {
    initPayload.Application.ApplicationID = createAppApplicationId;
    // todo: to be removed on review submit completion
    //initPayload.Application.ApplicationID = 'eae0902b-330b-4959-8989-7f17d29de3f0';
  }

  if (step === 0) {
    initPayload.Application = { ...initPayload.Application, ...formData };
  } else if (!userType && step !== 0) {
    //primary applicant form
    initPayload.Application.Applicants = { ...formData };
  }
  if (!userType && Object.entries(deletePayload).length > 0 && step === 1) {
    initPayload.Application.DeleteCoInformation = { ...deletePayload };
  }
  //revisit for correct key mapping for coapplicant cosigners
  if (!userType && Object.entries(coApplicant)?.length && (step === 1 || step === 0)) {
    initPayload.Application.CoApplicants = [
      {
        CoApplicantNumber: '1',
        InformationOnRequest: {
          CustName: {
            FirstName: coApplicant?.CoAppFirstName,
            LastName: coApplicant?.CoAppLastName,
          },
        },
      },
    ];
  }
  if (!userType && coSigner?.length && (step === 1 || step === 0)) {
    let cosignerArray = coSigner.map((x, index) => {
      return {
        CoSignerNumber: (index + 1).toString(),
        InformationOnRequest: {
          CustName: {
            FirstName: x.CoSignerName?.CoSignerFirstName,
            LastName: x.CoSignerName?.CoSignerLastName,
          },
        },
      };
    });
    initPayload.Application.CoSigners = cosignerArray;
  }
  //coapplicant form
  if (userType === 'coapplicant') {
    initPayload.Application.CoApplicants = [{ ...formData, CoApplicantNumber: 1 }];
  }
  //cosigner1 form
  if (userType === 'cosigner1') {
    initPayload.Application.CoSigners = [{ ...formData, CoSignerNumber: 1 }];
  }
  //cosigner2 form
  if (userType === 'cosigner2') {
    initPayload.Application.CoSigners = [{ ...formData, CoSignerNumber: 2 }];
  }
  return initPayload;
};
export const frameFinanceTermPayload = async ({
  formData,
  dealerId,
  createAppApplicationId,
  userId,
  step,
}) => {
  // submit primary applicant form
  // const productName = productNameMapping(coApplicant, coSigner);
  const productName = 'AutoLoanManual';
  const eventName = 'FinancialTerms';
  let initPayload = {
    Event: eventName,
    Application: {
      Products: {
        ProductType: 'Indirect Loan Account',
        ProductName: productName,
      },

      // ApplicationID: createAppApplicationId,
      DealerId: dealerId,
      UserId: userId,
      // Applicants: { ...formData },
      Source: {
        Language: 'EN',
        Location: 'PR',
      },
    },
  };

  if (createAppApplicationId) {
    initPayload.Application.ApplicationID = createAppApplicationId;
  }

  if (step === 4) {
    initPayload.Application = { ...initPayload.Application, ...formData };
  }
  return initPayload;
};

export const getSpecificPageDetailsStepOne = async ({
  userType,
  activeStep,
  setValue,
  setCoApplicant,
  setCoSigner,
  createAppApplicationId,
  setLoader,
  setIdVerificationStatus,
  callback,
}) => {
  const ApiClient = new ApiClass();
  const eventName = EventNameMapping(activeStep, userType);
  let cosignerData = [];
  let url;
  try {
    url = `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=${eventName}`;
    if (userType === 'coapplicant') {
      url = `${url}&CoApplicantNumber=1`;
    } else if (userType === 'cosigner1') {
      url = `${url}&CoSignerNumber=1`;
    } else if (userType === 'cosigner2') {
      url = `${url}&CoSignerNumber=2`;
    }

    setLoader(true);

    const getData = await ApiClient.get(url);
    console.log('getData', getData);
    if (Object.entries(getData)?.length) {
      let reviewData;
      if (!userType) {
        reviewData = getData?.ApplicantDetailInformation?.InformationOnRequest;
        if (
          getData?.CoApplicantBasicInformation_1 &&
          Object.entries(getData?.CoApplicantBasicInformation_1)?.length
        ) {
          let coapplicantData = getData?.CoApplicantBasicInformation_1;
          coapplicantData = {
            CoAppFirstName: coapplicantData?.FirstName,
            CoAppLastName: coapplicantData?.LastName,
          };
          setCoApplicant(coapplicantData);
          setValue('coApplicant', coapplicantData);
          console.log('coApplicant', coapplicantData);
        }
        if (getData?.CoSignerBasicInformation_1 || getData?.CoSignerBasicInformation_2) {
          if (
            getData?.CoSignerBasicInformation_1 &&
            Object.entries(getData?.CoSignerBasicInformation_1)?.length
          ) {
            const cosignerData1 = {
              CoSignerNumber: 1,
              CoSignerName: {
                CoSignerFirstName: getData?.CoSignerBasicInformation_1.FirstName,
                CoSignerLastName: getData?.CoSignerBasicInformation_1.LastName,
              },
            };
            cosignerData.push(cosignerData1);
          }

          if (
            getData?.CoSignerBasicInformation_2 &&
            Object.entries(getData?.CoSignerBasicInformation_2)?.length
          ) {
            const cosignerData2 = {
              CoSignerNumber: 2,
              CoSignerName: {
                CoSignerFirstName: getData?.CoSignerBasicInformation_2.FirstName,
                CoSignerLastName: getData?.CoSignerBasicInformation_2.LastName,
              },
            };
            cosignerData.push(cosignerData2);
          }
          console.log('cosignerData', cosignerData);
          setCoSigner(cosignerData);
          setValue('cosigner', cosignerData);
        }
        if (reviewData?.CustName?.FirstName) {
          applyReviewData({ setValue, reviewData });
          if (reviewData?.VouchedIdStatus === 'true' || reviewData?.VouchedIdStatus === true)
            setIdVerificationStatus(true);
          else {
            setIdVerificationStatus(false);
          }
        }
      } else {
        if (userType === 'coapplicant') {
          reviewData = getData?.CoApplicantDetailInformation?.InformationOnRequest;
        } else if (userType === 'cosigner1') {
          reviewData = getData?.CoSignerDetailInformation?.InformationOnRequest;
        } else if (userType === 'cosigner2') {
          reviewData = getData?.CoSignerDetailInformation?.InformationOnRequest;
        }
        if (reviewData?.CustName?.FirstName && reviewData?.SSN) {
          applyReviewData({ setValue, reviewData });
          if (reviewData?.VouchedIdStatus === 'true' || reviewData?.VouchedIdStatus === true)
            setIdVerificationStatus(true);
          else {
            setIdVerificationStatus(false);
          }
        } else {
          callback({
            firstName: reviewData?.CustName?.FirstName,
            lastName: reviewData?.CustName?.LastName,
          });
        }
      }
    } else {
      callback();
    }
  } catch (e) {
    console.log('e', e);
  } finally {
    setLoader(false);
  }
};

export const getSpecificPageDetailsStepTwo = async ({
  userType,
  activeStep,
  setValue,
  createAppApplicationId,
  setLoader,
  callback,
  setShowAdditionalIncome,
}) => {
  const ApiClient = new ApiClass();
  const eventName = EventNameMapping(activeStep, userType);

  let url;
  try {
    url = `${UrlHelpers.getSpecificDetails}?applicationId=${createAppApplicationId}&eventName=${eventName}`;
    if (userType === 'coapplicant') {
      url = `${url}&CoApplicantNumber=1`;
    } else if (userType === 'cosigner1') {
      url = `${url}&CoSignerNumber=1`;
    } else if (userType === 'cosigner2') {
      url = `${url}&CoSignerNumber=2`;
    }

    setLoader(true);

    const getData = await ApiClient.get(url);
    console.log('getData', getData);
    if (Object.entries(getData)?.length) {
      let reviewData;
      if (!userType) {
        reviewData = getData?.ApplicantEmploymentInformation;
      } else if (userType === 'coapplicant') {
        reviewData = getData?.CoApplicantEmploymentInformation;
      } else if (userType === 'cosigner1') {
        reviewData = getData?.CoSignerEmploymentInformation;
      } else if (userType === 'cosigner2') {
        reviewData = getData?.CoSignerEmploymentInformation;
      }
      if (reviewData?.AdditionalIncomeDetail) {
        setShowAdditionalIncome(true);
      }

      applyReviewDataStep2({ setValue, reviewData });
    } else {
      callback();
    }
  } catch (e) {
    console.log('e', e);
  } finally {
    setLoader(false);
  }
};
