import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cameraBack from 'assets/svg/Camera_back.svg';
import cameraReset from 'assets/svg/Camera_reset.svg';
import cameraTakePhoto from 'assets/svg/Take-photo.svg';
import CameraPhoto, { FACING_MODES, IMAGE_TYPES } from 'jslib-html5-camera-photo';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import globalClasses from 'styles/globalStyle.module.scss';
import { blobToFile } from 'utils/utility';

const CameraUploadStyles = makeStyles(
  (theme) => ({
    camera_container: {
      position: 'relative',
      height: 'calc(100vh - 100px)',
      border: '50px solid black',
      // width: 'calc(100vw - 100px)',
      width: '100%',
      [theme.breakpoints.down('900')]: {
        border: '30px solid black',
        height: 'calc(100vh - 60px)',
        // width: 'calc(100vw - 60px)',
      },
      [theme.breakpoints.down('600')]: {
        border: '15px solid black',
        height: 'calc(100vh - 30px)',
        // width: 'calc(100vw - 30px)',
      },
    },
    buttonGrid: {
      background: '#FFFFFF',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.12)',
      borderRadius: '38.5px',
      maxWidth: '326px',
      padding: '0px 15px',
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('900')]: {
        height: '65px',
      },
      [theme.breakpoints.down('600')]: {
        height: '50px',
        padding: '0px 10px',
      },
    },
    btn_main_container: {
      position: 'absolute',
      bottom: '45px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 2,
    },
    takePhotoBtn: {
      textTransform: 'none',
      minWidth: '169px',
      height: '52px',
      background: '#661C69',
      borderRadius: '56px',
      font: 'normal 700 16px/20px Arial',
      textAlign: 'center',
      color: '#FFFFFF',
      '&:hover': {
        color: '#FFFFFF',
        background: '#661C69',
      },
      [theme.breakpoints.down('900')]: {
        minWidth: '150px',
        height: '45px',
      },
      [theme.breakpoints.down('600')]: {
        font: 'normal 700 14px/17px Arial',
        height: '35px',
        maxWidth: '150px',
      },
    },
    backBtn: {
      font: 'normal 700 18px/24px Arial',
      color: '#661C69',
      textAlign: 'center',
      background: '#FFFFFF',
      textTransform: 'none',
      border: 'none',
      borderRadius: '38.5px',
      '&:hover': {
        background: '#FFFFFF',
        color: '#661C69',
      },
      [theme.breakpoints.down('600')]: {
        padding: '5px',
        font: 'normal 700 14px/17px Arial',
      },
    },
  }),
  { index: 1 }
);
const CameraUpload = ({
  setShowCamera,
  setOcrCameraFile,
  setShowIDCamera,
  showIDCamera,
  showCamera,
  setFile,
  clearErrors,
}) => {
  const classes = CameraUploadStyles();

  const [dataUri, setDataUri] = React.useState('');
  const [base64Uri, setBase64Uri] = React.useState('');
  const [cameraPhoto, setCameraPhoto] = React.useState({});

  const videoRef = useRef();

  async function startCameraMaxResolution(idealFacingMode) {
    await cameraPhoto.startCameraMaxResolution(idealFacingMode);
  }

  useEffect(() => {
    const facingMode = FACING_MODES.ENVIRONMENT;
    if (Object.keys(cameraPhoto).length > 0) {
      startCameraMaxResolution(facingMode);
    }
  }, [cameraPhoto]);

  useEffect(() => {
    setCameraPhoto(new CameraPhoto(videoRef.current));
  }, [videoRef.current]);

  function dataUrItoBlob(dataUri1) {
    const binary = atob(dataUri1?.split(',')[1]);
    const mimeString = dataUri1.split(',')[0].split(':')[1].split(';')[0];
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mimeString });
  }

  const handleBack = async () => {
    if (showCamera) setShowCamera(false);
    else if (showIDCamera) setShowIDCamera(false);
  };
  const handleSaveCameraAtob = async () => {
    const blob1 = dataUri;

    if (showCamera) {
      setShowCamera(false);
      const ocrCameraFile = blobToFile(blob1, 'utility-bill');
      clearErrors('utilitybillfile');
      setOcrCameraFile(ocrCameraFile);
    } else if (showIDCamera) {
      setShowIDCamera(false);
      const idFile = blobToFile(blob1, 'id-proof');
      clearErrors('manualFile');
      setFile(idFile);
    }
  };

  const takePhoto = () => {
    const config = {
      sizeFactor: 0.5,
      imageCompression: 0.5,
      imageType: IMAGE_TYPES.JPG,
    };
    const getDataUri = cameraPhoto.getDataUri(config);
    setDataUri(dataUrItoBlob(getDataUri));
    setBase64Uri(getDataUri);
  };

  const reset = () => {
    setDataUri('');
    const facingMode = FACING_MODES.ENVIRONMENT;

    if (Object.keys(cameraPhoto).length > 0) {
      startCameraMaxResolution(facingMode);
    }
  };
  useEffect(() => {
    if (dataUri) {
      const imgElement = document.getElementById('imgCamera');
      imgElement?.setAttribute('style', 'object-fit: cover; width: 100%; height: 100%');
    } else {
      const vidElement = document.getElementById('video');
      vidElement?.setAttribute('style', 'object-fit: cover;width: 100%;height: 100%');
    }
  }, [dataUri]);

  return (
    <div className={globalClasses.camContainer}>
      <div id="cam_container" className={classes.camera_container}>
        <span
          style={{
            position: 'absolute',
            height: '15%',
            width: '12%',
            top: '5%',
            left: '5%',
            borderTop: '5px solid #E4E9F2',
            borderLeft: '5px solid #E4E9F2',
          }}
        />
        <span
          style={{
            position: 'absolute',
            height: '15%',
            width: '12%',
            borderTop: '5px solid #E4E9F2',
            borderRight: '5px solid #E4E9F2',
            top: '5%',
            right: '5%',
          }}
        />
        <span
          style={{
            position: 'absolute',
            height: '15%',
            width: '12%',
            borderBottom: '5px solid #E4E9F2',
            borderLeft: '5px solid #E4E9F2',
            bottom: '5%',
            left: '5%',
          }}
        />
        <span
          style={{
            position: 'absolute',
            height: '15%',
            width: '12%',
            borderRight: '5px solid #E4E9F2',
            borderBottom: '5px solid #E4E9F2',
            bottom: '5%',
            right: '5%',
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            zIndex: dataUri ? '1 ' : '-1',
            width: '100%',
            height: '100%',
          }}
        >
          {dataUri ? (
            <img
              alt="imgCamera"
              src={base64Uri}
              id="imgCamera"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          ) : (
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              id="video"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          )}
        </div>
        <div className={classes.btn_main_container}>
          {dataUri ? (
            <div className={classes.buttonGrid}>
              <Button
                color="inherit"
                variant="outlined"
                className={classes.backBtn}
                onClick={reset}
                startIcon={<img src={cameraReset} alt="camera reset icon" />}
              >
                Reset
              </Button>
              <Button
                color="inherit"
                variant="outlined"
                onClick={handleSaveCameraAtob}
                className={classes.takePhotoBtn}
              >
                Confirm
              </Button>
            </div>
          ) : (
            <div className={classes.buttonGrid}>
              <Button
                color="inherit"
                variant="outlined"
                className={classes.backBtn}
                data-testid="back_testid"
                onClick={handleBack}
                startIcon={<img src={cameraBack} alt="camera back icon" />}
              >
                Back
              </Button>
              <Button
                color="inherit"
                variant="outlined"
                className={classes.takePhotoBtn}
                onClick={takePhoto}
                data-testid="takePhoto_testid"
                startIcon={<img src={cameraTakePhoto} alt="camera icon" />}
              >
                Take Photo
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CameraUpload;

CameraUpload.propTypes = {
  setShowCamera: PropTypes.func,
  setOcrCameraFile: PropTypes.func,
  setShowIDCamera: PropTypes.func,
  showIDCamera: PropTypes.bool,
  showCamera: PropTypes.bool,
  setFile: PropTypes.func,
  clearErrors: PropTypes.func,
};
CameraUpload.defaultProps = {
  setShowCamera: () => {},
  setOcrCameraFile: () => {},
  setShowIDCamera: () => {},
  showIDCamera: false,
  showCamera: false,
  setFile: () => {},
  clearErrors: () => {},
};
