import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import RegisterUserContext from 'context/RegisterUserContext';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { enrollDealerUserSchema } from 'schemaValidations/enrollDealerUserSchema';

import BasicInformation from '../BasicInformation/BasicInformation';

// import BasicInformation from '../InformationRequest/BasicInformation/BasicInformation';

const UserInformation = () => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',

    defaultValues: {
      userId: '',
      linkedUserId: '',
      firstName: '',
      lastName: '',
      secondLastName: '',
      mobile: '',
      areaCode: '787',
      email: '',
      includeLoanOriginator: '',
      includeSalesManager: '',
      includeReference: '',
      canSeeOwnApplication: '',
    },
    resolver: yupResolver(enrollDealerUserSchema),
  });
  const [
    watchemail,
    watchuserId,
    watchlinkedUserId,
    watchfirstName,
    watchlastName,
    watchsecondLastName,
    watchincludeLoanOriginator,
    watchincludeSalesManager,
    watchincludeReference,
    watchcanSeeOwnApplication,
    watchareaCode,
    watchmobile,
  ] = watch([
    'email',
    'userId',
    'linkedUserId',
    'firstName',
    'lastName',
    'secondLastName',
    'includeLoanOriginator',
    'includeSalesManager',
    'includeReference',
    'canSeeOwnApplication',
    'areaCode',
    'mobile',
  ]);
  const { activeStep, setActiveStep, formDetails, setFormDetails } =
    useContext(RegisterUserContext);
  const navigate = useNavigate();

  const nextStep = () => {
    const formObj = {
      email: watchemail,
      userId: watchuserId,
      linkedUserId: watchlinkedUserId,
      firstName: watchfirstName,
      lastName: watchlastName,
      secondLastName: watchsecondLastName,
      includeLoanOriginator: watchincludeLoanOriginator,
      includeSalesManager: watchincludeSalesManager,
      includeReference: watchincludeReference,
      canSeeOwnApplication: watchcanSeeOwnApplication,
      areaCode: watchareaCode,
      mobile: watchmobile,
    };
    console.log('formObj');
    console.log(formObj);
    setFormDetails({ ...formDetails, ...formObj });
    setActiveStep(activeStep + 1);
  };
  useEffect(() => {
    console.log(errors);
  }, [errors]);
  return (
    <>
      <Typography variant="h3" component="p" py={4}>
        User Information
      </Typography>
      <Grid id="1" container justifyContent="center">
        <Box id="2" sx={{ width: '70%' }}>
          <BasicInformation control={control} errors={errors} />
        </Box>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        {/* {userType && (
              <Box display="flex" gap={0.5} alignItems="center">
                <KeyboardArrowLeftIcon color="secondary" />
                <Link
                  component="button"
                  variant="subtitle2"
                  color="secondary"
                  onClick={() => {
                    console.log('back clicked', userDetails);
                    setBackFlag(true);
                    setActiveStep(1);
                    const getReturnIndex = userDetails?.length - 1;
                    const prevPageData = userDetails?.[getReturnIndex];
                    setUserType(prevPageData?.userType);
                  }}
                >
                  Back
                </Link>
              </Box>
            )} */}
        <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
          <Link
            component="button"
            onClick={() => {
              navigate('/manage-dealers');
            }}
            variant="subtitle2"
            color="secondary"
            sx={{ fontSize: '16px' }}
            data-testid="discard-userinfo-application"
          >
            Discard Application
          </Link>
          {/* <Button color="secondary" variant="outlined">
            Save as draft
          </Button> */}
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(nextStep)}
            disabled={Object.entries(errors)?.length}
            data-testid="save-continue-userinfo"
          >
            Save & continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserInformation;
