import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ocrFileUpload } from 'Services/commonComponentsApi';
import CameraIcon from 'assets/svg/cameraIcon.svg';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import customClasses from 'styles/InformationRequest.module.scss';

import { CustomAutoComplete } from 'components/CustomAutoComplete/CustomAutoComplete';
import CustomCollapse from 'components/CustomCollapse';
import CustomFileUploader from 'components/CustomFileUploader/CustomFileUploader';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const ResidentialAddress = ({
  control,
  errors,
  setIsMailResidentSame,
  isMailResidentSame,
  addressLookupData,
  addressLookupDataResponse,
  setValue,
  cityList,
  showUtility,
  setError,
  setLoader,
  setShowCamera,
  ocrCameraFile,
  clearErrors,
  setOcrDocumentId,
  utilityBillList,
  // setIsResidentAddressOpen,
  isResidentAddressOpen,
}) => {
  const [watchResAddress1, watchResUtilityBill] = useWatch({
    control,
    name: ['resAddress1', 'resUtilityBill', 'resAddress1', 'resAddress2', 'resCity', 'resZipcode'],
  });

  console.log('watchResUtilityBill', watchResUtilityBill);

  const { commonToken } = useContext(AuthContext);
  const { userType, reviewFlag } = useContext(MainApplicationContext);
  const fileTypes = ['JPG', 'PNG', 'PDF'];
  const [file, setFile] = useState(ocrCameraFile);
  const [fileSuccess, setFileSuccess] = useState(false);

  const handleChange = (filee) => {
    setFileSuccess(false);
    clearErrors('utilitybillfile');
    setFile(filee);
  };

  useEffect(() => {
    if (userType) {
      setFile('');
      setFileSuccess(false);
    }
  }, [userType]);

  useEffect(() => {
    if (!reviewFlag) {
      if (watchResUtilityBill && !file) {
        setError('utilitybillfile', {
          type: 'custom',
          message: 'Please upload the address proof file to proceed',
        });
      } else if (file && !watchResUtilityBill) {
        setError('resUtilityBill', { type: 'custom', message: 'Please select a utility bill' });
      } else if (file && watchResUtilityBill) {
        clearErrors(['utilitybillfile', 'resUtilityBill']);

        ocrFileUpload(file, commonToken, watchResUtilityBill, ocrSuccess, ocrFailure, setLoader);
      } else {
        clearErrors(['utilitybillfile', 'resUtilityBill']);
      }
    }
  }, [file, watchResUtilityBill]);

  const handleAutoCompleteData = (selectedObj) => {
    if (selectedObj && selectedObj?.Address) {
      let { AddressLine1, PostalCode, City } = selectedObj?.Address;
      setValue('resAddress1', AddressLine1);
      setValue('resZipcode', PostalCode.split('-')[0]);
      City = cityList.find((x) => x.value === City)?.value || '';
      setValue('resCity', City);
      clearErrors(['resAddress1', 'resZipcode', 'resCity']);
    } else {
      setValue('resAddress1', '');
      setValue('resZipcode', '');
      setValue('resCity', '');
    }
  };

  const ocrSuccess = (response) => {
    const { AddressLine1, AddressLine2, City, ZipCode, documentId } = response;
    if (documentId) {
      setFileSuccess(true);
      setOcrDocumentId(documentId);
      if (AddressLine1) {
        setValue('resAddress1', AddressLine1);
        setValue('resAddress2', AddressLine2);
        setValue('resCity', City);
        setValue('resZipcode', ZipCode);
        clearErrors(['resAddress1', 'resCity', 'resZipcode']);
      }
    } else {
      setFileSuccess(false);
    }
  };

  const ocrFailure = (e) => {
    setFileSuccess(false);

    if (e?.response?.data?.message) {
      setError('utilitybillfile', { type: 'custom', message: e?.response?.data?.message });
    } else {
      setError('utilitybillfile', {
        type: 'custom',
        message:
          'The auto address capture service is unavailable. Please upload the file and enter the details manually',
      });
    }
  };
  console.log('isResidentAddressOpen - in resi addr', isResidentAddressOpen);
  return (
    <div>
      <CustomCollapse
        heading="Residential Address"
        mailButton
        checked={isMailResidentSame}
        setIsMailResidentSame={setIsMailResidentSame}
        open={isResidentAddressOpen}
      >
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomAutoComplete
              id="resAddress1"
              name="resAddress1"
              flexDirection="column"
              regex={/^[a-zA-Z0-9áéíóúñü¿¡.,/-\s]*$/g}
              control={control}
              dataTestIdAutocomplete="autocomplete_address1"
              dataTestIdForLabel="resAddress1"
              dataTestIdForInput="addr1Input"
              required
              label="Address line 1"
              options={
                addressLookupData && watchResAddress1 && watchResAddress1?.length >= 6
                  ? addressLookupData
                  : []
              }
              handleChange={(_event, selectedValue) => {
                if (watchResAddress1 && watchResAddress1.length >= 6) {
                  const index = addressLookupData.indexOf(selectedValue);
                  const selectedObj = addressLookupDataResponse[index];
                  handleAutoCompleteData(selectedObj);
                }
              }}
              maxLength={40}
              autoFocus
              placeholder="Enter text"
              fullWidth
              error={errors?.resAddress1}
              errorText={errors?.resAddress1?.message}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.resAddress2}
              errorText={errors?.resAddress2?.message}
              maxLength="20"
              regex={/^[A-Za-z0-9áéíóúñü¿¡,./-\s]$/}
              placeholder="Enter text"
              label="Address line 2"
              flexDirection="column"
              name="resAddress2"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Country"
              control={control}
              name="resCountry"
              errors={!!errors?.resCountry}
              errorText={errors?.resCountry?.message}
              options={[
                {
                  name: 'United States',
                  value: 'USA',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
              isDisabled
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="State"
              control={control}
              name="resState"
              errors={!!errors?.resState}
              errorText={errors?.resState?.message}
              options={[
                {
                  name: 'PUERTO RICO',
                  value: 'PR',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
              isDisabled
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="City"
              control={control}
              name="resCity"
              errors={!!errors?.resCity}
              errorText={errors?.resCity?.message}
              options={cityList}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.resZipcode}
              errorText={errors?.resZipcode?.message}
              placeholder="Enter text"
              label="Zip code"
              name="resZipcode"
              regex={/^[0-9]$/}
              maxLength="5"
              type="text"
              flexDirection="column"
              required
            />
          </Grid>
          {showUtility && (
            <>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomSelectBox
                  control={control}
                  options={utilityBillList}
                  label="Utility Bill"
                  flexDirection="column"
                  name="resUtilityBill"
                  errors={!!errors?.resUtilityBill}
                  errorText={errors?.resUtilityBill?.message}
                  required
                />
              </Grid>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                mt={1.25}
              >
                <Grid item lg={5.5} md={5.5} sm={12} xs={12}>
                  <Box
                    className={customClasses.uploadContainer}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      display="flex"
                      onClick={() => setShowCamera(true)}
                    >
                      <img src={CameraIcon} /> &nbsp;
                      <Typography
                        variant="subtitle1"
                        component="p"
                        sx={{ fontWeight: '700' }}
                      ></Typography>
                      Camera
                    </Button>
                  </Box>
                </Grid>
                <Grid item lg={5.5} md={5.5} sm={12} xs={12}>
                  <CustomFileUploader
                    handleChange={handleChange}
                    name="utilitybillfile"
                    control={control}
                    fileTypes={fileTypes}
                    setError={setError}
                    errors={!!errors?.utilitybillfile}
                    errorText={errors?.utilitybillfile?.message}
                    fileSuccess={fileSuccess}
                  />
                </Grid>
                {fileSuccess && !errors?.utilitybillfile?.message && showUtility && (
                  <Box
                    display="flex"
                    gap={0.5}
                    mt={2.5}
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                  >
                    <CheckCircleIcon htmlColor="green" />
                    <Typography variant="">{file?.name}</Typography>
                  </Box>
                )}
                <Box
                  display="flex"
                  gap={0.5}
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  mt={2.5}
                >
                  <Typography component="p" color="red">
                    {errors?.utilitybillfile?.message}
                  </Typography>
                </Box>
              </Grid>{' '}
            </>
          )}
        </Grid>
      </CustomCollapse>
    </div>
  );
};
export default ResidentialAddress;

ResidentialAddress.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setIsMailResidentSame: PropTypes.func,
  isMailResidentSame: PropTypes.bool,
  addressLookupData: PropTypes.instanceOf(Object),
  addressLookupDataResponse: [],
  setValue: PropTypes.func,
  cityList: PropTypes.array,
  showUtility: PropTypes.bool,
  isResidentAddressOpen: PropTypes.bool,
  setError: PropTypes.func,
  setLoader: PropTypes.func,
  setShowCamera: PropTypes.func,
  ocrCameraFile: PropTypes.string,
  clearErrors: PropTypes.func,
  // ocrDocumentId: PropTypes.string,
  setOcrDocumentId: PropTypes.func,
  utilityBillList: PropTypes.instanceOf(Object),
};
ResidentialAddress.defaultProps = {
  control: {},
  errors: {},
  setIsMailResidentSame: () => {},
  isMailResidentSame: false,
  isResidentAddressOpen: false,
  addressLookupData: [],
  addressLookupDataResponse: [],
  setValue: () => {},
  cityList: [],
  showUtility: false,
  setError: () => {},
  setLoader: () => {},
  setShowCamera: () => {},
  ocrCameraFile: '',
  clearErrors: () => {},
  // ocrDocumentId: '',
  setOcrDocumentId: () => {},
  utilityBillList: [],
};
