const endPoint = process.env.REACT_APP_BASE_END_POINT;
const commonComponentsBaseUrl = process.env.REACT_APP_COMMON_SERVICE_URL;
let infoReqEndPoint = 'autoloanapi/application/save';

export const UrlHelpers = {
  payCalcOnLoad: `${endPoint}/autoutilapi/onload`,
  payCalcCalculate: `${endPoint}/autoutilapi/calculate`,
  distributedDataUploadOnLoad: `${endPoint}/autoutilapi/lookup`,
  distributedDataUpload: `${endPoint}/autoutilapi/upload`,
  lookUpDrillDownInfo: `${endPoint}/autointgapi/colValue/lookup/used/drillDownInfo`,
  lookUpDrillDownInfoNew: `${endPoint}/autointgapi/colValue/lookup/new/drillDownInfo`,
  saveLookupValue: `${endPoint}/autoutilapi/lookupsearch`,
  lookUpState: `${endPoint}/autointgapi/colValue/lookup/used/state`,
  lookUpDropDownInfo: `${endPoint}/autointgapi/colValue/lookup/used/dropdownInfo`,
  lookUpDropDownInfoNew: `${endPoint}/autointgapi/colValue/lookup/new/dropdownInfo`,
  getColorsAndOptions: `${endPoint}/autointgapi/colValue/lookup/new/getColorsAndOption`,
  getStandardEquipment: `${endPoint}/autointgapi/colValue/lookup/new/standardEquipment`,
  cityLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=country-state-city`,
  residentialStatusLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=residential-status`,
  maritalStatusLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=marital-status`,
  employmentStatusLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=employment-status`,
  utilityBillLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=utility-bill`,
  relationshipLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=relationship`,
  industryLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=industry`,
  occupationLookup: `${endPoint}/autoutilapi/masterlookup?propertyName=occupation`,
  lookUpVin: `${endPoint}/autointgapi/colValue/lookup`,
  distributedYear: `${endPoint}/autoutilapi/yearlookup`,
  distributedMakeList: `${endPoint}/autoutilapi/makelookup`,
  distributedModelList: `${endPoint}/autoutilapi/modellookup`,
  distributedSubModelList: `${endPoint}/autoutilapi/submodellookup`,
  applicationDraft: `${endPoint}/autoloanapi/application/draft`,
  informationReqSave: `${endPoint}/${infoReqEndPoint}`,
  createApiSubmit: `${endPoint}/autoloanapi/application/submit`,
  getApplicationDetails: `${endPoint}/autoloanapi/application/getDetails`,
  getSpecificDetails: `${endPoint}/autoloanapi/application/getSpecificDetails`,
  financeTermsPayCalcSave: `${endPoint}/autoutilapi/lookupsearch`,
  loanPurpose: `${endPoint}/autoutilapi/masterlookup?propertyName=loan-purpose`,
  program: `${endPoint}/autoutilapi/masterlookup?propertyName=program`,
  monthsDeferred: `${endPoint}/autoutilapi/masterlookup?propertyName=months-deferred`,
  newUsed: `${endPoint}/autoutilapi/masterlookup?propertyName=new-used`,
  make: `${endPoint}/autoutilapi/masterlookup?propertyName=make`,
  commonComponentsAddressLookup: `${commonComponentsBaseUrl}/customer-info-validator/lookup/address`,
  commonComponentsOcr: `${commonComponentsBaseUrl}/customer-info-validator/upload`,
  commonComponentsEmailVerification: `${commonComponentsBaseUrl}/customer-info-validator/email/validation`,
  commonComponentsPhoneVerification: `${commonComponentsBaseUrl}/customer-info-validator/phone/validation`,
  loanStatus: `${endPoint}/autoloanapi/application/poll`,
  enrollUserSubmit: `${endPoint}/ob-idmautoapi/authentication/enroll/dealer`,
  usernameAPI: `${endPoint}/ob-idmautoapi/authentication/username`,
  userRequestStatus: `${endPoint}/autouserapi/userrequest/`,
  newUserRequest: `${endPoint}/autouserapi/userrequest`,
  accessPolicy: `${endPoint}/ob-idmautoapi/authentication/getAccessPolicy`,
};
