import * as yup from 'yup';

export const InformationRequestSchema = (
  showutilitybill,
  coApplicantFlag,
  coSignerFlag,
  userType
) => {
  return yup.object().shape({
    firstName: yup
      .string()
      .required('* Mandatory Field')
      .test('len', 'Maximum 15 characters can be entered', (val) => val?.length <= 15)
      .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),

    lastName: yup
      .string()
      .required('* Mandatory Field')
      .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
      .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),

    middleName: yup
      .string()
      .nullable()
      .test('len', 'Maximum 20 characters can be entered', (val) =>
        val ? val?.length <= 20 : true
      )
      .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
    secondLastName: yup
      .string()
      .nullable()
      .test('len', 'Maximum 25 characters can be entered', (val) =>
        val ? val?.length <= 25 : true
      )
      .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
    motherLastName: yup
      .string()
      .nullable()
      .test('len', 'Maximum 25 characters can be entered', (val) =>
        val ? val?.length <= 25 : true
      )
      .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
    // gender: yup.string().required('*Mandatory Field'),
    mobile: yup
      .string()
      .required('* Mandatory field')
      .test('length', '* Mobile number should have 7 digits', (val) => {
        return val?.length === 7;
      })
      .matches(/^[0-9]+$/g),
    areaCode: yup
      .string()
      .required('* Area code is mandatory')
      .matches(/^[0-9]+$/g),
    mobile2: yup.string().matches(/^[0-9]*$/g),

    areaCode2: yup
      .string()
      .required('* Area code is mandatory')
      .matches(/^[0-9]+$/g),
    dob: yup
      .string()

      .nullable()
      .required('*Mandatory Field')
      .test('', 'Correct date format is mm/dd/yyyy', (value) => {
        return value && !isNaN(new Date(value)?.getTime());
      })
      .test('max length', 'Please enter a valid dob', (val) => {
        const maxDate = new Date(val);
        maxDate.setHours(23, 59, 59, 999);
        return maxDate <= new Date(new Date().setDate(new Date().getDate() + 1));
      })
      .test(
        'age',
        'Applicant must be 18 years or older to apply for the loan',
        (value) => value && new Date().getFullYear() - new Date(value)?.getFullYear() >= 18
      ),
    email: yup
      .string()
      .required('*Mandatory Field')

      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i, '*Invalid email format')
      .test('len', '* Invalid email length', (val) => {
        const value = val && val?.split('@');
        const value1 = val && val?.slice(val?.indexOf('@') + 1, val?.lastIndexOf('.'));

        return value?.[0]?.length <= 64 && value1?.length <= 255;
      })
      .test('len', '* Invalid email length', (val) => {
        const value = val && val?.split('@');
        const value1 = val && val?.slice(val?.indexOf('@') + 1, val?.lastIndexOf('.'));
        return value?.[0]?.length + value1?.length <= 320;
      }),
    ssn: yup
      .string()
      .required('*Mandatory Field')
      // .matches(/^[0-9]+$/g, '* Invalid format')
      .test('length', '* Social security number should have 9 digit', (val) =>
        val ? val?.length === 11 : true
      ),
    maritalStatus: yup.string().required('*Mandatory Field'),

    // numberOfDependents: yup.string().max(2, '* Exceeds maximum number of dependents allowed'),
    resAddress1: yup
      .string()
      .max(40, 'Address line 1 should be 40 characters or less')
      .required('* Mandatory field')
      .matches(/^[a-zA-Z0-9áéíóúñü¿¡,./-\s]*$/g, '* Invalid format'),
    resAddress2: yup
      .string()
      .nullable()
      .max(20, 'Address line 2 should be 20 characters or less')
      .matches(/^[a-zA-Z0-9áéíóúñü¿¡,./-\s]*$/g, '* Invalid format'),

    resCountry: yup.string().required('* Mandatory Field'),
    resState: yup.string().required('* Mandatory Field'),
    resCity: yup.string().required('* Mandatory Field'),
    resZipcode: yup
      .string()
      .required('* Mandatory Field')
      .matches(/^[0-9]+$/, '* Invalid format')
      .test('len', '* Zipcode should have 5 digits', (val) => val?.length === 5),
    perAddress1: yup.string().when('isAddrSame', {
      is: false,
      then: yup
        .string()
        .required('* Mandatory field')
        .max(40, 'Address line 1 should be 40 characters or less')
        .matches(/^[a-zA-Z0-9áéíóúñü¿¡.,/-\s]*$/g, '* Invalid format'),
    }),
    perAddress2: yup.string().when('isAddrSame', {
      is: false,
      then: yup
        .string()
        .nullable()
        .max(20, 'Address line 2 should be 20 characters or less')
        .matches(/^[a-zA-Z0-9áéíóúñü¿¡.,/-\s]*$/g, '* Invalid format'),
    }),

    perCountry: yup.string().when('isAddrSame', {
      is: false,
      then: yup.string().required('*Mandadtory Field'),
    }),
    perState: yup.string().when('isAddrSame', {
      is: false,
      then: yup.string().required('*Mandadtory Field'),
    }),
    perCity: yup.string().when('isAddrSame', {
      is: false,
      then: yup.string().required('*Mandadtory Field'),
    }),
    perZipcode: yup.string().when('isAddrSame', {
      is: false,
      then: yup
        .string()
        .required('* Mandatory Field')
        .matches(/^[0-9]+$/, '* Invalid format')
        .test('len', '* Zipcode should have 5 digits', (val) => val?.length === 5),
    }),

    residentStatus: yup.string().required('* Mandatory field'),
    residentYears: yup
      .string()
      .required('* Mandatory field')
      .matches(/^[0-9]*$/, '* Invalid Format')
      .test('year restriction', '* Invalid year', (val) => {
        return parseInt(val) <= 99;
      }),
    residentMonths: yup
      .string()
      .required('* Mandatory field')
      .matches(/^[0-9]*$/, '* Invalid Format')
      .test('month restriction', '* Invalid month', (val) => {
        return parseInt(val) <= 11;
      })

      .when('residentYears', {
        is: (val) => parseInt(val) === 0,
        then: yup
          .string()
          .test('check for zero', '* Please enter a valid value', (val) => parseInt(val) !== 0),
      }),
    monthlyPayment: yup.string().when('residentStatus', {
      is: (value) => value === 'Rent' || value === 'Homeowner',

      then: yup
        .string()
        .required('* Mandatory Field')
        .test('check for zero', '* Please enter a valid value', (val) => {
          const value = val?.replace(/[^0-9.]/g, '');
          console.log('val', val);
          console.log('value', value);
          return parseInt(value) !== 0;
        }),
    }),
    resUtilityBill: yup
      .string()
      .test('check utility bill', '* Please select a utility bill', (val) => {
        return !(!val && showutilitybill);
      }),
    relationship: yup.string().test('check relationship', '*Mandatory field', (val) => {
      return !(!val && userType);
    }),
    coApplicant: yup.object().shape({
      CoAppFirstName: yup
        .string()
        .max(15, 'Maximum 15 characters can be entered')
        .test('check coapp-first name', '* Mandatory Field', (val) => {
          return !(!val && coApplicantFlag);
        })
        .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/, '* Invalid format'),
      CoAppLastName: yup
        .string()
        .max(25, 'Maximum 25 characters can be entered')
        .test('check coapp-first name', '* Mandatory Field', (val) => {
          return !(!val && coApplicantFlag);
        })
        .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/, '* Invalid format'),
    }),
    coSigner: yup.array().of(
      yup.object().shape({
        CoSignerName: yup.object().shape({
          CoSignerFirstName: yup
            .string()
            .max(15, 'Maximum 15 characters can be entered')
            .test('check cosigne first name', '* Mandatory Field', (val) => {
              return !(!val && coSignerFlag);
            })
            .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/, '* Invalid format'),
          CoSignerLastName: yup
            .string()
            .max(25, 'Maximum 25 characters can be entered')
            .test('check cosigne LastName', '* Mandatory Field', (val) => {
              return !(!val && coSignerFlag);
            })
            .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/, '* Invalid format'),
        }),
      })
    ),
  });
};

export const FormatValidationSchema = yup.object().shape({
  firstName: yup
    .string()
    .test('len', 'Maximum 15 characters can be entered', (val) => (val?.length || 0) <= 15)
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),

  lastName: yup
    .string()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val?.length || 0) <= 25)
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),

  middleName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 20 characters can be entered', (val) => (val ? val?.length <= 20 : true))
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
  secondLastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val ? val?.length <= 25 : true))
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
  motherLastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val ? val?.length <= 25 : true))
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
  // gender: yup.string().required('*Mandatory Field'),
  mobile: yup
    .string()
    .test('length', '* Mobile number should have 7 digits', (val) => {
      return val ? val?.length === 7 : true;
    })
    .test('mobile invalid', 'Invalid Format', (val) => {
      if (!val) {
        return true;
      }
      return yup
        .string()
        .matches(/^[0-9]+$/g)
        .isValidSync(val);
    }),

  areaCode: yup.string(),
  mobile2: yup.string(),

  areaCode2: yup.string(),
  dob: yup
    .date()
    .transform((value) => {
      return value && !isNaN(value.getTime()) ? new Date(value) : null;
    })
    .nullable()
    .max(new Date(), 'Please enter a valid dob')

    .test('age', 'Applicant must be 18 years or older to apply for the loan', (value) =>
      value ? new Date().getFullYear() - new Date(value)?.getFullYear() >= 18 : true
    ),
  email: yup
    .string()
    .test('email format', 'Invalid email format', (val) => {
      if (!val) {
        return true;
      }
      return yup
        .string()
        .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i)
        .isValidSync(val);
    })

    .test('len', '* Invalid email length', (val) => {
      const value = val && val?.split('@');
      const value1 = val && val?.slice(val?.indexOf('@') + 1, val?.lastIndexOf('.'));
      return value ? value?.[0]?.length <= 64 && value1?.length <= 255 : true;
    })
    .test('len', '* Invalid email length', (val) => {
      const value = val && val?.split('@');
      const value1 = val && val?.slice(val?.indexOf('@') + 1, val?.lastIndexOf('.'));
      return value ? value?.[0]?.length + value1?.length <= 320 : true;
    }),

  ssn: yup
    .string()
    .test('length', '* Social security number should have 9 digit', (val) =>
      val ? val?.length === 11 : true
    ),
  maritalStatus: yup.string(),

  // numberOfDependents: yup.string().max(2, '* Exceeds maximum number of dependents allowed'),
  resAddress1: yup
    .string()
    .max(40, 'Address line 1 should be 40 characters or less')

    .matches(/^[a-zA-Z0-9áéíóúñü¿¡.,/-\s]*$/g, '* Invalid format'),
  resAddress2: yup
    .string()
    .nullable()
    .max(20, 'Address line 2 should be 20 characters or less')
    .matches(/^[a-zA-Z0-9áéíóúñü¿¡.,/-\s]*$/g, '* Invalid format'),

  resCountry: yup.string(),
  resState: yup.string(),
  resCity: yup.string(),
  resZipcode: yup
    .string()
    .test('zipcode invalid format', 'Invalid email format', (val) => {
      if (!val) {
        return true;
      }
      return yup
        .string()
        .matches(/^[0-9]+$/)
        .isValidSync(val);
    })

    .test('len', '* Zipcode should have 5 digits', (val) => (val ? val?.length === 5 : true)),
  perAddress1: yup.string().when('isAddrSame', {
    is: false,
    then: yup
      .string()

      .max(40, 'Address line 1 should be 40 characters or less')
      .matches(/^[a-zA-Z0-9áéíóúñü¿¡.,/-\s]*$/g, '* Invalid format'),
  }),
  perAddress2: yup.string().when('isAddrSame', {
    is: false,
    then: yup
      .string()
      .nullable()
      .max(20, 'Address line 2 should be 20 characters or less')
      .matches(/^[a-zA-Z0-9áéíóúñü¿¡.,/-\s]*$/g, '* Invalid format'),
  }),

  perCountry: yup.string().when('isAddrSame', {
    is: false,
    then: yup.string(),
  }),
  perState: yup.string().when('isAddrSame', {
    is: false,
    then: yup.string(),
  }),
  perCity: yup.string().when('isAddrSame', {
    is: false,
    then: yup.string(),
  }),
  perZipcode: yup.string().when('isAddrSame', {
    is: false,
    then: yup
      .string()
      .test('per zipcode invalid format', 'Invalid email format', (val) => {
        if (!val) {
          return true;
        }
        return yup
          .string()
          .matches(/^[0-9]+$/)
          .isValidSync(val);
      })
      .test('len', '* Zipcode should have 5 digits', (val) => (val ? val?.length === 5 : true)),
  }),

  residentStatus: yup.string(),
  residentYears: yup
    .string()

    .matches(/^[0-9]*$/, '* Invalid Format')
    .test('year restriction', '* Invalid year', (val) => {
      return val ? parseInt(val) <= 99 : true;
    }),
  residentMonths: yup
    .string()
    .matches(/^[0-9]*$/, '* Invalid Format')
    .test('month restriction', '* Invalid month', (val) => {
      return val ? parseInt(val) <= 11 : true;
    })

    .when('residentYears', {
      is: (val) => parseInt(val) === 0,
      then: yup
        .string()
        .test('check for zero', '* Please enter a valid value', (val) =>
          val ? parseInt(val) !== 0 : true
        ),
    }),
  monthlyPayment: yup.string().when('residentStatus', {
    is: (value) => value === 'Rent' || value === 'Homeowner',

    then: yup
      .string()

      .test('check for zero', '* Please enter a valid value', (val) => {
        const value = val?.replace(/[^0-9.]/g, '');
        console.log('val', val);
        console.log('value', value);
        return val ? parseInt(value) !== 0 : true;
      }),
  }),
  resUtilityBill: yup.string(),
  relationship: yup.string(),
  coApplicant: yup.object().shape({
    CoAppFirstName: yup
      .string()
      .max(15, 'Maximum 15 characters can be entered')

      .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/, '* Invalid format'),
    CoAppLastName: yup
      .string()
      .max(25, 'Maximum 25 characters can be entered')

      .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/, '* Invalid format'),
  }),
  coSigner: yup.array().of(
    yup.object().shape({
      CoSignerName: yup.object().shape({
        CoSignerFirstName: yup
          .string()
          .max(15, 'Maximum 15 characters can be entered')

          .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/, '* Invalid format'),
        CoSignerLastName: yup
          .string()
          .max(25, 'Maximum 25 characters can be entered')

          .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/, '* Invalid format'),
      }),
    })
  ),
});
