import {
  Box,
  FormHelperText, // InputAdornment,
  InputLabel,
  TextField, // Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import globalClasses from '../../styles/globalStyle.module.scss';

const NumberFormatCustom = (Props) => {
  return <NumberFormat thousandSeparator={true} prefix={'$ '} {...Props} />;
};
const CustomNumberFormat = (props) => {
  const {
    control,
    label,
    name,
    error,
    errorText,
    id,
    prefix,
    disabled,
    suffix,
    // placeholder,
    thousandSeparator,
    allowNegative,
    decimalScale,
    defaultValue,
    dataTestId,
    inputProps,
    // isAllowed,

    maxLength,

    setValue,
    maxValue,
    fixedDecimalScale,
    displayType,
    // allowLeadingZeros,
    allowEmptyFormatting,
    className,
    required,
    placeholder,
  } = props;
  // const CustomTextField = (TextFieldprops) => {
  //   return (
  //     <TextField
  //       {...TextFieldprops}
  //       InputProps={
  //         prefix && {
  //           startAdornment: (
  //             <InputAdornment position="start">
  //               <Typography variant="body1" component="p" sx={{ color: 'rgba(0,0,0,1)' }}>
  //                 {prefix}
  //               </Typography>
  //             </InputAdornment>
  //           ),
  //         }
  //       }
  //       placeholder={placeholder}
  //     />
  //   );
  // };

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            gap={0.5}
            my={0.5}
            className={className}
          >
            <InputLabel htmlFor={name}>
              {required && <span style={{ color: 'red' }}> *&nbsp;</span>}
              {label}
            </InputLabel>
            <TextField
              {...field}
              InputProps={{
                inputComponent: NumberFormatCustom,
                disableUnderline: true,
              }}
              id={id}
              // value={value}

              variant="outlined"
              size="medium"
              className={globalClasses.numberFormat}
              inputProps={{
                'data-testid': dataTestId,
                maxLength,
                placeholder: placeholder ? placeholder : 'Enter value',
                disabled: disabled,
                suffix: suffix,
                thousandSeparator: thousandSeparator,
                allowNegative: allowNegative,
                decimalScale: decimalScale,
                defaultValue: defaultValue,
                prefix: prefix,
                // customInput: CustomTextField,
                displayType: displayType,
                fixedDecimalScale: fixedDecimalScale,
                allowLeadingZeros: false,
                allowEmptyFormatting: allowEmptyFormatting,
                isAllowed: (values) => {
                  {
                    const { value, floatValue } = values;

                    if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
                      return true;
                    }
                    if (value.charAt(0) === '0') {
                      if (value.charAt(1) && value.charAt(1) != '.') {
                        return false;
                      }
                    }
                    if (maxValue) {
                      return floatValue <= maxValue;
                    } else {
                      return true;
                    }
                  }
                },
                onBlur: (e) => {
                  if (setValue && e.target.value?.charAt(e.target.value?.length - 2) === '.') {
                    return setValue(name, parseInt(e.target.value));
                  }
                  return setValue(name, e.target.value);
                },

                ...inputProps,
              }}

              // onChange={(e) => {
              //   let newValue = e.target.value;
              //   if (newValue.includes('.')) {
              //     newValue = parseFloat(newValue).toFixed(2);
              //   }
              //   onChange(newValue);
              // }}
            />
          </Box>
        )}
      />
      {error && (
        <FormHelperText gap={2} className={globalClasses.customSelectError}>
          {' '}
          {errorText}
        </FormHelperText>
      )}
    </>
  );
};

export default CustomNumberFormat;

CustomNumberFormat.propTypes = {
  control: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  error: PropTypes.bool,
  setValue: PropTypes.func,
  errorText: PropTypes.string,
  label: PropTypes.string,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,

  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  suffix: PropTypes.string,
  placeholder: PropTypes.string,
  thousandSeparator: PropTypes.bool,
  allowNegative: PropTypes.bool,
  decimalScale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.string,
  inputProps: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  // isAllowed: PropTypes.bool,
  fixedDecimalScale: PropTypes.bool,
  allowEmptyFormatting: PropTypes.bool,
  displayType: PropTypes.string,
  // allowLeadingZeros: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
};
CustomNumberFormat.defaultProps = {
  control: {},
  name: '',
  label: '',
  error: false,
  errorText: '',
  id: '',
  prefix: '',
  disabled: false,
  suffix: '',
  placeholder: '',
  thousandSeparator: false,
  allowNegative: false,
  allowEmptyFormatting: false,
  decimalScale: '',
  defaultValue: '',
  dataTestId: '',
  inputProps: {},
  className: '',
  // isAllowed: false,
  maxValue: 0,
  fixedDecimalScale: false,
  displayType: '',
  required: false,
  setValue: () => {},

  maxLength: 10,

  // allowLeadingZeros: false,
};
