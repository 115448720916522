import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import manualLogo from 'assets/svg/manual.svg';
import vinLogo from 'assets/svg/vin.svg';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { nameMapping } from 'utils/utility';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';
import Loader from 'components/Loader/Loader';

const LookupInterDialog = ({ control, setValue, errors, onNext, isValid, reset }) => {
  const ApiClient = new ApiClass();
  const { showInterPopup, setShowMainPopup, setShowInterPopup, setShowSecondaryPopup, stateList } =
    useContext(AuthContext);
  const [vinDetail, setVinDetail] = useState([]);
  const [loader, setLoader] = useState(false);
  const [err, setErr] = useState('');
  const [showFirstPage, setShowFirstPage] = useState(true);

  const [watchCollateralType, watchValueGuide, watchlookupType, watchVin] = useWatch({
    control,
    name: ['collateralType', 'valueGuide', 'lookuptype', 'vin', 'mileage'],
  });

  useEffect(() => {
    if (watchVin) {
      setErr('');
    }
  }, [watchVin]);
  const handleBack = () => {
    if (!showFirstPage) {
      setShowFirstPage(true);
    } else {
      setShowInterPopup(false);
      setShowMainPopup(true);
    }
  };

  const handleClose = () => {
    reset();
    setShowInterPopup(false);
  };
  const handleContinue = () => {
    setShowInterPopup(false);
    if (watchCollateralType === 'used' && watchlookupType === 'manual') {
      setShowSecondaryPopup(true);
    } else {
      onNext();
    }
  };

  const toggleChange = (e, value) => {
    setValue('lookuptype', value);
  };

  const onVinSubmit = async () => {
    setErr('');
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        `${UrlHelpers.lookUpVin}/${watchCollateralType}/vin`,
        { vin: watchVin },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      const vinDetails = resp?.used_vehicles?.used_vehicle_list;
      if (vinDetails.length > 0) {
        setVinDetail(vinDetails);
      } else {
        setErr('No data found');
      }
      setValue('year', vinDetails[0]?.model_year);
      setValue('make', vinDetails[0]?.make);
      setValue('model', vinDetails[0]?.model);
      setValue('subModel', vinDetails[0]?.series);
      setValue('style', vinDetails[0]?.style);
      setValue('state', vinDetails[0]?.state);
      setShowFirstPage(false);
    } catch (e) {
      console.log('error fetching', e);
      setErr('No data found');
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showInterPopup}
      >
        {loader && <Loader open={loader} />}
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Box display="flex" gap={2}>
            <KeyboardBackspaceIcon onClick={handleBack} sx={{ cursor: 'pointer' }} />
            <Typography variant="h3">Lookup</Typography>
          </Box>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography
            variant="h5"
            component="h4"
            sx={{ fontWeight: '700' }}
          >{`${nameMapping[watchValueGuide]} - ${nameMapping[watchCollateralType]}`}</Typography>
          <Box display="flex" flexDirection="column" gap={2.5} px={25} my={2.5}>
            {showFirstPage && (
              <>
                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                  Lookup Type
                </Typography>
                <CustomToggleButtonGroup
                  name="lookuptype"
                  exclusive={true}
                  control={control}
                  toggleChange={toggleChange}
                  toggleList={[
                    { valuee: 'vin', displayValue: 'VIN', logo: vinLogo },
                    { valuee: 'manual', displayValue: 'Manual', logo: manualLogo },
                  ]}
                />
              </>
            )}
          </Box>
          {watchlookupType === 'vin' && (
            <>
              <Box
                display="flex"
                gap={1}
                alignItems={err || !!errors?.vin ? 'center' : 'flex-end'}
                justifyContent="center"
              >
                <CustomInputBox
                  label="VIN"
                  name="vin"
                  control={control}
                  error={!!errors?.vin || !!err}
                  errorText={errors?.vin?.message || err}
                  classes={classes.vinInput}
                  flexDirection="column"
                  minWidth="60%"
                  my={0}
                />

                <Button
                  onClick={onVinSubmit}
                  variant="contained"
                  color="secondary"
                  float="right"
                  disabled={!watchVin || vinDetail.length > 0}
                >
                  Find Vehicle
                </Button>
              </Box>
              {!showFirstPage && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={1.5}
                  justifyContent="center"
                  alignItems="center"
                  mt={2.5}
                >
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{ minWidth: '80%', borderBottom: '1px solid black', paddingBottom: '8px' }}
                  >
                    Vehicle Information
                  </Typography>
                  <Divider />
                  <Typography variant="subtitle1" sx={{ minWidth: '80%', fontWeight: 700 }}>
                    {`${vinDetail[0]?.model_year} ${vinDetail[0]?.make} ${vinDetail[0]?.model} ${vinDetail[0]?.series} ${vinDetail[0]?.style} `}
                  </Typography>

                  <Box display="flex" flexDirection="column" gap={1.5} sx={{ minWidth: '80%' }}>
                    <Box display="flex">
                      <Typography variant="subtitle2p" sx={{ minWidth: '250px' }}>
                        Year
                      </Typography>
                      <Typography variant="subtitle2" color="secondary">
                        {vinDetail[0]?.model_year}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography variant="subtitle2" sx={{ minWidth: '250px' }}>
                        Make
                      </Typography>
                      <Typography variant="subtitle2" color="secondary">
                        {vinDetail[0]?.make}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography variant="subtitle2" sx={{ minWidth: '250px' }}>
                        Model
                      </Typography>
                      <Typography variant="subtitle2" color="secondary">
                        {vinDetail[0]?.model}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography variant="subtitle2" sx={{ minWidth: '250px' }}>
                        Series
                      </Typography>
                      <Typography variant="subtitle2" color="secondary">
                        {vinDetail[0]?.series}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography variant="subtitle2" sx={{ minWidth: '250px' }}>
                        Style
                      </Typography>
                      <Typography variant="subtitle2" color="secondary">
                        {vinDetail[0]?.style}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Box display="flex" width="80%" gap={2.5}>
                    <CustomSelectBox
                      flexDirection="column"
                      label="State"
                      name="state"
                      control={control}
                      options={stateList}
                      defaultValue="PR"
                      errors={!!errors?.state}
                      errorText={errors?.state?.message}
                      minWidth="50%"
                    />

                    <CustomInputBox
                      flexDirection="column"
                      label="Mileage"
                      name="mileage"
                      control={control}
                      error={!!errors?.mileage}
                      errorText={errors?.mileage?.message}
                      minWidth="50%"
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={
              !(
                (watchlookupType === 'vin' && vinDetail.length > 0 && isValid) ||
                watchlookupType === 'manual'
              )
            }
          >
            Continue
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
LookupInterDialog.propTypes = {
  setValue: PropTypes.func,
  control: PropTypes.instanceOf(Object),
  onNext: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  reset: PropTypes.func,
};
LookupInterDialog.defaultProps = {
  control: {},
  setValue: () => {},
  errors: {},
  onNext: () => {},
  isValid: false,
  reset: () => {},
};

export default LookupInterDialog;
