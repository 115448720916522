/* eslint-disable no-unused-vars */
import { Box, Paper, Typography } from '@mui/material';
import RegisterUserContext from 'context/RegisterUserContext';
import { useState } from 'react';
import classes from 'styles/globalStyle.module.scss';

import HorizontalLinearStepper from 'components/Stepper/Stepper';

import UserInformation from './UserInformation/UserInformation';
import UserProfile from './UserProfile/UserProfile';
import UserProfileSubmit from './UserProfileSubmit/UserProfileSubmit';
import UserStatusConfiguration from './UserStatusConfiguration/UserStatusConfiguration';

// import VehicleSomething from './InformationRequest/VehicleSomething';

const RegisterUser = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [formDetails, setFormDetails] = useState({ dealers: [] });
  const getStepComponent = {
    1: <UserInformation />,
    2: <UserStatusConfiguration />,
    3: <UserProfile />,
    4: <UserProfileSubmit />,
  };

  const steps = [
    'User Information',
    'User status and configuration',
    'User Profile',
    'Review & Submit',
  ];
  return (
    <RegisterUserContext.Provider
      value={{
        activeStep,
        setActiveStep,
        formDetails,
        setFormDetails,
        // scannedData: vouchedData,
        // setVouchedData,
        // coApplicant,
        // setCoApplicant,
        // coSigner,
        // setCoSigner,
        // userType,
        // setUserType,
        // userDetails,
        // setUserDetails,
        // formLoader,
        // setFormLoader,
      }}
    >
      <>
        <Box py={3} px={2} display="flex" justifyContent="space-between">
          <Typography variant="h3">Add User</Typography>
        </Box>
        {/* <Loader open={formLoader} /> */}
        <Box display="flex" justifyContent="center">
          <Box sx={{ width: '70%' }}>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              <Typography variant="h4" component="p" py={5} textAlign="center">
                Alright! Let’s create user profile in just 4 steps
              </Typography>
              <HorizontalLinearStepper steps={steps} activeStep={activeStep - 1} />
              {/* <RenderComponent /> */}
              {getStepComponent[activeStep]}
            </Paper>
          </Box>
        </Box>
      </>
    </RegisterUserContext.Provider>
  );
};

export default RegisterUser;
