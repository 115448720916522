import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { creditLifeOpt, creditLifeType } from 'utils/data';

import CustomCollapse from 'components/CustomCollapse';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

import constants from '../../utils/constants';

const { insurance } = constants;
const Insurance = ({ control, errors, watch }) => {
  const watchCreditLife = watch('creditLife');
  const watchVehicleInsuranceType = watch('vehicleInsuranceType');
  return (
    <div>
      <CustomCollapse heading={insurance} open={false}>
        <Grid container my={0.5} columnSpacing={4} rowSpacing={1}>
          {/* Fields on left */}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Credit Life"
              control={control}
              name="creditLife"
              errors={!!errors?.creditLife}
              errorText={errors?.creditLife?.message}
              options={creditLifeOpt}
              defaultValue=""
              displayEmpty
              isDisabled
            />
          </Grid>
          {watchCreditLife === 'yes' && (
            <>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomSelectBox
                  flexDirection="column"
                  label="Credit Life Type"
                  control={control}
                  name="creditLifeType"
                  errors={!!errors?.creditLifeType}
                  errorText={errors?.creditLifeType?.message}
                  options={creditLifeType}
                  defaultValue=""
                  required={watchCreditLife === 'yes'}
                  displayEmpty
                  isDisabled
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <CustomNumberFormat
                  name="creditLifeAmount"
                  label="Credit Life Insurance Amount"
                  id="creditLifeAmount"
                  dataTestId="creditLifeAmount"
                  placeholder="Enter value"
                  control={control}
                  error={!!errors?.creditLifeAmount}
                  errorText={errors?.creditLifeAmount?.message}
                  thousandSeparator={true}
                  required={watchCreditLife === 'yes'}
                />
              </Grid>
            </>
          )}
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              name="vehicleInsuranceType"
              label="Vehicle Insurance Type"
              control={control}
              errors={!!errors.vehicleInsuranceType}
              errorText={errors?.vehicleInsuranceType?.message}
              options={[
                // { name: 'Single', value: 'single' },
                { name: 'Double', value: 'double' },
                { name: 'Full', value: 'full' },
              ]}
              required={watchVehicleInsuranceType}
              defaultValue="single"
              displayEmpty
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              id="vehicleInsuranceAmount"
              dataTestId="vehicleInsuranceAmount"
              placeholder="Enter value"
              name="vehicleInsuranceAmount"
              label="Vehicle Insurance Amount"
              control={control}
              error={!!errors?.vehicleInsuranceAmount}
              errorText={errors?.vehicleInsuranceAmount?.message}
              // disabled={insuranceType}
              thousandSeparator={true}
              prefix={'$ '}
              required={watchVehicleInsuranceType}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              id="criticalIllness"
              dataTestId="criticalIllness"
              placeholder="Enter value"
              name="criticalIllness"
              label="Critical Illness"
              control={control}
              // error={!!errors?.vehicleInsurance}
              // errorText={errors?.vehicleInsurance?.message}
              // disabled={insuranceType}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              id="gapFee"
              dataTestId="gapFee"
              placeholder="Enter value"
              name="gapFee"
              label="Gap Fee"
              control={control}
              error={!!errors?.gapFee}
              errorText={errors?.gapFee?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              id="hospitalization"
              dataTestId="hospitalization"
              placeholder="Enter value"
              name="hospitalization"
              label="Hospitalization"
              control={control}
              // error={!!errors?.vehicleInsurance}
              // errorText={errors?.vehicleInsurance?.message}
              // disabled={insuranceType}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};

export default Insurance;

Insurance.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  watch: PropTypes.func,
};
Insurance.defaultProps = {
  control: {},
  errors: {},
  watch: () => {},
};
