import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import styledclass from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

const PaymentCalculatorDetails = ({ calculatedData }) => {
  return (
    <>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            padding: '30px 10px 10px',
          }}
        >
          {' '}
          Payment Calculator Details
        </Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Program</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.programVal}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Promotion</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.promotion}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>New / Used</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.newOrUsed === 'True' ? 'New' : 'Used'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Vehicle Class
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.vehicleClass}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Term</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.term}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Rate</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.rate} %
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Contract Date
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {moment(calculatedData?.formData?.contractDate).format('DD/MM/yyyy') ===
                  'Invalid date'
                    ? calculatedData?.formData?.contractDate
                    : moment(calculatedData?.formData?.contractDate).format('DD/MM/yyyy')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Sale Price</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.salePrice)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h6" component="h3" sx={{ fontWeight: '700', padding: '20px 10px' }}>
            Itemization Of Amount Financed
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Trade in Debt
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.tradeDept)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Cash Down</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.cashDown)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Trade in Allowance
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.tradeAllowance)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Rebate</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.rebate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Net Trade-In</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.calculatedData?.netTradeIn)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Car Care</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.carCare)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Extended Warranty
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.extendedWarranty || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Service Contract
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.serviceContract || 0}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Paint Protection
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.paintProtection)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Other</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.other)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Year</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.year || 'Nil'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Make</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.make || 'Nil'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Model</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.model || 'Nil'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Balance</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.calculatedData?.balance)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="h6" component="h3" sx={{ fontWeight: '700', padding: '20px 10px' }}>
            Insurance
          </Typography>

          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Credit Life</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.creditLife}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Credit Life Type
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.creditLifeType || 'Nil'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Vehicle Insurance Type
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.vehicleInsuranceType || 'Nil'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Credit Life Insurance Amount
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.creditLifeAmount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Vehicle Insurance Amount
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.vehicleInsuranceAmount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Critical Illness
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.criticalIllness)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Hospitalization
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.hospitalization)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>Gap Fee</TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.formData?.gapFee)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Typography variant="h6" component="h3" sx={{ fontWeight: '700', padding: '20px 10px' }}>
            Fees
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Registration Fees
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {numberToCurrency(calculatedData?.calculatedData?.registrationFees)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Typography variant="h6" component="h3" sx={{ fontWeight: '700', padding: '20px 10px' }}>
            Deferred Payment
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: styledclass.tableHeadingList }}>
                  Months Deferred
                </TableCell>
                <TableCell classes={{ root: styledclass.currencyValue }}>
                  {calculatedData?.formData?.monthDeferred || 0}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default PaymentCalculatorDetails;

PaymentCalculatorDetails.propTypes = {
  calculatedData: PropTypes.instanceOf(Object),
};
PaymentCalculatorDetails.defaultProps = {
  calculatedData: {},
};
