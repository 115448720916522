import { Box, Button, Typography } from '@mui/material';
import UploadIcon from 'assets/svg/uploadIcon.svg';
import PropTypes from 'prop-types';
import { FileUploader } from 'react-drag-drop-files';
import { Controller } from 'react-hook-form';
import customClasses from 'styles/InformationRequest.module.scss';

const CustomFileUploader = ({ control, name, handleChange, fileTypes, setError }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FileUploader
          handleChange={handleChange}
          inputProps={{
            'data-testid': name,
          }}
          name="file"
          types={fileTypes}
          onTypeError={(err) => {
            console.log('err', err);

            setError(name, {
              type: 'custom',
              message:
                'Only files with the following formats are allowed: PNG, JPG, PDF. Click to upload again',
            });
          }}
          maxSize={5}
          onSizeError={(err) => {
            console.log('err', err);

            setError(name, {
              type: 'custom',
              message: 'The file exceeds the maximum upload size (10 MB). Click to upload again',
            });
          }}
          // eslint-disable-next-line react/no-children-prop
          children={
            <Box
              className={customClasses.uploadContainer}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              // sx={{ borderTop: '1px solid #661c69' }}
            >
              <Typography variant="subtitle2" component="p">
                Drag and Drop your file here
              </Typography>
              <Typography variant="subtitle2" component="p" sx={{ padding: '20px 0' }}>
                Or
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                display="flex"
                sx={{ marginBottom: '20px' }}
              >
                <img src={UploadIcon} /> &nbsp;
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={{ fontWeight: '700' }}
                ></Typography>
                Upload
              </Button>
              <Typography component="p" sx={{ color: '#909090', fontSize: '10px' }}>
                Max: 5MB Supporting file types: PNG, JPG, PDF.
              </Typography>
              {/* <Typography component="p" color="red">
                {errors && errorText}
              </Typography> */}
            </Box>
          }
          {...field}
        />
      )}
    />
  );
};

export default CustomFileUploader;

CustomFileUploader.propTypes = {
  control: PropTypes.instanceOf(Object),
  name: PropTypes.string,
  handleChange: PropTypes.func,
  fileTypes: PropTypes.array,
  fileErr: PropTypes.string,
  setFileErr: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  errorText: PropTypes.string,
  setError: PropTypes.func,
};
CustomFileUploader.defaultProps = {
  control: {},
  name: '',
  handleChange: () => {},
  fileTypes: [],
  fileErr: '',
  setFileErr: () => {},
  errors: {},
  errorText: '',
  setError: () => {},
};
