const constants = {
  valueGuideLookUp: 'Value Guide Look up',
  submit: 'Submit',
  signIn: 'Sign In',
  dynamicLoans: 'Dynamic Loans',
  userName: 'UserName',
  paymentCalculator: 'Payment Calculator',
  lookUp: 'Value Guide',
  program: 'Program',
  term: 'Term',
  promotion: 'Promotion',
  rate: 'Rate',
  balloonPayment: 'Balloon Payment',
  contractDate: 'Contract Date',
  amountFinanced: 'Itemization of Amount Financed',
  insurance: 'Insurance',
  fees: 'Fees',
  deferredPayment: 'Deferred Payment',
  paymentSchedule: 'Payments Schedule',
  distributedData: 'Distributed Data',
};

export default constants;
