import * as yup from 'yup';

export const enrollDealerUserSchema = yup.object().shape({
  userId: yup
    .string()
    .required('* Mandatory Field')
    .test('len', 'Maximum 16 characters can be entered', (val) => val?.length <= 16)
    .matches(/^[a-zA-Záéíóúñü¿¡\d\s]*$/, '* Invalid format'),
  linkedUserId: yup
    .string()
    .required('* Mandatory Field')
    .test('len', 'Maximum 16 characters can be entered', (val) => val?.length <= 16)
    .matches(/^[a-zA-Záéíóúñü¿¡\d\s]*$/, '* Invalid format'),
  firstName: yup
    .string()
    .required('* Mandatory Field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),

  lastName: yup
    .string()
    .required('* Mandatory Field')
    .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
    .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),

  secondLastName: yup
    .string()
    .nullable()
    .test('len', 'Maximum 25 characters can be entered', (val) => (val ? val?.length <= 25 : true))
    .matches(/^[a-zA-Záéíóúñü¿¡]*$/, '* Invalid format'),
  mobile: yup
    .string()
    .required('* Mandatory field')
    .test('length', '* Mobile number should have 7 digits', (val) => {
      return val?.length === 7;
    })
    .matches(/^[0-9]+$/g),
  areaCode: yup
    .string()
    .required('* Area code is mandatory')
    .matches(/^[0-9]+$/g),
  email: yup
    .string()
    .required('* Mandatory Field')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i, '*Invalid email format')
    .max(319, '* Invalid email length')
    .test('len', 'error', (val) => {
      const value = val && val?.split('@');
      return value?.[0]?.length <= 64 && value[1]?.length <= 255;
    }),
  includeLoanOriginator: yup.string().required('* Mandatory Field'),
  includeSalesManager: yup.string().required('* Mandatory Field'),
  includeReference: yup.string().required('* Mandatory Field'),
  canSeeOwnApplication: yup.string().required('* Mandatory Field'),
});
