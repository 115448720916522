import { Box, Button, Link, Typography } from '@mui/material';
import Approved from 'assets/images/approved.png';
import Rejected from 'assets/images/declined.png';
import Review from 'assets/images/review.png';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext } from 'react';

const ApplicationApproved = () => {
  const { loanStatus, caseReferenceId } = useContext(MainApplicationContext);
  const { createAppApplicationId } = useContext(AuthContext);

  const handleImg = () => {
    switch (loanStatus) {
      case 'APPROVE':
      case 'APPSCOR':
        return <img src={Approved} height="324px" width="362px" />;
      case 'DECLINE':
      case 'DECSCOR':
        return <img src={Rejected} height="324px" width="362px" />;
      case 'PENDINGANALYST':
        return <img src={Review} height="324px" width="362px" />;
      default:
        break;
    }
  };

  const handleStatusText = () => {
    switch (loanStatus) {
      case 'APPROVE':
      case 'APPSCOR':
        return 'Loan Approved!';
      case 'DECLINE':
      case 'DECSCOR':
        return 'Loan Declined!';
      case 'PENDINGANALYST':
        return 'Application Under Review!';
      default:
        break;
    }
  };

  const handleDescriptionText = () => {
    switch (loanStatus) {
      case 'APPROVE':
      case 'APPSCOR':
        return 'Congratulations! Based on the details provided, your loan amount is approved.';
      case 'DECLINE':
      case 'DECSCOR':
        return 'We regret to inform you that the auto loan application has been declined.';
      case 'PENDINGANALYST':
        return 'Thank you for submitting the application. We are currently reviewing your application to ensure the best possible decision. Please check the status of the application in sometime. ';
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {handleImg()}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '30px',
          gap: '20px',
          textAlign: 'center',
        }}
      >
        <Typography fontSize="48px !important" fontWeight={700} color="#E37617">
          {handleStatusText()}
        </Typography>
        {loanStatus === 'PENDINGANALYST' ? (
          <Typography variant="subtitle1" sx={{ maxWidth: '752px' }}>
            {handleDescriptionText()}
            Your Application ID{' '}
            <Link
              component="button"
              color="#1976D2"
              sx={{ textDecoration: 'none', fontSize: '16px' }}
            >
              {caseReferenceId ? caseReferenceId : createAppApplicationId}
            </Link>
          </Typography>
        ) : (
          <Box sx={{ display: 'block' }}>
            <Typography variant="subtitle1" sx={{ maxWidth: '752px' }}>
              {handleDescriptionText()}
              <Box>
                Your {caseReferenceId ? 'Application' : 'Ref'} ID{' '}
                <Link
                  component="button"
                  color="#1976D2"
                  sx={{ textDecoration: 'none', fontSize: '16px' }}
                >
                  {caseReferenceId ? caseReferenceId : createAppApplicationId}
                </Link>
              </Box>
            </Typography>
          </Box>
        )}

        <Button variant="contained" color="secondary">
          View Application
        </Button>
      </Box>
    </Box>
  );
};

export default ApplicationApproved;
