import { Typography } from '@mui/material';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext } from 'react';

import { userLabel } from './moduleUtilities';

const ApplicationPageHeading = () => {
  const steps = {
    1: 'Information on request',
    2: 'Employment Information',
    3: 'Collateral Information',
    4: 'Financing Terms',
    5: 'Review & Submit',
  };
  const { userType, activeStep = 1 } = useContext(MainApplicationContext);
  let userLabell = userLabel(userType);
  return (
    <Typography variant="h3" component="p" py={4}>
      {activeStep === 1 || activeStep === 2
        ? `${steps[activeStep]}: ${userLabell}`
        : steps[activeStep]}
    </Typography>
  );
};

export default ApplicationPageHeading;
