import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import CameraIcon from 'assets/svg/cameraIcon.svg';
import PropTypes from 'prop-types';
import customClasses from 'styles/InformationRequest.module.scss';
import { getBase64 } from 'utils/utility';

import BootstrapDialogTitle, { BootstrapDialog } from 'components/CustomDialogBox/CustomDialogBox';
import CustomFileUploader from 'components/CustomFileUploader/CustomFileUploader';

const FileUploadDialog = ({
  control,
  errors,
  setOpen,
  open,
  setError,
  clearErrors,
  setValue,
  file,
  setFile,
  setShowIDCamera,
}) => {
  const fileTypes = ['JPG', 'PNG', 'PDF'];

  const handleChange = (filee) => {
    clearErrors('manualFile');

    setFile(filee);
  };

  const handleClose = () => {
    setValue('manualFile', '');
    setOpen(false);
  };

  const handleContinue = () => {
    getBase64(file, (result) => {
      if (result) {
        setValue('manualFile', result);
      }
    });
    setOpen(false);
  };
  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span">
            ID Verification
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display="flex" flexDirection="column" gap={5} px={5}>
            <Box display="flex" gap={0.5}>
              <LockIcon color="primary" />
              <Typography variant="subtitle2">
                There’s no need to worry, your documents are secure and will not be stored. Read
                more in Review Policy
              </Typography>
            </Box>
            <Box display="flex" gap={2}>
              <Box
                className={customClasses.fileuploadContainer}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  display="flex"
                  onClick={() => setShowIDCamera(true)}
                >
                  <img src={CameraIcon} /> &nbsp;
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ fontWeight: '700' }}
                  ></Typography>
                  Camera
                </Button>
              </Box>
              <Box display="flex" flexDirection="column" width="50%">
                <CustomFileUploader
                  handleChange={handleChange}
                  name="manualFile"
                  control={control}
                  fileTypes={fileTypes}
                  setError={setError}
                  errors={!!errors?.manualFile}
                  errorText={errors?.manualFile?.message}
                />
              </Box>
            </Box>
          </Box>
          {!errors?.manualFile?.message && file && (
            <Box
              display="flex"
              gap={0.5}
              mt={2.5}
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <CheckCircleIcon htmlColor="green" />
              <Typography variant="">{file?.name}</Typography>
            </Box>
          )}
          <Box display="flex" gap={0.5} justifyContent="center" alignItems="center" width="100%">
            <Typography component="p" color="red">
              {errors?.manualFile?.message}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            onClick={handleContinue}
            sx={{ maxHeight: '40px' }}
            disabled={!file || errors?.manualFile?.message}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};
FileUploadDialog.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  setValue: PropTypes.func,
  file: PropTypes.string,
  setFile: PropTypes.func,
  setShowIDCamera: PropTypes.func,
};
FileUploadDialog.defaultProps = {
  control: {},
  errors: {},
  setOpen: () => {},
  open: false,
  setError: () => {},
  clearErrors: () => {},
  setValue: () => {},
  file: '',
  setFile: () => {},
  setShowIDCamera: () => {},
};

export default FileUploadDialog;
