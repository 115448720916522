import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import RegisterUserContext from 'context/RegisterUserContext';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { enrollDealerUserProfileSchema } from 'schemaValidations/enrollDealerUserProfileSchema';

import UserProfileForm from '../UserProfileForm/UserProfileForm';

// import BasicInformation from '../InformationRequest/BasicInformation/BasicInformation';

const UserProfile = () => {
  const navigate = useNavigate();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',

    defaultValues: {
      team: '',
      securityProfile: '',
    },
    resolver: yupResolver(enrollDealerUserProfileSchema),
  });
  const { activeStep, setActiveStep, formDetails, setFormDetails } =
    useContext(RegisterUserContext);
  const [watchteam, watchsecurityProfile] = watch(['team', 'securityProfile']);
  const nextStep = () => {
    const formObj = {
      team: watchteam,
      securityProfile: watchsecurityProfile,
    };
    console.log('formDetailsformDetailsformDetailsformDetailsformDetails');
    setFormDetails({ ...formDetails, ...formObj });
    setActiveStep(activeStep + 1);
  };
  console.log({ errors });
  console.log('formDetails?.dealers && formDetails?.dealers.length < 1');
  console.log(formDetails.dealers);
  return (
    <>
      <Typography variant="h3" component="p" py={4}>
        User Profile
      </Typography>
      <Grid container justifyContent="center">
        <Box sx={{ width: '70%' }}>
          <UserProfileForm control={control} errors={errors} />
        </Box>
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap={2} py={4} marginLeft={'auto'}>
          <Link
            component="button"
            onClick={() => {
              navigate('/manage-dealers');
            }}
            variant="subtitle2"
            color="secondary"
            sx={{ fontSize: '16px' }}
            data-testid="discard-userprofile-application"
          >
            Discard Application
          </Link>
          {/* <Button color="secondary" variant="outlined">
            Save as draft
          </Button> */}
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit(nextStep)}
            disabled={
              (formDetails?.dealers && formDetails?.dealers.length < 1) ||
              Object.entries(errors)?.length
            }
            data-testid="save-continue-userprofile"
          >
            Save & continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default UserProfile;
