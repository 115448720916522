import { Box, Checkbox, Typography } from '@mui/material';
import RegisterUserContext from 'context/RegisterUserContext';
import React, { useContext, useState } from 'react';
import classes from 'styles/globalStyle.module.scss';

const DealerTable = () => {
  const { formDetails, setFormDetails } = useContext(RegisterUserContext);
  const data = [
    {
      id: 1,
      dealerId: '7777',
      dealer: 'Hampton Ford',
    },
    {
      id: 2,
      dealerId: '8888',
      dealer: 'Autos VEGA',
    },
    {
      id: 3,
      dealerId: '5555',
      dealer: 'Bella International Corp',
    },
    {
      id: 4,
      dealerId: '9999',
      dealer: 'Luis Rujel Auto Sales Inc',
    },
  ];
  const [selectedRows, setSelectedRows] = useState([]);
  const handleCheckboxChange = (event, row) => {
    const selected = event.target.checked;
    if (selected) {
      setSelectedRows([...selectedRows, row]);
      setFormDetails({ ...formDetails, dealers: [...selectedRows, row] });
    } else {
      setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
      setFormDetails({ ...formDetails, dealers: selectedRows.filter((r) => r.id !== row.id) });
    }
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.tableOutline}
        sx={{ marginTop: '5px' }}
      >
        <Box display="flex" gap={2} sx={{}} p={2}>
          <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
            Select Dealers
          </Typography>
        </Box>
        <Box display="flex" gap={2} sx={{ backgroundColor: '#F2F2F2' }} p={2}>
          <Typography
            variant="subtitle2"
            sx={{ flexGrow: 1, fontWeight: '700', marginLeft: '35px', maxWidth: '38%' }}
          >
            Dealer ID
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            Dealer
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
          {data?.map?.((x) => (
            <Box display="flex" key={x.id} p={2}>
              <Checkbox
                color="secondary"
                checked={selectedRows.find((el) => el.id === x.id)}
                onChange={(event) => handleCheckboxChange(event, x)}
                data-testid={x.dealerId}
              />
              <Typography
                variant="subtitle2"
                sx={{ flexGrow: 1, maxWidth: '40%', marginLeft: '15px' }}
              >
                {x.dealerId}
              </Typography>
              <Typography
                // key={index}
                variant="subtitle2"
                sx={{ overflowWrap: 'break-word' }}
              >
                {x.dealer}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box display="flex" justifyContent={'space-between'} gap={2} sx={{}} p={2}>
        <Typography variant="subtitle2" sx={{ fontWeight: '700' }}>
          Selected Dealers
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" className={classes.tableOutline}>
        <Box display="flex" gap={2} sx={{ backgroundColor: '#F2F2F2' }} p={2}>
          <Typography variant="subtitle2" sx={{ flexGrow: 1, fontWeight: '700', maxWidth: '38%' }}>
            Dealer ID
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ minWidth: '10%', maxWidth: '10%', fontWeight: '700' }}
          >
            Dealer
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
          {selectedRows?.map?.((x) => (
            <Box display="flex" key={x.id} p={2}>
              <Typography
                variant="subtitle2"
                sx={{ flexGrow: 1, maxWidth: '40%', marginLeft: '15px' }}
              >
                {x.dealerId}
              </Typography>
              <Typography variant="subtitle2" sx={{ overflowWrap: 'break-word' }}>
                {x.dealer}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default DealerTable;
