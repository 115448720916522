import * as yup from 'yup';

export const lookupSchema = yup
  .object()
  .shape({
    collateralType: yup.string().required('Type is empty'),
    valueGuide: yup.string().required('value guide required'),
    vin: yup.string().when('lookuptype', {
      is: 'vin',
      then: yup.string().required('VIN required'),
    }),
    year: yup.string().required('Year required'),

    make: yup.string().required('Make required'),

    model: yup.string().required('model required'),

    subModel: yup.string().required('sub Model required'),

    style: yup.string().when('valueGuide', {
      is: 'Blackbook',
      then: yup.string().required('style required'),
    }),

    mileage: yup.number().when('valueGuide', {
      is: 'Blackbook',
      then: yup
        .number()
        .transform((value) => (value ? value : 0))
        .min(1, 'Please enter a valid mileage'),
    }),
  })
  .required();
