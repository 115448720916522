import CloseIcon from '@mui/icons-material/Close';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext, useState } from 'react';
import { numberToCurrency } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';

const CollateralInformationForm = () => {
  const { collateralInfo } = useContext(AuthContext);
  const { setShowCollateralPopup } = useContext(MainApplicationContext);
  const [alertToggle, setAlertToggle] = useState('flex');

  const handleClose = () => {
    setAlertToggle('none');
  };

  const total =
    collateralInfo?.productDetails?.collateralType === 'new' &&
    collateralInfo?.productDetails?.valueGuide === 'Blackbook'
      ? collateralInfo?.vehicleValues?.basevehicleValue.msrp +
        collateralInfo?.vehicleValues?.equipmentAdjustment.msrp
      : collateralInfo?.vehicleValues?.basevehicleValue +
        collateralInfo?.vehicleValues?.equipmentAdjustment +
        collateralInfo?.vehicleValues?.mileageAdjustment;

  return (
    <Box
      width="70%"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '0px',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          display: `${alertToggle}`,
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '10px',
          justifyContent: 'space-between',
          background: 'rgba(239, 171, 57, 0.05)',
          borderWidth: '1px 1px 1px 5px',
          borderStyle: 'solid',
          borderColor: 'rgba(239, 171, 57, 0.5)',
          gap: '10px',
        }}
      >
        <WarningAmberRoundedIcon sx={{ color: '#EFAB3980' }} />
        <Typography fontSize="14px !important">
          Please click the Value lookup utility and enter the collateral vehicle information. Review
          the auto populated results in the collateral information and proceed. Iterate the value if
          required by taking the value lookup action again.
        </Typography>
        <IconButton
          data-testid="closeBtn"
          onClick={handleClose}
          sx={{ height: '16px', width: '16px' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ width: '100%' }}>
        <CustomCollapse
          heading="Collateral Information"
          open={true}
          valueLookupButton={true}
          setShowCollateralPopup={setShowCollateralPopup}
        >
          <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">New</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralInfo?.productDetails?.collateralType === 'new' ? 'Yes' : 'No'}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Vehicle Type</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                Local
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Year</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralInfo?.productDetails?.year}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Brand</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralInfo?.productDetails?.make}
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important">Model</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                {collateralInfo?.productDetails?.model}
              </Typography>
            </Grid>
            {collateralInfo?.productDetails?.series && (
              <>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important">Sub Model</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                    {collateralInfo?.productDetails?.series ||
                      collateralInfo?.productDetails?.subModel}
                  </Typography>
                </Grid>
              </>
            )}
            {collateralInfo?.productDetails?.mileage && (
              <>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important">Mileage</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                    {collateralInfo?.productDetails?.mileage}
                  </Typography>
                </Grid>
              </>
            )}
            {collateralInfo?.productDetails?.vin && (
              <>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important">VIN</Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
                    {collateralInfo?.productDetails?.vin}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </CustomCollapse>
        <CustomCollapse heading="Vehicle Values" open={true}>
          {collateralInfo?.productDetails?.valueGuide === 'Distributor data' && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              display="grid"
              sx={{ border: '1px solid #DADADA', gridTemplateColumns: '70% 30%' }}
            >
              <Box sx={{ backgroundColor: '#F2F2F2' }}></Box>
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight="700">
                  MSRP
                </Typography>
              </Box>
              <Box p={2}>
                <Typography fontSize="14px !important">Base Vehicle Value</Typography>
              </Box>
              <Box p={2}>
                <Typography fontSize="14px !important">{`${numberToCurrency(
                  collateralInfo?.vehicleValues?.basevehicleValue?.msrp
                )}`}</Typography>
              </Box>
            </Grid>
          )}
          {collateralInfo?.productDetails?.valueGuide === 'Blackbook' && (
            <Grid
              container
              rowSpacing={1}
              columnSpacing={2}
              display="grid"
              sx={{ border: '1px solid #DADADA', gridTemplateColumns: '70% 30%' }}
            >
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight="700">
                  Wholesale
                </Typography>
              </Box>
              <Box sx={{ backgroundColor: '#F2F2F2' }}></Box>
              <Box sx={{ borderBottom: '1px solid #DADADA' }}></Box>
              <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography fontSize="14px !important" fontWeight={700}>
                  XClean
                </Typography>
              </Box>
              <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography fontSize="14px !important">Base Vehicle Value</Typography>
              </Box>
              <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                <Typography fontSize="14px !important">
                  {collateralInfo?.productDetails?.collateralType === 'new'
                    ? `${numberToCurrency(collateralInfo?.vehicleValues?.basevehicleValue?.msrp)}`
                    : `${numberToCurrency(collateralInfo?.vehicleValues?.basevehicleValue)}`}
                </Typography>
              </Box>
              {collateralInfo?.vehicleValues?.equipmentAdjustment != 0 && (
                <>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important" fontWeight={700}>
                      Equipment Adjustment
                    </Typography>
                  </Box>
                  <Box sx={{ borderBottom: '1px solid #DADADA' }}></Box>
                  {collateralInfo?.vehicleValues?.selectedEquipmentAjustment?.map((x, index) => {
                    return (
                      <>
                        <Box key={index} p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                          <Typography fontSize="14px !important">
                            {x.name || x.description}
                          </Typography>
                        </Box>
                        <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                          <Typography fontSize="14px !important">
                            {numberToCurrency(x.xclean || x.retail)}
                          </Typography>
                        </Box>
                      </>
                    );
                  })}
                </>
              )}
              {collateralInfo?.vehicleValues?.mileageAdjustment != 0 && (
                <>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important" fontWeight={700}>
                      Mileage Adjustment
                    </Typography>
                  </Box>
                  <Box p={2} sx={{ borderBottom: '1px solid #DADADA' }}>
                    <Typography fontSize="14px !important">
                      {numberToCurrency(collateralInfo?.vehicleValues?.mileageAdjustment)}
                    </Typography>
                  </Box>
                </>
              )}
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight={700}>
                  Total
                </Typography>
              </Box>
              <Box p={2} sx={{ backgroundColor: '#F2F2F2' }}>
                <Typography fontSize="14px !important" fontWeight={700}>
                  {numberToCurrency(total)}
                </Typography>
              </Box>
            </Grid>
          )}
        </CustomCollapse>
      </Box>
    </Box>
  );
};

export default CollateralInformationForm;
