import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useNavigate } from 'react-router';

import logo from '../../assets/dealerPortalLogo.svg';

const Header = () => {
  const { userType } = React.useContext(AuthContext);

  const logOut = async () => {
    navigate('/login', { state: { userType: 'dealer' } });
  };
  const navigate = useNavigate();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: 'white' }}>
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          ></IconButton>
          {userType === 'dealer' && (
            <Box display="flex" gap={1} flexGrow="1">
              <img src={logo} alt="Dealer Portal Logo" />
              <Typography variant="h3" component="div">
                Dealer Portal
              </Typography>
            </Box>
          )}
          {userType === 'banker' && (
            <Box display="flex" gap={1} flexGrow="1">
              <img src={logo} alt="Dealer Portal Logo" />
              <Typography variant="h3" component="div">
                Digital Portal
              </Typography>
            </Box>
          )}

          {(!userType || userType === 'dealer') && (
            <Button color="inherit" onClick={logOut} sx={{ fontSize: '18px', color: 'black' }}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;

Header.propTypes = {
  open: PropTypes.bool,
};
Header.defaultProps = {
  open: false,
};
