import * as yup from 'yup';

export const enrollDealerUserStatusSchema = yup.object().shape({
  isActive: yup.string().required('* Mandatory Field'),
  maximizeTty: yup.string().required('* Mandatory Field'),
  ssn: yup
    .string()
    .required('*Mandatory Field')
    .matches(/^[0-9]+$/g, '* Invalid format')
    .test('length', '* Social security number should have 9 digit', (val) => val?.length === 9),
  dob: yup
    .date()
    .nullable()
    .typeError('Please enter a valid dob')
    .required('*Mandatory Field')
    .max(new Date(), 'Please enter a valid dob')
    .test(
      'age',
      'User must be 18 years or older',
      (value) => value && new Date().getFullYear() - new Date(value)?.getFullYear() >= 18
    ),
});
