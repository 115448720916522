import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

// import { relationshipArray } from 'utils/utility';
import CustomCollapse from 'components/CustomCollapse';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

import { userLabel } from './moduleUtilities';

const RelationWithOwner = ({ control, errors, userType, inputRef, relationshipList }) => {
  if (!userType) {
    return null;
  }
  const heading = userLabel(userType);
  return (
    <CustomCollapse heading={heading} open={true}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomSelectBox
          flexDirection="column"
          label="Relationship with Owner"
          control={control}
          name="relationship"
          errors={!!errors?.relationship}
          errorText={errors?.relationship?.message}
          options={relationshipList}
          defaultValue=""
          required
          displayEmpty
          inputRef={inputRef}
        />
      </Grid>
    </CustomCollapse>
  );
};

export default RelationWithOwner;

RelationWithOwner.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  userType: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  relationshipList: PropTypes.instanceOf(Object),
};
RelationWithOwner.defaultProps = {
  control: {},
  errors: {},
  userType: '',
  inputRef: null,
  relationshipList: [],
};
