import { Box, Grid, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext.js';
import MainApplicationContext from 'context/MainApplicationContext';
import LookupCommon from 'pages/LookupCommonComponent/LookupCommon';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CreateApplicationFooter from '../CreateApplicationFooter.js';
import { decidePrevious } from '../InformationRequest/moduleUtilities/moduleUtilities';
import CollateralInformationForm from './CollateralInformationForm.js';
import CollateralInformationLookupDialog from './CollateralInformationLookupDialog';
import ValueGuideLookupDialog from './ValueGuideLookupDialog.js';

const CollateralInformation = () => {
  const { lookupSaved, collateralInfo, createAppApplicationId } = useContext(AuthContext);
  const {
    setActiveStep,
    setCompletedSteps,
    userType,
    setUserType,
    activeStep,
    coApplicant,
    coSigner,
    setBackFlag,
    reviewFlag,
    setOpenDraftModal,
    calculationState,
  } = useContext(MainApplicationContext);
  console.log('coapplicant,cosigner details collateral info', coApplicant, coSigner);
  const ApiClient = new ApiClass();
  const onBackClick = async () => {
    setUserType('');
    await decidePrevious({
      userType,
      setUserType,
      activeStep,
      setActiveStep,
      coApplicant,
      coSigner,
      setBackFlag,
    });
  };
  const onSaveAndContinue = async ({ draft = false }) => {
    const { productDetails, vehicleValues } = collateralInfo;
    const payload = {
      VehicleValues: {
        BaseVehicleValue: `${
          productDetails?.collateralType === 'new'
            ? vehicleValues?.basevehicleValue.msrp
            : vehicleValues?.basevehicleValue
        }`,
        EquipmentAdjustment: `${
          productDetails?.collateralType === 'new' && productDetails?.valueGuide === 'Blackbook'
            ? vehicleValues?.equipmentAdjustment.msrp
            : vehicleValues?.equipmentAdjustment
        }`,
        MileageAdjustment: `${vehicleValues?.mileageAdjustment}`,
        SelectedEquipmentAjustment: vehicleValues?.selectedEquipmentAjustment?.map((x) => {
          return {
            Description: x.name || x.description,
            Category: x.category || '',
            Retail: `${x.xclean || x.retail}`,
          };
        }),
      },
      ProductDetails: {
        Year: `${productDetails.year}`,
        // VehicleType: `${productDetails.vehicleType}`,
        VehicleType: `Local`,
        Brand: `${productDetails.make}`,
        Model: `${productDetails.model}`,
        SubModel: `${
          productDetails?.valueGuide === 'Blackbook'
            ? productDetails.series
            : productDetails.subModel
        }`,
        Style: `${productDetails.style || ''}`,
        ValueGuide: productDetails.valueGuide === 'Blackbook' ? 'Black Book' : 'Distributor data',
        State: `${productDetails.state || ''}`,
        Mileage: `${productDetails.mileage || ''}`,
        // VehicleNewOrOld: `${productDetails.collateralType}`,
        VehicleNewOrOld: `${productDetails?.collateralType === 'new' ? 'True' : 'False'}`,
        Vin: `${productDetails.vin || ''}`,
      },
    };

    let initPayload = {
      Event: 'CollateralInformation',
      Application: {
        Products: {
          ProductType: 'Indirect Loan Account',
          ProductName: 'AutoLoanManual',
        },

        ApplicationID: createAppApplicationId,
        DealerId: '1234',
        UserId: 'co2607',
        CollateralInformation: { ...payload },
        Source: {
          Language: 'EN',
          Location: 'PR',
        },
      },
    };
    try {
      const endpoint = draft ? UrlHelpers.applicationDraft : UrlHelpers.informationReqSave;
      let calculate = await ApiClient.post(
        endpoint,
        { ...initPayload },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (!draft && calculate) {
        if (!reviewFlag) {
          setActiveStep(4);
          setCompletedSteps([1, 2, 3]);
        } else if (reviewFlag) {
          setActiveStep(5);
          setCompletedSteps([1, 2, 3, 4]);
        }
      } else if (draft && calculate) {
        setOpenDraftModal(true);
      }
    } catch (e) {
      console.log('error fetching', e);
    }
  };

  return (
    <>
      <Box>
        <Typography variant="h3" component="p" py={4}>
          Collateral Information
        </Typography>
        <Grid container justifyContent="center">
          {lookupSaved ? <CollateralInformationForm /> : <CollateralInformationLookupDialog />}
          <ValueGuideLookupDialog />
          <LookupCommon
            coApplicant={coApplicant}
            coSigner={coSigner}
            reviewFlag={reviewFlag}
            calculationState={calculationState}
          />
        </Grid>
      </Box>

      <CreateApplicationFooter
        disabledFlag={reviewFlag}
        onBackClick={onBackClick}
        onSaveContinue={onSaveAndContinue}
        saveDisabledFlag={!lookupSaved}
        onSaveDraft={() => onSaveAndContinue({ draft: true })}
        draftDisabledFlag={!lookupSaved}
      />
    </>
  );
};

export default CollateralInformation;

CollateralInformation.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
CollateralInformation.defaultProps = {
  control: {},
  errors: {},
};
