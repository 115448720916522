export const currencyToNumber = (currency) =>
  (currency && Number(currency.toString().replace(/[^0-9.-]+/g, ''))) || 0;

export const numberToCurrency = (number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number || 0);
};

export const nameMapping = {
  Blackbook: 'Black Book',
  'Distributor data': 'Distributor Data',
  new: 'New',
  used: 'Used',
};

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    console.log('parsed token', JSON.parse(jsonPayload));
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.log('error in token parsing', e);
    return '';
  }
};

export const getBase64 = (file, callback) => {
  try {
    console.log('file', file);
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result);
    };
  } catch (e) {
    console.log('Error converting file to base64: ', e);
  }
};

export const blobToFile = (theBlob, fileName) => {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  const blob = theBlob;
  const { type } = blob;
  const ext = imgExtension(type);
  blob.lastModifiedDate = new Date();
  blob.name = `${fileName}.${ext}`;
  return blob;
};

export const imgExtension = (type) => {
  let format;
  if (type === 'image/png') {
    format = 'png';
  } else if (type === 'application/pdf') {
    format = 'pdf';
  } else if (type === 'image/jpeg') {
    format = 'jpg';
  }
  return format;
};

export const relationshipArray = [
  { name: 'Father-Mother', value: 'Father-Mother' },
  { name: 'Spouse', value: 'Spouse' },
  { name: 'Other', value: 'Other' },
  { name: 'Not related', value: 'Not related' },
];

export const removeNullValues = (value) => {
  if (value) {
    return value;
  }
  return '';
};
