import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Link, Paper, Select, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import manageClasses from 'styles/ManageDealers.module.scss';
import classes from 'styles/globalStyle.module.scss';

import CustomSwitch from 'components/CustomSwitch/CustomSwitch';

import TablePagination from './TablePagination';
import { userProfileDummyData } from './userProfileDummyData';

function TableCellTypography({ children }) {
  return <Typography variant="subtitle2">{children}</Typography>;
}

TableCellTypography.propTypes = {
  children: PropTypes.node,
};

TableCellTypography.defaultProps = {
  children: <></>,
};

function TableHeaderTypography({ children }) {
  return (
    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
      {children}
    </Typography>
  );
}

TableHeaderTypography.propTypes = {
  children: PropTypes.node,
};

TableHeaderTypography.defaultProps = {
  children: <></>,
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      data-testid={index}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

TabPanel.defaultProps = {
  children: <></>,
  value: 0,
  index: 0,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

export const ManageDealers = () => {
  const navigate = useNavigate();
  const [searchOption, setSearchOption] = useState('');

  const [tabVal, setTabValue] = useState(0);
  const rowsPerPage = 10;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [page, setPage] = useState(1);

  // Change page
  const paginate = (_, pageNumber) => {
    setPage(pageNumber);
  };

  const indexOfLastPost = page * rowsPerPage;
  const indexOfFirstPost = indexOfLastPost - rowsPerPage;
  const calcUserList = (userArr) => {
    return userArr.slice(indexOfFirstPost, indexOfLastPost);
  };

  const handleSeachOption = (e) => {
    setSearchOption(e.target.value);
  };

  function CustomizedMenus() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      console.log(event.target.value);
      console.log(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const newApplication = () => {
      navigate('/register-user');
      handleClose();
    };
    const newUserRequest = () => {
      navigate('/new-user-request');
      handleClose();
    };

    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          color="secondary"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
          data-testid="manage-btn"
        >
          Manage
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            sx={{ height: '40px' }}
            onClick={newUserRequest}
            data-testid="new-user-request"
            disableRipple
          >
            New Dealer Enrollment
          </MenuItem>
          <MenuItem
            onClick={newApplication}
            sx={{ height: '40px' }}
            data-testid="new-application"
            disableRipple
          >
            Add User
          </MenuItem>
        </StyledMenu>
      </div>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: '98%' }}>
          <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
            <Box display="flex" flexDirection="column" className={classes.dummyHome} gap={2}>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h3">Manage Dealers</Typography>
                <CustomizedMenus />
              </Box>
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabVal} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Dealer Profiles" {...a11yProps(0)} />
                    <Tab label="User Profiles" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <TabPanel value={tabVal} index={0}>
                  Dealer Profiles
                </TabPanel>
                <TabPanel value={tabVal} index={1}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    className={classes.childBorderLine}
                    mt={1}
                    sx={{
                      width: '100%',
                      border: '1px solid rgb(224 224 224)',
                      borderRadius: '2px',
                    }}
                  >
                    <Box sx={{ padding: '15px' }}>
                      <TableCellTypography>
                        {`Showing ${indexOfFirstPost + 1} to ${indexOfLastPost} of ${
                          userProfileDummyData.length
                        } entries`}
                      </TableCellTypography>
                    </Box>
                    <Box
                      sx={{ marginLeft: '10px', padding: '10px' }}
                      display={'flex'}
                      flexDirection={'row'}
                      data-testid="user-table"
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-around'}
                      >
                        <FilterListIcon />
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-around'}
                        sx={{ marginLeft: '10px' }}
                      >
                        <Typography>Active</Typography>
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-around'}
                        sx={{ marginLeft: '10px' }}
                      >
                        <CustomSwitch />
                      </Box>
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-around'}
                        sx={{ marginLeft: '10px' }}
                      >
                        <Select
                          id={'select'}
                          defaultValue={''}
                          inputProps={{ 'data-testid': 'searchOption' }}
                          // {...field}
                          // value={defaultValue}
                          displayEmpty={true}
                          sx={{ width: '200px', marginLeft: '10px', height: '30px' }}
                          onChange={handleSeachOption}
                        >
                          <MenuItem value="">Select Option</MenuItem>

                          {[
                            { name: 'User ID', value: 'userId' },
                            { name: 'First Name', value: 'firstName' },
                            { name: 'Last Name', value: 'lastName' },
                            { name: 'Team', value: 'team' },
                          ].map((opt) => {
                            const { name, value } = opt;
                            return (
                              <MenuItem key={value} value={value ? value : name}>
                                {name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>
                      {searchOption && (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'space-around'}
                          sx={{ marginLeft: '10px' }}
                        >
                          <div className="search">
                            <input placeholder="Search term" className={manageClasses.inputStyle} />
                          </div>
                        </Box>
                      )}
                      {searchOption && (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'space-around'}
                          sx={{ marginLeft: '10px' }}
                        >
                          <Button
                            sx={{ width: '60px', height: '30px' }}
                            color="secondary"
                            variant="contained"
                            size="small"
                          >
                            Search
                          </Button>
                        </Box>
                      )}
                      {searchOption && (
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'space-around'}
                          sx={{ marginLeft: '10px' }}
                        >
                          <Typography variant="subtitle2"> ✖ Clear</Typography>
                        </Box>
                      )}
                    </Box>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        {/* <TableHead> */}
                        <TableRow sx={{ backgroundColor: '#F2F2F2' }}>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            <TableHeaderTypography>User ID</TableHeaderTypography>
                          </TableCell>
                          {/* TableHeaderTypography */}
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            <TableHeaderTypography>First Name</TableHeaderTypography>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            <TableHeaderTypography>Last Name</TableHeaderTypography>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            <TableHeaderTypography>Team</TableHeaderTypography>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            <TableHeaderTypography>User Setup Date</TableHeaderTypography>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            <TableHeaderTypography>User Type</TableHeaderTypography>
                          </TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>
                            <TableHeaderTypography>Active</TableHeaderTypography>
                          </TableCell>
                        </TableRow>
                        {/* </TableHead> */}
                        <TableBody>
                          {calcUserList(userProfileDummyData).map((row) => (
                            <TableRow
                              key={row.userId}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                <TableCellTypography>
                                  <Link component="button" variant="subtitle2" color="secondary">
                                    {row.userId}
                                  </Link>
                                </TableCellTypography>
                              </TableCell>
                              <TableCell>
                                <TableCellTypography>{row.firstName}</TableCellTypography>
                              </TableCell>
                              <TableCell>
                                <TableCellTypography>{row.lastName}</TableCellTypography>
                              </TableCell>
                              <TableCell>
                                <TableCellTypography>{row.team}</TableCellTypography>
                              </TableCell>
                              <TableCell>
                                <TableCellTypography>{row.userSetupDate}</TableCellTypography>
                              </TableCell>
                              <TableCell>
                                <TableCellTypography>{row.userType}</TableCellTypography>
                              </TableCell>
                              <TableCell>
                                <TableCellTypography>
                                  {row.isActive ? 'Yes' : 'No'}
                                </TableCellTypography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box sx={{ margin: '15px' }}>
                    <TablePagination
                      postsPerPage={10}
                      totalPosts={userProfileDummyData.length}
                      paginate={paginate}
                    />
                  </Box>
                </TabPanel>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};
