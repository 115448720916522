import * as yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
maxDate.setHours(23, 59, 59, 999);

export const paymentCalculatorSchema = yup
  .object()
  .shape({
    programVal: yup.string().required('Please select one of the options to proceed'),
    newOrUsed: yup.string().required('Please select one of the options to proceed'),
    term: yup.string().required('Please select one of the options to proceed'),
    promotion: yup.string().required('Please select one of the options to proceed'),
    vehicleClass: yup.string().required('Please select one of the options to proceed'),
    rate: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .required('Please enter a rate value')
      .test(
        'is-in-range',
        'Please enter a value between 0 and 100',
        (value) => parseFloat(value) > 0 && parseFloat(value) < 100
      ),

    // .test(
    //   'is-valid',
    //   'Please enter a valid numeric value with up to two decimal places',
    //   (value) => /^[0-9]+(\.[0-9]{1,2})?$/.test(value || '')
    // ),

    balloonPay: yup.string().when('programVal', {
      is: 'Oriental A+',
      then: yup
        .string()
        .nullable()
        .transform((value) => {
          // Remove the % sign and any non-numeric characters
          return value ? value.replace(/[^0-9.]/g, '') : null;
        })
        .required('Please enter a balloon payment value')
        .test(
          'is-in-range',
          'Please enter a balloon payment value greater than 0',
          (value) => parseFloat(value) > 0
        ),
    }),
    contractDate: yup
      .date()
      .transform((value) => (value && !isNaN(value.getTime()) ? new Date(value) : null))
      .nullable()
      .required('Please enter a valid contract date')

      .min(today, 'Contract date cannot be in the past')
      .max(maxDate, 'Contract date cannot be beyond one month into future'),

    salePrice: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .required('Please enter a Sale price value')
      .test(
        'is-in-range',
        'Maximum value that can be entered is $1,000,000',
        (value) => parseFloat(value) <= 1000000
      )
      .test('is-in-range', 'SalePrice should be greater than 0', (value) => parseFloat(value) > 0),
    cashDown: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'CashDown should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    rebate: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'Rebate should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    tradeDept: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'TradeDebt should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    tradeAllowance: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'TradeAllowance should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    netTrade: yup.string(),
    carCare: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'CarCare should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    other: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'Other should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    serviceContract: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'Service contract should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    extendedWarranty: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'Extended warranty should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    paintProtection: yup
      .string()
      .nullable()
      .transform((value) => {
        // Remove the % sign and any non-numeric characters
        return value ? value.replace(/[^0-9.]/g, '') : null;
      })
      .test('is-in-range', 'Maximum value that can be entered is $1,000,000', (value) =>
        value ? parseFloat(value) <= 1000000 : true
      )
      .test('is-in-range', 'Paint protection should be greater than 0', (value) =>
        value ? parseFloat(value) > 0 : true
      ),
    year: yup
      .string()
      .matches(/^[0-9]*$/g, '* Invalid format')
      .max(4, '* Invalid format')
      .test('validate year', 'year cannot be in future', (val) => {
        if (val) {
          const currentYear = new Date().getFullYear();
          console.log('curernt', currentYear);
          console.log('val', val);

          return parseInt(val) <= currentYear;
        }
        return true;
      })
      .test('is all 0 ', '* Invalid year entered', (val) => {
        return val !== '0000';
      }),
    make: yup
      .string()
      .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/g, '* Invalid format')
      .max(20, 'only 20 characters allowed'),
    model: yup
      .string()
      .matches(/^[a-zA-Z0-9áéíóúñü¿¡\s]*$/g, '* Invalid format')
      .max(20, 'only 20 characters allowed'),
    balance: yup.string(),
    creditLifeType: yup.string(),
    creditLife: yup.string(),
    creditLifeAmount: yup.string().when('creditLife', {
      is: 'yes',
      then: yup.string().required('* Mandatory Field'),
    }),
    criticalIllness: yup.string(),
    hospitalization: yup.string(),
    originator: yup.string(),
    gapFee: yup.string(),
    vehicleInsuranceType: yup.string(),
    vehicleInsuranceAmount: yup
      .string()

      .when('vehicleInsuranceType', {
        is: (val) => val?.length > 0,
        then: yup
          .string()
          .required('* Mandatory Field')
          .nullable()
          .transform((value) => {
            // Remove the % sign and any non-numeric characters
            return value ? value.replace(/[^0-9.]/g, '') : null;
          }),
      }),

    registrationfee: yup.string(),

    monthDeferred: yup.string(),
  })
  .required();
