import { Box, FormHelperText, InputLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import styles from 'styles/globalStyle.module.scss';

export const CustomAutoComplete = ({
  name,
  control,
  id,
  dataTestIdAutocomplete,
  required,
  inputLabelAriaLabel,
  label,
  handleChange,
  handleInputChange,
  options,
  dataTestIdForLabel,
  error,
  errorText,
  flexDirection,
  maxLength,
  regex,
}) => {
  const [autoOptions, setAutoOptions] = useState([]);

  useEffect(() => {
    setAutoOptions(options);
  }, [options]);

  const handleOnKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!regex?.test(keyValue)) {
      e.preventDefault();
    }
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <>
              <Box
                display="flex"
                alignItems="center"
                gap={0.625}
                my={0.5}
                flexDirection={flexDirection}
              >
                <InputLabel
                  data-testid={dataTestIdForLabel || null}
                  htmlFor={id || null}
                  aria-label={inputLabelAriaLabel || null}
                  // className={`${classes.label_title} ${InputLabelClassName || ''}`}
                >
                  {required && <span style={{ color: 'red' }}>*&nbsp;</span>} {label}
                </InputLabel>
                <Autocomplete
                  id={id || null}
                  // data-testid={dataTestIdAutocomplete || null}
                  options={autoOptions}
                  freeSolo
                  {...field}
                  sx={{ width: '100%' }}
                  classes={{
                    root: {
                      padding: '2px 4px 7.5px 6px',
                    },
                    input: {
                      padding: '2px 4px 7.5px 6px',
                    },
                  }}
                  renderInput={(params) => {
                    const updatedParams = params;
                    updatedParams['data-testid'] = dataTestIdAutocomplete || name;
                    updatedParams.inputProps['maxLength'] = maxLength;
                    return (
                      <Box display="flex" flexDirection="column">
                        <TextField
                          {...updatedParams}
                          {...field}
                          onKeyPress={regex && handleOnKeyPress}
                        />
                        {error && (
                          <FormHelperText gap={2} className={styles.customSelectError}>
                            {' '}
                            {errorText}
                          </FormHelperText>
                        )}
                      </Box>
                    );
                  }}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                />
              </Box>
            </>
          );
        }}
      />
    </>
  );
};

CustomAutoComplete.propTypes = {
  name: PropTypes.string,
  control: PropTypes.instanceOf(Object),
  required: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  filterOptions: PropTypes.func,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus: PropTypes.bool,
  error: PropTypes.string,
  errorText: PropTypes.string,
  InputClassName: PropTypes.string,
  id: PropTypes.string,
  inputLabelAriaLabel: PropTypes.string,
  InputLabelClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  dataTestIdAutocomplete: PropTypes.string,
  dataTestIdForInput: PropTypes.string,
  dataTestIdForLabel: PropTypes.string,
  flexDirection: PropTypes.string,
  handleInputChange: PropTypes.func,
  regex: PropTypes.instanceOf(RegExp),
};
