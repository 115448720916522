import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { getAccessToken } from 'Services/commonComponentsApi';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import { payCalcApis } from 'pages/CreateApplication/InformationRequest/moduleUtilities/dropdownApis';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { paymentCalculatorSchema } from 'schemaValidations/paymentCalculatorSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomCollapse from 'components/CustomCollapse';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';
import Loader from 'components/Loader/Loader';

import printIcon from '../../assets/printIcon.svg';
import classes from '../../styles/globalStyle.module.scss';
import constants from '../../utils/constants';
import { currencyToNumber, removeNullValues } from '../../utils/utility';
import BasicVehicleDetail from './BasicVehicleDetail';
import FinancedAmount from './FinancedAmount';
import Insurance from './Insurance';
import ResultPage from './ResultPage';

const { fees, deferredPayment } = constants;

const PaymentCalculator = () => {
  const ApiClient = new ApiClass();
  const {
    monthsDeferredList,
    setMonthsDeferredList,
    newUsedList,
    setNewUsedList,
    commonToken,
    setCommonToken,
    programList,
    setProgramList,
    makeList,
    setMakeList,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [calculateData, setCalculateData] = useState({});

  const [basicVehicleDetailData, setBasicVehicleDetailData] = useState({
    promotions: [],
  });

  const location = useLocation();

  const {
    handleSubmit,
    trigger,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      programVal: '',
      newOrUsed: '',
      term: '',
      balloonPay: '',
      promotion: '',
      vehicleClass: '',
      rate: '',
      contractDate: new Date(),

      salePrice: '',
      cashDown: '',
      rebate: '',
      tradeDept: '',
      tradeAllowance: '',
      netTrade: '',
      carCare: '',
      other: '',
      serviceContract: '',
      extendedWarranty: '',
      paintProtection: '',
      year: '',
      make: '',
      model: '',
      balance: '',

      creditLife: 'no',
      creditLifeAmount: '',
      creditLifeType: '',
      joint: '',
      criticalIllness: '',
      hospitalization: '',
      gapFee: '',
      vehicleInsuranceType: '',
      vehicleInsuranceAmount: '',

      registrationfee: '',

      monthDeferred: '1',
      financed: '',
      amountFinanced: '',
    },
    resolver: yupResolver(paymentCalculatorSchema),
  });
  console.log('state', location?.state);

  useEffect(() => {
    if (location?.state?.flow === 'financialTerms' && location?.state?.data) {
      console.log('into use effect');
      const {
        programVal,
        newOrUsed,
        term,
        promotion,
        vehicleClass,
        rate,
        contractDate,
        salePrice,
        cashDown,
        rebate,
        tradeDept,
        tradeAllowance,
        netTrade,
        carCare,
        other,
        serviceContract,
        extendedWarranty,
        paintProtection,
        year,
        make,
        model,
        balance,
        creditLife,
        creditLifeAmount,
        creditLifeType,
        joint,
        criticalIllness,
        hospitalization,
        gapFee,
        vehicleInsuranceType,
        vehicleInsuranceAmount,
        registrationfee,
        monthDeferred,
        financed,
        amountFinanced,
      } = location?.state?.data?.formData;
      console.log('flow', location?.state?.flow);
      console.log('data', location?.state?.data);
      setValue('programVal', removeNullValues(programVal));
      setValue('newOrUsed', removeNullValues(newOrUsed));
      setValue('term', parseInt(removeNullValues(term)));
      setValue('balloonPay', removeNullValues(location?.state?.data?.baloonPayment));
      setValue('promotion', removeNullValues(promotion));
      setValue('vehicleClass', removeNullValues(vehicleClass));
      setValue('rate', removeNullValues(rate));
      setValue('contractDate', removeNullValues(contractDate));
      setValue('salePrice', removeNullValues(salePrice));
      setValue('cashDown', removeNullValues(cashDown));
      setValue('rebate', removeNullValues(rebate));
      setValue('tradeDept', removeNullValues(tradeDept));
      setValue('tradeAllowance', removeNullValues(tradeAllowance));
      setValue('netTrade', removeNullValues(netTrade));
      setValue('carCare', removeNullValues(carCare));
      setValue('other', removeNullValues(other));
      setValue('serviceContract', removeNullValues(serviceContract));
      setValue('extendedWarranty', removeNullValues(extendedWarranty));
      setValue('paintProtection', removeNullValues(paintProtection));
      setValue('year', removeNullValues(year));
      setValue('make', removeNullValues(make));
      setValue('model', removeNullValues(model));
      setValue('balance', removeNullValues(balance));
      setValue('creditLife', removeNullValues(creditLife));
      setValue('creditLifeAmount', removeNullValues(creditLifeAmount));
      setValue('creditLifeType', removeNullValues(creditLifeType));
      setValue('joint', removeNullValues(joint));
      setValue('criticalIllness', removeNullValues(criticalIllness));
      setValue('hospitalization', removeNullValues(hospitalization));
      setValue('gapFee', removeNullValues(gapFee));
      setValue('vehicleInsuranceType', removeNullValues(vehicleInsuranceType));
      setValue('vehicleInsuranceAmount', removeNullValues(vehicleInsuranceAmount));
      setValue('registrationfee', removeNullValues(registrationfee));
      setValue('monthDeferred', removeNullValues(monthDeferred));
      setValue('financed', removeNullValues(financed));
      setValue('amountFinanced', removeNullValues(amountFinanced));
    }
  }, []);

  useEffect(async () => {
    console.log('commonToken', commonToken);
    if (!commonToken) {
      const token = await getAccessToken();

      setCommonToken(token);
    }
    await payCalcApis({
      monthsDeferredList,
      setMonthsDeferredList,
      newUsedList,
      setNewUsedList,
      programList,
      setProgramList,
      makeList,
      setMakeList,
      setLoader,
    });
  }, []);
  useEffect(async () => {
    try {
      setLoader(true);
      let onLoadData = await ApiClient.get(UrlHelpers.payCalcOnLoad, {
        headers: { 'Content-Type': 'application/json' },
      });
      if (onLoadData) {
        setBasicVehicleDetailData({ promotions: onLoadData });
      }
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  }, []);

  const handleCalculation = async (data) => {
    console.log('data', data);
    const {
      term,
      newOrUsed,
      programVal,
      vehicleClass,
      contractDate,
      rate,
      balloonPay,
      salePrice,
      cashDown,
      rebate,
      carCare,
      other,
      serviceContract,
      tradeDept,
      tradeAllowance,
      extendedWarranty,
      paintProtection,
      creditLife,
      creditLifeAmount,
      vehicleInsuranceAmount = currencyToNumber(vehicleInsuranceAmount),
      vehicleInsuranceType,
      gapFee,
      monthDeferred,
    } = data;
    setFormData({ ...data, vehicleInsuranceAmount: currencyToNumber(vehicleInsuranceAmount) });
    try {
      setLoader(true);
      let calculate = await ApiClient.post(
        UrlHelpers.payCalcCalculate,
        {
          term: +term,
          newUsed: newOrUsed,
          program: programVal,
          promotion: {
            name: basicVehicleDetailData.promotions[0]?.name,
            value: basicVehicleDetailData.promotions[0]?.value,
            rate: currencyToNumber(rate),
          },
          baloonPayment: currencyToNumber(balloonPay),
          vehicleClass: vehicleClass,
          comtractDate: moment(contractDate).format('DD/MM/yyyy'),
          monthDeferred: monthDeferred,
          rate: rate && currencyToNumber(rate),
          itemizationOfAmount: {
            salePrice: currencyToNumber(salePrice),
            cashDown: currencyToNumber(cashDown),
            rebate: currencyToNumber(rebate),
            carCare: currencyToNumber(carCare),
            other: currencyToNumber(other),
            serviceContact: currencyToNumber(serviceContract),
            tradeInDebt: currencyToNumber(tradeDept),
            tradeInAllowance: currencyToNumber(tradeAllowance),
            extendedWarranty: currencyToNumber(extendedWarranty),
            paintProtection: currencyToNumber(paintProtection),
          },
          insurance: {
            creditLife: creditLife,
            creditLifeAmount: currencyToNumber(creditLifeAmount),
            vehicleInsurance: vehicleInsuranceType,
            vehicleInsuranceAmount: currencyToNumber(vehicleInsuranceAmount),
            gapFee: currencyToNumber(gapFee),
          },
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      if (calculate) {
        setCalculateData(calculate);
        setValue('registrationfee', calculate?.registrationFees);
      }
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  };
  console.log('formData', formData);
  return (
    <Paper className={classes.payCalcPaper} sx={{ padding: '10px' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: '24px',
            padding: '0px 20px',
          }}
          my={2}
        >
          Payment Calculator
        </Typography>
        <Box display="flex" alignItems="center">
          <img src={printIcon} />
          &nbsp;
          <Typography
            color="secondary"
            sx={{ textDecoration: 'underline', fontSize: '14px' }}
            mr={4}
          >
            Print
          </Typography>
          {!location?.state?.flow && (
            <Button variant="contained" color="secondary">
              Create Application
            </Button>
          )}
        </Box>
      </Box>

      <Grid container className={classes.payCalc} justifyContent="space-between">
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <Grid container justifyContent="center">
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <BasicVehicleDetail
                setValue={setValue}
                control={control}
                errors={errors}
                optionsData={basicVehicleDetailData}
                trigger={trigger}
                flow={location?.state}
              />
              <FinancedAmount
                setValue={setValue}
                control={control}
                errors={errors}
                makeList={makeList}
              />
              <Insurance control={control} errors={errors} watch={watch} />

              {/* Registration Fees */}
              <div>
                <CustomCollapse heading={fees} open={false}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12}>
                      <CustomNumberFormat
                        id="registrationfee"
                        dataTestId="registrationfee"
                        placeholder="Enter value"
                        name="registrationfee"
                        label="Registration Fees"
                        control={control}
                        error={!!errors?.registrationfee}
                        errorText={errors?.registrationfee?.message}
                        thousandSeparator={true}
                        prefix={'$ '}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </CustomCollapse>
              </div>
              {/* Deferred payment */}
              <div>
                <CustomCollapse heading={deferredPayment} open={false}>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <CustomSelectBox
                        label="Months Deferred"
                        control={control}
                        name="monthDeferred"
                        flexDirection="column"
                        errors={!!errors.monthDeferred}
                        errorText={errors?.monthDeferred?.message}
                        options={monthsDeferredList}
                        defaultValue="1"
                      />
                    </Grid>
                  </Grid>
                </CustomCollapse>
              </div>

              <Loader open={loader} />
              {/* Buttons */}
              <Box display="flex" justifyContent="end" gap={3} py={5} px={2} mb={3}>
                <Button
                  onClick={handleSubmit(handleCalculation)}
                  data-testid="calculate"
                  variant="contained"
                  color="secondary"
                >
                  Compute
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <ResultPage
            state={{
              data: calculateData,
              baloonPayment: getValues('balloonPay'),
              flowData: location?.state,
              formData: formData,
            }}
            watch={watch}
            getValues={getValues}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PaymentCalculator;
