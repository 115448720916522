import ApiClass from 'Api/ApiClient';
import { UrlHelpers } from 'urlHelpers/urlHelper';

export const step1DropDownApis = async ({
  cityList,
  setCityList,
  residentStatusList,
  setResidentStatusList,
  maritalStatusList,
  setMaritalStatusList,
  relationshipList,
  setRelationShipList,
  utilityBillList,
  setUtilityBillList,
}) => {
  const ApiClient = new ApiClass();
  if (!cityList?.length) {
    try {
      let cityListt = await ApiClient.get(UrlHelpers.cityLookup);

      cityListt = JSON.parse(cityListt?.['country-state-city'])[0]?.states[0]?.cities;
      console.log('cityList', cityListt);

      cityListt = cityListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setCityList(cityListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!residentStatusList?.length) {
    try {
      let residentStatusListt = await ApiClient.get(UrlHelpers.residentialStatusLookup);

      residentStatusListt = JSON.parse(residentStatusListt?.['residential-status']);

      residentStatusListt = residentStatusListt.map((x) => {
        const { displayName, value } = x;
        return { label: displayName, value };
      });
      setResidentStatusList(residentStatusListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!maritalStatusList?.length) {
    try {
      let maritalStatusListt = await ApiClient.get(UrlHelpers.maritalStatusLookup);

      maritalStatusListt = JSON.parse(maritalStatusListt?.['marital-status']);

      maritalStatusListt = maritalStatusListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setMaritalStatusList(maritalStatusListt);
    } catch (e) {
      console.log('e', e);
    }
  }

  if (!relationshipList?.length) {
    try {
      let relationshipListt = await ApiClient.get(UrlHelpers.relationshipLookup);

      relationshipListt = JSON.parse(relationshipListt?.['relationship']);

      relationshipListt = relationshipListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setRelationShipList(relationshipListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!utilityBillList?.length) {
    try {
      let utilityBillListt = await ApiClient.get(UrlHelpers.utilityBillLookup);

      utilityBillListt = JSON.parse(utilityBillListt?.['utility-bill']);

      utilityBillListt = utilityBillListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setUtilityBillList(utilityBillListt);
    } catch (e) {
      console.log('e', e);
    }
  }
};

export const step2DropDownApis = async ({
  occupationList,
  setOccupationList,
  empStatusList,
  setEmpStatusList,
  cityList,
  setCityList,
  industryList,
  setIndustryList,
}) => {
  const ApiClient = new ApiClass();
  if (!cityList?.length) {
    try {
      let cityListt = await ApiClient.get(UrlHelpers.cityLookup);

      cityListt = JSON.parse(cityListt?.['country-state-city'])[0]?.states[0]?.cities;

      cityListt = cityListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setCityList(cityListt);
    } catch (e) {
      console.log('e', e);
    }
  }
  if (!empStatusList?.length) {
    try {
      let employmentStatusListt = await ApiClient.get(UrlHelpers.employmentStatusLookup);

      employmentStatusListt = JSON.parse(employmentStatusListt?.['employment-status']);

      employmentStatusListt = employmentStatusListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setEmpStatusList(employmentStatusListt);
    } catch (e) {
      console.log('e', e);
    }
  }

  if (!occupationList?.length) {
    try {
      let occupationListt = await ApiClient.get(UrlHelpers.occupationLookup);

      occupationListt = JSON.parse(occupationListt?.['occupation']);

      occupationListt = occupationListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setOccupationList(occupationListt);
    } catch (e) {
      console.log('e', e);
    }
  }

  if (!industryList?.length) {
    try {
      let industryListt = await ApiClient.get(UrlHelpers.industryLookup);

      industryListt = JSON.parse(industryListt?.['industry']);

      industryListt = industryListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setIndustryList(industryListt);
    } catch (e) {
      console.log('e', e);
    }
  }
};
export const popUpApis = async ({
  loanPurposeList,
  setLoanPurposeList,
  // programList,
  setProgramList,
  setInitialApiLoader,
}) => {
  const ApiClient = new ApiClass();

  if (!loanPurposeList?.length) {
    try {
      setInitialApiLoader(true);
      let loanPurposeListt = await ApiClient.get(UrlHelpers.loanPurpose);

      loanPurposeListt = JSON.parse(loanPurposeListt?.['loan-purpose']);

      loanPurposeListt = loanPurposeListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setLoanPurposeList(loanPurposeListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setInitialApiLoader(false);
    }
  }

  try {
    setInitialApiLoader(true);
    let programListt = await ApiClient.get(UrlHelpers.program);

    programListt = JSON.parse(programListt?.['program']);

    programListt = programListt.map((x) => {
      const { displayName, value } = x;
      return { label: displayName, value };
    });
    setProgramList(programListt);
  } catch (e) {
    console.log('e', e);
  } finally {
    setInitialApiLoader(false);
  }
};
export const payCalcApis = async ({
  newUsedList,
  setNewUsedList,
  monthsDeferredList,
  setMonthsDeferredList,
  setLoader,
  // programList,
  setProgramList,
  makeList,
  setMakeList,
}) => {
  const ApiClient = new ApiClass();

  if (!makeList.length) {
    try {
      setLoader(true);
      let makeListt = await ApiClient.get(UrlHelpers.make);

      makeListt = JSON.parse(makeListt?.['make']);
      console.log('make', makeListt);
      makeListt = makeListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setMakeList(makeListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }

  try {
    setLoader(true);
    let programListt = await ApiClient.get(UrlHelpers.program);

    programListt = JSON.parse(programListt?.['program']);

    programListt = programListt.map((x) => {
      const { displayName, value } = x;
      return { name: displayName, value };
    });
    setProgramList(programListt);
  } catch (e) {
    console.log('e', e);
  } finally {
    setLoader(false);
  }
  if (!newUsedList.length) {
    try {
      setLoader(true);
      let newUsedListt = await ApiClient.get(UrlHelpers.newUsed);

      newUsedListt = JSON.parse(newUsedListt?.['new-used']);

      newUsedListt = newUsedListt.map((x) => {
        const { displayName, value } = x;
        return { label: displayName, value };
      });
      setNewUsedList(newUsedListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }
  if (!monthsDeferredList?.length) {
    setLoader(true);
    try {
      let monthsDeferredListt = await ApiClient.get(UrlHelpers.monthsDeferred);

      monthsDeferredListt = JSON.parse(monthsDeferredListt?.['months-deferred']);

      monthsDeferredListt = monthsDeferredListt.map((x) => {
        const { displayName, value } = x;
        return { name: displayName, value };
      });
      setMonthsDeferredList(monthsDeferredListt);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }
};
