const userProfileDummyData = [
  {
    userId: 21317,
    firstName: 'Robinson',
    lastName: 'Orr',
    team: 'Data Entry',
    userSetupDate: '08/11/2015',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 19747,
    firstName: 'Larson',
    lastName: 'Harrison',
    team: 'Business Development',
    userSetupDate: '04/10/2023',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 14262,
    firstName: 'Krista',
    lastName: 'Johns',
    team: 'Supervisor',
    userSetupDate: '04/04/2016',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 15153,
    firstName: 'Millicent',
    lastName: 'Glenn',
    team: 'Data Entry',
    userSetupDate: '02/09/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 21288,
    firstName: 'Dorothea',
    lastName: 'Mosley',
    team: 'Data Entry',
    userSetupDate: '07/28/2017',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 16046,
    firstName: 'Workman',
    lastName: 'Wagner',
    team: 'Supervisor',
    userSetupDate: '07/22/2018',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 19237,
    firstName: 'Maricela',
    lastName: 'Perry',
    team: 'Business Development',
    userSetupDate: '03/27/2018',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 17074,
    firstName: 'John',
    lastName: 'Waters',
    team: 'Data Entry',
    userSetupDate: '12/06/2022',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 16745,
    firstName: 'Cook',
    lastName: 'Mccall',
    team: 'Business Development',
    userSetupDate: '01/08/2019',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 16497,
    firstName: 'Kay',
    lastName: 'Morales',
    team: 'Data Entry',
    userSetupDate: '08/28/2018',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 12723,
    firstName: 'Gregory',
    lastName: 'Palmer',
    team: 'Data Entry',
    userSetupDate: '08/10/2017',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 14986,
    firstName: 'Christine',
    lastName: 'Rollins',
    team: 'Data Entry',
    userSetupDate: '05/18/2015',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 14791,
    firstName: 'Moreno',
    lastName: 'Dillon',
    team: 'Dealer',
    userSetupDate: '04/30/2019',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 18611,
    firstName: 'Patrick',
    lastName: 'Frye',
    team: 'Supervisor',
    userSetupDate: '06/07/2022',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 17512,
    firstName: 'Whitaker',
    lastName: 'Vinson',
    team: 'Supervisor',
    userSetupDate: '09/22/2021',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 13887,
    firstName: 'Delaney',
    lastName: 'Weiss',
    team: 'Dealer',
    userSetupDate: '11/07/2016',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 13804,
    firstName: 'Johnston',
    lastName: 'Adkins',
    team: 'Dealer',
    userSetupDate: '09/23/2014',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 20300,
    firstName: 'Roberts',
    lastName: 'Mccray',
    team: 'Supervisor',
    userSetupDate: '05/06/2023',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 12595,
    firstName: 'Angel',
    lastName: 'Bolton',
    team: 'Dealer',
    userSetupDate: '02/05/2020',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 20486,
    firstName: 'Duran',
    lastName: 'Owens',
    team: 'Data Entry',
    userSetupDate: '11/29/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 13093,
    firstName: 'Ramirez',
    lastName: 'Price',
    team: 'Business Development',
    userSetupDate: '03/17/2023',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 18273,
    firstName: 'Haney',
    lastName: 'Harrell',
    team: 'Business Development',
    userSetupDate: '04/17/2023',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 21319,
    firstName: 'Sullivan',
    lastName: 'Justice',
    team: 'Data Entry',
    userSetupDate: '01/09/2022',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 15000,
    firstName: 'Minnie',
    lastName: 'Travis',
    team: 'Business Development',
    userSetupDate: '10/08/2016',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 11113,
    firstName: 'Keller',
    lastName: 'Willis',
    team: 'Dealer',
    userSetupDate: '06/22/2019',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 15575,
    firstName: 'Clemons',
    lastName: 'Beach',
    team: 'Supervisor',
    userSetupDate: '02/25/2018',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 18883,
    firstName: 'Bray',
    lastName: 'Adams',
    team: 'Business Development',
    userSetupDate: '01/20/2020',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 18070,
    firstName: 'Shepard',
    lastName: 'Munoz',
    team: 'Business Development',
    userSetupDate: '06/27/2019',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 19233,
    firstName: 'Underwood',
    lastName: 'Rasmussen',
    team: 'Supervisor',
    userSetupDate: '02/25/2014',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 16249,
    firstName: 'Roman',
    lastName: 'Mcdaniel',
    team: 'Dealer',
    userSetupDate: '01/12/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 15583,
    firstName: 'Gayle',
    lastName: 'Booker',
    team: 'Business Development',
    userSetupDate: '11/08/2016',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 13569,
    firstName: 'Holcomb',
    lastName: 'Holden',
    team: 'Supervisor',
    userSetupDate: '09/02/2018',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 19012,
    firstName: 'Kathryn',
    lastName: 'Jackson',
    team: 'Dealer',
    userSetupDate: '08/15/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 20347,
    firstName: 'Barbara',
    lastName: 'Cleveland',
    team: 'Business Development',
    userSetupDate: '05/03/2019',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 20886,
    firstName: 'Berta',
    lastName: 'Vargas',
    team: 'Data Entry',
    userSetupDate: '04/09/2017',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 18833,
    firstName: 'Tonya',
    lastName: 'Perez',
    team: 'Supervisor',
    userSetupDate: '01/23/2020',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 12116,
    firstName: 'Middleton',
    lastName: 'Bradford',
    team: 'Supervisor',
    userSetupDate: '06/04/2017',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 16532,
    firstName: 'Joy',
    lastName: 'Garza',
    team: 'Supervisor',
    userSetupDate: '06/09/2021',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 14691,
    firstName: 'Collins',
    lastName: 'Daniels',
    team: 'Dealer',
    userSetupDate: '05/20/2021',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 18763,
    firstName: 'Maynard',
    lastName: 'Mack',
    team: 'Supervisor',
    userSetupDate: '03/03/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 15662,
    firstName: 'Marquez',
    lastName: 'Brown',
    team: 'Supervisor',
    userSetupDate: '12/06/2017',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 20933,
    firstName: 'Ruthie',
    lastName: 'Dudley',
    team: 'Supervisor',
    userSetupDate: '07/01/2021',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 15856,
    firstName: 'Dejesus',
    lastName: 'Weeks',
    team: 'Business Development',
    userSetupDate: '05/16/2019',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 13959,
    firstName: 'Koch',
    lastName: 'Ross',
    team: 'Supervisor',
    userSetupDate: '06/03/2017',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 14229,
    firstName: 'Ava',
    lastName: 'May',
    team: 'Supervisor',
    userSetupDate: '02/04/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 15994,
    firstName: 'Alicia',
    lastName: 'Shepherd',
    team: 'Supervisor',
    userSetupDate: '05/27/2022',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 13168,
    firstName: 'Toni',
    lastName: 'Mendoza',
    team: 'Dealer',
    userSetupDate: '02/06/2015',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 14758,
    firstName: 'Young',
    lastName: 'Gonzales',
    team: 'Business Development',
    userSetupDate: '03/13/2020',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 12897,
    firstName: 'Jewell',
    lastName: 'Skinner',
    team: 'Dealer',
    userSetupDate: '01/16/2018',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 14869,
    firstName: 'Glenn',
    lastName: 'Clayton',
    team: 'Data Entry',
    userSetupDate: '09/28/2022',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 18250,
    firstName: 'Emerson',
    lastName: 'Acosta',
    team: 'Supervisor',
    userSetupDate: '02/13/2023',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 20306,
    firstName: 'Diaz',
    lastName: 'Lynn',
    team: 'Data Entry',
    userSetupDate: '10/19/2018',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 12938,
    firstName: 'Henrietta',
    lastName: 'Patrick',
    team: 'Business Development',
    userSetupDate: '11/08/2020',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 16476,
    firstName: 'Daniels',
    lastName: 'Kinney',
    team: 'Supervisor',
    userSetupDate: '06/09/2019',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 18007,
    firstName: 'Lorena',
    lastName: 'Beck',
    team: 'Data Entry',
    userSetupDate: '11/15/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 15379,
    firstName: 'Jeri',
    lastName: 'Shaw',
    team: 'Business Development',
    userSetupDate: '05/30/2021',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 19522,
    firstName: 'Shana',
    lastName: 'Fowler',
    team: 'Data Entry',
    userSetupDate: '08/23/2021',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 16203,
    firstName: 'Madeleine',
    lastName: 'Medina',
    team: 'Supervisor',
    userSetupDate: '01/22/2023',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 13626,
    firstName: 'Magdalena',
    lastName: 'Lang',
    team: 'Dealer',
    userSetupDate: '03/23/2015',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 21283,
    firstName: 'Elaine',
    lastName: 'Fuentes',
    team: 'Business Development',
    userSetupDate: '11/13/2015',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 13057,
    firstName: 'Bryan',
    lastName: 'Foreman',
    team: 'Business Development',
    userSetupDate: '10/13/2016',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 13811,
    firstName: 'Augusta',
    lastName: 'Sherman',
    team: 'Business Development',
    userSetupDate: '09/17/2018',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 21234,
    firstName: 'Higgins',
    lastName: 'Castillo',
    team: 'Data Entry',
    userSetupDate: '05/01/2018',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 15951,
    firstName: 'Norman',
    lastName: 'Charles',
    team: 'Dealer',
    userSetupDate: '12/06/2016',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 16603,
    firstName: 'Shauna',
    lastName: 'Ward',
    team: 'Supervisor',
    userSetupDate: '03/24/2021',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 18749,
    firstName: 'Donna',
    lastName: 'Landry',
    team: 'Data Entry',
    userSetupDate: '02/20/2019',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 20951,
    firstName: 'Salinas',
    lastName: 'Wright',
    team: 'Dealer',
    userSetupDate: '04/10/2022',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 18757,
    firstName: 'Leigh',
    lastName: 'Alford',
    team: 'Supervisor',
    userSetupDate: '09/23/2018',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 17254,
    firstName: 'Alyce',
    lastName: 'Moore',
    team: 'Dealer',
    userSetupDate: '09/08/2022',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 20075,
    firstName: 'Stuart',
    lastName: 'Thornton',
    team: 'Data Entry',
    userSetupDate: '11/14/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 15381,
    firstName: 'Doris',
    lastName: 'Wolf',
    team: 'Dealer',
    userSetupDate: '11/26/2021',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 11221,
    firstName: 'Benton',
    lastName: 'Finley',
    team: 'Data Entry',
    userSetupDate: '07/14/2021',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 19035,
    firstName: 'Hurley',
    lastName: 'Bray',
    team: 'Supervisor',
    userSetupDate: '08/26/2018',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 19380,
    firstName: 'Oneil',
    lastName: 'Bowen',
    team: 'Dealer',
    userSetupDate: '06/19/2015',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 18139,
    firstName: 'Merle',
    lastName: 'Welch',
    team: 'Data Entry',
    userSetupDate: '03/14/2016',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 21033,
    firstName: 'Alexis',
    lastName: 'Ramsey',
    team: 'Business Development',
    userSetupDate: '10/04/2022',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 11624,
    firstName: 'Darcy',
    lastName: 'Case',
    team: 'Dealer',
    userSetupDate: '07/23/2020',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 16745,
    firstName: 'Barr',
    lastName: 'Vaughn',
    team: 'Business Development',
    userSetupDate: '08/09/2021',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 16997,
    firstName: 'Lelia',
    lastName: 'Downs',
    team: 'Business Development',
    userSetupDate: '08/30/2017',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 12960,
    firstName: 'Lawanda',
    lastName: 'Sexton',
    team: 'Dealer',
    userSetupDate: '04/02/2020',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 15453,
    firstName: 'Candy',
    lastName: 'Jefferson',
    team: 'Dealer',
    userSetupDate: '08/14/2021',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 14112,
    firstName: 'Hansen',
    lastName: 'Walker',
    team: 'Data Entry',
    userSetupDate: '02/19/2016',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 15824,
    firstName: 'Osborne',
    lastName: 'Strong',
    team: 'Data Entry',
    userSetupDate: '12/26/2016',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 19781,
    firstName: 'Sallie',
    lastName: 'Kirkland',
    team: 'Business Development',
    userSetupDate: '10/17/2014',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 20225,
    firstName: 'Hogan',
    lastName: 'Cole',
    team: 'Supervisor',
    userSetupDate: '05/21/2022',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 21849,
    firstName: 'Lucia',
    lastName: 'Walton',
    team: 'Data Entry',
    userSetupDate: '07/29/2017',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 16067,
    firstName: 'Latasha',
    lastName: 'Dickerson',
    team: 'Data Entry',
    userSetupDate: '07/07/2014',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 14295,
    firstName: 'Clayton',
    lastName: 'Monroe',
    team: 'Dealer',
    userSetupDate: '02/19/2023',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 11551,
    firstName: 'Annie',
    lastName: 'Blackburn',
    team: 'Dealer',
    userSetupDate: '10/27/2022',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 13756,
    firstName: 'Ingram',
    lastName: 'Fisher',
    team: 'Supervisor',
    userSetupDate: '03/03/2023',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 22135,
    firstName: 'Kayla',
    lastName: 'Vega',
    team: 'Data Entry',
    userSetupDate: '12/20/2016',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 12131,
    firstName: 'Stacey',
    lastName: 'Stanton',
    team: 'Supervisor',
    userSetupDate: '09/22/2017',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 17170,
    firstName: 'Gomez',
    lastName: 'Robbins',
    team: 'Dealer',
    userSetupDate: '04/16/2017',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 19704,
    firstName: 'Tracy',
    lastName: 'Bell',
    team: 'Supervisor',
    userSetupDate: '04/20/2022',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 18932,
    firstName: 'Baldwin',
    lastName: 'Beard',
    team: 'Business Development',
    userSetupDate: '02/20/2015',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 12896,
    firstName: 'Ford',
    lastName: 'Carroll',
    team: 'Business Development',
    userSetupDate: '03/17/2014',
    userType: 'Dealer Portal',
    isActive: false,
  },
  {
    userId: 14605,
    firstName: 'Scott',
    lastName: 'Vazquez',
    team: 'Dealer',
    userSetupDate: '04/21/2022',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 16289,
    firstName: 'Lawson',
    lastName: 'Lott',
    team: 'Data Entry',
    userSetupDate: '10/02/2022',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 11338,
    firstName: 'Violet',
    lastName: 'Chen',
    team: 'Dealer',
    userSetupDate: '04/08/2023',
    userType: 'Dealer Portal',
    isActive: true,
  },
  {
    userId: 11154,
    firstName: 'Sargent',
    lastName: 'Gaines',
    team: 'Data Entry',
    userSetupDate: '02/21/2020',
    userType: 'Dealer Portal',
    isActive: false,
  },
];
export { userProfileDummyData };
