import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import CustomCollapse from 'components/CustomCollapse';

import FinancingDetails from '../FinancialTerms/FinancingDetails';
import PaymentCalculatorDetails from '../FinancialTerms/PaymentCalculatorDetails/PaymentCalculatorDetails';

const FinancialTermsSection = ({ financialTerms }) => {
  const monthlyPayData = {
    financedFees: financialTerms?.EstimatedMonthlyPayment?.FinancedFees,
    registrationFees: financialTerms?.EstimatedMonthlyPayment?.RegistrationFees,
    amountFinanced: financialTerms?.EstimatedMonthlyPayment?.AmountFinanced,
    balanceAmountFinance: financialTerms?.EstimatedMonthlyPayment?.BalanceAmountFinance,
    netTradeIn: financialTerms?.EstimatedMonthlyPayment?.NetTradeIn,
    financeCharge: financialTerms?.EstimatedMonthlyPayment?.FinanceCharge,
    baloonPayment: financialTerms?.EstimatedMonthlyPayment?.BaloonPayment,
    nonFinancedFees: financialTerms?.EstimatedMonthlyPayment?.NonFinancedFees,
    apr: financialTerms?.EstimatedMonthlyPayment?.APR,
    paymentSchedules: financialTerms?.EstimatedMonthlyPayment?.PaymentSchedule,
  };

  const calculatedData = {
    formData: {
      programVal: financialTerms?.PaymentCalculatorDetails?.Program,
      promotion: financialTerms?.PaymentCalculatorDetails?.Promotion,
      newOrUsed: financialTerms?.PaymentCalculatorDetails?.NewUsed,
      vehicleClass: financialTerms?.PaymentCalculatorDetails?.VehicleClass,
      term: financialTerms?.PaymentCalculatorDetails?.Term,
      rate: financialTerms?.PaymentCalculatorDetails?.Rate,
      contractDate: financialTerms?.PaymentCalculatorDetails?.ContractDate,
      salePrice: financialTerms?.PaymentCalculatorDetails?.SalePrice,
      tradeDept: financialTerms?.PaymentCalculatorDetails?.TradeInDebt,
      cashDown: financialTerms?.PaymentCalculatorDetails?.CashDown,
      tradeAllowance: financialTerms?.PaymentCalculatorDetails?.TradeInAllowance,
      rebate: financialTerms?.PaymentCalculatorDetails?.Rebate,
      carcare: financialTerms?.PaymentCalculatorDetails?.CarCare,
      extendedWarranty: financialTerms?.PaymentCalculatorDetails?.ExtendedWarranty,
      serviceContract: financialTerms?.PaymentCalculatorDetails?.ServiceContract,
      paintProtection: financialTerms?.PaymentCalculatorDetails?.PaintProtection,
      other: financialTerms?.PaymentCalculatorDetails?.Other,
      year: financialTerms?.PaymentCalculatorDetails?.Year,
      make: financialTerms?.PaymentCalculatorDetails?.Brand,
      model: financialTerms?.PaymentCalculatorDetails?.Model,
      creditLife: financialTerms?.PaymentCalculatorDetails?.CreditLife,
      creditLifeType: financialTerms?.PaymentCalculatorDetails?.CreditLifeType,
      vehicleInsuranceType: financialTerms?.PaymentCalculatorDetails?.VehicleInsuranceType,
      creditLifeAmount: financialTerms?.PaymentCalculatorDetails?.CreditLifeLifeInsuranceAmount,
      vehicleInsuranceAmount: financialTerms?.PaymentCalculatorDetails?.VehicleInsuranceAmount,
      criticalIllness: financialTerms?.PaymentCalculatorDetails?.CrititicalIllness,
      hospitalization: financialTerms?.PaymentCalculatorDetails?.Hospitalization,
      gapFee: financialTerms?.PaymentCalculatorDetails?.GapFee,
      monthDeferred: financialTerms?.PaymentCalculatorDetails?.MonthsDeferred,
    },
    calculatedData: {
      balance: financialTerms?.PaymentCalculatorDetails?.Balance,
      registrationFees: financialTerms?.PaymentCalculatorDetails?.RegistrationFees,
    },
  };

  console.log('financialTerms', financialTerms);
  const { setReviewFlag, setActiveStep, setReviewData } = useContext(MainApplicationContext);
  const onEdit = () => {
    setReviewData(financialTerms);
    setReviewFlag(true);
    setActiveStep(4);
  };
  return (
    <>
      <CustomCollapse heading="Financial Terms" open={true} editable={true} onEdit={onEdit}>
        <FinancingDetails monthlyPayData={monthlyPayData} calculatedData={calculatedData} />
        <PaymentCalculatorDetails calculatedData={calculatedData} />
      </CustomCollapse>
    </>
  );
};

export default FinancialTermsSection;
FinancialTermsSection.propTypes = {
  financialTerms: PropTypes.instanceOf(Object),
};
FinancialTermsSection.defaultProps = {
  financialTerms: {},
};
