/* eslint-disable no-unused-vars */
import { Box, FormHelperText, Grid, InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import globalClasses from 'styles/globalStyle.module.scss';

import CustomCollapse from 'components/CustomCollapse';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';
import CustomRadioButton from 'components/CustomRadioButton/CustomRadioButton';

const ResidentStatus = ({ control, errors, watch, residentStatusList, setValue, clearErrors }) => {
  const watchResidentStatus = watch('residentStatus');
  useEffect(() => {
    if (
      watchResidentStatus &&
      watchResidentStatus !== 'Rent' &&
      watchResidentStatus !== 'Homeowner'
    ) {
      console.log('watchResidentStatus', watchResidentStatus);
      setValue('monthlyPayment', '');
      clearErrors('monthlyPayment');
    }
  }, [watchResidentStatus]);

  return (
    <div>
      <CustomCollapse heading="Resident Status" open={false}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomRadioButton
              control={control}
              radioValues={residentStatusList}
              label="Resident Status"
              required
              name="residentStatus"
              errors={!!errors?.residentStatus}
              errorText={errors?.residentStatus?.message}
            />
          </Grid>
          {/* //Alquiler for rent */}
          {(watchResidentStatus === 'Rent' || watchResidentStatus === 'Homeowner') && (
            <Grid item lg={6} md={6} sm={12} xs={12} mr={1}>
              <CustomNumberFormat
                name="monthlyPayment"
                id="monthlyPayment"
                dataTestId="monthlyPayment"
                label="Monthly Payment"
                control={control}
                error={!!errors?.monthlyPayment}
                errorText={errors?.monthlyPayment?.message}
                thousandSeparator={true}
                prefix={'$ '}
                required={watchResidentStatus === 'Rent' || watchResidentStatus === 'Homeowner'}
              />
            </Grid>
          )}
          <Grid item lg={6} md={6} sm={12} xs={12} container gap={1} alignItems="flex-start">
            <InputLabel>
              <span style={{ color: 'red' }}>*&nbsp;</span>Time at residence
            </InputLabel>
            <Grid
              item
              lg={5.5}
              md={5.5}
              sm={12}
              xs={12}
              display="flex"
              alignItems="center"
              sx={{
                gap: '16px',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" gap={1} alignItems="center">
                  <CustomNumberFormat
                    name="residentYears"
                    id="residentYears"
                    dataTestId="residentYears"
                    placeholder="0"
                    control={control}
                    error={!!errors?.residentYears}
                    maxLength={2}
                  />

                  <Typography variant="subtitle2" component="p">
                    Years
                  </Typography>
                </Box>
                {!!errors?.residentYears && (
                  <FormHelperText gap={2} className={globalClasses.customSelectError}>
                    {' '}
                    {errors?.residentYears?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
            <Grid
              item
              lg={5.5}
              md={5.5}
              sm={12}
              xs={12}
              display="flex"
              alignItems="center"
              sx={{
                gap: '16px',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" gap={1} alignItems="center">
                  <CustomNumberFormat
                    name="residentMonths"
                    id="residentMonths"
                    dataTestId="residentMonths"
                    placeholder="0"
                    control={control}
                    error={!!errors?.residentMonths}
                    maxLength={2}
                  />
                  <Typography variant="subtitle2" component="p">
                    Months
                  </Typography>
                </Box>
                {!!errors?.residentMonths && (
                  <FormHelperText gap={2} className={globalClasses.customSelectError}>
                    {' '}
                    {errors?.residentMonths?.message}
                  </FormHelperText>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};
export default ResidentStatus;

ResidentStatus.propTypes = {
  control: PropTypes.instanceOf(Object),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  clearErrors: PropTypes.func,
  errors: PropTypes.instanceOf(Object),
  residentStatusList: PropTypes.instanceOf(Object),
};
ResidentStatus.defaultProps = {
  control: {},
  errors: {},
  watch: () => {},
  setValue: () => {},
  clearErrors: () => {},
  residentStatusList: [],
};
