import { Box, Grid, InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import CustomCollapse from 'components/CustomCollapse';
import CustomDatePicker from 'components/CustomDatePicker/CustomDatePicker';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

const UserStatus = ({ control, errors }) => {
  return (
    <div>
      <CustomCollapse heading="User Status" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Status</InputLabel>
              <Typography sx={{ width: '100%' }}>Activated</Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Active"
              control={control}
              name="isActive"
              errors={!!errors?.isActive}
              errorText={errors?.isActive?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Last Inactivation Date</InputLabel>
              <Typography sx={{ width: '100%' }}>-</Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Last Reactivation Date</InputLabel>
              <Typography sx={{ width: '100%' }}>-</Typography>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel>Last Login</InputLabel>
              <Typography sx={{ width: '100%' }}>-</Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="User Configuration" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Maximize TTY"
              control={control}
              name="maximizeTty"
              errors={!!errors?.maximizeTty}
              errorText={errors?.maximizeTty?.message}
              options={[
                {
                  name: 'Yes',
                  value: 'Yes',
                },
                {
                  name: 'No',
                  value: 'No',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="Miscellaneous fields" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.ssn}
              errorText={errors?.ssn?.message}
              placeholder="Enter your text"
              label="SSN"
              name="ssn"
              flexDirection="column"
              required
              maxLength={9}
              regex={/^\d$/}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomDatePicker
              name="dob"
              label="Date of Birth"
              control={control}
              error={!!errors?.dob}
              errorText={errors?.dob?.message}
              required
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};

UserStatus.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
UserStatus.defaultProps = {
  control: {},
  errors: {},
};
export default UserStatus;
