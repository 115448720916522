import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Link } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { phoneValidation } from 'Services/commonComponentsApi';
import AddIcon from 'assets/svg/AddIcon.svg';
import { fetchAddressLookup } from 'commonComponents/commonComponents';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import debounceLodash from 'lodash.debounce';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  EmploymentInformationSchema,
  FormatValidationSchema,
} from 'schemaValidations/EmploymentInformationSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { currencyToNumber } from 'utils';

import Loader from 'components/Loader/Loader';

import CreateApplicationFooter from '../CreateApplicationFooter';
import ApplicationPageHeading from '../InformationRequest/moduleUtilities/ApplicationPageHeading';
import {
  frameInfoReqPayload,
  getSpecificPageDetailsStepTwo,
} from '../InformationRequest/moduleUtilities/InfoReqPayload';
import { step2DropDownApis } from '../InformationRequest/moduleUtilities/dropdownApis';
import {
  applyReviewDataStep2,
  decideNextUser,
  decidePrevious,
  saveApplicantDetails,
  validateDuplicates,
} from '../InformationRequest/moduleUtilities/moduleUtilities';
import AdditionalIncome from './AdditionalIncome/AdditionalIncome';
import EmpInfoForm from './EmpInfoForm/EmpInfoForm';

const EmploymentInformation = () => {
  const [showAdditionalIncome, setShowAdditionalIncome] = useState(false);
  const [loader, setLoader] = useState(false);
  const [addressLookupData, setAddressLookupData] = useState([]);
  const [addressLookupDataResponse, setAddressLookupDataResponse] = useState([]);

  const {
    commonToken,
    occupationList,
    setOccupationList,
    empStatusList,
    setEmpStatusList,
    cityList,
    setCityList,
    industryList,
    setIndustryList,
    createAppApplicationId,
  } = useContext(AuthContext);
  const {
    coApplicant,
    coSigner,
    // setCoApplicant,
    // setCoSigner,
    userType,
    setUserType,
    activeStep,
    setActiveStep,
    setVouchedData,
    userDetails,
    setUserDetails,
    setFormLoader,
    reviewFlag,
    reviewData,
    // backFlag,
    setBackFlag,
    completedSteps,
    setCompletedSteps,
    setOpenDraftModal,
  } = useContext(MainApplicationContext);

  const inputRef = useRef(null);
  const ApiClient = new ApiClass();
  const {
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      empStatus: '',
      empName: '',
      empAddressLine1: '',
      empAddressLine2: '',
      empCountry: 'USA',
      empState: 'PR',
      empCity: '',
      empZipcode: '',
      jobTitle: '',
      occupation: '',
      industry: '',
      empYears: '',
      empMonths: '',
      monthlyIncome: '',
      mobile: '',
      areaCode: '787',

      showAddInfo: false,

      addEmpName: '',
      addJobTitle: '',
      addOccupation: '',
      mobile2: '',
      addAreaCode: '787',
      addMonthlyIncome: '',
    },
    resolver: yupResolver(EmploymentInformationSchema),
  });
  console.log('----errors-------', errors);
  useEffect(async () => {
    await step2DropDownApis({
      occupationList,
      setOccupationList,
      empStatusList,
      setEmpStatusList,
      cityList,
      setCityList,
      industryList,
      setIndustryList,
    });
  }, []);

  const resetForm = () => {
    if (!reviewFlag) {
      console.log('into reset');
      reset();
      inputRef?.current?.focus();
    }
  };
  useEffect(async () => {
    console.log('userType', userType);

    await getSpecificPageDetailsStepTwo({
      userType,
      activeStep,
      setValue,
      createAppApplicationId,
      setLoader,
      setShowAdditionalIncome,
      callback: resetForm,
    });
  }, [userType]);

  useEffect(() => {
    if (reviewData?.AdditionalIncomeDetail) {
      setShowAdditionalIncome(true);
    }
    applyReviewDataStep2({ setValue, reviewData });
  }, [reviewFlag, reviewData]);

  const [
    watchName,
    watchmobile1,
    watchAreaCode1,
    watchmobile2,
    watchAreaCode2,
    watchEmpAddressLine1,
    watchEmpAddressLine2,
    watchEmpCity,
    watchEmpState,
    watchEmpZipCode,
  ] = watch([
    'empName',
    'mobile',
    'areaCode',
    'mobile2',
    'addAreaCode',
    'empAddressLine1',
    'empAddressLine2',
    'empCity',
    'empState',
    'empZipcode',
  ]);

  useEffect(() => {
    setValue('showAddInfo', showAdditionalIncome);
  }, [showAdditionalIncome]);

  const debounceFunc5 = debounceLodash((address1, country, query5, commonTokenn, addressType) => {
    fetchAddressLookup({
      address1,
      country,
      query5,
      commonTokenn,
      addressType,
      setAddressLookupData,
      setAddressLookupDataResponse,
    });
  }, 500);

  const fetchAddress5 = useCallback(debounceFunc5, []);
  useEffect(() => {
    if (dirtyFields?.empAddressLine1 && commonToken) {
      let query5 = watchEmpAddressLine1;

      if (watchEmpAddressLine2) {
        query5 += ` ${watchEmpAddressLine2} `;
      }
      if (watchEmpState) {
        query5 += ` ${watchEmpState} `;
      }
      if (watchEmpCity) {
        query5 += ` ${watchEmpCity} `;
      }
      if (watchEmpZipCode) {
        query5 += ` ${watchEmpZipCode} `;
      }
      fetchAddress5(watchEmpAddressLine1, 'USA', query5, commonToken, 'res');
    }
  }, [watchEmpAddressLine1]);

  const handleAdditionalClick = () => {
    setShowAdditionalIncome(true);
  };
  const validateForm = async () => {
    const validWithoutDuplicates = await validateDuplicates({
      data: {
        firstName: watchName,
        workMobile: watchmobile1,
      },
      userDetails,
      setError,
      userType,
    });
    let validFlag1 = false;
    if (validWithoutDuplicates) {
      validFlag1 = await phoneValidation(
        watchAreaCode1,
        watchmobile1,
        watchAreaCode2,
        watchmobile2,
        commonToken,
        setError,
        setLoader
      );
    }

    return !!(validFlag1 && validWithoutDuplicates);
  };
  const watchAll = watch();
  const isdraftDisabled = Object.keys(dirtyFields)?.length === 0 && !watchName;
  useEffect(() => {
    inputRef?.current?.focus();
    if (userType && !reviewFlag) {
      reset();
    }
  }, [userType]);

  const draftClick = async () => {
    console.log('into draft click');
    const formValue = getValues();
    let validFlag = true;

    let isValid = false;

    try {
      isValid = FormatValidationSchema.isValidSync(formValue);

      FormatValidationSchema.validateSync(formValue);
    } catch (err) {
      console.log('err in', err);
    }

    if (watchmobile1 && isValid) {
      await phoneValidation(
        watchAreaCode1,
        watchmobile1,
        watchAreaCode2,
        watchmobile2,
        commonToken,
        setError,
        setLoader
      );
    }
    console.log('isValid', isValid);
    if (isValid && validFlag) {
      clearErrors();
      await handleEmpSubmit(watchAll, { draft: true });
      console.log('call draft api');
    }
  };
  const handleEmpSubmit = async (data, { draft = false }) => {
    let submitFlag = false;

    if (!Object.entries(errors).length && !draft) {
      submitFlag = await validateForm();
    }
    if (submitFlag || draft) {
      setFormLoader(true);
      setBackFlag(false);
      const {
        empStatus,
        empName,
        empAddressLine1,
        empAddressLine2,
        empCountry,
        empState,
        empCity,
        empZipcode,
        jobTitle,
        occupation,
        industry,
        empYears,
        empMonths,
        monthlyIncome,
        mobile,
        areaCode,
        addEmpName,
        addJobTitle,
        addOccupation,
        mobile2,
        addAreaCode,
        addMonthlyIncome,
      } = data;

      const formData = {
        EmploymentInformation: {
          EmploymentInformationDetail: {
            EmploymentStatus: empStatus,
            Employer: empName,
            Address: {
              LineOne: empAddressLine1,
              LineTwo: empAddressLine2,
              AddrCity: empCity,
              AddrState: empState,
              AddrCountry: 'United States',
              AddrCountryCode: empCountry,
              ZipCode: empZipcode,
            },
            JobTitle: jobTitle,
            Occupation: occupation,
            OccupationCode:
              occupationList?.length > 0 &&
              occupationList.find((x) => x.name === occupation)?.value,
            Industry: industry,
            // MonthlyIncome: monthlyIncome,
            EmploymentTimePeriodYear: empYears,
            EmploymentTimePeriodMonth: empMonths,
            WorkPhone: `${areaCode}${mobile}`,
            GrossMonthlyIncome: currencyToNumber(monthlyIncome),
          },
        },
      };
      if (showAdditionalIncome) {
        formData.EmploymentInformation.AdditionalIncomeDetail = {
          AddlEmployerName: addEmpName,
          AddlJobTitle: addJobTitle,
          AddlOccupation: addOccupation,

          AddlOccupationCode:
            occupationList?.length > 0 &&
            occupationList.find((x) => x.name === addOccupation)?.value,

          AddlWorkPhone: `${addAreaCode}${mobile2}`,
          AddlGrossMonthlyIncome: currencyToNumber(addMonthlyIncome),
        };
      }
      const employmentInfoPayload = await frameInfoReqPayload({
        formData,
        userType,
        coApplicant,
        coSigner,
        createAppApplicationId,
        dealerId: '1234',
        userId: 'co2607',
        step: 2,
      });
      try {
        const endpoint = draft ? UrlHelpers.applicationDraft : UrlHelpers.informationReqSave;
        let calculate = await ApiClient.post(
          endpoint,
          { ...employmentInfoPayload },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );

        if (calculate && !draft) {
          setVouchedData({});
          saveApplicantDetails({ data, userType, activeStep, userDetails, setUserDetails });
          decideNextUser({
            userType,
            setUserType,
            watchCoApplicant: coApplicant,
            watchCoSigner: coSigner,
            activeStep,
            setActiveStep,
            setValue,
            setLoader,
            loader,
            reviewFlag,
            completedSteps,
            setCompletedSteps,
          });
        } else if (calculate && draft) {
          console.log('draft success', calculate);
          setOpenDraftModal(true);
        }
      } catch (e) {
        console.log('error fetching', e);
      } finally {
        setFormLoader(false);
      }
    }
  };

  const onBackClick = async () => {
    await decidePrevious({
      userType,
      setUserType,
      activeStep,
      setActiveStep,
      coApplicant,
      coSigner,
      setBackFlag,
    });
  };
  return (
    <>
      <Loader open={loader} />

      <ApplicationPageHeading />

      <Grid container justifyContent="center">
        <Box sx={{ width: '70%' }}>
          <EmpInfoForm
            control={control}
            errors={errors}
            setValue={setValue}
            inputRef={inputRef}
            clearErrors={clearErrors}
            addressLookupData={addressLookupData}
            addressLookupDataResponse={addressLookupDataResponse}
          />
          {!showAdditionalIncome && (
            <Link
              component="button"
              variant="subtitle2"
              color="secondary"
              sx={{ padding: '40px 0' }}
              onClick={handleAdditionalClick}
            >
              <Box display="flex" alignItems="center">
                <img src={AddIcon} alt="Add Button" style={{ marginRight: '8px' }} /> Add Additional
                Income
              </Box>
            </Link>
          )}

          {showAdditionalIncome && (
            <AdditionalIncome
              control={control}
              errors={errors}
              setShowAdditionalIncome={setShowAdditionalIncome}
              setValue={setValue}
            />
          )}
        </Box>
      </Grid>

      <CreateApplicationFooter
        disabledFlag={reviewFlag}
        onBackClick={onBackClick}
        onSaveContinue={handleSubmit(handleEmpSubmit)}
        saveDisabledFlag={Object.entries(errors)?.length > 0}
        onSaveDraft={draftClick}
        draftDisabledFlag={isdraftDisabled}
      />
    </>
  );
};

export default EmploymentInformation;
