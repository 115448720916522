import AuthContext from 'context/AuthContext';
import CreateAppMain from 'pages/CreateApplication/CreateAppMain/CreateAppMain';
import SideBar from 'pages/SideBar/SideBar';
import { useContext } from 'react';

export const ProtectedRouteOutlet = () => {
  const { openPopUp } = useContext(AuthContext);
  return (
    <>
      {openPopUp && <CreateAppMain />}
      <SideBar />
    </>
  );
};
