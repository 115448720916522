import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { currencyToNumber } from 'utils';

import CustomCollapse from 'components/CustomCollapse';
import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomNumberFormat from 'components/CustomNumberFormat/CustomNumberFormat';

import classes from '../../styles/globalStyle.module.scss';
import constants from '../../utils/constants';

const { amountFinanced } = constants;

const FinancedAmount = ({ control, errors, setValue }) => {
  const [
    watchSalePrice,
    watchCashDown,
    watchRebate,
    watchCarCare,
    watchTradeDept,
    watchTradeAllowance,
    watchOther,
    watchServiceContract,
    watchExtendedWarranty,
    watchPaintProtection,
    watchNewOrUsed,
    watchVehicleClass,
  ] = useWatch({
    control,
    name: [
      'salePrice',
      'cashDown',
      'rebate',
      'carCare',
      'tradeDept',
      'tradeAllowance',
      'other',
      'serviceContract',
      'extendedWarranty',
      'paintProtection',
      'newOrUsed',
      'vehicleClass',
    ],
  });
  console.log('watchSalePrice', watchSalePrice);
  useEffect(() => {
    if (
      watchSalePrice ||
      watchCashDown ||
      watchCarCare ||
      watchOther ||
      watchRebate ||
      watchServiceContract ||
      watchExtendedWarranty ||
      watchPaintProtection ||
      watchTradeAllowance ||
      watchTradeDept
    ) {
      const value =
        currencyToNumber(watchSalePrice) -
        (currencyToNumber(watchTradeAllowance) - currencyToNumber(watchTradeDept)) -
        currencyToNumber(watchCashDown) -
        currencyToNumber(watchRebate) +
        currencyToNumber(watchCarCare) +
        currencyToNumber(watchOther) +
        currencyToNumber(watchServiceContract) +
        currencyToNumber(watchExtendedWarranty) +
        currencyToNumber(watchPaintProtection);

      setValue('balance', value);
    }
  }, [
    watchSalePrice,
    watchCashDown,
    watchCarCare,
    watchOther,
    watchServiceContract,
    watchExtendedWarranty,
    watchPaintProtection,
    watchRebate,
    watchTradeAllowance,
    watchTradeDept,
    setValue,
  ]);

  useEffect(() => {
    if (
      (watchNewOrUsed === 'new' && watchVehicleClass) ||
      (watchNewOrUsed === 'used' && watchVehicleClass === 'import')
    ) {
      const value =
        (currencyToNumber(watchSalePrice) + currencyToNumber(watchPaintProtection)) * 0.005 +
        15 +
        11;
      setValue('registrationfee', value);
    } else if (watchNewOrUsed === 'used' && watchVehicleClass === 'local') {
      const value =
        (currencyToNumber(watchSalePrice) +
          currencyToNumber(watchOther) +
          currencyToNumber(watchPaintProtection)) *
          0.005 +
        15 +
        30;
      setValue('registrationfee', value);
    }
  }, [
    watchSalePrice,
    watchOther,
    watchPaintProtection,
    watchNewOrUsed,
    watchVehicleClass,
    setValue,
  ]);
  useEffect(() => {
    if (watchTradeAllowance || watchTradeDept) {
      const value = currencyToNumber(watchTradeAllowance) - currencyToNumber(watchTradeDept);
      setValue('netTrade', value.toFixed(2));
    }
  }, [watchTradeAllowance, watchTradeDept, setValue]);

  return (
    <div>
      <CustomCollapse heading={amountFinanced} open={false}>
        <Grid container my={0.5} columnSpacing={4} rowSpacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="cashDown"
              id="cashDown"
              dataTestId="cashDown"
              placeholder="Enter value"
              label="Cash Down"
              control={control}
              error={!!errors?.cashDown}
              errorText={errors?.cashDown?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="rebate"
              id="rebate"
              dataTestId="rebate"
              placeholder="Enter value"
              label="Rebate"
              control={control}
              error={!!errors?.rebate}
              errorText={errors?.rebate?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="carCare"
              id="carCare"
              dataTestId="carCare"
              placeholder="Enter value"
              label="Car Care"
              control={control}
              error={!!errors?.carCare}
              errorText={errors?.carCare?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="other"
              label="Other"
              id="other"
              dataTestId="other"
              placeholder="Enter value"
              control={control}
              error={!!errors?.other}
              errorText={errors?.other?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="serviceContract"
              id="serviceContract"
              dataTestId="serviceContract"
              placeholder="Enter value"
              label="Service Contract"
              control={control}
              error={!!errors?.serviceContract}
              errorText={errors?.serviceContract?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="tradeDept"
              id="tradeDept"
              dataTestId="tradeDept"
              placeholder="Enter value"
              label="Trade In Debt"
              control={control}
              error={!!errors?.tradeDept}
              errorText={errors?.tradeDept?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="tradeAllowance"
              id="tradeAllowance"
              dataTestId="tradeAllowance"
              placeholder="Enter value"
              label="Trade In Allowance"
              control={control}
              error={!!errors?.tradeAllowance}
              errorText={errors?.tradeAllowance?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="netTrade"
              id="netTrade"
              dataTestId="netTrade"
              label="Net Trade-in"
              control={control}
              error={!!errors?.netTrade}
              errorText={errors?.netTrade?.message}
              disabled
              thousandSeparator={true}
              decimalScale={2}
              prefix={'$ '}
              // displayType="text"
              className={classes.customNumberFormat}
              allowNegative
              allowEmptyFormatting
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="extendedWarranty"
              id="extendedWarranty"
              dataTestId="extendedWarranty"
              placeholder="Enter value"
              label="Extended Warranty"
              control={control}
              error={!!errors?.extendedWarranty}
              errorText={errors?.extendedWarranty?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="paintProtection"
              id="paintProtection"
              dataTestId="paintProtection"
              placeholder="Enter value"
              label="Paint Protection"
              control={control}
              error={!!errors?.paintProtection}
              errorText={errors?.paintProtection?.message}
              thousandSeparator={true}
              prefix={'$ '}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.year}
              errorText={errors?.year?.message}
              placeholder="Enter Year"
              label="Year"
              name="year"
              flexDirection="column"
            />
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.make}
              errorText={errors?.make?.message}
              placeholder="Enter Make"
              label="Make"
              name="make"
              flexDirection="column"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomInputBox
              control={control}
              errors={!!errors?.model}
              errorText={errors?.model?.message}
              placeholder="Enter Model"
              label="Model"
              name="model"
              flexDirection="column"
            />
          </Grid>
        </Grid>

        <hr style={{ marginTop: '16px' }} />
        <Grid container columnSpacing={4} rowSpacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomNumberFormat
              name="balance"
              id="balance"
              dataTestId="balance"
              label="Balance"
              control={control}
              error={!!errors?.balance}
              errorText={errors?.balance?.message}
              disabled
              thousandSeparator={true}
              prefix={'$ '}
              allowEmptyFormatting
            />
          </Grid>
        </Grid>
      </CustomCollapse>
    </div>
  );
};

export default FinancedAmount;

FinancedAmount.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  setValue: PropTypes.func,
  makeList: PropTypes.array,
};
FinancedAmount.defaultProps = {
  control: {},
  errors: {},
  setValue: () => {},
  makeList: [],
};
