/* eslint-disable no-unused-vars */
import { Box, Paper, Typography } from '@mui/material';
import MainApplicationContext from 'context/MainApplicationContext';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';

import Loader from 'components/Loader/Loader';
import HorizontalLinearStepper from 'components/Stepper/Stepper';

import ApplicationApproved from './ApplicationApproved';
import ApplicationSubmitted from './ApplicationSubmitted';
import RenderStep from './RenderStep';
import SaveAsDraftDialog from './SaveAsDraft/SaveAsDraftDialog';

const CreateApplicationBase = () => {
  const location = useLocation();

  let {
    scannedData = {},
    coApplicant: coApplicantState = {},
    coSigner: coSignerState = [],
    reviewFlag: reviewFlagState = false,
    activeStep: activeStepState = 0,
    calculationState: calculationsState = '',
    completedSteps: completedStepsState = [],
    watchCoApplicant,
    watchCoSigner,
    userType: userTypeState,
    vouchedFlag = false,
    userDetails: userDetailsState = [],
    formFields: formFieldsState = {},
  } = location?.state || {};
  console.log('state passed from popup', location?.state);
  const [activeStep, setActiveStep] = useState(activeStepState);
  const [vouchedData, setVouchedData] = useState({});
  const [calculationState, setCalculationState] = useState(calculationsState);
  const [coApplicant, setCoApplicant] = useState(coApplicantState);
  const [coSigner, setCoSigner] = useState(coSignerState);
  const [userType, setUserType] = useState('');
  const [userDetails, setUserDetails] = useState(userDetailsState);
  const [formLoader, setFormLoader] = useState(false);
  const [backFlag, setBackFlag] = useState(false);
  const [showVouched, setShowVouched] = useState(false);
  const [reviewFlag, setReviewFlag] = useState(reviewFlagState);
  const [reviewData, setReviewData] = useState(false);
  const [showCollateralPopup, setShowCollateralPopup] = useState(false);
  const [completedSteps, setCompletedSteps] = useState(completedStepsState);
  const [vouchedReturnFlag, setVouchedReturnFlag] = useState(false);
  const [appSubmitted, setAppSubmitted] = useState(false);
  const [appApproved, setAppApproved] = useState(false);
  const [loanStatus, setLoanStatus] = useState('');
  const [formFields, setFormFields] = useState({});
  const [openDraftModal, setOpenDraftModal] = useState(false);
  const [caseReferenceId, setCaseReferenceId] = useState('');

  useEffect(() => {
    if (vouchedFlag) {
      setVouchedReturnFlag(true);
      if (scannedData) {
        setVouchedData(scannedData);
      }
      setCoApplicant(watchCoApplicant);
      setCoSigner(watchCoSigner);
      setFormFields(formFieldsState);
      setUserType(userTypeState);
    }
    setActiveStep(activeStepState);
  }, [location?.state]);
  const steps = [
    'Information on request',
    'Employment Information',
    'Collateral Information',
    'Financing Terms',
    'Review & Submit',
  ];
  return (
    <MainApplicationContext.Provider
      value={{
        activeStep,
        setActiveStep,
        scannedData: vouchedData,
        setVouchedData,
        coApplicant,
        setCoApplicant,
        coSigner,
        setCoSigner,
        userType,
        setUserType,
        userDetails,
        setUserDetails,
        formLoader,
        setFormLoader,
        formFields,
        setFormFields,
        backFlag,
        setBackFlag,
        showVouched,
        setShowVouched,
        showCollateralPopup,
        setShowCollateralPopup,
        reviewFlag,
        setReviewFlag,
        reviewData,
        setReviewData,
        calculationState,
        setCalculationState,
        completedSteps,
        setCompletedSteps,
        vouchedReturnFlag,
        setVouchedReturnFlag,
        setOpenDraftModal,
        appSubmitted,
        setAppSubmitted,
        appApproved,
        setAppApproved,
        loanStatus,
        setLoanStatus,
        caseReferenceId,
        setCaseReferenceId,
      }}
    >
      <>
        <Box py={3} px={2} display="flex" justifyContent="space-between">
          <Typography variant="h3">Create Application</Typography>
        </Box>
        <Loader open={formLoader} />
        {appSubmitted && !appApproved && (
          <Box>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              <ApplicationSubmitted />
            </Paper>
          </Box>
        )}
        {appApproved && (
          <Box>
            <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
              <ApplicationApproved />
            </Paper>
          </Box>
        )}
        {!appSubmitted && !appApproved && (
          <>
            <SaveAsDraftDialog openModal={openDraftModal} setOpenModal={setOpenDraftModal} />
            <Box display="flex" justifyContent="center">
              <Box sx={{ width: '70%' }}>
                <Paper className={classes.infoRequestPaper} sx={{ padding: '10px' }}>
                  <Typography variant="h4" component="p" py={5} textAlign="center">
                    Alright! Let’s create application in just 5 steps
                  </Typography>
                  <HorizontalLinearStepper
                    steps={steps}
                    activeStep={activeStep - 1}
                    completedSteps={completedSteps}
                  />

                  <RenderStep activeStep={activeStep} />
                </Paper>
              </Box>
            </Box>
          </>
        )}
      </>
    </MainApplicationContext.Provider>
  );
};

export default CreateApplicationBase;
