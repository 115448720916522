import axios from 'axios';

class ApiClient {
  async get(url, config = {}) {
    const response = await axios.get(url, config);
    const { data: resData = {} } = response || {};
    return resData;
  }

  async post(url, data = {}, config = {}) {
    const response = await axios.post(url, data, config);
    const { data: resData = {} } = response || {};
    return resData;
  }
}

export default ApiClient;
