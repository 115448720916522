import { Box, Step, StepLabel, Stepper } from '@mui/material';
import PropTypes from 'prop-types';
import * as React from 'react';

export default function HorizontalLinearStepper({ steps, activeStep, completedSteps }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          let iscompleted = completedSteps.find((x) => x === index + 1);
          const completedFlag = activeStep !== index ? !!iscompleted : null;

          return (
            <Step
              key={label}
              {...stepProps}
              completed={completedFlag}
              sx={{
                '& .MuiStepIcon-root': {
                  border: '2px solid #D0D0D0',
                  borderRadius: '50%',

                  fontSize: '36px',
                },
                '& .MuiStepIcon-root.Mui-completed': {
                  color: '#661c69',
                },
                '& .MuiStepConnector-root': {
                  top: '20px',
                },
                '& .MuiStepIcon-root.Mui-active': {
                  color: '#661c69',
                  fill: '#fff',
                  border: '3px solid #661c69',
                  borderRadius: '50%',
                },
                '& .MuiStepLabel-label.Mui-active': {
                  color: '#661c69',
                  fontWeight: '700',
                  fontSize: '14px',
                },
                '& .MuiStepLabel-label': {
                  color: '#727578;',
                  fontWeight: '400',
                  fontSize: '14px',
                },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: '#661c69',
                  fontWeight: '700',
                },
              }}
            >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

HorizontalLinearStepper.propTypes = {
  steps: PropTypes.array,
  activeStep: PropTypes.number,
  completedSteps: PropTypes.array,
};
HorizontalLinearStepper.defaultProps = {
  steps: [],
  activeStep: 0,
  completedSteps: [],
};
