import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { currencyToNumber, numberToCurrency } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import classes from '../../styles/resultPage.module.scss';
import Results from './Results';

const ResultPage = ({ state, watch, getValues }) => {
  let values = state;
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  console.log('values.data', values);
  const [loader, setLoader] = useState(false);
  const [payScheduleFinance, setPayScheduleFinance] = useState([]);
  const [make, setMake] = useState('');

  const { makeList } = useContext(AuthContext);
  const watchMake = watch('make');
  const { createAppApplicationId } = useContext(AuthContext);
  useEffect(() => {
    if (values?.flowData?.data?.calculatedData?.paymentSchedules) {
      setPayScheduleFinance(values?.flowData?.data?.calculatedData?.paymentSchedules);
    }
  }, []);
  useEffect(() => {
    console.log("getValues('make')", watchMake);
    const makeName = makeList?.find((x) => x.value === watchMake);
    console.log('object', makeName);
    setMake(makeName?.name);
  }, [watchMake]);
  console.log('make', make);
  const FinanceTermValues = {
    calculatedData: values.data,
    formData: values.formData,
    apr: values.apr,
    make: make,
    baloonPayment: currencyToNumber(values.baloonPayment),
  };

  const handleSaveApplicationDetails = async () => {
    try {
      setLoader(true);
      let calculate = await ApiClient.post(
        UrlHelpers.financeTermsPayCalcSave,
        {
          applicationId: createAppApplicationId,

          type: 'FinanceTermPayCalc',

          searchResult: JSON.stringify(FinanceTermValues),
        },

        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      console.log('calcuate', calculate);
      if (calculate) {
        console.log('calcuate', calculate);

        setLoader(false);
        navigate('/home/create-app', {
          state: {
            activeStep: 4,
            coApplicant: values?.flowData?.coApplicant,
            coSigner: values?.flowData?.coSigner,
            calculationState: 'success',
            completedSteps: [1, 2, 3],
          },
        });
      }
    } catch (e) {
      console.log('error fetching', e);
    } finally {
      setLoader(false);
    }
  };
  const paymentSchedules = values?.data?.paymentSchedules
    ? values?.data?.paymentSchedules
    : payScheduleFinance;
  return (
    <>
      <div className={classes.resultContainer}>
        <Box className={classes.resultContent}>
          <Typography variant="subtitle1" component="h3" my={2} sx={{ fontWeight: 700 }}>
            Estimated monthly payment
          </Typography>
        </Box>

        <Results values={values} watch={watch} getValues={getValues} />
        <Loader open={loader} />

        <Box mt={5}>
          <Typography
            variant="subtitle1"
            component="h3"
            my={2}
            color="#fff"
            sx={{ fontWeight: 700 }}
          >
            Payment Schedule
          </Typography>
          <Table aria-label="simple table" className={classes.tableContainer}>
            <TableBody>
              <TableRow>
                <TableCell classes={{ root: classes.TableHeading }}>No. of Payments</TableCell>
                <TableCell classes={{ root: classes.TableHeading }}>Payment Amount</TableCell>
                <TableCell classes={{ root: classes.TableHeading }}>Start Date</TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              {paymentSchedules?.map((row) => (
                <TableRow key={row?.startDate}>
                  <TableCell classes={{ root: classes.TableCell }}>{row?.noOfPayments}</TableCell>
                  <TableCell classes={{ root: classes.TableCell }}>
                    {' '}
                    {numberToCurrency(row?.paymentAmount?.toFixed?.(2))}
                  </TableCell>
                  <TableCell classes={{ root: classes.TableCell }}>{row?.startDate}</TableCell>
                </TableRow>
              )) || (
                <TableRow>
                  <TableCell classes={{ root: classes.TableCell }}>--</TableCell>
                  <TableCell classes={{ root: classes.TableCell }}>--</TableCell>
                  <TableCell classes={{ root: classes.TableCell }}>--</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </div>
      {values?.flowData?.flow && (
        <Box display="flex" justifyContent="flex-end" my={3}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!Object.entries(values?.data).length}
            onClick={handleSaveApplicationDetails}
          >
            Save Value & continue to application
          </Button>
        </Box>
      )}
    </>
  );
};

export default ResultPage;

ResultPage.propTypes = {
  state: PropTypes.instanceOf(Object),
  setShowResults: PropTypes.func,
  watch: PropTypes.func,
  getValues: PropTypes.func,
};
ResultPage.defaultProps = {
  state: {},
  setShowResults: () => {},
  watch: () => {},
  getValues: () => {},
};
