import { Box, Button, Typography } from '@mui/material';
import valueLookupUtility from 'assets/images/value-lookup-utility.png';
import MainApplicationContext from 'context/MainApplicationContext';
import { useContext } from 'react';

const CollateralInformationLookupDialog = () => {
  const { setShowCollateralPopup } = useContext(MainApplicationContext);
  return (
    <Box
      width="631px"
      height="262px"
      sx={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F3F3F3 100%)',
        border: '1px solid #909090',
        display: 'flex',
      }}
    >
      <img src={valueLookupUtility} width="185px" height="207px" style={{ margin: '31px' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '31px',
          gap: '27px',
        }}
      >
        <Typography variant="h3" color="#E37617">
          Value Lookup Utility
        </Typography>
        <Typography fontSize="14px !important">
          Value lookup utility benefits to enter the collateral vehicle information and help the
          result to be displayed on the screen. Go ahead and click below{' '}
        </Typography>
        <Button
          sx={{
            background: '#E37617',
            borderRadius: '5px',
            width: '177px',
            height: '40px',
            color: '#000000',
          }}
          onClick={() => setShowCollateralPopup(true)}
        >
          Value Lookup
        </Button>
      </Box>
    </Box>
  );
};

export default CollateralInformationLookupDialog;
