import * as yup from 'yup';

export const CreateApplicationSchema = (isSkipped, coappdet, cosignerEnabled) => {
  return yup.object().shape({
    CoAppFirstName: yup.string().when('applicantOrSigner', {
      is: (val) => {
        return val?.includes('coApplicant');
      },
      then: yup
        .string()
        .test('len', 'Maximum 15 characters can be entered', (val) => val.length <= 15)
        .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format')
        .required('* Mandatory Field'),
    }),

    CoAppLastName: yup.string().when('applicantOrSigner', {
      is: (val) => {
        return val?.includes('coApplicant');
      },
      then: yup
        .string()
        .test('len', 'Maximum 25 characters can be entered', (val) => val?.length <= 25)
        .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format')
        .required('* Mandatory Field'),
    }),
    CoSigner: yup.array().of(
      yup.object().shape({
        CoSignerName: yup.object().shape({
          CoSignerFirstName: yup
            .string()
            .max(15, 'Maximum 15 characters can be entered')
            .test('check cosigne LastName', '* Mandatory Field', (val) => {
              return !(!val && !isSkipped && (coappdet?.includes('coSigner') || cosignerEnabled));
            })
            .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
          CoSignerLastName: yup
            .string()
            .max(25, 'Maximum 25 characters can be entered')
            .test('check cosigne LastName', '* Mandatory Field', (val) => {
              return !(!val && !isSkipped && (coappdet?.includes('coSigner') || cosignerEnabled));
            })
            .matches(/^[a-zA-Záéíóúñü¿¡\s]*$/, '* Invalid format'),
        }),
      })
    ),
  });
};
