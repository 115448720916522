/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import {
  makeLists,
  modelLists,
  subModelLists,
  yearLists,
} from 'pages/Lookup/DistributorDataResult/FilterDetailsService';
import LookupDialog from 'pages/Lookup/LookupDialog/LookupDialog';
import LookupInterDialog from 'pages/Lookup/LookupInterDialog/LookupInterDialog';
import LookupSecondDialog from 'pages/Lookup/LookupSecondDialog/LookupSecondDialog';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { lookupSchema } from 'schemaValidations/lookupSchema';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import Loader from 'components/Loader/Loader';

const LookupCommon = ({ coApplicant, coSigner, reviewFlag, calculationState }) => {
  const navigate = useNavigate();
  const {
    showMainPopup,
    showInterPopup,
    showSecondaryPopup,
    setShowSecondaryPopup,
    setStateList,
    yearList,
    setYearList,
    makeList,
    setMakeList,
    modelList,
    setModelList,
    subModelList,
    setSubModelList,
    setStyleList,
    setVinDetail,
    distributorData,
    setDistributorResult,
    newVehicleDetails,
    setNewVehicleDetails,
    usedVehicleDetails,
    setUsedVehicleDetails,
    newDistributorDetails,
    setNewDistributorDetails,
    setShowSummary,
    setPageData,
    setShowPage,
  } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      collateralType: '',
      valueGuide: '',
      make: '',
      year: '',
      model: '',
      style: '',
      subModel: '',
      lookuptype: '',
      mileage: 0,
      vin: '',
      state: 'PR',
    },
    resolver: yupResolver(lookupSchema),
  });

  const [
    watchCollateralType,
    watchValueGuide,
    watchlookupType,
    watchyear,
    watchmake,
    watchModel,
    watchSubModel,
    watchStyle,
    watchState,
    watchMileage,
    watchVin,
  ] = watch([
    'collateralType',
    'valueGuide',
    'lookuptype',
    'year',
    'make',
    'model',
    'subModel',
    'style',
    'state',
    'mileage',
    'vin',
  ]);

  const ApiClient = new ApiClass();
  useEffect(async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(UrlHelpers.lookUpState);

      const statesData = resp.states?.state_list?.map((x) => {
        return { name: x?.name, value: x?.abbreviation };
      });
      setStateList(statesData);
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, []);
  const newBlackbookFn = async () => {
    if (newVehicleDetails.length === 0) {
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.lookUpDropDownInfoNew,
          { vehicleclass: 'All' },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const respData = resp.drilldown?.class_list[0]?.year_list;

        const firsttwo = respData.slice(0, 2);
        setNewVehicleDetails(firsttwo);
        setYearList(firsttwo);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    } else {
      setYearList(newVehicleDetails);
    }
  };
  const usedBlackBookFn = async () => {
    if (usedVehicleDetails.length === 0) {
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.lookUpDropDownInfo,
          { vehicleclass: 'All' },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        const respData = resp.drilldown?.class_list[0]?.year_list;
        setUsedVehicleDetails(respData);
        setYearList(respData);
      } catch (e) {
        console.log('dropdown api error', e);
      } finally {
        setLoader(false);
      }
    } else {
      setYearList(usedVehicleDetails);
    }
  };

  const newDistributorDataFn = async () => {
    if (newDistributorDetails.length === 0) {
      try {
        const response = await yearLists(setLoader);
        const yearsList =
          response?.map?.((x) => {
            const { year } = x;
            return { name: year, value: year };
          }) || [];
        setYearList(yearsList);
        setNewDistributorDetails(yearsList);
      } catch (e) {
        console.log('dropdown api error', e);
      }
    } else {
      setYearList(newDistributorDetails);
    }
  };
  useEffect(async () => {
    if (watchValueGuide === 'Distributor data' && watchyear) {
      setValue('make', '');
      setValue('model', '');
      setValue('subModel', '');
      let response = await makeLists(setLoader, watchyear);
      const makeListOpt =
        response?.map?.((x) => {
          const { make } = x;
          return { name: make, value: make };
        }) || [];

      setMakeList(makeListOpt);
    } else {
      const opt = yearList?.find((x) => x?.name === watchyear)?.make_list || [];
      setMakeList(opt);
    }
  }, [watchyear]);

  useEffect(async () => {
    if (watchValueGuide === 'Distributor data' && watchyear && watchmake) {
      let response = await modelLists(setLoader, watchyear, watchmake);
      const modelListOpt =
        response?.map?.((x) => {
          const { model } = x;
          return { name: model, value: model };
        }) || [];
      setModelList(modelListOpt);
    } else {
      if (watchmake) {
        const modelResponse = await getModelList();
        if (modelResponse) {
          setModelList(modelResponse);
        }
      }
    }
  }, [makeList, watchmake]);

  const getModelList = async () => {
    const serviceUrl =
      watchCollateralType === 'new'
        ? UrlHelpers.lookUpDropDownInfoNew
        : UrlHelpers.lookUpDropDownInfo;
    try {
      setLoader(true);
      const resp = await ApiClient.post(
        serviceUrl,
        { vehicleclass: 'All', year: watchyear, make: watchmake },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      return resp.drilldown?.class_list[0]?.year_list[0].make_list[0].model_list;
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  useEffect(async () => {
    if (watchValueGuide === 'Distributor data' && watchyear && watchmake && watchModel) {
      let response = await subModelLists(setLoader, watchyear, watchmake, watchModel);
      const subModelListOpt =
        response?.map?.((x) => {
          const { subModel } = x;
          return { name: subModel, value: subModel };
        }) || [];
      setDistributorResult(response);
      setSubModelList(subModelListOpt);
    } else if (watchValueGuide === 'Blackbook') {
      const opt = modelList.find((x) => x.name === watchModel)?.series_list || [];
      setSubModelList(opt);
    }
  }, [modelList, watchModel]);

  useEffect(() => {
    if (watchValueGuide === 'Blackbook') {
      let opt = subModelList.find((x) => x.name === watchSubModel)?.style_list || [];
      setStyleList(opt);
    }
  }, [subModelList, watchSubModel]);

  useEffect(async () => {
    if (watchCollateralType === 'new') {
      setValue('lookuptype', 'manual');
      if (watchValueGuide === 'Blackbook') {
        await newBlackbookFn();
      } else if (watchValueGuide === 'Distributor data') {
        await newDistributorDataFn();
      }
    } else if (watchCollateralType === 'used') {
      setValue('lookuptype', '');
      if (watchValueGuide === 'Distributor data') {
        setValue('valueGuide', '');
      }
      await usedBlackBookFn();
    }
  }, [watchCollateralType, watchValueGuide]);

  useEffect(() => {
    setValue('year', '');
    setValue('make', '');
    setValue('model', '');
    setValue('subModel', '');
    setValue('stylee', '');
    setValue('mileage', 0);
    setValue('state', 'PR');
    setValue('vin', '');
    setVinDetail([]);
  }, [watchlookupType, watchValueGuide, watchCollateralType]);

  const getColorsAndOptions = async (uvc = '', uvc_tie_breaker = '') => {
    try {
      const res = await ApiClient.post(
        UrlHelpers.getColorsAndOptions,
        {
          uvc,
          uvc_tie_breaker,
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const colors = JSON.parse(res?.color || '{}')?.vehicle_colors?.color_list || [];
      const options = JSON.parse(res?.option || '{}')?.options?.option_list || [];

      return { colors, options };
    } catch (e) {
      console.log('e', e);
    }
  };

  const getEquipmentAdjustment = async (getType) => {
    try {
      setLoader(true);

      const serviceUrl =
        getType === 'used' ? UrlHelpers.lookUpDrillDownInfo : UrlHelpers.lookUpDrillDownInfoNew;
      const resp = await ApiClient.post(
        serviceUrl,
        {
          year: watchyear,
          make: watchmake,
          model: watchModel,
          series: watchSubModel,
          style: watchStyle,
          state: watchState,
          mileage: watchMileage.toString(),
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      const productDetails = {
        year: watchyear,
        make: watchmake,
        model: watchModel,
        series: watchSubModel,
        style: watchStyle,
        valueGuide: watchValueGuide,
        state: watchState,
        mileage: watchMileage,
        collateralType: getType,
        vin: watchVin,
      };
      let data = [];
      if (getType === 'used') {
        data = resp?.used_vehicles?.used_vehicle_list?.[0]?.add_deduct_list || [];
        setShowSummary(false);
        setPageData({ data, productDetails });
        setShowPage('equipmentAdjustment');
      } else {
        const {
          uvc,
          uvc_tie_breaker = '',
          msrp,
          invoice,
          add_deduct_list = [],
        } = resp?.new_vehicles?.new_vehicle_list?.[0];
        const { colors = [], options = [] } = await getColorsAndOptions(uvc, uvc_tie_breaker);
        setShowSummary(false);
        setPageData({
          colors,
          options,
          addDeductList: add_deduct_list,

          productDetails,
          msrp,
          invoice,
          uvc,
          uvcTieBreaker: uvc_tie_breaker,
        });
        setShowPage('equipmentAdjustmentNew');
      }
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  };
  const onNext = async () => {
    setShowSecondaryPopup(false);

    if (watchValueGuide === 'Distributor data' && watchCollateralType === 'new') {
      const data = distributorData.find((x) => x.subModel === watchSubModel);

      setPageData({ data });
      setShowPage('distributorDataResult');
    } else if (watchCollateralType === 'new' && watchValueGuide === 'Blackbook') {
      getEquipmentAdjustment('new');
    } else {
      getEquipmentAdjustment('used');
    }
    setValue('valueGuide', '');
    setValue('collateralType', '');
    setValue('year', '');
    setValue('make', '');
    setValue('model', '');
    setValue('subModel', '');
    setValue('stylee', '');
    setValue('mileage', 0);
    setValue('state', 'PR');
    setValue('vin', '');
    setVinDetail([]);
    navigate('/lookup', {
      state: {
        coApplicant: coApplicant,
        coSigner: coSigner,
        reviewFlag,
        calculationState,
      },
    });
  };
  return (
    <>
      {loader && <Loader open={loader} />}
      {showMainPopup && <LookupDialog control={control} setValue={setValue} reset={reset} />}
      {showSecondaryPopup && (
        <LookupSecondDialog
          control={control}
          setValue={setValue}
          errors={errors}
          isValid={isValid}
          handleSubmit={handleSubmit}
          onNext={onNext}
          reset={reset}
        />
      )}
      {showInterPopup && (
        <LookupInterDialog
          control={control}
          setValue={setValue}
          errors={errors}
          isValid={isValid}
          handleSubmit={handleSubmit}
          onNext={onNext}
          reset={reset}
        />
      )}
    </>
  );
};

export default LookupCommon;

LookupCommon.propTypes = {
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: false,
  calculationState: PropTypes.string,
};
LookupCommon.defaultProps = {
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
};
