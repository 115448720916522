import { Box, Button, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';

const PageHeading = ({ collateralType, valueGuide, setSelectedRows }) => {
  const { setShowMainPopup, createAppFlag } = useContext(AuthContext);
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="h3">{`${valueGuide} - ${collateralType}`}</Typography>
      <Box display="flex" gap={1}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => {
            setShowMainPopup(true);
            setSelectedRows([]);
          }}
        >
          Lookup Again
        </Button>
        {!createAppFlag && (
          <Button color="secondary" variant="contained">
            Create Application
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default PageHeading;

PageHeading.propTypes = {
  collateralType: PropTypes.string,
  valueGuide: PropTypes.string,
  setSelectedRows: PropTypes.func,
};
PageHeading.defaultProps = {
  collateralType: '',
  valueGuide: '',
  setSelectedRows: () => {},
};
