export const decideNextUser = async ({
  userType,
  setUserType,
  watchCoApplicant,
  watchCoSigner,
  activeStep,
  setActiveStep,
  // setLoader,
  loader,
  reviewFlag,
  completedSteps,
  setCompletedSteps,
}) => {
  console.log('loader', loader);
  console.log('activeStep', activeStep, userType);
  console.log('into decide next user', watchCoApplicant, watchCoSigner);
  const coApplicant = Object.entries(watchCoApplicant);
  if (!reviewFlag) {
    // current user is primary user
    if (!userType && !coApplicant?.length && !watchCoSigner?.length) {
      setCompletedSteps([...completedSteps, activeStep]);
      setActiveStep(activeStep + 1);
      setUserType('');
    } else if (!userType && coApplicant?.length) {
      setUserType('coapplicant');
    } else if (!userType && !coApplicant?.length && watchCoSigner?.length) {
      setUserType('cosigner1');
    }
    // current user is coapplicant
    else if (userType === 'coapplicant' && !watchCoSigner?.length) {
      setCompletedSteps([...completedSteps, activeStep]);
      setActiveStep(activeStep + 1);

      setUserType('');
    } else if (userType === 'coapplicant' && watchCoSigner?.length) {
      setUserType('cosigner1');
    }
    //current user is cosigner
    else if (userType === 'cosigner1' && watchCoSigner?.length === 2) {
      setUserType('cosigner2');
    } else if (
      (userType === 'cosigner1' && watchCoSigner?.length === 1) ||
      userType === 'cosigner2'
    ) {
      setCompletedSteps([...completedSteps, activeStep]);
      setUserType('');
      setActiveStep(activeStep + 1);
    }
  } else {
    setActiveStep(5);
  }
  // setLoader(false);
};

export const userLabel = (userType) => {
  let heading;

  if (userType === '') {
    heading = 'Primary Applicant';
  }
  if (userType === 'coapplicant') {
    heading = 'Co-Applicant';
  } else if (userType === 'cosigner1') {
    heading = 'Co-Signer 1';
  } else if (userType === 'cosigner2') {
    heading = 'Co-Signer 2';
  }
  return heading;
};

//for back and forth logic to be executed and for validating duplicates
export const saveApplicantDetails = ({
  data,
  userType,
  activeStep,
  userDetails,
  setUserDetails,
}) => {
  const userdetailsArray = userDetails;
  console.log('saveapplicant fn', { data, userType, activeStep, userDetails, setUserDetails });
  if (!userdetailsArray.length) {
    const userdetailsObj = {
      userType,
      activeStep,
      data,
    };
    setUserDetails([{ ...userdetailsObj }]);
  } else {
    let findobjIndex = userDetails.findIndex(
      (x) => x.userType === userType && x.activeStep === activeStep
    );
    if (findobjIndex > -1) {
      let replaceObj = {
        userType,
        activeStep,
        data,
      };
      userdetailsArray[findobjIndex] = replaceObj;
    } else {
      userdetailsArray.push({
        userType,
        activeStep,
        data,
      });
    }
    setUserDetails(userdetailsArray);
  }
  console.log('saved details', userdetailsArray);
};

export const validateDuplicates = async ({ data, userDetails, setError, userType }) => {
  let isvalid = true;
  console.log('into validate duplicates', { data, userDetails, setError, userType });
  if (userType && userDetails?.length) {
    const issameName = userDetails.find(
      (x) => x.data.firstName === data?.firstName && x.data.lastName === data?.lastName
    )?.userType;
    console.log('isamename', issameName);
    if (issameName !== undefined && issameName !== userType) {
      setError('firstName', {
        type: 'custom',
        message: `Name cannot be same for ${userLabel(issameName)} and ${userLabel(userType)}`,
      });
      isvalid = false;
    }
    const issameNumber = userDetails.find((x) => x.data.mobile === data?.mobile)?.userType;
    if (issameNumber !== undefined && issameNumber !== userType) {
      setError('mobile', {
        type: 'custom',
        message: `Mobile Number cannot be same for ${userLabel(issameNumber)} and ${userLabel(
          userType
        )}`,
      });
      isvalid = false;
    }
    const issameWorkNumber = userDetails.find((x) => x.data.mobile === data?.workMobile)?.userType;
    if (issameWorkNumber !== undefined && issameWorkNumber !== userType) {
      setError('mobile', {
        type: 'custom',
        message: `Work Mobile Number cannot be same for ${userLabel(
          issameWorkNumber
        )} and ${userLabel(userType)}`,
      });
      isvalid = false;
    }
    console.log('issameNumber', issameNumber);
    const issameEmail = userDetails.find(
      (x) => x.data.email && x.data.email === data?.email
    )?.userType;
    if (issameEmail !== undefined && issameEmail !== userType) {
      setError('email', {
        type: 'custom',
        message: `Email ID cannot be same for ${userLabel(issameEmail)} and ${userLabel(userType)}`,
      });
      isvalid = false;
    }
    console.log('issameEmail', issameEmail);
    // based on issamename return value get in which obj name is repeated and display error msg accordingly.
  }
  console.log('isvalid', isvalid);
  return isvalid;
};

export const applyReviewData = async ({ setValue, reviewData, setCoApplicant, setCoSigner }) => {
  console.log('reviewData', reviewData);
  setValue('firstName', reviewData?.CustName?.FirstName);
  setValue('lastName', reviewData?.CustName?.LastName);
  setValue('middleName', reviewData?.CustName?.MiddleName);
  setValue('relationship', reviewData?.RelationshipWithOwner);
  setValue('secondLastName', reviewData?.CustName?.SecondLastName);
  setValue('dob', reviewData?.DateOfBirth);
  setValue('mobile', reviewData?.Contacts?.Mobile?.replaceAll('-', '')?.slice(3)?.trim());
  setValue(
    'mobile2',
    reviewData?.Contacts?.AlternatePhoneNumber?.replaceAll('-', '')?.slice(3).trim()
  );
  setValue('email', reviewData?.Contacts?.ApplicantEmail);
  let ssn = reviewData?.SSN?.toString();
  if (ssn) {
    ssn = [ssn?.slice(0, 3), ssn?.slice(3, 5), ssn?.slice(5, 9)].join('-');
    setValue('ssn', ssn);
  }

  setValue('maritalStatus', reviewData?.MaritalStatus);

  setValue('resAddress1', reviewData?.Addresses?.[0]?.LineOne);
  setValue('resAddress2', reviewData?.Addresses?.[0]?.LineTwo);
  setValue('resCity', reviewData?.Addresses?.[0]?.AddrCity);
  setValue('resZipcode', reviewData?.Addresses?.[0]?.ZipCode);
  setValue('resUtilityBill', '');

  setValue('perAddress1', reviewData?.Addresses?.[1]?.LineOne);
  setValue('perAddress2', reviewData?.Addresses?.[1]?.LineTwo);
  setValue('perCity', reviewData?.Addresses?.[1]?.AddrCity);
  setValue('perZipcode', reviewData?.Addresses?.[1]?.ZipCode);

  setValue('residentStatus', reviewData?.ResidentialStatus);
  setValue('monthlyPayment', reviewData?.MonthlyPayment);
  setValue('residentYears', reviewData?.TimeOfResidenceInYears);
  setValue('residentMonths', reviewData?.TimeOfResidenceInMonths);
  if (reviewData?.CoApplicants?.length) {
    let coapplicantData = reviewData?.CoApplicants[0]?.InformationOnRequest?.CustName;
    coapplicantData = {
      CoAppFirstName: coapplicantData?.FirstName,
      CoAppLastName: coapplicantData?.LastName,
    };
    setCoApplicant(coapplicantData);
    setValue('coApplicant', coapplicantData);
    console.log('coApplicant', coapplicantData);
  }
  if (reviewData?.CoSigners?.length) {
    console.log('reviewData?.CoSigners', reviewData?.CoSigners);
    const cosignerData = reviewData?.CoSigners?.map((x, index) => {
      return {
        CoSignerNumber: index + 1,
        CoSignerName: {
          CoSignerFirstName: x?.InformationOnRequest?.CustName?.FirstName,
          CoSignerLastName: x?.InformationOnRequest?.CustName?.LastName,
        },
      };
    });
    console.log('cosignerData', cosignerData);
    setCoSigner(cosignerData);
    setValue('cosigner', cosignerData);
  }
};

export const applyReviewDataStep2 = async ({ setValue, reviewData }) => {
  setValue('empStatus', reviewData?.EmploymentInformationDetail?.EmploymentStatus);
  setValue('empName', reviewData?.EmploymentInformationDetail?.Employer);
  setValue('empAddressLine1', reviewData?.EmploymentInformationDetail?.Address?.LineOne);
  setValue('empAddressLine2', reviewData?.EmploymentInformationDetail?.Address?.LineTwo);
  setValue('empCity', reviewData?.EmploymentInformationDetail?.Address?.AddrCity);
  setValue('empZipcode', reviewData?.EmploymentInformationDetail?.Address?.ZipCode);
  setValue('jobTitle', reviewData?.EmploymentInformationDetail?.JobTitle);
  setValue('occupation', reviewData?.EmploymentInformationDetail?.Occupation);
  setValue('industry', reviewData?.EmploymentInformationDetail?.Industry);
  setValue('empYears', reviewData?.EmploymentInformationDetail?.EmploymentTimePeriodYear);
  setValue('empMonths', reviewData?.EmploymentInformationDetail?.EmploymentTimePeriodMonth);

  setValue(
    'mobile',
    reviewData?.EmploymentInformationDetail?.WorkPhone.replaceAll('-', '')?.slice(3).trim()
  );
  setValue('monthlyIncome', reviewData?.EmploymentInformationDetail?.GrossMonthlyIncome);
  // setValue('empMonths',reviewData?.EmploymentInformationDetail?.IncomeFrequency)
  // setValue('empMonths',reviewData?.EmploymentInformationDetail?.IncomeType)

  setValue('addEmpName', reviewData?.AdditionalIncomeDetail?.AddlEmployerName);
  setValue('addJobTitle', reviewData?.AdditionalIncomeDetail?.AddlJobTitle);
  setValue('addOccupation', reviewData?.AdditionalIncomeDetail?.AddlOccupation);
  setValue(
    'mobile2',
    reviewData?.AdditionalIncomeDetail?.AddlWorkPhone.replaceAll('-', '')?.slice(3).trim()
  );
  setValue('addMonthlyIncome', reviewData?.AdditionalIncomeDetail?.AddlGrossMonthlyIncome);
  // setValue('empYears', reviewData?.AdditionalIncomeDetail?.AddlIncomeFrequency);
  // setValue('empMonths', reviewData?.AdditionalIncomeDetail?.AddlIncomeType);
};

export const checkDeletion = async ({ oldData, newData, userDetails, setUserDetails }) => {
  console.log('userDetails deletion func', userDetails);
  let deleteObj = {};
  console.log('check deletion fn', { oldData, newData });
  if (
    Object.entries(oldData?.coApplicant)?.length > 0 &&
    Object.entries(newData?.coApplicant)?.length === 0
  ) {
    console.log('into coapplicant del', deleteObj);
    deleteObj = { ...deleteObj, DeleteCoApplicants: [1] };
  }
  if (newData?.coSigner?.length < oldData?.coSigner?.length) {
    console.log('into cosigner del', deleteObj);
    let deletecosignerArr = oldData?.coSigner?.filter(
      (x) =>
        !newData.coSigner?.some(
          (each) =>
            each?.CoSignerName?.CoSignerFirstName === x.CoSignerName?.CoSignerFirstName &&
            each?.CoSignerName?.CoSignerLastName === x.CoSignerName?.CoSignerLastName
        )
    );
    deletecosignerArr = deletecosignerArr.map((x) => x?.CoSignerNumber);
    deleteObj = { ...deleteObj, DeleteCoSigners: deletecosignerArr };
  }
  console.log('delete obj', deleteObj);
  let userDetailsCopy = userDetails;
  let changeEl, changeElIndex;

  if (deleteObj?.DeleteCoSigners?.includes(1)) {
    userDetailsCopy = userDetailsCopy?.filter((x) => x?.userType !== 'cosigner1');
    changeElIndex = userDetailsCopy?.findIndex((x) => x?.userType === 'cosigner2');
    if (changeElIndex > -1) {
      changeEl = userDetailsCopy?.find((x) => x?.userType === 'cosigner2');
      changeEl.userType = 'cosigner1';

      userDetailsCopy[changeElIndex] = { ...changeEl };
    }
  }
  if (deleteObj?.DeleteCoSigners?.includes(2)) {
    userDetailsCopy = userDetailsCopy?.filter((x) => x?.userType !== 'cosigner2');
  }
  if (deleteObj?.DeleteCoApplicants?.length) {
    userDetailsCopy = userDetailsCopy?.filter((x) => x?.userType !== 'coapplicant');
  }
  console.log('userdetailscopy', userDetailsCopy);
  setUserDetails(userDetailsCopy);

  return deleteObj;
};

export const decidePrevious = async ({
  userType,
  setUserType,
  activeStep,
  setActiveStep,
  coApplicant,
  coSigner,
  setBackFlag,
}) => {
  // Step 1 backwards

  if (userType === 'cosigner2') {
    setUserType('cosigner1');
  } else if (userType === 'cosigner1' && Object.entries(coApplicant)?.length) {
    setUserType('coapplicant');
  } else if (
    (userType === 'cosigner1' && !Object.entries(coApplicant)?.length) ||
    userType === 'coapplicant'
  ) {
    setUserType('');
    setBackFlag(true);
  } else if (!userType) {
    setBackFlag(true);
    setActiveStep(activeStep - 1);
    if (coSigner?.length === 2) {
      setUserType('cosigner2');
    } else if (coSigner?.length === 1) {
      setUserType('cosigner1');
    } else if (Object.entries(coApplicant)?.length && !coSigner?.length) {
      setUserType('coapplicant');
    }
  }
};
