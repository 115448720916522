/* eslint-disable react/jsx-props-no-spreading */
import { Box, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import classes from '../../styles/globalStyle.module.scss';

const CustomFileUpload = (props) => {
  const { control, label, name, register } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <Box display="flex" alignItems="center" gap={2} my={0.5} px={2} sx={{ width: '60%' }}>
          <InputLabel htmlFor={name}>{label}</InputLabel>

          {/* // eslint-disable-next-line react/jsx-props-no-spreading */}
          <input
            type="file"
            className={classes.customUpload}
            id={name}
            name={name}
            data-testid={name}
            {...register(name)}
          />
        </Box>
      )}
    />
  );
};

export default CustomFileUpload;

CustomFileUpload.propTypes = {
  control: PropTypes.instanceOf(Object),
  classes: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  register: PropTypes.func,
};
CustomFileUpload.defaultProps = {
  control: {},
  classes: '',
  name: '',
  label: '',
  error: false,
  isDisabled: false,
  errorText: '',
  register: () => {},
};
