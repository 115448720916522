import { Box, Button, Paper, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from 'styles/globalStyle.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { numberToCurrency } from 'utils/utility';

import Loader from 'components/Loader/Loader';

import GeneralInfo from '../components/GeneralInfo';
import PageHeading from '../components/PageHeading';

const DistributorDataResult = ({
  data,
  valueGuide,
  collateralType,
  coApplicant,
  coSigner,
  reviewFlag,
  calculationState,
}) => {
  const {
    createAppFlag,
    setCollateralInfo,
    setLookupSaved,
    createAppApplicationId,
    collateralInfo,
    lookupLinkClicked,
    setLookupValue,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const ApiClient = new ApiClass();
  const [loader, setLoader] = useState(false);
  const productDetails = { ...data, valueGuide, collateralType };
  const { dealerCost = 0, msrp = 0 } = data || {};

  useEffect(() => {
    const payload = {
      vehicleValues: {
        basevehicleValue: { msrp, dealerCost },
        equipmentAdjustment: '',
        mileageAdjustment: '',
        selectedEquipmentAjustment: [],
        totalMSRP: msrp,
        totalInvoice: dealerCost,
      },
      productDetails: productDetails,
    };
    if (!createAppFlag && !lookupLinkClicked) {
      setLookupValue([{ ...payload }]);
    }
  }, [data]);

  const onSaveValue = async () => {
    const payload = {
      vehicleValues: {
        basevehicleValue: { msrp, dealerCost },
        equipmentAdjustment: '',
        mileageAdjustment: '',
        selectedEquipmentAjustment: [],
        totalMSRP: msrp,
        totalInvoice: dealerCost,
      },
      productDetails: productDetails,
    };

    if (!lookupLinkClicked) {
      setCollateralInfo({ ...payload });
      try {
        setLoader(true);
        const resp = await ApiClient.post(
          UrlHelpers.saveLookupValue,
          {
            applicationId: createAppApplicationId,
            type: 'vehicle',
            searchResult: JSON.stringify({ ...payload }),
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        console.log(resp);
      } catch (e) {
        console.log('e', e);
      } finally {
        setLoader(false);
      }
    }
    setLookupSaved(true);
    navigate('/home/create-app', {
      state: {
        activeStep: 3,
        completedSteps: [1, 2],
        coApplicant,
        coSigner,
        reviewFlag,
        calculationState,
      },
    });
  };

  return (
    <Paper sx={{ minHeight: '80vh', padding: '20px' }}>
      {loader && <Loader open={loader} />}
      <PageHeading collateralType="New" valueGuide="Distributor Data" />
      <Typography variant="h6" fontWeight="700" sx={{ margin: '16px 0' }}>
        Vehicle Values
      </Typography>
      <Box className={classes.equipAdjustment} sx={{ gap: { md: '16px' } }}>
        <Box display="flex" flexDirection="column" gap={2} flexGrow="1">
          <Box display="flex" flexDirection="column" className={classes.tableOutline}>
            <Box display="flex" gap={2} p={2} sx={{ backgroundColor: '#F2F2F2' }}>
              <Typography
                variant="subtitle2"
                sx={{ minWidth: '50%', fontWeight: '700', marginLeft: '35px' }}
              ></Typography>

              <Typography variant="subtitle2" fontWeight="700" minWidth="25%">
                Dealer Cost
              </Typography>

              <Typography variant="subtitle2" fontWeight="700" minWidth="25%">
                MSRP
              </Typography>
            </Box>
            <Box display="flex" gap={2} p={2} sx={{ borderBottom: '1px solid #f2f2f2' }}>
              <Typography variant="subtitle2" sx={{ minWidth: '50%', marginLeft: '35px' }}>
                Base Vehicle value
              </Typography>

              <Typography variant="subtitle2" minWidth="25%">
                {numberToCurrency(
                  parseInt(
                    (lookupLinkClicked
                      ? collateralInfo?.vehicleValues?.basevehicleValue?.dealerCost
                      : dealerCost) || 0
                  )?.toFixed?.(2)
                )}
              </Typography>

              <Typography variant="subtitle2" minWidth="25%">
                {numberToCurrency(
                  parseInt(
                    (lookupLinkClicked
                      ? collateralInfo?.vehicleValues?.basevehicleValue?.msrp
                      : msrp) || 0
                  )?.toFixed?.(2)
                )}
              </Typography>
            </Box>
            {/* <Box display="flex" gap={2} p={2}>
              <Typography variant="subtitle2" sx={{ minWidth: '50%', marginLeft: '35px' }}>
                Equipment Adjustment
              </Typography>

              <Typography variant="subtitle2" minWidth="25%">
                $0.00
              </Typography>

              <Typography variant="subtitle2" minWidth="25%">
                $0.00
              </Typography>
            </Box>
            <Box display="flex" gap={2} p={2} sx={{ backgroundColor: '#F2F2F2' }}>
              <Typography
                variant="subtitle2"
                sx={{ minWidth: '50%', marginLeft: '35px', fontWeight: 700 }}
              >
                Total Values
              </Typography>

              <Typography variant="subtitle2" fontWeight="700" minWidth="25%">
                {numberToCurrency(parseInt(dealerCost || 0)?.toFixed?.(2))}
              </Typography>

              <Typography variant="subtitle2" fontWeight="700" minWidth="25%">
                {numberToCurrency(parseInt(msrp || 0)?.toFixed?.(2))}
              </Typography>
            </Box> */}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Box>
              {createAppFlag && (
                <Button onClick={onSaveValue} variant="contained" color="secondary">
                  Save Value
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2.5}
          className={classes.tableOutline}
          maxHeight="450px"
          minWidth="25%"
          backgroundColor="#f2f2f2"
          p={2.5}
        >
          <GeneralInfo
            productDetails={lookupLinkClicked ? collateralInfo?.productDetails : productDetails}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default DistributorDataResult;

DistributorDataResult.propTypes = {
  data: PropTypes.instanceOf(Object),
  valueGuide: PropTypes.string,
  collateralType: PropTypes.string,
  setShowPage: PropTypes.func,
  coApplicant: PropTypes.instanceOf(Object),
  coSigner: PropTypes.instanceOf(Object),
  reviewFlag: PropTypes.bool,
  calculationState: PropTypes.string,
};
DistributorDataResult.defaultProps = {
  data: {},
  valueGuide: '',
  collateralType: '',
  setShowPage: () => {},
  coApplicant: {},
  coSigner: [],
  reviewFlag: false,
  calculationState: '',
};
