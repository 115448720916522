/* eslint-disable no-irregular-whitespace */
import { yupResolver } from '@hookform/resolvers/yup';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Alert, Box, Button, Grid, Link, Typography } from '@mui/material';
import ApiClass from 'Api/ApiClient';
import { emailValidation, getAccessToken, phoneValidation } from 'Services/commonComponentsApi';
import IdentityImage from 'assets/svg/Identity.svg';
import { fetchAddressLookup } from 'commonComponents/commonComponents';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import debounceLodash from 'lodash.debounce';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  FormatValidationSchema,
  InformationRequestSchema,
} from 'schemaValidations/InformationRequestSchema';
import customClasses from 'styles/InformationRequest.module.scss';
import { UrlHelpers } from 'urlHelpers/urlHelper';
import { currencyToNumber } from 'utils';

import CameraUpload from 'components/CameraUpload/CameraUpload';
import FileUploadDialog from 'components/FileUploadDialog/FileUploadDialog';
import Loader from 'components/Loader/Loader';

import AddCoUsers from './AddCoUsers/AddCoUsers';
import BasicInformation from './BasicInformation/BasicInformation';
import MailingAddress from './MailingAddress/MailingAddress';
import ResidentStatus from './ResidentStatus/ResidentStatus';
import ResidentialAddress from './ResidentialAddress/ResidentialAddress';
import ApplicationPageHeading from './moduleUtilities/ApplicationPageHeading';
import {
  frameInfoReqPayload,
  getSpecificPageDetailsStepOne,
} from './moduleUtilities/InfoReqPayload';
import RelationWithOwner from './moduleUtilities/RelationWithOwner';
import { step1DropDownApis } from './moduleUtilities/dropdownApis';
import {
  applyReviewData,
  checkDeletion,
  decideNextUser,
  decidePrevious,
  saveApplicantDetails,
  validateDuplicates,
} from './moduleUtilities/moduleUtilities';

const InformationRequest = () => {
  const {
    commonToken,
    setCommonToken,
    cityList,
    setCityList,
    residentStatusList,
    setResidentStatusList,
    relationshipList,
    setRelationShipList,
    maritalStatusList,
    setMaritalStatusList,
    utilityBillList,
    setUtilityBillList,
  } = useContext(AuthContext);
  const {
    scannedData = {},
    coApplicant = {},
    coSigner = [],
    setCoApplicant,
    setCoSigner,
    userType,
    setUserType,
    activeStep,
    setActiveStep,
    setVouchedData,
    userDetails,
    setUserDetails,
    setFormLoader,
    backFlag,
    setBackFlag,
    reviewFlag,
    reviewData,
    completedSteps,
    setCompletedSteps,
    vouchedReturnFlag,
    setVouchedReturnFlag,
    setOpenDraftModal,
    formFields,
  } = useContext(MainApplicationContext);

  const [showCamera, setShowCamera] = useState(false);
  const [showIDCamera, setShowIDCamera] = useState(false);

  const [file, setFile] = useState('');
  const [ocrCameraFile, setOcrCameraFile] = useState('');
  const [openFileUpload, setOpenFileUpload] = useState(false);

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const ApiClient = new ApiClass();

  const [idVerificationStatus, setIdVerificationStatus] = useState(false);
  const [verifyAlert, setVerifyAlert] = useState(false);
  const [draftAlert, setDraftAlert] = useState(false);
  const [addressLookupData, setAddressLookupData] = useState([]);
  const [addressLookupDataResponse, setAddressLookupDataResponse] = useState([]);
  const [mailAddressLookupData, setMailAddressLookupData] = useState([]);
  const [mailAddressLookupDataResponse, setMailAddressLookupDataResponse] = useState([]);
  const [showUtility, setShowUtility] = useState(false);
  const [coApplicantFlag, setCoApplicantFlag] = useState(false);
  const [coSignerFlag, setCoSignerFlag] = useState(false);
  const [jobId, setJobId] = useState('');

  const [vouchedStatus, setVouchedStatus] = useState('init');
  const [ocrDocumentId, setOcrDocumentId] = useState('');
  const [isMailResidentSame, setIsMailResidentSame] = useState(true);

  const [isResidentAddressOpen, setIsResidentAddressOpen] = useState(false);
  const { createAppApplicationId } = useContext(AuthContext);
  const inputRef = useRef(null);

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isDirty, dirtyFields },

    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      manualFile: '',
      relationship: '',
      firstName: '',
      middleName: '',
      lastName: '',
      secondLastName: '',
      // gender: '',
      dob: '',
      mobile: '',
      areaCode: '787',
      mobile2: '',
      areaCode2: '787',
      ssn: '',
      email: '',
      maritalStatus: '',
      // numberOfDependents: '',
      isAddrSame: true,
      resAddress1: '',
      resAddress2: '',
      resCountry: 'USA',
      resState: 'PR',
      resCity: '',
      resZipcode: '',
      resUtilityBill: '',
      utilitybillfile: null,
      resProofCam: '',
      resProofFile: '',
      coApplicant: {},
      coSigner: [],
      perAddress1: '',
      perAddress2: '',
      perCountry: 'USA',
      perState: 'PR',
      perCity: '',
      perZipcode: '',
      perUtilityBill: '',
      perProofCam: '',
      perProofFile: '',
      residentStatus: '',
      monthlyPayment: '',
      residentYears: '',
      residentMonths: '',
    },
    resolver: yupResolver(
      InformationRequestSchema(showUtility, coApplicantFlag, coSignerFlag, userType)
    ),
  });

  console.log('isDirty', isDirty);

  const [
    watchFirstName,
    watchLastName,
    watchresAddress1,
    watchresAddress2,
    watchresState,
    watchresCity,
    watchresZipcode,
    watchperAddress1,
    watchperAddress2,
    watchperState,
    watchperCity,
    watchperZipcode,
    watchEmail,
    watchmobile1,
    watchAreaCode1,
    watchmobile2,
    watchAreaCode2,
    watchmanualFile,
    watchCoApplicant,
    watchCoSigner,
    watchRelationship,
    watchssn,
  ] = watch([
    'firstName',
    'lastName',
    'resAddress1',
    'resAddress2',
    'resState',
    'resCity',
    'resZipcode',
    'perAddress1',
    'perAddress2',
    'perState',
    'perCity',
    'perZipcode',
    'email',
    'mobile',
    'areaCode',
    'mobile2',
    'areaCode2',
    'manualFile',

    'coApplicant',
    'coSigner',
    'relationship',
    'ssn',
  ]);
  const isdraftDisabled = Object.keys(dirtyFields)?.length === 0 && !watchssn && !watchFirstName;
  const watchAll = watch();
  const resetForm = ({ firstName = '', lastName = '' }) => {
    if (!reviewFlag) {
      console.log('into reset');
      setShowCamera(false);
      setShowIDCamera(false);
      setFile('');
      setOcrCameraFile('');
      setOpenFileUpload(false);
      setIdVerificationStatus(false);
      setVerifyAlert(false);
      setAddressLookupData([]);
      setAddressLookupDataResponse([]);
      setShowUtility(false);
      setCoApplicantFlag(false);
      setCoSignerFlag(false);
      setVouchedStatus('init');
      setIsMailResidentSame(true);
      reset({
        manualFile: '',
        relationship: '',
        firstName,
        lastName,
        middleName: '',
        secondLastName: '',
        dob: '',
        mobile: '',
        areaCode: '787',
        mobile2: '',
        areaCode2: '787',
        ssn: '',
        email: '',
        maritalStatus: '',
        isAddrSame: true,
        resAddress1: '',
        resAddress2: '',
        resCountry: 'USA',
        resState: 'PR',
        resCity: '',
        resZipcode: '',
        resUtilityBill: '',
        utilitybillfile: null,
        resProofCam: '',
        resProofFile: '',
        coApplicant: {},
        coSigner: [],
        perAddress1: '',
        perAddress2: '',
        perCountry: 'USA',
        perState: 'PR',
        perCity: '',
        perZipcode: '',
        perUtilityBill: '',
        perProofCam: '',
        perProofFile: '',
        residentStatus: '',
        monthlyPayment: '',
        residentYears: '',
        residentMonths: '',
      });
      setValue('dob', null);

      inputRef?.current?.focus();
    }
  };

  useEffect(() => {
    setIdVerificationStatus(reviewData?.VouchedIdStatus);
    console.log('reviewData', reviewData);
    applyReviewData({ setValue, reviewData, setCoApplicant, setCoSigner });
    console.log('dob1', getValues('dob'));
  }, [reviewFlag, reviewData]);

  useEffect(async () => {
    console.log('vouchedReturnFlag', vouchedReturnFlag);
    if (!vouchedReturnFlag && (userType || backFlag)) {
      console.log('userType', userType);
      setVerifyAlert(false);
      await getSpecificPageDetailsStepOne({
        userType,
        activeStep,
        setValue,
        setCoApplicant,
        setCoSigner,
        createAppApplicationId,
        setLoader,
        setIdVerificationStatus,
        callback: resetForm,
      });
      setShowUtility(false);
      clearErrors(['relationship', 'manualFile']);
      // if (userType === 'coapplicant' && Object.entries(coApplicant)) {
      //   setValue('firstName', coApplicant?.CoAppFirstName);
      //   setValue('lastName', coApplicant?.CoAppLastName);
      // } else if (userType === 'cosigner1' && coSigner?.length) {
      //   setValue('firstName', coSigner?.[0]?.CoSignerName?.CoSignerFirstName);
      //   setValue('lastName', coSigner?.[0]?.CoSignerName?.CoSignerLastName);
      // } else if (userType === 'cosigner2' && coSigner?.length) {
      //   setValue('firstName', coSigner?.[1]?.CoSignerName?.CoSignerFirstName);
      //   setValue('lastName', coSigner?.[1]?.CoSignerName?.CoSignerLastName);
      // }
    }

    if (
      getValues('resAddress1') !== getValues('perAddress1') ||
      getValues('resAddress2') !== getValues('perAddress2') ||
      getValues('resState') !== getValues('perState') ||
      getValues('resCity') !== getValues('perCity') ||
      getValues('resZipcode') !== getValues('perZipcode')
    ) {
      setIsMailResidentSame(false);
    } else {
      setIsMailResidentSame(true);
    }
  }, [userType, vouchedReturnFlag]);

  useEffect(() => {
    if (watchCoSigner.length) {
      setCoSignerFlag(true);
    } else {
      setCoSignerFlag(false);
    }
  }, [watchCoSigner]);
  useEffect(async () => {
    if (!commonToken) {
      const token = await getAccessToken();

      setCommonToken(token);
    }
    await step1DropDownApis({
      cityList,
      setCityList,
      residentStatusList,
      setResidentStatusList,
      maritalStatusList,
      setMaritalStatusList,
      relationshipList,
      setRelationShipList,
      utilityBillList,
      setUtilityBillList,
    });
  }, []);

  useEffect(() => {
    setValue('coApplicant', coApplicant);
    setValue('coSigner', coSigner);
  }, [coApplicant, coSigner]);

  useEffect(() => {
    if (vouchedReturnFlag) {
      applyFormFields(formFields);
    }
    if (scannedData && scannedData?.success) {
      setIdVerificationStatus(true);
      setVouchedStatus('success');
      setShowUtility(false);
      setVerifyAlert(true);
      const {
        firstName,
        middleName,
        lastName,
        // gender,
        dob,
        birthDate,
        unverifiedIdAddress,
        idAddress,
        jobId,
      } = scannedData;
      setJobId(jobId);
      setValue('firstName', firstName);
      setValue('middleName', middleName);
      setValue('lastName', lastName);
      setValue('resUtilityBill', '');
      // if (gender.gender === 'woman') setValue('gender', 'female');
      // else if (gender.gender === 'man') setValue('gender', 'male');
      setValue('dob', dob ? dob : birthDate);
      clearErrors('utilitybillfile');
      if (unverifiedIdAddress?.[0]?.trim()) {
        console.log('isResidentAddressOpen set state');
        setIsResidentAddressOpen(true);
      }
      setValue('resAddress1', unverifiedIdAddress?.[0]?.trim());
      if (scannedData?.unverifiedIdAddress?.length > 2) {
        if (scannedData?.unverifiedIdAddress?.[1]?.length > 20) {
          setError('resAddress2', {
            type: 'custom',
            message: 'Address line 2 should be 20 characters or less',
          });
          setError('perAddress2', {
            type: 'custom',
            message: 'Address line 2 should be 20 characters or less',
          });
        }
      }
      if (unverifiedIdAddress?.length > 2) {
        // if address line 2 present
        setValue('resAddress2', unverifiedIdAddress?.[1]?.trim());

        let city = unverifiedIdAddress[2]?.split(',')?.[0]?.trim();
        city = cityList?.find((x) => x.value?.toLowerCase() === city?.toLowerCase())?.value;

        setValue('resCity', city);
        setValue(
          'resZipcode',
          unverifiedIdAddress[2]?.split(', ')?.[1]?.split(' ')[1]?.split('-')?.[0]?.trim()
        );
      } else if (unverifiedIdAddress?.length === 2) {
        // if address line 2 not present
        setValue(
          'resZipcode',
          unverifiedIdAddress[1]?.split(', ')?.[1]?.split(' ')[1]?.split('-')?.[0]?.trim()
        );
        let city = unverifiedIdAddress[1]?.split(',')?.[0]?.trim();
        city = cityList?.find((x) => x.value?.toLowerCase() === city?.toLowerCase())?.value;
        setValue('resCity', city);
      }

      if (idAddress && idAddress.postalCode) {
        setValue('resZipcode', idAddress.postalCode?.trim());
      }
    } else if (Object.entries(scannedData).length && !scannedData?.success) {
      setVouchedStatus('failed');
    }
  }, [scannedData, vouchedReturnFlag]);

  useEffect(() => {
    setValue('isAddrSame', isMailResidentSame);

    if (isMailResidentSame) {
      setValue('perAddress1', getValues('resAddress1'));
      setValue('perAddress2', getValues('resAddress2'));
      setValue('perCountry', getValues('resCountry'));
      setValue('perState', getValues('resState'));
      setValue('perCity', getValues('resCity'));
      setValue('perZipcode', getValues('resZipcode'));
      console.log('error-info', errors);
    }
    if (isMailResidentSame) {
      clearErrors([
        'perAddress2',
        'perAddress1',
        'perCountry',
        'perState',
        'perCity',
        'perZipcode',
      ]);
    }
    if (isMailResidentSame && getValues('resAddress1')?.length > 40) {
      setError('resAddress1', {
        type: 'custom',
        message: 'Address line 1 should be 40 characters or less',
      });
      clearErrors('perAddress1');
    } else if (isMailResidentSame && getValues('resAddress1')?.length <= 40) {
      clearErrors(['perAddress1', 'resAddress1']);
    }
    if (isMailResidentSame && getValues('resAddress2')?.length > 20) {
      setError('resAddress2', {
        type: 'custom',
        message: 'Address line 2 should be 20 characters or less',
      });
      clearErrors('perAddress2');
    } else if (isMailResidentSame && getValues('resAddress2')?.length <= 20) {
      clearErrors(['perAddress2', 'resAddress2']);
    }
    if (!isMailResidentSame && getValues('perAddress1')?.length > 40) {
      setError('perAddress1', {
        type: 'custom',
        message: 'Address line 1 should be 40 characters or less',
      });
    } else if (!isMailResidentSame && getValues('perAddress1')?.length <= 40) {
      clearErrors('perAddress1');
    }
    if (!isMailResidentSame && getValues('perAddress2')?.length > 20) {
      setError('perAddress2', {
        type: 'custom',
        message: 'Address line 2 should be 20 characters or less',
      });
    } else if (!isMailResidentSame && getValues('perAddress2')?.length <= 20) {
      clearErrors('perAddress2');
    }
  }, [
    isMailResidentSame,
    getValues('resAddress1'),
    getValues('resAddress2'),
    getValues('resCountry'),
    getValues('resState'),
    getValues('resCity'),
    getValues('resZipcode'),
  ]);

  const debounceFunc5 = debounceLodash((address1, country, query5, commonTokenn, addressType) => {
    fetchAddressLookup({
      address1,
      country,
      query5,
      commonTokenn,
      addressType,
      setAddressLookupData,
      setAddressLookupDataResponse,
      setMailAddressLookupData,
      setMailAddressLookupDataResponse,
    });
  }, 500);

  const fetchAddress5 = useCallback(debounceFunc5, []);

  useEffect(() => {
    if (
      dirtyFields?.resAddress1 ||
      dirtyFields?.resAddress2 ||
      dirtyFields?.resCity ||
      dirtyFields?.resZipcode
    ) {
      setShowUtility(true);
    } else {
      setShowUtility(false);
    }
  }, [watchresAddress1, watchresAddress2, watchresCity, watchresZipcode]);

  useEffect(() => {
    if (dirtyFields?.resAddress1 && commonToken) {
      let query5 = watchresAddress1;

      if (watchresAddress2) {
        query5 += ` ${watchresAddress2} `;
      }
      if (watchresState) {
        query5 += ` ${watchresState} `;
      }
      if (watchresCity) {
        query5 += ` ${watchresCity} `;
      }
      if (watchresZipcode) {
        query5 += ` ${watchresZipcode} `;
      }
      fetchAddress5(watchresAddress1, 'USA', query5, commonToken, 'res');
    }
  }, [watchresAddress1]);

  useEffect(() => {
    if (watchperAddress1 && commonToken && !isMailResidentSame) {
      let query5 = watchperAddress1;

      if (watchperAddress2) {
        query5 += ` ${watchperAddress2} `;
      }
      if (watchperState) {
        query5 += ` ${watchperState} `;
      }
      if (watchperCity) {
        query5 += ` ${watchperCity} `;
      }
      if (watchperZipcode) {
        query5 += ` ${watchperZipcode} `;
      }
      fetchAddress5(watchperAddress1, 'USA', query5, commonToken, 'mail');
    }
  }, [watchperAddress1]);

  useEffect(() => {
    console.log('intooooooooo', coApplicant, coSigner);
    setValue('coApplicant', coApplicant);
    setValue('coSigner', coSigner);
  }, [userType, vouchedReturnFlag]);

  const applyFormFields = async (formFieldss) => {
    console.log('formFields', formFieldss);
    Object.entries(formFieldss).forEach(([key, value]) => {
      setValue(key, value);
    });
  };

  useEffect(() => {
    if (!watchmanualFile && !idVerificationStatus && isDirty && !reviewFlag) {
      setError('manualFile', { type: 'manual', message: 'Please upload a file to proceed' });
    } else {
      clearErrors('manualFile');
    }
  }, [watchmanualFile, idVerificationStatus, isDirty]);

  const validateForm = async () => {
    const validWithoutDuplicates = await validateDuplicates({
      data: {
        firstName: watchFirstName,
        lastName: watchLastName,
        mobile: watchmobile1,
        email: watchEmail,
      },
      userDetails,
      setError,
      userType,
    });
    let validFlag1 = false;
    let validFlag2 = false;
    if (validWithoutDuplicates) {
      validFlag1 = await emailValidation(watchEmail, commonToken, setError, setLoader);
      validFlag2 = await phoneValidation(
        watchAreaCode1,
        watchmobile1,
        watchAreaCode2,
        watchmobile2,
        commonToken,
        setError,
        setLoader
      );
    }

    return !!(validFlag1 && validFlag2 && validWithoutDuplicates);
  };

  const draftClick = async () => {
    console.log('into draft click');
    const formValue = getValues();
    let validFlag1 = true;
    let validFlag2 = true;
    let isValid = false;

    try {
      isValid = FormatValidationSchema.isValidSync(formValue);
      if (watchssn && watchssn?.length !== 11) {
        trigger('ssn');
      } else {
        clearErrors('ssn');
      }
      FormatValidationSchema.validateSync(formValue);
    } catch (err) {
      console.log('err in', err);
    }
    if (!userType && (!watchFirstName || !watchLastName)) {
      setDraftAlert(true);
      isValid = false;
    } else {
      setDraftAlert(false);
    }

    if (watchEmail && isValid)
      validFlag1 = await emailValidation(watchEmail, commonToken, setError, setLoader);
    if (watchmobile1 && isValid) {
      validFlag2 = await phoneValidation(
        watchAreaCode1,
        watchmobile1,
        watchAreaCode2,
        watchmobile2,
        commonToken,
        setError,
        setLoader
      );
    }
    console.log('isValid', isValid);
    if (isValid && validFlag1 && validFlag2) {
      clearErrors();
      await handleInfoSubmit(watchAll, { draft: true });
      console.log('call draft api');
    }
  };
  const onBackClick = async () => {
    setVouchedReturnFlag(false);
    await decidePrevious({
      userType,
      setUserType,
      activeStep,
      setActiveStep,
      coApplicant,
      coSigner,
      setBackFlag,
    });
  };
  const handleInfoSubmit = async (data, { draft = false }) => {
    console.log('data', { data, draft });
    let deletePayload = {};
    let submitFlag = false;

    if (!Object.entries(errors).length && !draft) {
      submitFlag = await validateForm();
    }
    // if (!watchmanualFile && !idVerificationStatus && !draft && !reviewFlag) {
    //   setError('manualFile', { type: 'manual', message: 'Please upload a file to proceed' });
    //   submitFlag = false;
    // }
    if (submitFlag || draft) {
      setDraftAlert(false);
      setBackFlag(false);
      setFormLoader(true);
      const decideCoUsers = { coApplicant, coSigner };
      const { coApplicant: coApplicantData = {}, coSigner: coSignerData = [] } = data;
      if (!userType) {
        console.log(
          'into primaryapplicant - adding cosigner and coapplicant data',
          coApplicantData,
          coSignerData
        );
        deletePayload = await checkDeletion({
          oldData: { coApplicant: coApplicant, coSigner: coSigner },
          newData: { coApplicant: coApplicantData, coSigner: coSignerData },
          userDetails,
          setUserDetails,
        });
        decideCoUsers.coApplicant = coApplicantData;
        decideCoUsers.coSigner = coSignerData;
        setCoApplicant(coApplicantData);
        setCoSigner(coSignerData);
      }
      console.log('delete payload', deletePayload);
      const {
        firstName,
        lastName,
        middleName,
        secondLastName,
        // gender,
        dob,
        mobile,
        areaCode,
        mobile2,
        areaCode2,
        ssn,
        email,
        maritalStatus,
        // numberOfDependents,
        resAddress1,
        resAddress2,
        resCountry,
        resState,
        resCity,
        resZipcode,
        resUtilityBill,
        perAddress1,
        perAddress2,
        perCountry,
        perState,
        perCity,
        perZipcode,
        residentStatus,
        monthlyPayment,
        residentYears,
        residentMonths,
      } = data;

      const formData = {
        InformationOnRequest: {
          CustName: {
            FirstName: firstName,
            MiddleName: middleName,
            LastName: lastName,
            SecondLastName: secondLastName,
          },
          // Gender: gender,
          Gender: 'Male',
          DateOfBirth: moment(dob).format('MM/DD/YYYY'),
          SSN: ssn?.replaceAll('-', ''),
          Contacts: {
            Mobile: mobile ? `${areaCode}${mobile}` : '',
            AlternatePhoneNumber: mobile2 ? `${areaCode2}${mobile2}` : '',
            ApplicantEmail: email,
          },
          MaritalStatus: maritalStatus,
          Addresses: [
            {
              AddrType: 'Home address',
              LineOne: resAddress1,
              LineTwo: resAddress2,
              AddrCity: resCity,
              AddrState: resState,
              AddrCountry: 'United States',
              AddrCountryCode: resCountry,
              ZipCode: resZipcode,
              UtilityBill: resUtilityBill,
            },
            {
              AddrType: 'Mail Address',
              LineOne: perAddress1,
              LineTwo: perAddress2,
              AddrCity: perCity,
              AddrState: perState,
              AddrCountry: 'United States',
              AddrCountryCode: perCountry,
              ZipCode: perZipcode,
            },
          ],
          ResidentialStatus: residentStatus,
          TimeOfResidenceInYears: residentYears,
          TimeOfResidenceInMonths: residentMonths,
          VouchedIdStatus: idVerificationStatus,
          VouchedJobId: jobId,
          ManualUploadFlag: !!(watchmanualFile && !idVerificationStatus),
          // ManualUploadFile: watchmanualFile && !idVerificationStatus ? watchmanualFile : '',
          ManualUploadFile: '',
          OCRFlag: !!resUtilityBill,
          OCRDocumentId: ocrDocumentId,
        },
      };
      if (userType) {
        formData.InformationOnRequest.RelationshipWithOwner = watchRelationship;
      }
      if (monthlyPayment) {
        formData.InformationOnRequest.MonthlyPayment = currencyToNumber(monthlyPayment);
      }
      const infoPayload = await frameInfoReqPayload({
        formData,
        userType,
        coApplicant: decideCoUsers?.coApplicant,
        coSigner: decideCoUsers?.coSigner,
        createAppApplicationId,
        dealerId: '1234',
        userId: 'co2607',
        step: 1,
        deletePayload,
      });
      try {
        const endpoint = draft ? UrlHelpers.applicationDraft : UrlHelpers.informationReqSave;
        let calculate = await ApiClient.post(
          endpoint,
          { ...infoPayload },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        if (calculate && !draft) {
          setVouchedReturnFlag(false);
          setVouchedData({});
          saveApplicantDetails({
            data: {
              firstName: watchFirstName,
              lastName: watchLastName,
              mobile: watchmobile1,
              areacode: watchAreaCode1,
              mobile2: watchmobile2,
              areacode2: watchAreaCode2,
              email: watchEmail,
            },
            userType,
            activeStep,
            userDetails,
            setUserDetails,
          });
          setValue('dob', null);
          await decideNextUser({
            userType,
            setUserType,
            watchCoApplicant: decideCoUsers?.coApplicant,
            watchCoSigner: decideCoUsers?.coSigner,
            activeStep,
            setActiveStep,
            setValue,
            setLoader,
            loader,
            reviewFlag,
            completedSteps,
            setCompletedSteps,
          });
        } else if (calculate && draft) {
          console.log('draft success', calculate);
          setOpenDraftModal(true);
        }
      } catch (e) {
        console.log('error fetching', e);
      } finally {
        setFormLoader(false);
      }
    }
  };

  return (
    <>
      {showCamera || showIDCamera ? (
        <CameraUpload
          showCamera={showCamera}
          setShowCamera={setShowCamera}
          setOcrCameraFile={setOcrCameraFile}
          setShowIDCamera={setShowIDCamera}
          showIDCamera={showIDCamera}
          setFile={setFile}
          clearErrors={clearErrors}
        />
      ) : (
        <>
          <Loader open={loader} />

          <ApplicationPageHeading />
          {!idVerificationStatus && (
            <Grid container justifyContent="center">
              <Grid container className={customClasses.identityBox}>
                <Grid item lg={4} md={4} sm={3} display="flex" alignItems="flex-end">
                  <img src={IdentityImage} />
                </Grid>
                <Grid item lg={8} md={8} sm={9} px={3}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ fontWeight: '700', paddingTop: '25px' }}
                  >
                    Verify your identity
                  </Typography>
                  <Typography variant="subtitle2" component="p" py={4}>
                    You’ll need to verify your identity to get proceed with new application. Please
                    provide an image of your current government-issued photo ID. The name on the ID
                    should exactly match the name you provide on this application.
                  </Typography>
                  <FileUploadDialog
                    control={control}
                    errors={errors}
                    open={openFileUpload}
                    setOpen={setOpenFileUpload}
                    setError={setError}
                    setValue={setValue}
                    file={file}
                    setFile={setFile}
                    setShowIDCamera={setShowIDCamera}
                    clearErrors={clearErrors}
                  />
                  <Box display="flex" justifyContent="space-between" pb={2} gap={2}>
                    <>
                      <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ maxWidth: '200px' }}
                        onClick={() => setOpenFileUpload(true)}
                      >
                        Upload Manually
                      </Button>
                    </>

                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ maxWidth: '200px' }}
                      // onClick={() => setShowVouched(true)}
                      onClick={() =>
                        navigate('/vouched', {
                          state: {
                            activeStep: 1,
                            watchCoApplicant: Object.entries(watchCoApplicant)?.length
                              ? watchCoApplicant
                              : coApplicant,
                            watchCoSigner: watchCoSigner.length ? watchCoSigner : coSigner,
                            userType,
                            userDetails,
                            formFields: watchAll,
                          },
                        })
                      }
                    >
                      Start ID Verification
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}

          {vouchedStatus === 'failed' && !watchmanualFile && (
            <Typography
              variant="subtitle2"
              component="p"
              color="red"
              textAlign="center"
              sx={{ marginTop: '8px' }}
            >
              The ID verification service is unavailable. Please upload the ID manually and enter
              the details
            </Typography>
          )}

          <Typography
            variant="subtitle2"
            component="p"
            color="red"
            textAlign="center"
            sx={{ marginTop: '8px' }}
          >
            {errors?.manualFile?.message}
          </Typography>

          {file && watchmanualFile && !idVerificationStatus && (
            <Box display="flex" gap={0.5} justifyContent="center" mt={1}>
              <CheckCircleOutlineOutlinedIcon htmlColor="#128A08" />
              <Typography variant="subtitle2" component="p" textAlign="center">
                {file?.name}
              </Typography>
            </Box>
          )}

          <Grid container justifyContent="center">
            <Box sx={{ width: '70%' }}>
              {verifyAlert && (
                <Alert
                  severity="success"
                  onClose={() => {
                    setVerifyAlert(false);
                  }}
                >
                  ID Verification done successfully!
                </Alert>
              )}
              <Box sx={{ width: '60%' }} display="flex" alignItems="center" mt={2.5}>
                <Typography variant="subtitle2" component="p" pr={1} sx={{ fontWeight: '700' }}>
                  ID Verification Status:{' '}
                </Typography>
                <Box display="flex" alignItems="center">
                  {idVerificationStatus ? (
                    <CheckCircleOutlineOutlinedIcon htmlColor="#128A08" />
                  ) : (
                    <CancelOutlinedIcon htmlColor="#D71721" />
                  )}
                  &nbsp;
                  <Typography
                    variant="subtitle2"
                    component="p"
                    color={idVerificationStatus ? '#128A08' : '#D71721'}
                  >
                    {idVerificationStatus ? 'Verified' : 'Not Verified'}
                  </Typography>
                </Box>
              </Box>
              {draftAlert && (
                <Alert
                  sx={{ marginTop: '8px' }}
                  severity="error"
                  onClose={() => {
                    setDraftAlert(false);
                  }}
                >
                  First name and Last name of Primary applicant need to be completed to save the
                  application as draft.
                </Alert>
              )}
              <RelationWithOwner
                control={control}
                errors={errors}
                userType={userType}
                inputRef={inputRef}
                relationshipList={relationshipList}
              />
              <BasicInformation
                control={control}
                errors={errors}
                maritalStatusList={maritalStatusList}
                getValues={getValues}
                setValue={setValue}
                clearErrors={clearErrors}
              />
              <ResidentialAddress
                control={control}
                errors={errors}
                isMailResidentSame={isMailResidentSame}
                setIsMailResidentSame={setIsMailResidentSame}
                addressLookupData={addressLookupData}
                addressLookupDataResponse={addressLookupDataResponse}
                setValue={setValue}
                cityList={cityList}
                showUtility={showUtility}
                setError={setError}
                clearErrors={clearErrors}
                setLoader={setLoader}
                showCamera={showCamera}
                setShowCamera={setShowCamera}
                ocrCameraFile={ocrCameraFile}
                ocrDocumentId={ocrDocumentId}
                setOcrDocumentId={setOcrDocumentId}
                utilityBillList={utilityBillList}
                isResidentAddressOpen={isResidentAddressOpen}
                setIsResidentAddressOpen={setIsResidentAddressOpen}
              />

              {!isMailResidentSame && (
                <MailingAddress
                  control={control}
                  errors={errors}
                  cityList={cityList}
                  addressLookupData={mailAddressLookupData}
                  addressLookupDataResponse={mailAddressLookupDataResponse}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />
              )}

              <ResidentStatus
                control={control}
                errors={errors}
                watch={watch}
                residentStatusList={residentStatusList}
                setValue={setValue}
                clearErrors={clearErrors}
              />
              {!userType && (
                <AddCoUsers
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                  setCoApplicantFlag={setCoApplicantFlag}
                  clearErrors={clearErrors}
                />
              )}
            </Box>
          </Grid>

          <Box display="flex" justifyContent="space-between">
            {userType && (
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: reviewFlag ? 'none' : 'pointer' }}
              >
                <KeyboardArrowLeftIcon color="secondary" sx={{ opacity: reviewFlag ? 0.5 : 1 }} />
                <Link
                  component="button"
                  variant="subtitle2"
                  color="secondary"
                  disabled={reviewFlag}
                  onClick={onBackClick}
                >
                  Back
                </Link>
              </Box>
            )}
            <Box display="flex" gap={2} py={4} marginLeft={!userType && 'auto'}>
              <Link
                component="button"
                variant="subtitle2"
                color="secondary"
                sx={{ fontSize: '16px' }}
              >
                Discard Application
              </Link>
              <Button
                color="secondary"
                variant="outlined"
                onClick={draftClick}
                disabled={isdraftDisabled}
              >
                Save As Draft
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit(handleInfoSubmit)}
                disabled={Object.entries(errors)?.length}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default InformationRequest;
