import { Pagination } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const TablePagination = ({ postsPerPage, totalPosts, paginate }) => {
  const countOfPages = totalPosts / postsPerPage;

  return <Pagination count={countOfPages} onChange={paginate} />;
};

export default TablePagination;

TablePagination.propTypes = {
  postsPerPage: PropTypes.number,
  totalPosts: PropTypes.number,
  paginate: PropTypes.func,
};

TablePagination.defaultProps = {
  postsPerPage: PropTypes.number,
  totalPosts: PropTypes.number,
  paginate: PropTypes.func,
};
