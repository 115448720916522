import { Box, Typography } from '@mui/material';
import AuthContext from 'context/AuthContext';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';

import CustomCollapse from 'components/CustomCollapse';

const EmploymentInformationSection = ({ employmentInformation, label }) => {
  const { setReviewFlag, setActiveStep, setUserType, setReviewData } =
    useContext(MainApplicationContext);
  const { empStatusList, industryList } = useContext(AuthContext);
  const empStatusMapping =
    empStatusList.find(
      (x) => x.value === employmentInformation?.EmploymentInformationDetail?.EmploymentStatus
    )?.name || '--';

  const industryMapping =
    industryList.find(
      (x) => x.value === employmentInformation?.EmploymentInformationDetail?.Industry
    )?.name || '--';

  const onEdit = () => {
    if (label.indexOf('Primary') > -1) {
      setUserType('');
    } else if (label.indexOf('Co-Applicant') > -1) {
      setUserType('coapplicant');
    } else if (label.indexOf('Co-Signer 1') > -1) {
      setUserType('cosigner1');
    } else if (label.indexOf('Co-Signer 2') > -1) {
      setUserType('cosigner2');
    }

    setReviewData(employmentInformation);
    setReviewFlag(true);
    setActiveStep(2);
  };
  return (
    <CustomCollapse
      heading={`Employment Information for ${label}`}
      open={true}
      editable={true}
      onEdit={onEdit}
    >
      <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
        <Typography variant="subtitle2">Employment Status</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {empStatusMapping}
        </Typography>
        <Typography variant="subtitle2">Employer Name Address Line 1</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.Address?.LineOne}
        </Typography>
        <Typography variant="subtitle2">Employer Name Address Line 2</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.Address?.LineTwo}
        </Typography>

        <Typography variant="subtitle2">Country</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.Address?.AddrCountry}
        </Typography>
        <Typography variant="subtitle2">State</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.Address?.AddrState}
        </Typography>
        <Typography variant="subtitle2">City</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.Address?.AddrCity}
        </Typography>
        <Typography variant="subtitle2">Zip Code</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.Address?.ZipCode}
        </Typography>
        <Typography variant="subtitle2">Job Title</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.JobTitle}
        </Typography>
        <Typography variant="subtitle2">Occupation</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.Occupation}
        </Typography>
        <Typography variant="subtitle2">Industry</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {industryMapping}
        </Typography>
        <Typography variant="subtitle2">Monthly Income</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.GrossMonthlyIncome}
        </Typography>
        <Typography variant="subtitle2">Time Under Current Employer Name</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {`${employmentInformation?.EmploymentInformationDetail?.EmploymentTimePeriodYear} Years ${employmentInformation?.EmploymentInformationDetail?.EmploymentTimePeriodMonth} months `}
        </Typography>
        <Typography variant="subtitle2">Work Phone</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.WorkPhone}
        </Typography>
        <Typography variant="subtitle2">Gross Monthly Income</Typography>
        <Typography variant="subtitle2" className={classes.currencyValue}>
          {employmentInformation?.EmploymentInformationDetail?.GrossMonthlyIncome}
        </Typography>
      </Box>
      {employmentInformation?.AdditionalIncomeDetail && (
        <>
          <Typography variant="h6" fontWeight="700 !important" my="20px !important">
            Additional Information
          </Typography>
          <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
            <Typography variant="subtitle2">Employer Name</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {employmentInformation?.AdditionalIncomeDetail?.AddlEmployerName}
            </Typography>
            <Typography variant="subtitle2">Qualification</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {employmentInformation?.AdditionalIncomeDetail?.AddlQualification}
            </Typography>
            <Typography variant="subtitle2">Work Phone</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {employmentInformation?.AdditionalIncomeDetail?.AddlWorkPhone}
            </Typography>
            <Typography variant="subtitle2">Gross Monthly Income</Typography>
            <Typography variant="subtitle2" className={classes.currencyValue}>
              {employmentInformation?.AdditionalIncomeDetail?.AddlGrossMonthlyIncome}
            </Typography>
          </Box>
        </>
      )}
    </CustomCollapse>
  );
};

export default EmploymentInformationSection;
EmploymentInformationSection.propTypes = {
  employmentInformation: PropTypes.instanceOf(Object),
  label: PropTypes.string,
};
EmploymentInformationSection.defaultProps = {
  employmentInformation: {},
  label: '',
};
