import { Box, Grid, InputLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import CustomCollapse from 'components/CustomCollapse';
// import CustomInputBox from 'components/CustomInputBox/CustomInputBox';
import CustomSelectBox from 'components/CustomSelectBox/CustomSelectBox';

import DealerTable from './DealerTable';

const UserProfileForm = ({ control, errors }) => {
  return (
    <div>
      <CustomCollapse heading="User Status" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Team"
              control={control}
              name="team"
              errors={!!errors?.team}
              errorText={errors?.team?.message}
              options={[
                {
                  name: 'DEALER',
                  value: '234675',
                },
                {
                  name: 'DATA ENTRY',
                  value: '125489',
                },
                {
                  name: 'UNDERWRITING',
                  value: '244675',
                },
                {
                  name: 'VENDEROR',
                  value: '123489',
                },
                {
                  name: 'SUPERVISOR',
                  value: '234575',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <CustomSelectBox
              flexDirection="column"
              label="Security Profile"
              control={control}
              name="securityProfile"
              errors={!!errors?.securityProfile}
              errorText={errors?.securityProfile?.message}
              options={[
                {
                  name: 'SALES REPRESENTATIVE',
                  value: '345453545',
                },
                {
                  name: 'DEALER 2',
                  value: '346453545',
                },
                {
                  name: 'Manager 2',
                  value: '345456545',
                },
              ]}
              defaultValue=""
              required
              displayEmpty
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel htmlFor={'Dealer'}>{'User Type'}</InputLabel>
              <Typography sx={{ width: '100%' }}>Dealer Portal</Typography>
            </Box>
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="Dealer Information" open={true}>
        <Grid container rowSpacing={1} columnSpacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
              <InputLabel htmlFor={'Dealer'}>
                <span style={{ color: 'red' }}>*&nbsp;</span> {'Dealer'}
              </InputLabel>
              <Typography sx={{ width: '100%' }}>Dealer</Typography>
            </Box>
          </Grid>
        </Grid>
        <DealerTable />
      </CustomCollapse>
    </div>
  );
};

UserProfileForm.propTypes = {
  control: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
};
UserProfileForm.defaultProps = {
  control: {},
  errors: {},
};
export default UserProfileForm;
