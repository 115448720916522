/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Switch,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from 'assets/svg/Edit-icon.svg';
import RemoveIcon from 'assets/svg/RemoveIcon.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import downArrow from '../assets/downArrow.svg';
import rightArrow from '../assets/rightArrow.svg';
import classes from '../styles/globalStyle.module.scss';

const CustomCollapse = ({
  heading,
  children,
  mailButton,
  checked,
  setIsMailResidentSame,
  setShowAdditionalIncome,
  open,
  removeButton,
  editable,
  onEdit,
  payCalcButton,
  monthlyPayData,
  valueLookupButton,
  setShowCollateralPopup,
}) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(open);
  const navigate = useNavigate();

  const handleClick = () => {
    setIsCollapseOpen(!isCollapseOpen);
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
      checked={checked}
      inputProps={{
        'data-testid': 'toggle-btn',
      }}
      onChange={() => {
        setIsMailResidentSame(!checked);
      }}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#E9E9EA' : '#661c69',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#661c69',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <div className={classes.collapsableDiv}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.heading}
      >
        <Button onClick={handleClick} style={{ padding: '0' }}>
          {isCollapseOpen ? (
            <img src={downArrow} alt="Expand less" />
          ) : (
            <img src={rightArrow} data-testid="ExpandMoreIcon" alt="Expand more" />
          )}
          <Grid container justifyContent="space-between">
            <Typography variant="h6" component="h3" my={2} style={{ fontWeight: 700 }}>
              &nbsp;&nbsp;{heading}
            </Typography>
          </Grid>
        </Button>
        {mailButton && (
          <Typography
            variant="subtitle2"
            component="h3"
            my={2}
            classes={{ root: classes.customToggleText }}
          >
            Mailing address same as the residential address
            <FormGroup>
              <FormControlLabel
                sx={{ margin: '0' }}
                control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
              />
            </FormGroup>
          </Typography>
        )}
        {removeButton && (
          <Link
            component="button"
            variant="subtitle2"
            color="secondary"
            onClick={() => setShowAdditionalIncome(false)}
          >
            <Box display="flex" alignItems="center">
              <img src={RemoveIcon} alt="Remove Button" style={{ marginRight: '8px' }} />
              Remove
            </Box>
          </Link>
        )}
        {payCalcButton && (
          <Button
            variant="contained"
            color="primary"
            sx={{ maxHeight: '40px' }}
            onClick={() =>
              navigate('/paymentCalculator', {
                state: { flow: 'financialTerms', data: monthlyPayData },
              })
            }
          >
            Payment Calculator
          </Button>
        )}
        {valueLookupButton && (
          <Button
            sx={{
              background: '#E37617',
              borderRadius: '5px',
              width: '177px',
              height: '40px',
              color: '#000000',
            }}
            onClick={() => setShowCollateralPopup(true)}
          >
            Value Lookup
          </Button>
        )}
        {editable && (
          <Link component="button" variant="subtitle2" color="secondary" onClick={onEdit}>
            <Box display="flex" alignItems="center">
              <img
                src={EditIcon}
                alt="Edit Button"
                data-testid="edit-icon"
                style={{ marginRight: '8px' }}
              />
              Edit
            </Box>
          </Link>
        )}
      </Box>
      <Collapse in={isCollapseOpen} timeout="auto">
        {children}
      </Collapse>
    </div>
  );
};

export default CustomCollapse;
