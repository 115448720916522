/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import Business from 'assets/svg/business.svg';
import Personal from 'assets/svg/personal.svg';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useContext } from 'react';

import BootstrapDialogTitle from 'components/CustomDialogBox/CustomDialogBox';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';

const ApplicationTypeDialog = ({ reset, control, setValue, watch }) => {
  const { showApptype, setShowAppType, setShowCoApp } = useContext(CreateApplicationContext);
  const { setOpenPopUp, setCreateAppFlag } = useContext(AuthContext);
  const toggleChange = (e, value) => {
    setValue('applicationType', value);
  };
  const handleClose = () => {
    setShowAppType(false);
    setOpenPopUp(false);
    setCreateAppFlag(false);
    reset();
  };
  const watchApplicationType = watch('applicationType');
  const handleContinue = () => {
    setShowAppType(false);
    setShowCoApp(true);
  };
  const applicationType = [
    { valuee: 'personal', displayValue: 'Personal', logo: Personal },
    { valuee: 'business', displayValue: 'Business', logo: Business },
  ];
  return (
    <>
      <Dialog
        data-testid="close_btn"
        open={showApptype}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h3" component="span">
            Create Application
          </Typography>
          <Divider sx={{ backgroundColor: '#F28023', my: 2 }} />
        </BootstrapDialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4" textAlign="center" my={3}>
              What type of application would you like to create?
            </Typography>

            <CustomToggleButtonGroup
              exclusive={true}
              name="applicationType"
              control={control}
              toggleChange={toggleChange}
              toggleList={applicationType}
              disableCheck
              disableValue="Business"
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={() => {
              handleContinue();
            }}
            disabled={!watchApplicationType}
          >
            Ok, Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApplicationTypeDialog;
