import { getAddressLookupData } from 'Services/commonComponentsApi';

export async function fetchAddressLookup({
  address1,
  country,
  query5,
  commonTokenn,
  addressType,
  setAddressLookupData,
  setAddressLookupDataResponse,
  setMailAddressLookupData,
  setMailAddressLookupDataResponse,
}) {
  if (address1?.length >= 6) {
    const response = await getAddressLookupData(country, query5, commonTokenn);
    if (response && response.Results) {
      const options = [];
      response.Results.forEach((result) => {
        const address = result.Address;
        const zipCode = address.PostalCode.split('-')[0];
        options.push(`${address.AddressLine1}, ${address.City}, ${address.State}, ${zipCode}`);
      });
      if (addressType === 'res') setAddressLookupData(options);
      else if (addressType === 'mail') setMailAddressLookupData(options);
    }
    if (addressType === 'res') setAddressLookupDataResponse(response?.Results);
    else if (addressType === 'mail') setMailAddressLookupDataResponse(response?.Results);
  }
}
