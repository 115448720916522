import { Box, Button, Checkbox, Grid, InputLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ApiClass from 'Api/ApiClient';
import AuthContext from 'context/AuthContext';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UrlHelpers } from 'urlHelpers/urlHelper';

import CustomCollapse from 'components/CustomCollapse';
import Loader from 'components/Loader/Loader';

const ViewUserRequestStyles = makeStyles(() => ({
  rejectBtn: {
    background: '#D71721',
    color: '#FFFFFF',
    borderRadius: '5px',
    height: '40px',
    '&:hover': {
      background: '#D71721',
      color: '#FFFFFF',
    },
  },
  approveBtn: {
    background: '#128A08',
    color: '#FFFFFF',
    borderRadius: '5px',
    height: '40px',
    '&:hover': {
      background: '#128A08',
      color: '#FFFFFF',
    },
  },
}));

const ViewUserRequest = () => {
  const { setRejectClicked, setApproveClicked } = useContext(AuthContext);
  const [loader, setLoader] = useState(false);
  const [authorizeCheck, setAuthorizeCheck] = useState(false);
  const [data, setData] = useState({});
  const location = useLocation();
  const { reqId } = location?.state || {};
  const handleCheckboxChange = (e) => {
    setAuthorizeCheck(e.target.checked);
  };
  const ApiClient = new ApiClass();

  const classes = ViewUserRequestStyles();

  useEffect(async () => {
    try {
      setLoader(true);
      const resp = await ApiClient.get(`${UrlHelpers.userRequestStatus}${reqId}`, {
        headers: { 'Content-Type': 'application/json' },
      });

      const { data } = resp;

      setData({ ...data });
    } catch (e) {
      console.log('e', e);
    } finally {
      setLoader(false);
    }
  }, [reqId]);

  return (
    <>
      {loader && <Loader open={loader} />}
      <CustomCollapse heading="Information About Dealer" open={true}>
        <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Dealer</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.dealer}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Dealer Number</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.dealerNumber}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Phone</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.phoneDealer}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Access Type</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.accessTypeDealer}
            </Typography>
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="Information About User" open={true}>
        <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">First Name</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.firstName}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Last Name</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.lastName}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Second Last Name</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data?.secondLastName}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Birth Date</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.dob}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">SSN</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.ssn}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Phone</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.phoneUser}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Email</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.email}
            </Typography>
          </Grid>
        </Grid>
      </CustomCollapse>
      <CustomCollapse heading="Comment" open={true}>
        <Grid container rowSpacing={1} columnSpacing={2} sx={{ rowGap: '8px' }}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important">Comment</Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography fontSize="14px !important" fontWeight="700" color="#661C69">
              {data.comments}
            </Typography>
          </Grid>
        </Grid>
      </CustomCollapse>
      <Box
        sx={{
          height: '50px',
          backgroundColor: '#E6E6E6',
          // opacity: '0.1',
          display: 'flex',
          margin: '10px 0',
          padding: '10px',
        }}
      >
        <Box display="flex" justifyContent={'flex-start'}>
          <Box display="flex" justifyContent={'space-around'} sx={{ gap: '10px' }}>
            <Checkbox
              color="primary"
              onChange={(event) => handleCheckboxChange(event)}
              checked={authorizeCheck}
              data-testid="authorizeCheckbox"
            />
            <Box
              display="flex"
              alignItems="center"
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Typography variant="subtitle2">I authorize the creation of user</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid container rowSpacing={1} columnSpacing={4}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
            <InputLabel>Name</InputLabel>
            <Typography sx={{ width: '100%' }}>John Smith</Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
            <InputLabel>Title</InputLabel>
            <Typography sx={{ width: '100%' }}>Manager</Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box display="flex" alignItems="center" gap={0.625} my={0.5} flexDirection={'column'}>
            <InputLabel>Date</InputLabel>
            <Typography sx={{ width: '100%' }}>{moment().format('MM/DD/YYYY')}</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center" sx={{ margin: '10px 0' }}>
        <Box display="flex" sx={{ gap: '10px' }}>
          <Button
            variant="contained"
            className={classes.rejectBtn}
            onClick={() => setRejectClicked(true)}
            disabled={authorizeCheck}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            className={classes.approveBtn}
            onClick={() => setApproveClicked(true)}
            disabled={!authorizeCheck}
          >
            Approve
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ViewUserRequest;
