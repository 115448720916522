/* eslint-disable react/prop-types */
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import CoApplicant from 'assets/svg/coApplicant.svg';
import CoSigner from 'assets/svg/coSigner.svg';
import AuthContext from 'context/AuthContext';
import CreateApplicationContext from 'context/CreateApplicationContext';
import { useContext, useEffect } from 'react';

import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';

import ApplicationDialogHeading from '../InformationRequest/moduleUtilities/ApplicationDialogHeading';

const CoAppDialog = ({ reset, control, getValues, setValue, watch, setCosignerEnabled }) => {
  const {
    showCoApp,
    setShowCoApp,
    setShowCoAppDet,
    setShowInviManual,
    setShowAppType,
    setIsSkipped,
  } = useContext(CreateApplicationContext);
  const { setOpenPopUp } = useContext(AuthContext);
  const watchApplicantOrSigner = watch('applicantOrSigner');

  const toggleChange = (e, value) => {
    const values = getValues('applicantOrSigner');

    if (!values.includes(value)) {
      values.push(value);
    }
    setValue('applicantOrSigner', value);
  };
  useEffect(() => {
    if (watchApplicantOrSigner.includes('coSigner')) {
      setCosignerEnabled(true);
    } else if (!watchApplicantOrSigner.includes('coSigner')) {
      setCosignerEnabled(false);
    }
  }, [watchApplicantOrSigner]);
  const handleClose = () => {
    setShowCoApp(false);
    setOpenPopUp(false);
    reset();
  };
  const handleContinue = () => {
    if (
      !watchApplicantOrSigner.includes('coSigner') &&
      !watchApplicantOrSigner.includes('coApplicant')
    ) {
      setIsSkipped(true);
      setShowInviManual(true);
      setValue('CoAppFirstName', '');
      setValue('CoAppLastName', '');
      setValue('CoSigner', []);
    } else if (
      !watchApplicantOrSigner.includes('coSigner') &&
      watchApplicantOrSigner.includes('coApplicant')
    ) {
      setIsSkipped(false);
      setShowCoAppDet(true);
      setValue('CoSigner', []);
    } else if (
      watchApplicantOrSigner.includes('coSigner') &&
      !watchApplicantOrSigner.includes('coApplicant')
    ) {
      setIsSkipped(false);
      setShowCoAppDet(true);
      setValue('CoAppFirstName', '');
      setValue('CoAppLastName', '');
    } else {
      setIsSkipped(false);
      setShowCoAppDet(true);
    }
    setShowCoApp(false);
  };
  const handleBack = () => {
    setShowCoApp(false);
    setShowAppType(true);
  };
  const applicantOrSignerList = [
    { valuee: 'coApplicant', displayValue: 'Co-Applicant', logo: CoApplicant },
    { valuee: 'coSigner', displayValue: 'Co-Signer', logo: CoSigner },
  ];
  return (
    <>
      <Dialog
        open={showCoApp}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              minWidth: 'clamp(400px, 50vw, 800px) !important',
              minHeight: 'clamp(450px,60vh,100%) !important',
            },
          },
        }}
      >
        <ApplicationDialogHeading handleBack={handleBack} handleClose={handleClose} />
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
        >
          <Box display="flex" flexDirection="column" gap={2.5} px={5} mb={2.5}>
            <Typography variant="h4" textAlign="center" my={3}>
              What type of application would you like to create?
            </Typography>

            <CustomToggleButtonGroup
              name="applicantOrSigner"
              control={control}
              toggleChange={toggleChange}
              toggleList={applicantOrSignerList}
              exclusive={false}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: '20px', p: '40px' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{ maxHeight: '40px' }}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="secondary"
            autoFocus
            sx={{ maxHeight: '40px' }}
            onClick={handleContinue}
          >
            {watchApplicantOrSigner.includes('coSigner') ||
            watchApplicantOrSigner.includes('coApplicant')
              ? 'Continue'
              : 'Skip & Continue'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CoAppDialog;
