import { Box, Typography } from '@mui/material';
import MainApplicationContext from 'context/MainApplicationContext';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import classes from 'styles/globalStyle.module.scss';
import { numberToCurrency } from 'utils/utility';

import CustomCollapse from 'components/CustomCollapse';

const CollateralInfoSection = ({ collateralInformation }) => {
  const { setReviewFlag, setActiveStep, setReviewData } = useContext(MainApplicationContext);
  const onEdit = () => {
    setReviewData(collateralInformation);
    setReviewFlag(true);
    setActiveStep(3);
  };
  const total =
    +collateralInformation?.VehicleValues?.BaseVehicleValue +
    +collateralInformation?.VehicleValues?.EquipmentAdjustment +
    +collateralInformation?.VehicleValues?.MileageAdjustment;
  return (
    <>
      <CustomCollapse heading="Collateral Information" open={true} editable={true} onEdit={onEdit}>
        <Box display="grid" gridTemplateColumns="60% 40%" gap={1.25}>
          <Typography variant="subtitle2">New</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.ProductDetails?.VehicleNewOrOld === 'True' ? 'Yes' : 'No'}
          </Typography>
          <Typography variant="subtitle2">Vehicle Type</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.ProductDetails?.VehicleType}
          </Typography>
          <Typography variant="subtitle2">Year</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.ProductDetails?.Year}
          </Typography>
          <Typography variant="subtitle2">Brand</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.ProductDetails?.Brand}
          </Typography>
          <Typography variant="subtitle2">Model</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.ProductDetails?.Model}
          </Typography>
          <Typography variant="subtitle2">Sub Model</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.ProductDetails?.SubModel}
          </Typography>
          <Typography variant="subtitle2">Mileage</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.ProductDetails?.Mileage}
          </Typography>
          <Typography variant="subtitle2">VIN</Typography>
          <Typography variant="subtitle2" className={classes.currencyValue}>
            {collateralInformation?.ProductDetails?.Vin}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              padding: '15px 0',
            }}
          >
            {' '}
            Vehicle values
          </Typography>
          <Box display="flex" flexDirection="column" className={classes.tableOutline}>
            <Typography
              variant="subtitle2"
              sx={{
                backgroundColor: '#F2F2F2',
                borderBottom: '1px solid f2f2f2',
                padding: '10px',
                fontWeight: 700,
              }}
            >
              WholeSale
            </Typography>
            <Box display="flex" flexDirection="column" className={classes.childBorderLine}>
              <Box
                display="flex"
                gap={2}
                sx={{ backgroundColor: '#f9f9f9' }}
                p={2}
                className={classes.childBorderLine}
              >
                <Typography variant="subtitle2" sx={{ flexGrow: 1, fontWeight: 700 }}></Typography>

                <Typography
                  variant="subtitle2"
                  sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                >
                  XClean
                </Typography>
              </Box>
              <Box display="flex" gap={2} p={2}>
                <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                  Base Vehicle Value
                </Typography>

                <Typography variant="p" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                  {numberToCurrency(collateralInformation?.VehicleValues?.BaseVehicleValue)}
                </Typography>
              </Box>
              {collateralInformation?.VehicleValues?.EquipmentAdjustment && (
                <Box display="flex" gap={2} p={2}>
                  <Typography variant="subtitle2" sx={{ flexGrow: 1, fontWeight: 700 }}>
                    Equipment Adjustment
                  </Typography>

                  <Typography
                    variant="p"
                    sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                  >
                    {numberToCurrency(collateralInformation?.VehicleValues?.EquipmentAdjustment)}
                  </Typography>
                </Box>
              )}
              {collateralInformation?.VehicleValues?.MileageAdjustment && (
                <Box display="flex" gap={2} p={2}>
                  <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                    Mileage Adjustment
                  </Typography>

                  <Typography variant="p" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                    {numberToCurrency(collateralInformation?.VehicleValues?.MileageAdjustment)}
                  </Typography>
                </Box>
              )}
              {collateralInformation?.VehicleValues?.SelectedEquipmentAdjustment?.length > 0 &&
                collateralInformation?.VehicleValues?.SelectedEquipmentAdjustment?.map(
                  (x, index) => {
                    return (
                      <Box display="flex" gap={2} p={2} key={index}>
                        <Typography variant="subtitle2" sx={{ flexGrow: 1 }}>
                          {x?.Description}
                        </Typography>

                        <Typography variant="p" sx={{ minWidth: '15%', maxWidth: '15%' }}>
                          {numberToCurrency(x?.Retail)}
                        </Typography>
                      </Box>
                    );
                  }
                )}
              <Box display="flex" gap={2} sx={{ backgroundColor: '#f9f9f9' }} p={2}>
                <Typography variant="p" sx={{ flexGrow: 1, fontWeight: 700 }}>
                  Total value
                </Typography>

                <Typography
                  variant="subtitle2"
                  sx={{ minWidth: '15%', maxWidth: '15%', fontWeight: 700 }}
                >
                  {numberToCurrency(total)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomCollapse>
    </>
  );
};

export default CollateralInfoSection;
CollateralInfoSection.propTypes = {
  collateralInformation: PropTypes.instanceOf(Object),
};
CollateralInfoSection.defaultProps = {
  collateralInformation: {},
};
