import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

const CustomToggleButtonGroup = ({
  toggleChange,
  control,
  name,
  toggleList,
  disableCheck,
  disableValue,
  exclusive,
}) => {
  const CircleIcon = (valuee, fieldValue) => {
    if (fieldValue === valuee || fieldValue?.includes(valuee)) {
      return (
        <CheckCircleIcon
          color="primary"
          sx={{ alignSelf: 'flex-start', height: '15px', width: '15px' }}
        />
      );
    }
  };
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <>
            <ToggleButtonGroup
              exclusive={exclusive}
              aria-label={name}
              sx={{ display: 'flex', justifyContent: 'center', gap: '40px' }}
              {...field}
              onChange={toggleChange}
            >
              {toggleList.length > 0 &&
                toggleList.map((x, index) => {
                  const { logo, valuee, displayValue } = x;

                  return (
                    <ToggleButton
                      key={index}
                      value={valuee}
                      aria-label={displayValue}
                      disabled={disableCheck && displayValue === disableValue}
                      data-testid={`${valuee}-button`}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap={1.25}
                        width="128px"
                        alignItems="center"
                      >
                        <img src={logo} height="50px" width="50px" color="primary" />
                      </Box>

                      {CircleIcon(valuee, field.value)}
                    </ToggleButton>
                  );
                })}
            </ToggleButtonGroup>
            {toggleList.length > 0 && (
              <Box display="flex" justifyContent="center" gap="40px">
                {toggleList?.map((x, index) => {
                  const { displayValue } = x;
                  return (
                    <Typography
                      key={index}
                      variant="subtitle1"
                      color="primary"
                      textAlign="center"
                      sx={{
                        width: '120px',
                        opacity: disableCheck && disableValue === displayValue ? '0.4' : 1,
                      }}
                    >
                      {displayValue}
                    </Typography>
                  );
                })}
              </Box>
            )}
          </>
        );
      }}
    />
  );
};

export default CustomToggleButtonGroup;
CustomToggleButtonGroup.propTypes = {
  name: PropTypes.string,
  toggleList: PropTypes.instanceOf(Object),
  toggleChange: PropTypes.func,
  control: PropTypes.instanceOf(Object),
  disableCheck: PropTypes.bool,
  exclusive: PropTypes.bool,
  formats: PropTypes.array,
  disableValue: PropTypes.string,
};
CustomToggleButtonGroup.defaultProps = {
  name: '',
  toggleList: [],
  formats: [],
  toggleChange: () => {},
  control: {},
  disableCheck: false,
  exclusive: false,
  disableValue: '',
};
